import { useState } from "react";
import QuestionCard from "features/lesson/QuestionCard";
import TopicSelector from "features/lesson/TopicSelector";

function StandaloneQuestionCard() {
    const [topicChoices, setTopicChoices] = useState([]);
    const [questionComponent, setQuestionComponent] = useState(null);
    const [showAnswer, setShowAnswer] = useState(false);
    const [calc, setCalc] = useState(false);

    return (
        <div className={"standaloneCard"}>
            <QuestionCard
                questionNum={0}
                isStarter={true}
                title={`Question Preview`}
                answerTitle={`Answer Preview`}
                senBg={undefined}
                questionFont={8}
                subtopic={topicChoices[0] && topicChoices[0].subtopic}
                difficulty={topicChoices[0] && topicChoices[0].difficulty}
                id={topicChoices[0] && topicChoices[0].id}
                setId={(id) =>
                    topicChoices[0] &&
                    setTopicChoices((prevState) => {
                        const copy = [...prevState];
                        copy[0].id = id;
                        return copy;
                    })
                }
                setDifficulty={(diff) =>
                    topicChoices[0] &&
                    setTopicChoices((prevState) => {
                        const copy = [...prevState];
                        copy[0].difficulty = diff;
                        return copy;
                    })
                }
                calculatorAllowed={calc}
                setCalculatorAllowed={setCalc}
                randomSeed={topicChoices[0] && topicChoices[0].randomSeed}
                setRandomSeed={(value) =>
                    topicChoices[0] &&
                    setTopicChoices((prevState) => {
                        const copy = [...prevState];
                        if (typeof value === "function") {
                            copy[0].randomSeed = value(prevState);
                        } else {
                            copy[0].randomSeed = value;
                        }
                        return copy;
                    })
                }
                getNewRandomSeed={() =>
                    topicChoices[0] &&
                    setTopicChoices((prevState) => {
                        const copy = [...prevState];
                        copy[0].randomSeed = Math.floor(Math.random() * 10000);
                        return copy;
                    })
                }
                setStaleEdits={() => {}}
                fullscreen={false}
                questionComponent={questionComponent}
                setQuestionComponent={setQuestionComponent}
                recallTagData={topicChoices[0] && topicChoices[0].recallTag}
                setRecallTagData={(tag) =>
                    topicChoices[0] &&
                    setTopicChoices((prevState) => {
                        const copy = [...prevState];
                        copy[0].recallTag = tag;
                        return copy;
                    })
                }
                hasRecallTags={false}
                showAnswer={showAnswer}
                setShowAnswer={setShowAnswer}
                whiteboardEnabled={false}
                topicSelector={
                    <TopicSelector
                        questionNum={1}
                        isStarter={true}
                        setQuestionTopicChoices={setTopicChoices}
                        initialTopicChoice={topicChoices[0]}
                        setStaleEdits={() => {}}
                    />
                }
            />
        </div>
    );
}

export default StandaloneQuestionCard;
