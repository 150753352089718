import * as React from "react";

const SvgLogOut = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M21 19a2 2 0 0 1-2 2h-9v-8h4.586l-1.293 1.293a1 1 0 0 0 1.414 1.414l3-3a.997.997 0 0 0 .293-.704v-.006a.996.996 0 0 0-.288-.7l.212.32-.218-.325-2.999-3a1 1 0 1 0-1.414 1.415L14.586 11H10V3h9a2 2 0 0 1 2 2v14Z"
            fill="currentColor"
            fillOpacity={0.6}
        />
        <path
            d="M5 21a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5v18H5Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgLogOut;
