import { Flipped, Flipper } from "react-flip-toolkit";
import { Spin } from "antd";
import { OurLibraryCard } from "features/ourLibrary/OurLibraryCard";
import React from "react";

const placeholderImg = (
    <img
        src={process.env.PUBLIC_URL + "svgs/IllustrationOutline.svg"}
        alt={"Outline of a artist's easel with the letters A to D on it"}
    />
);

export function SubTopicCards({
    disabled = false,
    updateKey,
    visibleSubTopics,
    loading,
    selectedSubTopic,
    setSelectedSubTopic,
    addToSelectedStarters
}) {
    return (
        <Flipper flipKey={updateKey} spring={"noWobble"}>
            {visibleSubTopics?.length ? (
                <div className={"our-library__starters_container"}>
                    {disabled && (
                        <div
                            className={
                                "our-library__starters_container__disabled_mask"
                            }
                        />
                    )}
                    {loading ? (
                        <Spin />
                    ) : (
                        visibleSubTopics.map((t) => (
                            <Flipped
                                flipId={t.label}
                                spring={"stiff"}
                                key={t.label}
                            >
                                <OurLibraryCard
                                    title={t.label}
                                    tag={t.topic.label}
                                    colour={t.colour}
                                    selected={selectedSubTopic === t.label}
                                    onClick={() => {
                                        if (disabled) {
                                            return;
                                        }
                                        setSelectedSubTopic(t.label);
                                    }}
                                    disabledDifficulties={t.children
                                        .filter((d) => d.disabled)
                                        .map((d) => d.label)}
                                    onDifficultySelect={(difficulty) => {
                                        if (disabled) {
                                            return;
                                        }
                                        addToSelectedStarters({
                                            subTopic: t,
                                            difficulty: difficulty
                                        });
                                        setSelectedSubTopic(null);
                                    }}
                                />
                            </Flipped>
                        ))
                    )}
                </div>
            ) : (
                <div className={"our-library__content__placeholder"}>
                    {placeholderImg}
                    Check your search term is spelt correctly or try a different
                    one!
                </div>
            )}
        </Flipper>
    );
}
