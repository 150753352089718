import React, { useEffect, useState } from "react";
import { Button, Checkbox, Modal, Radio, Tooltip } from "antd";
import MultiplicationHeader from "./components/MultiplicationHeader";
import {
    CloseOutlined,
    DownloadOutlined,
    QuestionCircleOutlined
} from "@ant-design/icons";
import { apiGet, apiGetPublic } from "helpers/api";
import { TexComponent } from "helpers/questionHelpers";
import { ColourTags } from "features/classTags/ClassNotesModal";
import { useCustomSwitch } from "commonComponents/CustomSwitch";
import { useGetUserQuery } from "features/account/accountSlice";

function PrintableModal({ isModalVisible, setIsModalVisible }) {
    return (
        <Modal
            title={<div className={"sideTitle"}>Printable Student Sheets</div>}
            closable={false}
            open={isModalVisible}
            className={"multiplicationPrintModal"}
            footer={
                <Button
                    type="default"
                    className={"modalClose"}
                    onClick={() => {
                        setIsModalVisible(false);
                    }}
                >
                    Close
                </Button>
            }
            centered
            width={"min(500px, 80%)"}
        >
            <p>
                Pre-made templates you can print out and have students stick in
                the back (or front) of their books.
            </p>
            <p>
                So they are always ready to practise their tables with no fuss
                or rulers!
            </p>
            <Button
                type={"primary"}
                icon={<DownloadOutlined />}
                href={
                    "https://www.mathsplanner.com/wp-content/uploads/2022/10/Quick-Tables-Student-Sheets.pdf"
                }
            >
                Download Printable
            </Button>
            <Button
                type={"primary"}
                ghost={true}
                icon={<DownloadOutlined />}
                href={
                    "https://www.mathsplanner.com/wp-content/uploads/2022/10/Quick-Tables-Student-Sheets-SEN.pdf"
                }
            >
                Download SEN Printable
            </Button>
            <p className={"hint"}>
                We recommend printing double sided and having students glue them
                in with a folded tab to minimize printing and maximise usability{" "}
                <QuestionCircleOutlined />
            </p>
        </Modal>
    );
}

const GRID_WIDTH = 6;

function getRandomRow(negativesEnabled) {
    let numbers = Array.from({ length: 11 }, (v, k) => k + 2);
    let newRow = [];
    for (let i = 0; i < GRID_WIDTH; i++) {
        const index = Math.floor(Math.random() * numbers.length);
        let element = numbers.splice(index, 1)[0];
        if (negativesEnabled && Math.random() < 0.5) {
            element = -element;
        }
        newRow.push(element);
    }
    return newRow;
}

function newRandom(
    setCustomIsActive,
    setNumberChoices,
    setTopRow,
    setLeftCol,
    setShowAnswers,
    negativesEnabled
) {
    setCustomIsActive(false);
    setNumberChoices(null);
    setTopRow(getRandomRow(negativesEnabled));
    setLeftCol(getRandomRow(negativesEnabled));
    setShowAnswers(false);
}

function newCustom(
    setCustomIsActive,
    setNumberChoices,
    setTopRow,
    setLeftCol,
    setShowAnswers,
    negativesEnabled
) {
    setCustomIsActive(true);
    setTopRow((list) =>
        list.map((element) =>
            element && negativesEnabled && Math.random() < 0.5
                ? -element
                : Math.abs(element)
        )
    );
    setLeftCol(getRandomRow(negativesEnabled));
    setShowAnswers(false);
}

const ALLOW_FRACTIONS_FOR_ALL_OVERRIDE = true;

function newAlgebraic(
    user,
    setAlgebraicGridAnswers,
    setTopRow,
    setLeftCol,
    setShowAnswers,
    numVariables,
    negativesEnabled,
    powersEnabled,
    fractionsEnabled
) {
    const getFromApi = user ? apiGet : apiGetPublic;

    getFromApi(
        `/api/algebraic-quick-table?gridWidth=${GRID_WIDTH}&numVars=${numVariables}&negatives=${negativesEnabled}&powers=${powersEnabled}&fractions=${fractionsEnabled}`,
        (body) => {
            setTopRow(body.topRow);
            setLeftCol(body.leftCol);
            setAlgebraicGridAnswers(body.gridAnswers);
            setShowAnswers(false);
        }
    );
}

function MultiplicationActivity({ fullscreen, makeFullscreen }) {
    const { data: user } = useGetUserQuery();
    const [showAnswers, setShowAnswers] = useState(false);
    const [topRow, setTopRow] = useState(Array(GRID_WIDTH).fill(null));
    const [leftCol, setLeftCol] = useState(Array(GRID_WIDTH).fill(null));
    const [algebraicGridAnswers, setAlgebraicGridAnswers] = useState([]);
    const [customIsActive, setCustomIsActive] = useState(false);
    const [showPrintableStarter, setShowPrintableStarter] = useState(false);
    const [numberChoices, setNumberChoices] = useState(null);
    const [numVariables, setNumVariables] = useState(3);
    const [senColour, setSenColour] = useState(null);

    const resetGrid = () => {
        setTopRow(Array(GRID_WIDTH).fill(null));
        setLeftCol(Array(GRID_WIDTH).fill(null));
        setNumberChoices([]);
        setAlgebraicGridAnswers([]);
    };

    const [algebraSwitch, isAlgebraSelected] = useCustomSwitch(
        "NUMBER",
        "ALGEBRA",
        true,
        false,
        () => {
            setNumberChoices([]);
            setAlgebraicGridAnswers([]);
        }
    );
    const [randomSwitch, ordered] = useCustomSwitch(null, "Ordered", false);
    const [powersSwitch, powersEnabled] = useCustomSwitch(
        null,
        "Powers",
        false,
        false
    );
    const [fractionsSwitch, fractionsEnabled] = useCustomSwitch(
        null,
        "Fractions",
        false,
        !ALLOW_FRACTIONS_FOR_ALL_OVERRIDE && Boolean(!user)
    );
    const [negativesSwitch, negativesEnabled] = useCustomSwitch(
        null,
        "Negatives",
        false,
        false
    );

    const hasAllCustomSelected = Boolean(
        numberChoices && numberChoices.length === GRID_WIDTH
    );

    useEffect(() => {
        isAlgebraSelected
            ? newAlgebraic(
                  user,
                  setAlgebraicGridAnswers,
                  setTopRow,
                  setLeftCol,
                  setShowAnswers,
                  numVariables,
                  negativesEnabled,
                  powersEnabled,
                  fractionsEnabled
              )
            : customIsActive && numberChoices?.length === 6
              ? newCustom(
                    setCustomIsActive,
                    setNumberChoices,
                    setTopRow,
                    setLeftCol,
                    setShowAnswers,
                    negativesEnabled
                )
              : (numberChoices?.length === undefined ||
                    numberChoices?.length === 0) &&
                newRandom(
                    setCustomIsActive,
                    setNumberChoices,
                    setTopRow,
                    setLeftCol,
                    setShowAnswers,
                    negativesEnabled
                );
    }, [
        isAlgebraSelected,
        negativesEnabled,
        fractionsEnabled,
        powersEnabled,
        user,
        numVariables,
        numberChoices,
        customIsActive
    ]);

    function addToTopRow(choices) {
        setTopRow((prevState) => {
            let copy = [...prevState].map((s) =>
                isNaN(s?.toString()) ? null : s
            );
            const toAdd = choices.filter((e) => !copy.includes(e));
            const toRemove = copy.filter(
                (e) => e != null && !choices.includes(e)
            );
            toRemove.forEach((number) => {
                const i = copy.indexOf(number);
                copy[i] = null;
            });
            toAdd.forEach((number) => {
                if (!copy.includes(null)) {
                    // topRow is full
                    return;
                }
                let i = 0;
                do {
                    i = Math.floor(Math.random() * copy.length);
                } while (copy[i] != null);
                copy[i] = number;
            });
            return copy;
        });
    }

    const getCellContents = (n, topRow, leftCol) => {
        let sortedTopRow = [...topRow];
        if (ordered) {
            sortedTopRow = sortedTopRow.filter((i) => i);
            sortedTopRow.sort((a, b) => (a || 0) - (b || 0));
        }

        if (n === 0) {
            return <CloseOutlined />;
        } else if (n < 7) {
            return (
                sortedTopRow[n - 1] && (
                    <TexComponent
                        tex={"$" + sortedTopRow[n - 1].toString() + "$"}
                    />
                )
            );
        } else if (n % 7 === 0) {
            return (
                leftCol[n / 7 - 1] && (
                    <TexComponent
                        tex={"$" + leftCol[n / 7 - 1].toString() + "$"}
                    />
                )
            );
        } else if (!isAlgebraSelected) {
            const col = (n % 7) - 1;
            const row = Math.floor(n / 7) - 1;
            const answer = sortedTopRow[col] * leftCol[row];
            return sortedTopRow[col] && leftCol[row] ? (
                <TexComponent tex={"$" + answer.toString() + "$"} />
            ) : (
                ""
            );
        } else {
            const col = (n % 7) - 1;
            const row = Math.floor(n / 7) - 1;
            return algebraicGridAnswers?.[col + row * GRID_WIDTH] ? (
                <TexComponent
                    tex={
                        "$" +
                        algebraicGridAnswers[
                            col + row * GRID_WIDTH
                        ].toString() +
                        "$"
                    }
                />
            ) : (
                ""
            );
        }
    };
    const multiplicationTooltipColour = "#08979c";

    // useEffect(() => {
    //     if (isAlgebraSelected) {
    //         newAlgebraic()
    //     } else {
    //         if (customIsActive) {
    //             newCustom();
    //         } else {
    //             newRandom();
    //         }
    //     }
    // }, [
    //     negativesEnabled,
    //     isAlgebraSelected,
    //     newAlgebraic,
    //     newCustom,
    //     newRandom
    // ]);

    const grid = (
        <div className={"grid " + (fullscreen ? "fullscreen" : "")}>
            {Array.from({ length: 49 }, (v, k) => k).map((n) => (
                <div
                    key={n}
                    className={
                        "gridCell " +
                        (!showAnswers ? "hideAnswers " : "") +
                        (isAlgebraSelected ? "algebraic " : "") +
                        (n < 7 ? "headerCell " : "") +
                        (n % 7 === 0 ? "sideCell " : "") +
                        (senColour || "")
                    }
                >
                    {getCellContents(n, topRow, leftCol)}
                </div>
            ))}
        </div>
    );

    const numberOptions = (
        <>
            <div className={"sideBox"}>
                <h3>Grid options</h3>
                {randomSwitch}
                {negativesSwitch}
                <ColourTags
                    colour={senColour}
                    onClick={setSenColour}
                    includeNone
                />
            </div>
            <div className={"sideBox"}>
                <h3>Give me anything</h3>
                <Button
                    type={"primary"}
                    disabled={hasAllCustomSelected}
                    onClick={() =>
                        newRandom(
                            setCustomIsActive,
                            setNumberChoices,
                            setTopRow,
                            setLeftCol,
                            setShowAnswers,
                            negativesEnabled
                        )
                    }
                >
                    RANDOMISE GRID
                </Button>
            </div>
            <div className={"sideBox"}>
                <h3>Customise</h3>
                <p
                    className={hasAllCustomSelected ? "success" : "error"}
                >{`Choose ${GRID_WIDTH}`}</p>
                <Checkbox.Group
                    className={"checkboxes"}
                    options={Array.from({ length: 12 }, (v, k) => k + 1).map(
                        (k) => ({
                            key: k,
                            label: k,
                            value: k
                        })
                    )}
                    value={numberChoices}
                    onChange={async (list) => {
                        if (list.length <= GRID_WIDTH) {
                            setLeftCol(Array(GRID_WIDTH).fill(null));
                            setNumberChoices(list);
                            addToTopRow(list);
                            if (list.length < GRID_WIDTH) {
                                setCustomIsActive(false);
                            } else {
                                setCustomIsActive(true);
                            }
                        }
                    }}
                />
                <Tooltip
                    title={
                        hasAllCustomSelected
                            ? null
                            : `Select ${GRID_WIDTH} Numbers First`
                    }
                    color={multiplicationTooltipColour}
                    placement="right"
                >
                    <Button
                        type={"primary"}
                        disabled={!hasAllCustomSelected}
                        onClick={() =>
                            newCustom(
                                setCustomIsActive,
                                setNumberChoices,
                                setTopRow,
                                setLeftCol,
                                setShowAnswers,
                                negativesEnabled
                            )
                        }
                    >
                        CREATE CUSTOM
                    </Button>
                </Tooltip>
                <Button
                    type={"text"}
                    disabled={!numberChoices || numberChoices.length === 0}
                    onClick={() => {
                        setNumberChoices([]);
                        resetGrid();
                    }}
                >
                    Reset
                </Button>
            </div>
        </>
    );

    const algebraOptions = (
        <>
            <div className={"sideBox"}>
                <div className={"radioButtonsContainer narrowControl"}>
                    <span>Number of variables</span>
                    <Radio.Group
                        options={Array.from({ length: 4 }, (v, k) => k + 1)}
                        value={numVariables}
                        onChange={(e) => {
                            setNumVariables(e.target.value);
                            newAlgebraic(
                                user,
                                setAlgebraicGridAnswers,
                                setTopRow,
                                setLeftCol,
                                setShowAnswers,
                                e.target.value,
                                negativesEnabled,
                                powersEnabled,
                                fractionsEnabled
                            );
                        }}
                    />
                </div>
                {negativesSwitch}
                {powersSwitch}
                {fractionsSwitch}
                <ColourTags
                    colour={senColour}
                    onClick={setSenColour}
                    includeNone
                />
                <Button
                    type={"primary"}
                    onClick={() =>
                        newAlgebraic(
                            user,
                            setAlgebraicGridAnswers,
                            setTopRow,
                            setLeftCol,
                            setShowAnswers,
                            numVariables,
                            negativesEnabled,
                            powersEnabled,
                            fractionsEnabled
                        )
                    }
                >
                    GENERATE
                </Button>
            </div>
        </>
    );

    const sidePanel = (
        <div className={"sidePanel"}>
            <div className={"sideTitle"}>
                {(isAlgebraSelected ? "Algebraic" : "Numeric") +
                    " Quick Tables"}
            </div>
            <div className={"sidePanelContent"}>
                {algebraSwitch}
                {isAlgebraSelected ? algebraOptions : numberOptions}
                <PrintableModal
                    isModalVisible={showPrintableStarter}
                    setIsModalVisible={setShowPrintableStarter}
                />
                <Button
                    type={"text"}
                    onClick={() => setShowPrintableStarter(true)}
                >
                    PRINT STUDENT SHEETS (BLANK)
                </Button>
            </div>
        </div>
    );

    return (
        <div
            className={
                "multiplicationContainer main " +
                (fullscreen ? "fullscreen" : "")
            }
        >
            {!fullscreen && (
                <span>{/* Dummy component for grid layout */}</span>
            )}

            {!fullscreen && sidePanel}

            <MultiplicationHeader
                fullscreen={fullscreen}
                makeFullscreen={makeFullscreen}
                showAnswers={showAnswers}
                setShowAnswers={setShowAnswers}
                newCallback={() =>
                    isAlgebraSelected
                        ? newAlgebraic(
                              user,
                              setAlgebraicGridAnswers,
                              setTopRow,
                              setLeftCol,
                              setShowAnswers,
                              numVariables,
                              negativesEnabled,
                              powersEnabled,
                              fractionsEnabled
                          )
                        : customIsActive
                          ? newCustom(
                                setCustomIsActive,
                                setNumberChoices,
                                setTopRow,
                                setLeftCol,
                                setShowAnswers,
                                negativesEnabled
                            )
                          : newRandom(
                                setCustomIsActive,
                                setNumberChoices,
                                setTopRow,
                                setLeftCol,
                                setShowAnswers,
                                negativesEnabled
                            )
                }
            />

            {grid}
        </div>
    );
}

export default MultiplicationActivity;
