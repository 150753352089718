import React, { useEffect, useState } from "react";
import { apiGet } from "helpers/api";
import { Button, Spin, Typography } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import * as dayjs from "dayjs";

export function TrialEndDate({ unixTimestamp }) {
    if (!unixTimestamp) {
        return null;
    }
    const date = dayjs.unix(parseInt(unixTimestamp));
    const dateString = date.format("D MMM. YYYY");
    const daysRemaining = date.diff(dayjs(), "days");
    if (daysRemaining <= 0) {
        return <Typography.Text type={"danger"}>{dateString}</Typography.Text>;
    } else if (daysRemaining <= 30) {
        return <Typography.Text type={"warning"}>{dateString}</Typography.Text>;
    } else {
        return dateString;
    }
}

export function RenewalDate({ defaultDateString, stripeId }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [stripeDateString, setStripeDateString] = useState(null);

    useEffect(() => {
        if (!loading) {
            return;
        }
        setError(null);
        apiGet(
            `/api/user-stripe-renewal-date?stripeId=${stripeId}`,
            (body) => {
                setStripeDateString(body?.renewalDate);
                setLoading(false);
            },
            (err) => {
                setError(err);
                setLoading(false);
            }
        );
    }, [stripeId, loading]);

    if (!defaultDateString && !stripeId) {
        return null;
    }
    if (loading) {
        return <Spin />;
    }
    if (error) {
        return (
            <p style={{ color: "red", fontSize: "0.8em" }}>
                Error: {error.message}
            </p>
        );
    }
    if (!defaultDateString && !stripeDateString) {
        return (
            <Button
                type={"text"}
                icon={<DownloadOutlined />}
                onClick={() => setLoading(true)}
            />
        );
    }
    const dateString = String(defaultDateString || stripeDateString);
    const date = new Date(dateString);
    const daysRemaining = Math.round(
        (date.getTime() - new Date().getTime()) / (1000 * 3600 * 24)
    );
    if (daysRemaining <= 0) {
        return <Typography.Text type={"danger"}>{dateString}</Typography.Text>;
    } else if (daysRemaining <= 30) {
        return <Typography.Text type={"warning"}>{dateString}</Typography.Text>;
    } else {
        return dateString;
    }
}
