import React from "react";
import { Typography } from "antd";

const { Title, Text } = Typography;

function DripForm() {
    return (
        <form
            action="https://www.getdrip.com/forms/670371684/submissions"
            method="post"
            data-drip-embedded-form="670371684"
            id="drip-ef-670371684"
        >
            <div data-drip-attribute="description" />

            <div>
                <label htmlFor="drip-first-name">First Name</label>
                <input
                    type="text"
                    id="drip-first-name"
                    name="fields[first_name]"
                />
            </div>
            <div>
                <label htmlFor="drip-email">School Email Address</label>
                <input type="email" id="drip-email" name="fields[email]" />
            </div>
            <div style={{ display: "none" }} aria-hidden="true">
                <label htmlFor="website">Website</label>
                <input
                    type="text"
                    id="website"
                    name="website"
                    tabIndex="-1"
                    autoComplete="false"
                />
            </div>

            <input
                type="hidden"
                name="tags[]"
                id="tags_"
                value="Quick Tables Mobile Lead"
                tabIndex="-1"
            />
            <div>
                <input
                    type="submit"
                    value="Send me the link"
                    data-drip-attribute="sign-up-button"
                />
            </div>
        </form>
    );
}

function MobileIntercept({
    name = "Maths Planner",
    text,
    videoTitle,
    showForm = false,
    showEmails = false,
    embedID = "yDH4WWqo8_M"
}) {
    return (
        <div id={"mobile-intercept"}>
            <div className={"img-container"}>
                <img
                    src={
                        process.env.PUBLIC_URL +
                        "/svgs/Maths_planner_desktop_background.svg"
                    }
                    alt="Illustration of desktop computer with the Maths Planner logo on it."
                />
            </div>
            <div className={"text-container"}>
                <Title>{name} is designed for desktop and whiteboard use</Title>
                <Text>{text}</Text>
            </div>

            {showEmails && (
                <div className={"text-container"}>
                    <Text>
                        <b>Need help? </b> Get in touch
                    </Text>
                    <a href={"mailto:henry@mathsplanner.com"}>
                        henry@mathsplanner.com
                    </a>
                    <a href={"mailto:chloe@mathsplanner.com"}>
                        chloe@mathsplanner.com
                    </a>
                </div>
            )}

            {showForm && (
                <div className={"text-container"}>
                    <DripForm />
                </div>
            )}

            <div className={"text-container"}>
                <Title level={2}>{videoTitle}</Title>
                <div className={"videoContainer"}>
                    <iframe
                        width="100%"
                        height="800"
                        src={`https://www.youtube.com/embed/${embedID}?rel=0`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </div>
            </div>
        </div>
    );
}

export default MobileIntercept;
