import React, { useCallback, useContext, useEffect, useState } from "react";
import { CloseOutlined, LoginOutlined } from "@ant-design/icons";
import {
    getSubscriptionType,
    isEnvironmentContentCreationOnly,
    isEnvironmentFreeResourcesOnly,
    isUserAdmin,
    isUserAwaitingPayment,
    login,
    paymentModalVisible,
    useLoginState,
    userHasMockPapersAccess
} from "helpers/security";
import { Divider, Drawer, Spin, Tag, Tooltip } from "antd";
import PaymentModal from "features/account/PaymentModal";
import { withCookies } from "react-cookie";
import ClassNotesModal from "features/classTags/ClassNotesModal";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import DashboardSettingsModal from "features/dashboard/DashboardSettingsModal";
import { getStepIndex, TourContext } from "features/tour/Tour";
import SvgLogOut from "assets/icons/LogOut";
import SvgDashboard from "assets/icons/DashboardIcon";
import SvgDashboardIcon from "assets/icons/DashboardIcon";
import SvgClassNotesIcon from "assets/icons/ClassNotesIcon";
import SvgNewStarterIcon from "assets/icons/NewStarterIcon";
import SvgQuickTablesIcon from "assets/icons/QuickTablesIcon";
import SvgTimetableIcon from "assets/icons/TimetableIcon";
// import SvgTimetableIconAlt from "assets/icons/TimetableIconAlt";
import SvgIconSettingsTwo from "assets/icons/IconSettings2";
import SvgTakeTourIcon from "assets/icons/TakeTourIcon";
import MpLogoIcon from "assets/icons/MpLogoIcon";
import SvgAccountBillingIcon from "assets/icons/AccountBillingIcon";
import SvgStarterDefaultsIcon from "assets/icons/StarterDefaultsIcon";
import SvgDashboardSettingsIcon from "assets/icons/DashboardSettingsIcon";
import SvgSubAccountsIcon from "assets/icons/SubAccountsIcon";
import SvgAdminIcon from "assets/icons/AdminIcon";
import SvgUserMetricsIcon from "assets/icons/UserMetricsIcon";
import SvgRequestFeatureIcon from "assets/icons/RequestFeatureIcon";
import SvgReportIssueIcon from "assets/icons/ReportIssueIcon";
import { useGetClassesQuery } from "features/classTags/classesSlice";
import RoundedButton from "commonComponents/RoundedButton";
import ClassesListModal from "features/classTags/ClassesListModal";
import {
    useGetUserQuery,
    useLogOutUserMutation
} from "features/account/accountSlice";
import { setShowNewSaveView } from "features/dashboard/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { tooltipColour } from "helpers/starterHelpers";
import ExternalResourcesModal from "commonComponents/ExternalResourcesModal";
import { MOCK_PAPERS_PATH } from "app/App";
import SvgManyAccountsIcon from "assets/icons/ManyAccountsIcon";
import SvgTimetableIconAlt from "assets/icons/TimetableIconAlt";
import MockPapersAdvertModal from "features/mockPapers/MockPapersAdvertModal";

function SideBarDrawer({ children, visible, setVisible, clearSelected }) {
    const [isHidden, setIsHidden] = useState(true);

    const tourContext = useContext(TourContext);

    useEffect(() => {
        if (visible) {
            setIsHidden(false);
        } else {
            setTimeout(() => {
                setIsHidden(true);
                clearSelected();
            }, 200);
        }
    }, [visible, clearSelected]);

    return (
        <div
            className={"sidebarDrawersWrapper"}
            style={{
                position: "relative",
                visibility: isHidden ? "hidden" : "visible"
            }}
        >
            <Drawer
                placement={"left"}
                open={visible}
                closable={!tourContext.isOpen}
                closeIcon={
                    <>
                        Collapse
                        <CloseOutlined />
                    </>
                }
                mask={true}
                maskClosable={!tourContext.isOpen}
                onClose={() => setVisible(false)}
                getContainer={false}
                style={{ position: "absolute" }}
                className={
                    "sidebarDrawer " + (tourContext.isOpen && "tourOpen")
                }
                width={"100%"}
            >
                <div className="feature-btns drawer">{children}</div>
            </Drawer>
        </div>
    );
}

function SideBar({ sideEffectsOnly = false, cookies }) {
    const history = useHistory();
    const { data: user, isFetching: loadingUser } = useGetUserQuery();
    const isLoggedIn = useLoginState();
    const tourContext = useContext(TourContext);
    const isContentEnvironment = isEnvironmentContentCreationOnly();
    const isFreeResourcesEnvironment = isEnvironmentFreeResourcesOnly();
    const { data: classes } = useGetClassesQuery(undefined, {
        skip: isFreeResourcesEnvironment
    });

    const [showDrawer, setShowDrawer] = useState(false);
    const [selected, setSelectedItem] = useState(null);
    const clearSelected = () => {
        setSelectedItem(null);
        setShowDrawer(false);
    };
    const toggleSelected = (name, showDrawer = true) => {
        clearSelected();
        if (selected !== name) {
            setSelectedItem(name);
            setShowDrawer(showDrawer);
        }
    };
    const SelectedOptions = {
        SETTINGS: 1,
        CLASSES: 2,
        RESOURCES: 3
    };
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [showClassesModal, setShowClassesModal] = useState(false);
    const [showDashboardSettingsModal, setShowDashboardSettingsModal] =
        useState(false);
    const [showResourcesModal, setShowResourcesModal] = useState(false);
    const [showMockPapersSalesModal, setShowMockPapersSalesModal] =
        useState(false);

    const location = useLocation();
    const [csrfToken] = useState(cookies.get("XSRF-TOKEN"));

    const dispatch = useDispatch();
    const [logout] = useLogOutUserMutation();
    const inNewSaveView = useSelector(
        (state) => state.dashboard.showNewSaveView
    );
    const showNewStarterView = useCallback(
        () => dispatch(setShowNewSaveView(true)),
        [dispatch]
    );

    const admin = isUserAdmin(user);
    const awaitingPayment = isUserAwaitingPayment(user);
    const subscriptionTypeEnumValue = getSubscriptionType(user);
    const isSiteLicence =
        subscriptionTypeEnumValue === "Site" ||
        subscriptionTypeEnumValue === "SubSite";
    const isSiteAdmin =
        subscriptionTypeEnumValue === "Site" ||
        subscriptionTypeEnumValue === "Admin";
    const isSubSiteLicence = subscriptionTypeEnumValue === "SubSite";
    const showPaymentModal = paymentModalVisible(user);
    const hasMockPapersAccess = userHasMockPapersAccess(user);

    useEffect(() => {
        if (!tourContext.containsModalClose("sideBarDrawer")) {
            tourContext.addTourModalClose("sideBarDrawer", () =>
                setShowDrawer(false)
            );
        }
    }, [tourContext]);

    if (isContentEnvironment) {
        return (
            <>
                <div
                    className={"sidebar " + (selected && "extended")}
                    data-testid="sideBar"
                >
                    <div className="feature-btns">
                        <a href={"/"}>
                            <div className="logo">
                                <MpLogoIcon className="mainlogo" />
                            </div>
                        </a>
                        <Divider
                            orientation="left"
                            plain
                            className="sidebar-divider"
                        />
                        <ul>
                            {admin && (
                                <li>
                                    <a href={"/admin"}>
                                        <SvgDashboard className={"menuIcon"} />
                                        <p>Questions</p>
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="account-btns">
                        {!isLoggedIn ? (
                            <ul>
                                <li>
                                    {/* eslint-disable-next-line*/}
                                    <a onClick={login}>
                                        <LoginOutlined className="menuIcon" />
                                        <p>Log In</p>
                                    </a>
                                </li>
                            </ul>
                        ) : (
                            <ul>
                                <li>
                                    {/* eslint-disable-next-line*/}
                                    <a
                                        onClick={() => {
                                            if (!tourContext.isOpen) {
                                                window.location.href =
                                                    "/settings/account";
                                            }
                                        }}
                                    >
                                        <SvgIconSettingsTwo className="menuIcon" />
                                        <p>Account</p>
                                    </a>
                                </li>
                                <li>
                                    {/* eslint-disable-next-line*/}
                                    <a onClick={() => logout(csrfToken)}>
                                        <SvgLogOut className={"menuIcon"} />
                                        <p>Log Out</p>
                                    </a>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </>
        );
    }

    const freeResourcesOptions = [
        {
            label: "Quick Tables",
            path: "/quick-tables",
            logo: <SvgQuickTablesIcon className={"menuIcon"} />
        },
        {
            label: "Skills Check",
            path: "/skills",
            logo: <SvgQuickTablesIcon className={"menuIcon"} />,
            disabled: "Coming soon"
        },
        {
            label: "Custom Starters",
            path: "https://www.mathsplanner.com",
            logo: <SvgNewStarterIcon className={"menuIcon"} />
        },
        {
            label: "Exit Tickets",
            path: "https://www.mathsplanner.com",
            logo: <SvgNewStarterIcon className={"menuIcon"} />
        }
    ];

    if (isFreeResourcesEnvironment) {
        return (
            <>
                <div
                    className={"sidebar " + (selected && "extended")}
                    data-testid="sideBar"
                >
                    <div className="feature-btns">
                        <a href={"/"}>
                            <div className="logo">
                                <MpLogoIcon className="mainlogo" />
                            </div>
                        </a>
                        <Divider
                            orientation="left"
                            plain
                            className="sidebar-divider"
                        />
                        <ul>
                            {freeResourcesOptions.map((opt, i) =>
                                !opt.disabled ? (
                                    <li
                                        key={`link-${i}`}
                                        className={
                                            matchPath(location.pathname, {
                                                path: opt.path,
                                                exact: false
                                            }) && "current"
                                        }
                                    >
                                        <a href={opt.path}>
                                            {opt?.logo}
                                            <p>{opt.label}</p>
                                        </a>
                                    </li>
                                ) : (
                                    <Tooltip
                                        title={opt.disabled}
                                        color={tooltipColour}
                                        key={`link-${i}`}
                                    >
                                        <li className={"disabled"}>
                                            {/* eslint-disable-next-line */}
                                            <a>
                                                {opt?.logo}
                                                <p className={"disabled"}>
                                                    {opt.label}
                                                </p>
                                            </a>
                                        </li>
                                    </Tooltip>
                                )
                            )}
                        </ul>
                    </div>
                </div>
            </>
        );
    }

    const standardOptions = (
        <>
            <li
                className={
                    (inNewSaveView ? "selected" : "") +
                    " " +
                    (matchPath(location.pathname, {
                        path: "/starter",
                        exact: false
                    }) && "current")
                }
            >
                {/* eslint-disable-next-line*/}
                <a
                    onClick={() => {
                        /* use of history prevents re-load if already on dashboard */
                        history.push("/dashboard");
                        showNewStarterView();
                    }}
                >
                    <SvgNewStarterIcon className="menuIcon" /> <p>New Lesson</p>
                </a>
            </li>
            <li
                className={
                    matchPath(location.pathname, {
                        path: "/multiplication",
                        exact: false
                    }) && "current"
                }
            >
                {/* eslint-disable-next-line*/}
                <a
                    onClick={() => {
                        if (!tourContext.isOpen) {
                            window.location.href = "/multiplication";
                        }
                    }}
                >
                    <SvgQuickTablesIcon className="menuIcon" />
                    <p>Quick Tables</p>
                </a>
            </li>
            <li
                className={
                    selected === SelectedOptions.CLASSES && showDrawer
                        ? "selected"
                        : ""
                }
            >
                {/* eslint-disable-next-line*/}
                <a onClick={() => toggleSelected(SelectedOptions.CLASSES)}>
                    <SvgClassNotesIcon className="menuIcon" />
                    <p>Classes</p>
                </a>
            </li>
            <li
                className={
                    matchPath(location.pathname, {
                        path: MOCK_PAPERS_PATH,
                        exact: false
                    }) && "current"
                }
            >
                {/* eslint-disable-next-line*/}
                <a
                    onClick={() => {
                        if (loadingUser) {
                            return;
                        }
                        if (hasMockPapersAccess) {
                            window.location.href = MOCK_PAPERS_PATH;
                        } else {
                            setShowMockPapersSalesModal(true);
                        }
                    }}
                >
                    {loadingUser ? (
                        <Spin />
                    ) : (
                        <SvgTimetableIconAlt className="menuIcon" />
                    )}
                    <p>Classroom Mocks</p>
                </a>
            </li>
        </>
    );

    return (
        <>
            {user && showPaymentModal && (
                <PaymentModal
                    isVisible={showPaymentModal}
                    isSingleLicence={!isSiteLicence}
                    isSubSiteLicence={isSubSiteLicence}
                    awaitingPayment={awaitingPayment}
                />
            )}
            <ClassNotesModal
                classId={showNotesModal}
                isModalVisible={Boolean(showNotesModal)}
                setIsModalVisible={setShowNotesModal}
                onLoadStarter={() => setShowNotesModal(false)}
            />
            <ClassesListModal
                isOpen={showClassesModal}
                setIsOpen={setShowClassesModal}
            />
            <DashboardSettingsModal
                isModalVisible={showDashboardSettingsModal}
                setIsModalVisible={setShowDashboardSettingsModal}
            />
            <ExternalResourcesModal
                isVisible={showResourcesModal}
                setIsModalVisible={setShowResourcesModal}
                onClose={() => setSelectedItem(null)}
            />
            <MockPapersAdvertModal
                visible={showMockPapersSalesModal}
                onClose={() => setShowMockPapersSalesModal(false)}
            />

            <div
                className={`sidebar ${selected && "extended"} ${
                    sideEffectsOnly ? "hidden" : ""
                }`}
                data-testid="sideBar"
            >
                <div className="feature-btns">
                    <a href={"/dashboard"}>
                        <div className="logo">
                            <MpLogoIcon className="mainlogo" />
                        </div>
                    </a>
                    <Divider
                        orientation="left"
                        plain
                        className="sidebar-divider"
                    />
                    <ul>
                        <li
                            data-tour={"dashboardButton"}
                            className={
                                matchPath(location.pathname, {
                                    path: "/dashboard",
                                    exact: false
                                }) && "current"
                            }
                        >
                            {/* eslint-disable-next-line*/}
                            <a
                                onClick={() => {
                                    if (tourContext.isOpen) {
                                        if (
                                            tourContext.currentStep ===
                                            getStepIndex("backToDashboard")
                                        ) {
                                            clearSelected();
                                            tourContext.nextStep();
                                        }
                                    } else {
                                        window.location.href = "/dashboard";
                                    }
                                }}
                            >
                                <SvgTimetableIcon className={"menuIcon"} />
                                <p>Timetable</p>
                            </a>
                        </li>
                        {!matchPath(location.pathname, {
                            path: MOCK_PAPERS_PATH,
                            exact: false
                        }) && standardOptions}
                    </ul>
                </div>
                <div className="account-btns">
                    {!isLoggedIn ? (
                        <ul>
                            <li>
                                {/* eslint-disable-next-line*/}
                                <a onClick={login}>
                                    <LoginOutlined className="menuIcon" />
                                    <p>Log In</p>
                                </a>
                            </li>
                        </ul>
                    ) : (
                        <ul>
                            <li
                                className={
                                    tourContext?.isOpen ? "disabled" : ""
                                }
                            >
                                <a
                                    href={
                                        "https://www.mathsplanner.com/premium-app-how-to-tutorials/"
                                    }
                                    target={"_blank"}
                                    rel="noreferrer"
                                    disabled={tourContext?.isOpen}
                                >
                                    <SvgDashboardIcon className="menuIcon" />
                                    <p>How-to Videos</p>
                                </a>
                            </li>
                            {!matchPath(location.pathname, {
                                path: MOCK_PAPERS_PATH,
                                exact: false
                            }) && (
                                <li
                                    className={
                                        tourContext?.isOpen ? "disabled" : ""
                                    }
                                >
                                    {/* eslint-disable-next-line*/}
                                    <a
                                        onClick={() => {
                                            if (!tourContext?.isOpen) {
                                                tourContext.startTour();
                                            }
                                        }}
                                        disabled={tourContext?.isOpen}
                                    >
                                        <SvgTakeTourIcon className="menuIcon" />
                                        <p>Take Tour</p>
                                    </a>
                                </li>
                            )}
                            <li
                                className={
                                    (selected === SelectedOptions.SETTINGS &&
                                    showDrawer
                                        ? "selected"
                                        : "") +
                                    " " +
                                    ((matchPath(location.pathname, {
                                        path: "/settings",
                                        exact: false
                                    }) ||
                                        matchPath(location.pathname, {
                                            path: "/admin",
                                            exact: false
                                        })) &&
                                        "current")
                                }
                                data-tour={"settingsOpen"}
                            >
                                {/* eslint-disable-next-line*/}
                                <a
                                    onClick={() => {
                                        if (tourContext.isOpen) {
                                            if (
                                                tourContext.currentStep ===
                                                getStepIndex("settingsOpen")
                                            ) {
                                                toggleSelected(
                                                    SelectedOptions.SETTINGS
                                                );
                                                tourContext.nextStep();
                                            }
                                        } else {
                                            toggleSelected(
                                                SelectedOptions.SETTINGS
                                            );
                                        }
                                    }}
                                >
                                    <SvgIconSettingsTwo className="menuIcon" />
                                    <p>Settings</p>
                                </a>
                            </li>
                        </ul>
                    )}
                </div>
            </div>

            <SideBarDrawer
                visible={showDrawer}
                setVisible={setShowDrawer}
                clearSelected={clearSelected}
            >
                {selected === SelectedOptions.SETTINGS && (
                    <ul>
                        <li>
                            {/* eslint-disable-next-line*/}
                            <a
                                onClick={() => {
                                    if (!tourContext.isOpen) {
                                        window.location.href =
                                            "/settings/account";
                                    }
                                }}
                            >
                                <SvgAccountBillingIcon className="menuIcon" />
                                <p>
                                    {!isSubSiteLicence
                                        ? "Account / Billing"
                                        : "Account"}
                                </p>
                            </a>
                        </li>
                        <li data-tour={"starterDefaultsOpen"}>
                            {/* eslint-disable-next-line*/}
                            <a
                                onClick={() => {
                                    if (tourContext.isOpen) {
                                        if (
                                            tourContext.currentStep ===
                                            getStepIndex("starterDefaultsOpen")
                                        ) {
                                            clearSelected();
                                            tourContext.nextStep();
                                        }
                                    } else {
                                        window.location.href =
                                            "/settings/starter";
                                    }
                                }}
                            >
                                <SvgStarterDefaultsIcon className="menuIcon" />
                                <p>Template Defaults</p>
                            </a>
                        </li>
                        <li>
                            {/* eslint-disable-next-line*/}
                            <a
                                onClick={() => {
                                    if (!tourContext.isOpen) {
                                        setShowDashboardSettingsModal(true);
                                        clearSelected();
                                    }
                                }}
                            >
                                <SvgDashboardSettingsIcon className="menuIcon" />
                                <p>Timetable Settings</p>
                            </a>
                        </li>
                        {isSiteAdmin && (
                            <li>
                                <a href={"/settings/sub-users"}>
                                    <SvgSubAccountsIcon className="menuIcon" />
                                    <p>Sub-Accounts</p>
                                </a>
                            </li>
                        )}
                        {admin && (
                            <li>
                                <a href={"/admin/accounts"}>
                                    <SvgManyAccountsIcon className="menuIcon" />
                                    <p>User Accounts</p>
                                </a>
                            </li>
                        )}
                        {admin && (
                            <li>
                                <a href={"/admin"}>
                                    <SvgAdminIcon className="menuIcon" />
                                    <p>Content Admin</p>
                                </a>
                            </li>
                        )}
                        {isSiteAdmin && (
                            <li>
                                <a href={"/settings/metrics"}>
                                    <SvgUserMetricsIcon className="menuIcon" />
                                    <p>User Metrics</p>
                                </a>
                            </li>
                        )}
                        <li>
                            <a
                                href={
                                    "https://mathsplanner.com/suggest-feature"
                                }
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                <SvgRequestFeatureIcon className="menuIcon" />
                                <p>Request a Feature</p>
                            </a>
                        </li>
                        <li>
                            <a
                                href={"https://mathsplanner.com/report-issue"}
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                <SvgReportIssueIcon className="menuIcon" />
                                <p>Report an Issue</p>
                            </a>
                        </li>
                        <li>
                            {/* eslint-disable-next-line*/}
                            <a onClick={() => logout(csrfToken)}>
                                <SvgLogOut className={"menuIcon"} />
                                <p>Log Out</p>
                            </a>
                        </li>
                    </ul>
                )}
                {selected === SelectedOptions.CLASSES && (
                    <div className={"classesDrawerContainer"}>
                        <div className={"tagMenuItem"}>
                            <RoundedButton
                                type={"primary"}
                                onClick={() => setShowClassesModal(true)}
                            >
                                + New Class
                            </RoundedButton>
                        </div>
                        {classes && classes.length > 0 ? (
                            classes.map((c) => (
                                <div
                                    className={"tagMenuItem"}
                                    key={"class-item-" + c.id}
                                >
                                    <div
                                        className={"tagWrapper clickable"}
                                        onClick={() => {
                                            setShowNotesModal(c.id);
                                            clearSelected();
                                        }}
                                    >
                                        <Tag color={c.colour}>
                                            <span>{c.name}</span>
                                        </Tag>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div
                                style={{
                                    width: "80%",
                                    textAlign: "center",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    paddingTop: "3rem"
                                }}
                            >
                                Add some classes to use class tags.
                            </div>
                        )}
                    </div>
                )}
            </SideBarDrawer>
        </>
    );
}

export default withCookies(SideBar);
