import React, { createContext } from "react";
import { matchPath, withRouter } from "react-router-dom";
import Reactour from "reactour";
import { Button, List, Popover, Spin, Steps, Tag } from "antd";
import {
    ArrowLeftOutlined,
    CloseCircleOutlined,
    InfoCircleOutlined,
    PlusOutlined
} from "@ant-design/icons";
import SvgIconLink from "assets/icons/IconLink";
import SvgIconSettings from "assets/icons/IconSettings";
import SvgIconPrint from "assets/icons/IconPrint";
import SvgIconSave from "assets/icons/IconSave";
import SvgIconEdit from "assets/icons/IconEdit";
import SvgIconReload from "assets/icons/IconReload";
import SvgIconPlay from "assets/icons/IconPlay";

const GIF_WIDTH = "500px";
// const SMALL_GIF_WIDTH = "200px";
const MEDIUM_GIF_WIDTH = "350px";

const steps = (
    pathname,
    history,
    nextStep,
    prevStep,
    endTour,
    userIsSiteAdmin
) =>
    [
        {
            name: "tourStart",
            selector: "[data-tour='tourStart']",
            content:
                "Welcome to Maths Planner! To show you around, we've created a short tour"
        },
        {
            name: "tourStartRedirect",
            action: () => {
                if (pathname !== "/dashboard") {
                    history.push("/dashboard");
                } else {
                    nextStep();
                }
            },
            disableActions: true,
            content: <Spin />,
            hidden: true
        },
        {
            name: "newStarter",
            selector: "[data-tour='newStarter']",
            content:
                "First let's create a new starter by clicking on one of the empty cells and selecting Custom Starter...",
            delay: 1000,
            stepInteraction: true
        },
        {
            name: "renameDashboardStarter",
            selector: ".tourHighlightRename",
            resizeObservables: [".timetableRenamePopover"],
            mutationObservables: [".timetableRenamePopover"],
            highlightedSelectors: [".timetableRenamePopover"],
            content:
                "Then you can give your starter a name. How about 'My first starter'?",
            delay: 300,
            stepInteraction: true
        },
        {
            name: "openDashboardStarter",
            selector: ".tourHighlightRename",
            resizeObservables: [".tourHighlightRename"],
            mutationObservables: [".tourHighlightRename"],
            content: "Next click on the starter to open it up",
            delay: 300,
            stepInteraction: true
        },
        {
            name: "starterRedirect",
            action: () => {
                if (pathname !== "/starter") {
                    history.push("/starter");
                } else {
                    nextStep();
                }
            },
            disableActions: true,
            content: <Spin />,
            hidden: true
        },
        {
            name: "gridSettingsModal",
            selector: ".gridSettingsModal",
            resizeObservables: [".gridSettingsModal"],
            mutationObservables: [".gridSettingsModal"],
            position: "top",
            content: "Here you can change the settings for your new starter. ",
            hasNext: true,
            delay: 1000
        },
        {
            name: "gridSizeSwitch",
            selector: "[data-tour='gridSizeSwitch']",
            content: "Let's set the grid size.",
            hasNext: true,
            stepInteraction: true
        },
        // {
        //     name: "recallSwitch",
        //     selector: "[data-tour='recallSwitch']",
        //     content: (
        //         <>
        //             <p>... then let's turn on recall tags ...</p>
        //
        //             <div className={"tourGifContainer small"}>
        //                 <img
        //                     src={toggleGIF}
        //                     alt={
        //                         "Animation demonstrating how to turn on a toggle switch"
        //                     }
        //                 />
        //             </div>
        //         </>
        //     ),
        //     style: { maxWidth: SMALL_GIF_WIDTH },
        //     stepInteraction: true
        // },
        {
            name: "settingsClose",
            selector: "[data-tour='settingsClose']",
            content: "Now we can close the settings panel.",
            delay: 300,
            stepInteraction: true
        },
        {
            name: "title",
            selector: "[data-tour='title']",
            mutationObservables: ["[data-tour='title']"],
            content: "You can add a starter title by typing above",
            hasNext: true,
            action: (node) => node?.focus(),
            stepInteraction: true
        },
        {
            name: "recallTagOpen",
            selector: "[data-tour='recallTag']",
            content: (
                <>
                    <p>Next, select a recall tag by clicking on this... </p>

                    <div className={"tourGifContainer"}>
                        <img
                            src={process.env.PUBLIC_URL + "/svgs/recallTag.gif"}
                            alt={
                                "Animation demonstrating how to select a recall tag"
                            }
                        />
                    </div>
                </>
            ),
            style: { maxWidth: MEDIUM_GIF_WIDTH },
            stepInteraction: true
        },
        {
            name: "recallTagSelect",
            selector: "[data-tour='q1']",
            content: (
                <>
                    <p>... and selecting your new tag. </p>

                    <div className={"tourGifContainer"}>
                        <img
                            src={process.env.PUBLIC_URL + "/svgs/recallTag.gif"}
                            alt={
                                "Animation demonstrating how to select a recall tag"
                            }
                        />
                    </div>
                </>
            ),
            style: { maxWidth: GIF_WIDTH },
            resizeObservables: [".questionCardTags"],
            mutationObservables: [".questionCardTags"],
            highlightedSelectors: [".questionCardTags"],
            updateDelay: 500,
            delay: 500,
            stepInteraction: true
        },
        {
            name: "firstQuestion",
            selector: "[data-tour='q1']",
            content:
                "There are 2 ways to find topics for your starter questions... ",
            hasNext: true
        },
        {
            name: "firstQuestionTopicOpen",
            selector: "[data-tour='q1TopicSelector']",
            content: (
                <>
                    <p>
                        ...one is to click through each sub-topic and then hover
                        over a difficulty to preview the question type you’re
                        after...
                    </p>

                    <p>For example:</p>
                    <div className={"tourGifContainer"}>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/svgs/questionSelect2.gif"
                            }
                            alt={
                                "Animation demonstrating how to select a question with the topic buttons"
                            }
                        />
                    </div>
                </>
            ),
            hasNext: true,
            style: { maxWidth: GIF_WIDTH },
            action: (node) => node?.focus(),
            stepInteraction: false
        },
        // {
        //     name: "firstQuestionTopicSelect",
        //     selector: "[data-tour='q1']",
        //     content: (
        //         <>
        //             <p>
        //                 Try selecting 'Number' > 'Four Operations' > 'Addition'.{" "}
        //             </p>
        //
        //             <div className={"tourGifContainer"}>
        //                 <img
        //                     src={questionSelectClickGIF}
        //                     alt={
        //                         "Animation demonstrating how to select a question with the topic buttons"
        //                     }
        //                 />
        //             </div>
        //
        //             If you hover over a difficulty button you can see a preview of the questions. Go ahead and pick one!
        //         </>
        //     ),
        //     style: { maxWidth: GIF_WIDTH },
        //     // resizeObservables: [".topicsPopup"],
        //     // mutationObservables: [".topicsPopup"],
        //     // highlightedSelectors: [".topicsPopup"],
        //     delay: 150,
        //     stepInteraction: true
        // },
        // {
        //     name: "firstQuestionTopicHover",
        //     selector: "[data-tour='q1']",
        //     content:
        //         "If you hover over a difficulty button you can see a preview of " +
        //         "the questions. Go ahead and pick one!",
        //     resizeObservables: [
        //         ".topicsPopup",
        //         ".ant-popover:not(.ant-popover-hidden)"
        //     ],
        //     mutationObservables: [
        //         ".topicsPopup",
        //         ".ant-popover:not(.ant-popover-hidden)"
        //     ],
        //     highlightedSelectors: [".ant-popover:not(.ant-popover-hidden)", "[data-tour='q2']"],
        //     delay: 150,
        //     stepInteraction: true
        // },
        {
            name: "secondQuestionTopicSearch",
            selector: "[data-tour='q2TopicSelector']",
            content: (
                <>
                    <p>
                        The second route is simply typing into the search bar.
                    </p>

                    <p>For example:</p>

                    <div className={"tourGifContainer"}>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/svgs/questionSelect3.gif"
                            }
                            alt={
                                "Animation demonstrating how to select a question with the search bar"
                            }
                        />
                    </div>
                </>
            ),
            style: { maxWidth: GIF_WIDTH },
            resizeObservables: [".topicsPopup"],
            mutationObservables: [".topicsPopup"],
            hasNext: true,
            // highlightedSelectors: [".topicsPopup"],
            // action: (node) => node?.focus(),
            stepInteraction: false
        },
        // {
        //     name: "secondQuestionTopicSelect",
        //     selector: ".topicsPopup",
        //     content: (
        //         <>
        //             <p>... and pick one of the questions with a difficulty. </p>
        //
        //             <div className={"tourGifContainer"}>
        //                 <img
        //                     src={questionSelectSearchGIF}
        //                     alt={
        //                         "Animation demonstrating how to select a question with the search bar"
        //                     }
        //                 />
        //             </div>
        //         </>
        //     ),
        //     style: { maxWidth: GIF_WIDTH },
        //     resizeObservables: [".topicsPopup"],
        //     mutationObservables: [".topicsPopup"],
        //     delay: 150,
        //     stepInteraction: true
        // },
        // {
        //     name: "questionBack",
        //     selector: "[data-tour='questionBack']",
        //     content: "You can change a question with the back button.",
        //     stepInteraction: true
        // },
        {
            name: "questionExperimenting",
            selector: "[data-tour='questionGrid']",
            content:
                "Feel free to experiment with adding questions and " +
                "recall tags. When you are ready to continue with the " +
                "tour click 'Next'. ",
            hasNext: true,
            position: "top",
            delay: 300,
            stepInteraction: true
        },
        {
            name: "extensionOpen",
            selector: "[data-tour='extensionOpen']",
            content:
                "As well as the visible questions, you can open an extension " +
                "question with the button below.",
            stepInteraction: true
        },
        {
            name: "extensionAutomatic",
            selector: "[data-tour='extensionAutomatic']",
            content:
                "You can either select an extension question using the same method " +
                "as the others, or we can generate one for you based on the other " +
                "questions in the starter. Give it a try!",
            delay: 500,
            stepInteraction: true
        },
        {
            name: "extensionClose",
            selector: ".extension.modal .ant-modal-close",
            content:
                "Next let's close the extension and move on to the exit ticket...",
            stepInteraction: true
        },
        {
            name: "plenaryRedirect",
            action: () => {
                if (
                    !matchPath(pathname, {
                        path: "/starter",
                        exact: false
                    })
                ) {
                    history.push(encodeURI("/starter"));
                } else {
                    nextStep();
                }
            },
            disableActions: true,
            content: <Spin />,
            hidden: true
        },
        {
            name: "plenaryOpen",
            selector: "[data-tour='plenaryOpen']",
            content: "You can switch to the exit ticket here.",
            delay: 1000,
            stepInteraction: true
        },
        {
            name: "plenaryQuestions",
            selector: "[data-tour='plenaryQuestions']",
            content:
                "Exit Ticket questions are chosen using the same methods as starters. " +
                "Click 'Next' and we'll fill them in for you. ",
            delay: 3000,
            hasNext: true
        },
        {
            name: "autoStarter",
            selector: "[data-tour='autoStarter']",
            content: (
                <>
                    <p>
                        Once you've made an exit card and written your feedback
                        statements, you'll be able to print these ready for a
                        handout.
                    </p>
                    <p>
                        From here, to successfully complete the feedback loop,
                        you can click the ‘Auto Generate Starter’ button above
                        to create next lesson's starter based on your exit card.
                        Give it a try!
                    </p>
                </>
            ),
            stepInteraction: true
        },
        {
            name: "autoStarterResult",
            selector: "[data-tour='questionGrid']",
            content: (
                <>
                    <p>
                        The generated starter covers the same topics and ability
                        levels as the exit card questions with the addition of a
                        challenge question for each topic.
                    </p>
                    <p>
                        Allowing your students to act on the feedback you’ve
                        provided them (DIT) whilst simultaneously encouraging
                        them to try a higher difficulty question.
                    </p>
                </>
            ),
            hasNext: true,
            position: "top",
            delay: 1000
        },
        {
            name: "timerRedirect",
            action: () => {
                if (
                    !matchPath(pathname, {
                        path: "/starter/auto",
                        exact: false
                    })
                ) {
                    history.push(
                        encodeURI(
                            '/starter/auto?topics=[["Addition","Medium"]]&num=4'
                        )
                    );
                } else {
                    nextStep();
                }
            },
            disableActions: true,
            content: <Spin />,
            hidden: true
        },
        {
            name: "timer",
            selector: "[data-tour='timer']",
            content: (
                <>
                    <p>
                        {"The timer length can be changed with the "}{" "}
                        <SvgIconEdit />
                        {" button and restarted with the "} <SvgIconReload />
                        {" button. "}
                    </p>
                    <p>
                        {"When the timer is started with the "} <SvgIconPlay />{" "}
                        {" button, the window will enter " +
                            "fullscreen mode. Give it a try!"}
                    </p>
                </>
            ),
            resizeObservables: [".timerPopover:not(.ant-popover-hidden)"],
            mutationObservables: [".timerPopover:not(.ant-popover-hidden)"],
            highlightedSelectors: [".timerPopover:not(.ant-popover-hidden)"],
            delay: 1000,
            stepInteraction: true
        },
        {
            name: "fullscreen",
            selector: "[data-tour='fullscreen']",
            content:
                "In fullscreen mode the side menu is hidden to make more space for questions. ",
            hasNext: true,
            delay: 500
        },
        {
            name: "exitFullscreen",
            selector: "[data-tour='exitFullscreen']",
            content: "To exit fullscreen click this button",
            stepInteraction: true
        },
        {
            name: "starterSettings",
            selector: "[data-tour='starterSettings']",
            content: (
                <>
                    <p>
                        Along with the fullscreen button, there are also buttons
                        to:
                    </p>
                    <List size="small">
                        <List.Item>
                            <SvgIconLink style={{ marginRight: "4px" }} />
                            Copy link of a saved or pre-generated starter
                        </List.Item>
                        <List.Item>
                            {" "}
                            <SvgIconSettings style={{ marginRight: "4px" }} />
                            Open starter & exit ticket settings
                        </List.Item>
                        <List.Item>
                            <SvgIconPrint style={{ marginRight: "4px" }} />
                            Print as sheets{" "}
                            <Popover
                                trigger={"click"}
                                content={
                                    "You can print both the starter and/or plenary with 1-2 copies per sheet, as well " +
                                    "as print blank question templates for students to use with future starters."
                                }
                                zIndex={10000002}
                                overlayStyle={{ maxWidth: "400px" }}
                            >
                                <InfoCircleOutlined />
                            </Popover>
                        </List.Item>
                        <List.Item>
                            <SvgIconSave style={{ marginRight: "4px" }} />
                            Save the starter/exit ticket
                        </List.Item>
                    </List>
                </>
            ),
            hasNext: true
        },
        {
            name: "showSingleAnswer",
            selector: "[data-tour='showSingleAnswer']",
            content:
                "The answer to a question can be shown and hidden with this button",
            stepInteraction: true
        },
        {
            name: "showAllAnswers",
            selector: "[data-tour='showAllAnswers']",
            content:
                "And the answer to all questions can be shown and hidden with this button",
            stepInteraction: true
        },
        {
            name: "whiteboardOpen",
            selector: "[data-tour='whiteboardOpen']",
            content: "This button opens the question in whiteboard mode",
            stepInteraction: true
        },
        {
            name: "whiteboard",
            selector: ".sketchModal",
            resizeObservables: [".sketchModal"],
            mutationObservables: [".sketchModal"],
            content: (
                <>
                    <p>
                        Whiteboard mode allows you to write out
                        workings/diagrams to explain questions.
                    </p>
                    <p>
                        Feel free to experiment with the whiteboard, and when
                        you are ready to continue, click the X button in the top
                        right.
                    </p>
                    <div className={"tourGifContainer"}>
                        <img
                            src={
                                process.env.PUBLIC_URL + "/svgs/whiteboard.gif"
                            }
                            alt={"Animation demonstrating whiteboard mode"}
                        />
                    </div>
                </>
            ),
            style: { maxWidth: GIF_WIDTH },
            delay: 600,
            stepInteraction: true
        },
        {
            name: "saveOpen",
            selector: "[data-tour='saveOpen']",
            content: "Now let's save this starter.",
            delay: 300,
            stepInteraction: true
        },
        {
            name: "saveDetails",
            selector: "[data-tour='saveDetails']",
            resizeObservables: [
                ".newSavePopover:not(.ant-popover-hidden)",
                ".timetableColumnPopover:not(.ant-select-dropdown-hidden)"
            ],
            mutationObservables: [
                ".newSavePopover:not(.ant-popover-hidden)",
                ".timetableColumnPopover:not(.ant-select-dropdown-hidden)"
            ],
            highlightedSelectors: [
                ".newSavePopover:not(.ant-popover-hidden)",
                ".timetableColumnPopover:not(.ant-select-dropdown-hidden)"
            ],
            content: "Give the starter a memorable name then click 'Save'.",
            delay: 700,
            stepInteraction: true
        },
        {
            name: "backToDashboard",
            selector: "[data-tour='dashboardButton']",
            content:
                "Now let's head back to the dashboard and check out your timetable!",
            stepInteraction: true
        },
        {
            name: "dashboardRedirect",
            action: () => {
                if (pathname !== "/dashboard") {
                    history.push("/dashboard");
                } else {
                    nextStep();
                }
            },
            disableActions: true,
            content: <Spin />,
            hidden: true
        },
        {
            name: "saveLocation",
            selector: "[data-tour='dashboardTimetable']",
            content: (
                <>
                    <p>
                        Here you can reorder your saves and drag them to
                        different days. Feel free to give it a go!{" "}
                    </p>

                    <div className={"tourGifContainer"}>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/svgs/draggable_Save.gif"
                            }
                            alt={
                                "Animation demonstrating dragging a save around the timetable"
                            }
                        />
                    </div>
                </>
            ),
            position: [40, 40],
            style: { maxWidth: GIF_WIDTH },
            hasNext: true,
            delay: 2000,
            stepInteraction: true
        },
        {
            name: "allSavesOpen",
            selector: "[data-tour='savesDrawerButton']",
            content: "Click this button to open the 'All Saves' drawer.",
            stepInteraction: true
        },
        {
            name: "allSaves",
            selector: ".app-container",
            content: (
                <>
                    <p>
                        Once the drawer is open, you can drag saves to and from
                        it. Give it a go!{" "}
                    </p>

                    <div className={"tourGifContainer"}>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/svgs/savesDrawerDrag.gif"
                            }
                            alt={
                                "Animation demonstrating dragging a save to the saves drawer"
                            }
                        />
                    </div>
                </>
            ),
            style: { maxWidth: GIF_WIDTH },
            hasNext: true,
            delay: 300,
            stepInteraction: true
        },
        {
            name: "allSavesClose",
            selector: "[data-tour='savesDrawerButton']",
            content: "You can close the drawer with the same button.",
            stepInteraction: true
        },
        // {
        //     name: "saveOptions",
        //     selector: "[data-tour='saveOptions']",
        //     content:
        //         "Each save has options to delete it, duplicate it, rename it " +
        //         "and change its class tag. ",
        //     hasNext: true,
        //     resizeObservables: [".moreOptionsPopover:not(.ant-popover-hidden)"],
        //     mutationObservables: [
        //         ".moreOptionsPopover:not(.ant-popover-hidden)"
        //     ],
        //     highlightedSelectors: [
        //         ".moreOptionsPopover:not(.ant-popover-hidden)"
        //     ],
        //     delay: 400
        // },
        {
            name: "newClassTagRedirect",
            action: () => {
                if (pathname !== "/dashboard") {
                    history.push("/dashboard");
                } else {
                    nextStep();
                }
            },
            disableActions: true,
            content: <Spin />,
            hidden: true
        },
        {
            name: "newClassTag",
            selector: "[data-tour='newClassTag']",
            content: "Now let's create a new class.",
            delay: 1000,
            stepInteraction: true
        },
        {
            name: "classTagDetails",
            selector: "[data-tour='classTagDetails']",
            mutationObservables: [
                "[data-tour='classTagDetails']",
                ".colourSelectDropdown:not(.ant-select-dropdown-hidden)"
            ],
            resizeObservables: [
                "[data-tour='classTagDetails']",
                ".colourSelectDropdown:not(.ant-select-dropdown-hidden)"
            ],
            highlightedSelectors: [
                ".colourSelectDropdown:not(.ant-select-dropdown-hidden)"
            ],
            content: "Enter the details and click save. E.g. 'Y11-A'",
            delay: 300,
            stepInteraction: true
        },
        {
            name: "classTagAdd",
            selector: "[data-tour='dashboardTimetable']",
            content: (
                <>
                    <p>
                        Then you can tag your save with the new class by
                        clicking the{" "}
                        <Tag
                            className="addClassTag"
                            style={{ cursor: "default" }}
                        >
                            <PlusOutlined /> Class
                        </Tag>{" "}
                        button. Feel free to try it out.
                    </p>
                    <div className={"tourGifContainer"}>
                        <img
                            src={process.env.PUBLIC_URL + "/svgs/classTags.gif"}
                            alt={
                                "Animation demonstrating how to select a class tag"
                            }
                        />
                    </div>
                </>
            ),
            style: { maxWidth: MEDIUM_GIF_WIDTH },
            hasNext: true,
            stepInteraction: true
        },
        {
            name: "classTagOpen",
            selector: "[data-tour='classTagOpen']",
            content: "This button opens the class notes window.",
            stepInteraction: true
        },
        {
            name: "classModal",
            selector: ".classNotes",
            resizeObservables: [".classNotes"],
            mutationObservables: [".classNotes"],
            content:
                "Here you can can write notes for your class and see all saves " +
                "tagged with that class. Feel free to try it out. When you are ready " +
                "to continue, click the X button in the top right.",
            delay: 700,
            stepInteraction: true
        },
        {
            name: "settingsOpen",
            selector: "[data-tour='settingsOpen']",
            content: "Finally, let's visit your settings",
            delay: 200,
            stepInteraction: true
        },
        {
            name: "starterDefaultsOpen",
            selector: "[data-tour='starterDefaultsOpen']",
            content: ".. and click here to set your template defaults.",
            delay: 300,
            stepInteraction: true
        },
        {
            action: () => {
                if (pathname !== "/settings/starter") {
                    history.push("/settings/starter");
                } else {
                    nextStep();
                }
            },
            disableActions: true,
            content: <Spin />,
            hidden: true
        },
        {
            name: "starterDefaults",
            selector: "[data-tour='starterDefaults']",
            content:
                "The settings you select here, along with the recall tags will " +
                "be used as the defaults whenever you create a new starter. ",
            hasNext: true,
            delay: 1000
        },
        {
            name: "subUsersOpen",
            selector: "[data-tour='settingsSubUsersButton']",
            content:
                "Finally, click here to manage your department members' accounts.",
            hidden: !userIsSiteAdmin,
            stepInteraction: userIsSiteAdmin,
            action: () => {
                if (!userIsSiteAdmin) {
                    nextStep();
                }
            }
        },
        {
            name: "subUsersRedirect",
            disableActions: true,
            content: <Spin />,
            hidden: true,
            action: () => {
                if (!userIsSiteAdmin) {
                    nextStep();
                } else {
                    if (pathname !== "/settings/sub-users") {
                        history.push("/settings/sub-users");
                    } else {
                        nextStep();
                    }
                }
            }
        },
        {
            name: "subUsers",
            selector: "[data-tour='settingsSubUsers']",
            content:
                "Here is where you can add new accounts for your department members.",
            hidden: !userIsSiteAdmin,
            action: () => {
                if (!userIsSiteAdmin) {
                    nextStep();
                }
            },
            hasNext: true,
            delay: 1000
        },
        {
            name: "tourEnd",
            action: () => {
                if (pathname !== "/dashboard") {
                    history.push("/dashboard");
                } else {
                    nextStep();
                }
            },
            disableActions: true,
            content: <Spin />,
            hidden: true
        },
        {
            name: "tourEndContent",
            selector: "[data-tour='tourEnd']",
            content: (
                <>
                    <p>
                        And that completes the tour. We hope you enjoy
                        MathsPlanner and its time saving features!{" "}
                    </p>
                    <p>
                        You can retake the tour at any time from the side bar,
                        and more help and videos can be found on our{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={"https://www.mathsplanner.com/guides"}
                        >
                            guides & tutorials page
                        </a>
                        .
                    </p>
                </>
            ),
            noPrevious: true,
            delay: 1000
        }
    ].map((step, i, steps) => {
        const hasPrevious =
            steps[i > 0 ? i - 1 : null]?.hasNext && !step?.noPrevious;
        return {
            ...step,
            content: (
                <>
                    <div>{step.content}</div>
                    {!step.hidden && (
                        <div
                            className={
                                "tourButtons " + (hasPrevious && "hasBack")
                            }
                        >
                            {hasPrevious && (
                                <Button
                                    onClick={prevStep}
                                    className={"tourBackButton"}
                                    type={"text"}
                                    icon={<ArrowLeftOutlined />}
                                />
                                // Back
                                // </Button>
                            )}
                            {step.hasNext && (
                                <Button type={"primary"} onClick={nextStep}>
                                    {(userIsSiteAdmin &&
                                        step.name === "subUsers") ||
                                    (!userIsSiteAdmin &&
                                        step.name === "starterDefaults")
                                        ? "Finish Tour"
                                        : "Next"}
                                </Button>
                            )}
                            {i === 0 && (
                                <Button type={"primary"} onClick={nextStep}>
                                    Start Tour!
                                </Button>
                            )}
                            {i === steps.length - 1 && (
                                <Button type={"primary"} onClick={endTour}>
                                    Get Started!
                                </Button>
                            )}
                        </div>
                    )}
                </>
            )
        };
    });

const localSteps = steps(
    null,
    null,
    () => {},
    () => {},
    () => {},
    false
);

export const getStepIndex = (name) =>
    localSteps.map((s) => s.name || "").indexOf(name);

const unHiddenIndexes = localSteps.reduce((filtered, step, i) => {
    if (!step?.hidden) {
        filtered.push(i);
    }
    return filtered;
}, []);

export const sectionStarts = [
    {
        step: "tourStartRedirect",
        name: "Starter Creation"
    },
    {
        step: "plenaryRedirect",
        name: "Exit Tickets"
    },
    {
        step: "timerRedirect",
        name: "Using Starters"
    },
    {
        step: "dashboardRedirect",
        name: "Your Timetable"
    },
    {
        step: "newClassTagRedirect",
        name: "Class Tags"
    },
    {
        step: "settingsOpen",
        name: "Application Settings"
    },
    {
        step: "tourEnd",
        name: "Exit Tour"
    }
].map((s, i) => ({ ...s, number: i, index: getStepIndex(s.step) }));

export const getNextSection = (stepIndex) =>
    stepIndex < getStepIndex("tourEnd")
        ? sectionStarts.find((section) => section.index > stepIndex)
        : null;

export const getStepNumber = (index) =>
    index < localSteps.length
        ? unHiddenIndexes.includes(index)
            ? unHiddenIndexes.indexOf(index)
            : getStepNumber(index + 1)
        : -1;

export const tourDelays = localSteps.map((s) => s.delay || 0);

export const TourContext = createContext({});

const Tour = withRouter(
    ({
        isOpen,
        closeTour,
        curr,
        nextStep,
        setSection,
        prevStep,
        userIsSiteAdmin,
        isFullscreen,
        location: { pathname },
        history
    }) => {
        return (
            <>
                <Reactour
                    disableKeyboardNavigation={false}
                    disableDotsNavigation={true}
                    inViewThreshold={300}
                    showButtons={false}
                    showPrevNextButtons={false}
                    showCloseButton={false}
                    showNavigation={false}
                    disableFocusLock={true}
                    disableInteraction={true}
                    closeWithMask={false}
                    scrollSmooth={true}
                    badgeContent={() => "i"}
                    steps={steps(
                        pathname,
                        history,
                        nextStep,
                        prevStep,
                        closeTour,
                        userIsSiteAdmin
                    )}
                    isOpen={isOpen}
                    onRequestClose={closeTour}
                    update={pathname}
                    goToStep={curr}
                    maskClassName={"tourMask"}
                />
                {isOpen && (
                    <div
                        className={
                            "tourStepsContainer " +
                            ((isFullscreen ||
                                curr === getStepIndex("allSaves") ||
                                curr === getStepIndex("allSavesClose")) &&
                                "fullscreen")
                        }
                    >
                        <p>
                            <span className="semibold">Tip:</span> You can skip
                            to a section of the tour by clicking on it:
                        </p>
                        <Steps
                            current={
                                getNextSection(curr)
                                    ? getNextSection(curr).number - 1
                                    : sectionStarts.length - 1
                            }
                            onChange={(index) => setSection(index)}
                        >
                            {sectionStarts.map((section, i) =>
                                i !== sectionStarts.length - 1 ? (
                                    <Steps.Step
                                        key={i}
                                        title={`Section ${i + 1}`}
                                        description={section.name}
                                    />
                                ) : (
                                    <Steps.Step
                                        key={i}
                                        title={`Exit Tour`}
                                        icon={
                                            <CloseCircleOutlined
                                                style={{ fontSize: "1.35em" }}
                                            />
                                        }
                                        status={"error"}
                                    />
                                )
                            )}
                        </Steps>
                    </div>
                )}
            </>
        );
    }
);

export default Tour;
