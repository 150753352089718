import { Tag } from "antd";
import React, { useState } from "react";
import MockPaperOptionsModal from "./MockPaperOptionsModal";

function MockPapersGridCard({
    fullscreen,
    makeFullscreen,
    id,
    code,
    title,
    colour,
    isBooster = false,
    useDefaultOptions = true,
    onClick = (id) => {}
}) {
    const [showModal, setShowModal] = useState(false);

    const paperNumber = code.split("-").slice(-1)?.[0] || "";

    return (
        <>
            {useDefaultOptions && (
                <MockPaperOptionsModal
                    fullscreen={fullscreen}
                    makeFullscreen={makeFullscreen}
                    id={id}
                    code={code}
                    boosterNumber={isBooster ? paperNumber : null}
                    open={showModal}
                    onClose={() => setShowModal(false)}
                />
            )}
            <div
                className={
                    "draggable-save " + (!isBooster ? colour : "booster-style")
                }
                onClick={() => {
                    setShowModal(true);
                    onClick(id);
                }}
            >
                <div className="save-item-name">{title}</div>
                <span className={"save-item-fn-row"}>
                    <Tag
                        className={"saveItemTag"}
                        color={colour}
                        closable={false}
                        style={{
                            fontSize: "1rem",
                            fontWeight: "600"
                        }}
                    >
                        {code}
                    </Tag>
                </span>
            </div>
        </>
    );
}

export default MockPapersGridCard;
