import * as React from "react";

const SvgTimetableIcon = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.667 24a2.667 2.667 0 0 0 2.666 2.667h21.334A2.667 2.667 0 0 0 29.333 24V10.667H2.667V24Zm6.666-8a1.333 1.333 0 1 0-2.666 0 1.333 1.333 0 0 0 2.666 0ZM8 20a1.333 1.333 0 1 1 0 2.667A1.333 1.333 0 0 1 8 20Zm6.667-4A1.333 1.333 0 1 0 12 16a1.333 1.333 0 0 0 2.667 0Zm-1.333 4a1.333 1.333 0 1 1 0 2.667 1.333 1.333 0 0 1 0-2.667ZM20 16a1.333 1.333 0 1 0-2.666 0A1.333 1.333 0 0 0 20 16Zm-1.333 4a1.333 1.333 0 1 1 0 2.667 1.333 1.333 0 0 1 0-2.667Zm6.666-4a1.333 1.333 0 1 0-2.666 0 1.333 1.333 0 0 0 2.666 0Z"
            fill="currentColor"
        />
        <path
            d="M26.667 5.333A2.667 2.667 0 0 1 29.333 8v2.667H2.667V8a2.667 2.667 0 0 1 2.666-2.667h21.334Z"
            fill="currentColor"
            fillOpacity={0.6}
        />
    </svg>
);

export default SvgTimetableIcon;
