import {
    Button,
    Checkbox,
    DatePicker,
    Empty,
    Form,
    Input,
    InputNumber,
    List,
    Modal,
    Popconfirm,
    Switch,
    Table,
    Tooltip,
    Typography
} from "antd";
import React, { useEffect, useState } from "react";
import { apiDelete, apiPost, apiPut } from "helpers/api";
import { useCookies } from "react-cookie";
import dayjs from "dayjs";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import StatusGroupTag from "./StatusGroupTag";

function BaseModal({ open, onClose, children }) {
    return (
        <Modal
            open={open}
            onCancel={onClose}
            width={"fit-content"}
            style={{ maxWidth: "95%" }}
            destroyOnClose={true}
            title={null}
            footer={null}
            closable
            centered
        >
            {children}
        </Modal>
    );
}

export function ChangeAdminModal({
    open,
    onClose,
    currentAdmin,
    subUsers,
    onSuccess
}) {
    const [{ "XSRF-TOKEN": csrfToken }] = useCookies(["XSRF-TOKEN"]);
    const tableColumns = [
        {
            title: "Name",
            dataIndex: "given_name",
            render: (given_name, { family_name }) =>
                `${given_name} ${family_name}`
        },
        {
            title: "Email",
            dataIndex: "email"
        },
        {
            title: "Admin",
            dataIndex: "email",
            render: (email) => (
                <Popconfirm
                    title={
                        "Switch account admin to " + email + "? Are you sure?"
                    }
                    okText={"Confirm"}
                    onConfirm={() =>
                        apiPut(
                            `/api/swap-site-admin-account?currentAdminEmail=${encodeURIComponent(
                                currentAdmin.email
                            )}&newAdminEmail=${encodeURIComponent(email)}`,
                            {},
                            csrfToken,
                            () => {
                                onSuccess();
                                onClose();
                            }
                        )
                    }
                >
                    <Checkbox checked={currentAdmin?.email === email} />
                </Popconfirm>
            )
        }
    ];

    return (
        <BaseModal open={open} onClose={onClose}>
            <h2>Switch Site Admin User</h2>
            {currentAdmin?.["custom:stripeCustomerId"] ? (
                <Table
                    columns={tableColumns}
                    dataSource={subUsers?.length > 1 ? subUsers : []}
                    pagination={false}
                    locale={{
                        emptyText: (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description="No Sub-Users"
                            />
                        )
                    }}
                />
            ) : (
                "This user needs a Stripe ID before sub-users can be changed/managed"
            )}
        </BaseModal>
    );
}

export function ManageAccountStatusModal({
    open,
    onClose,
    currentAdmin,
    onSuccess
}) {
    const [{ "XSRF-TOKEN": csrfToken }] = useCookies(["XSRF-TOKEN"]);
    return (
        <BaseModal open={open} onClose={onClose}>
            <List
                dataSource={["paid", "trial", "awaitingPayment"]}
                renderItem={(groupName) => (
                    <List.Item>
                        <Checkbox
                            onChange={() =>
                                apiPut(
                                    `/api/set-group-membership?userEmail=${encodeURIComponent(
                                        currentAdmin.email
                                    )}&group=${encodeURIComponent(
                                        groupName
                                    )}&isMember=${!currentAdmin?.groups?.includes(
                                        groupName
                                    )}`,
                                    {},
                                    csrfToken,
                                    () => {
                                        onSuccess();
                                        onClose();
                                    }
                                )
                            }
                            checked={currentAdmin?.groups?.includes(groupName)}
                        >
                            {groupName}
                        </Checkbox>
                    </List.Item>
                )}
            />
        </BaseModal>
    );
}

export function EditTrialModal({
    open,
    onClose,
    adminUser,
    currentTrialEnd = null,
    onSuccess
}) {
    const [{ "XSRF-TOKEN": csrfToken }] = useCookies(["XSRF-TOKEN"]);
    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldValue("endtime", currentTrialEnd);
    }, [currentTrialEnd, form]);

    return (
        <BaseModal open={open} onClose={onClose}>
            <h2>Change Trial End Date</h2>
            <Form
                form={form}
                initialValues={{ endtime: currentTrialEnd }}
                onFinish={({ endtime, addToTrial = false }) => {
                    apiPut(
                        `/api/set-trial-end?userEmail=${encodeURIComponent(
                            adminUser.email
                        )}&trialEndTimestamp=${encodeURIComponent(
                            dayjs(endtime).unix()
                        )}`,
                        {},
                        csrfToken,
                        () => {
                            onSuccess();
                            onClose();
                        }
                    );
                    if (addToTrial) {
                        apiPut(
                            `/api/set-group-membership?userEmail=${encodeURIComponent(
                                adminUser.email
                            )}&group=trial&isMember=true`,
                            {},
                            csrfToken,
                            () => {
                                onSuccess();
                                onClose();
                            }
                        );
                    }
                }}
            >
                <Form.Item name={"endtime"} label={"Trial End"}>
                    <DatePicker picker={"date"} showTime />
                </Form.Item>
                {adminUser?.groups &&
                    !adminUser.groups?.includes("trial") &&
                    !adminUser.groups?.includes("awaitingPayment") && (
                        <Form.Item
                            name={"addToTrial"}
                            label={"Add user back into Trial group?"}
                            style={{ maxWidth: "53rem" }}
                            help={
                                <p>
                                    Use this when an account has "Trial Expired"
                                    status, to re-activate their trial
                                </p>
                            }
                        >
                            <Switch />
                        </Form.Item>
                    )}
                <Form.Item className={"formButtons"}>
                    <Button htmlType={"submit"} type={"primary"}>
                        Confirm
                    </Button>
                </Form.Item>
            </Form>
        </BaseModal>
    );
}

export function AddStripeIdModal({
    open,
    onClose,
    adminUser,
    currentId = null,
    onSuccess
}) {
    const [{ "XSRF-TOKEN": csrfToken }] = useCookies(["XSRF-TOKEN"]);
    return (
        <BaseModal open={open} onClose={onClose}>
            <h2>Add Stripe Customer ID</h2>
            <div style={{ maxWidth: "min(90rem, 90vw)" }}>
                <p>
                    This will allow the customer to manage their Stripe
                    subscription via the Stripe portal and to create Sub-Users.
                </p>
                <p>
                    If the customer does not have a subscription through Stripe,
                    this ID can be any UNIQUE placeholder ID (e.g. the site
                    admin ID or school name) -- this is what is used to link
                    sub-users to the site admin account
                </p>
            </div>
            <Form
                initialValues={{
                    stripeId: currentId
                }}
                onFinish={({ stripeId }) =>
                    currentId
                        ? apiPut(
                              `/api/edit-stripe-admin-id?userEmail=${encodeURIComponent(
                                  adminUser.email
                              )}&stripeId=${encodeURIComponent(stripeId)}`,
                              {},
                              csrfToken,
                              () => {
                                  onSuccess();
                                  onClose();
                              }
                          )
                        : apiPost(
                              `/api/add-stripe-admin-id?userEmail=${encodeURIComponent(
                                  adminUser.email
                              )}&stripeId=${encodeURIComponent(stripeId)}`,
                              {},
                              csrfToken,
                              () => {
                                  onSuccess();
                                  onClose();
                              }
                          )
                }
            >
                <Form.Item name={"stripeId"} label={"Stripe ID"}>
                    <Input placeholder={"cus_NzudWZhXw0Cm8z"} />
                </Form.Item>
                <Form.Item className={"formButtons"}>
                    <Button htmlType={"submit"} type={"primary"}>
                        Confirm
                    </Button>
                </Form.Item>
            </Form>
        </BaseModal>
    );
}

export function EditMaxSubUsersModal({
    open,
    onClose,
    adminUser,
    currentMax = 30,
    onSuccess
}) {
    const [{ "XSRF-TOKEN": csrfToken }] = useCookies(["XSRF-TOKEN"]);
    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldValue("maxSubUsers", currentMax);
    }, [currentMax, form]);

    return (
        <BaseModal open={open} onClose={onClose}>
            <h2>Edit Sub-Users Limit</h2>
            <Form
                form={form}
                onFinish={({ maxSubUsers }) =>
                    apiPut(
                        `/api/set-max-sub-users?userEmail=${encodeURIComponent(
                            adminUser.email
                        )}&maxSubUsers=${maxSubUsers}`,
                        {},
                        csrfToken,
                        () => {
                            onSuccess();
                            onClose();
                        }
                    )
                }
                initialValues={{ maxSubUsers: currentMax }}
            >
                <Form.Item name={"maxSubUsers"} label={"Max Sub Users:"}>
                    <InputNumber min={0} max={999} />
                </Form.Item>
                <Form.Item className={"formButtons"}>
                    <Button htmlType={"submit"} type={"primary"}>
                        Confirm
                    </Button>
                </Form.Item>
            </Form>
        </BaseModal>
    );
}

export function ManageAccountsModal({
    open,
    onClose,
    accounts,
    onNewSubUser,
    onDeleteSuccess
}) {
    const [{ "XSRF-TOKEN": csrfToken }] = useCookies(["XSRF-TOKEN"]);
    const tableColumns = [
        {
            title: "Name",
            dataIndex: "given_name",
            render: (given_name, { family_name }) =>
                `${given_name} ${family_name}`
        },
        {
            title: "Email",
            dataIndex: "email"
        },
        {
            title: "Has Logged In (approx.)",
            dataIndex: "saves_current",
            render: (has_default_saves) =>
                has_default_saves ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                ) : (
                    <CloseCircleTwoTone twoToneColor="#f5222d" />
                )
        },
        {
            title: (
                <>
                    Groups
                    <br />
                    (Should all match)
                </>
            ),
            dataIndex: "groups",
            render: (allGroups) =>
                (allGroups ?? []).map((group) => (
                    <StatusGroupTag groupName={group} />
                ))
        },
        {
            title: "Invalidate Password",
            dataIndex: "email",
            render: (email) => (
                <Popconfirm
                    title={
                        <>
                            <b>Invalidate password for {email}: </b>Are you
                            sure?
                        </>
                    }
                    onConfirm={() =>
                        apiPost(
                            `/api/invalidate-user-password?userEmail=${encodeURIComponent(
                                email
                            )}`,
                            {},
                            csrfToken
                        )
                    }
                    onCancel={() => {}}
                    okText="Confirm Invalidation"
                    cancelText="Cancel"
                >
                    <Button type={"default"}>Invalidate Password</Button>
                </Popconfirm>
            )
        },
        {
            title: "Resend Temporary Password",
            dataIndex: "email",
            render: (email) => (
                <Popconfirm
                    title={
                        <>
                            <b>Reset password for {email}: </b>Are you sure?
                        </>
                    }
                    onConfirm={() =>
                        apiPost(
                            `/api/reset-user-password?userEmail=${encodeURIComponent(
                                email
                            )}`,
                            {},
                            csrfToken
                        )
                    }
                    onCancel={() => {}}
                    okText="Send New Password"
                    cancelText="Cancel"
                >
                    <Button type={"default"}>Reset Expired Password</Button>
                </Popconfirm>
            )
        },
        {
            title: "Delete",
            dataIndex: "email",
            render: (email, record) =>
                record?.["custom:subscriptionType"] !== "Site" ||
                record?.subUsersCount === 0 ? (
                    <Popconfirm
                        title={
                            <>
                                <b>Delete {email}: </b>Are you sure?
                            </>
                        }
                        onConfirm={() =>
                            apiDelete(
                                `/api/account?userEmail=${encodeURIComponent(
                                    email
                                )}`,
                                csrfToken,
                                () => {
                                    onDeleteSuccess();
                                    onClose();
                                }
                            )
                        }
                        onCancel={() => {}}
                        okText="Delete"
                        cancelText="Cancel"
                    >
                        <Button type={"primary"} danger={true}>
                            Delete
                        </Button>
                    </Popconfirm>
                ) : (
                    <Tooltip
                        title={
                            <>
                                <p>
                                    Cannot delete the site admin. Please
                                    transfer ownership to a sub-user first.
                                </p>
                                <p>
                                    If you want to remove the school completely,
                                    please delete all sub-users first
                                </p>
                            </>
                        }
                    >
                        <Button type={"primary"} danger={true} disabled>
                            Delete
                        </Button>
                    </Tooltip>
                )
        }
    ];

    return (
        <BaseModal open={open} onClose={onClose}>
            <h2>Manage Account(s)</h2>

            <div style={{ maxWidth: "min(90rem, 90vw)" }}>
                <p>
                    <u>
                        <strong>Invalidating</strong>
                    </u>{" "}
                    a user's password will mean that when the user next attempts
                    to log in with their old password, they will be forced to
                    change it using the <b>standard password reset</b> flow --
                    This requires the customer to know their password. If they
                    do not remember their password, they should use the standard
                    "forgot my password" flow when signing in.
                </p>
                <p>
                    <u>
                        <strong>Re-Sending</strong>
                    </u>{" "}
                    a new temporary password should be used for new users who
                    have <b>not yet logged in</b> within 7 days of receiving
                    their temporary password -- and as such their temporary
                    password has <b>expired</b>. This action will send them
                    <b>a new email containing a new temporary password</b> --
                    allowing them to activate their account.
                </p>
            </div>
            <Table
                columns={tableColumns}
                dataSource={accounts}
                pagination={false}
            />
            <br />
            <div className={"formButtons"}>
                <Button onClick={onNewSubUser} type={"primary"}>
                    + New Sub-User
                </Button>
            </div>
        </BaseModal>
    );
}

export function NewSubUserModal({ open, onClose, adminUser, onSuccess }) {
    const [{ "XSRF-TOKEN": csrfToken }] = useCookies(["XSRF-TOKEN"]);
    const [currentEmailDomain, setCurrentEmailDomain] = useState(null);
    const createUser = (formValues) => {
        apiPost(
            "/api/admin-create-sub-user",
            JSON.stringify({
                email: formValues.email,
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                siteAdminId: adminUser["custom:stripeCustomerId"]
            }),
            csrfToken,
            () => {
                onSuccess();
                onClose();
            }
        );
    };

    const domainsMatch =
        !currentEmailDomain ||
        adminUser?.email?.split("@")?.[1] === currentEmailDomain;

    return (
        <BaseModal open={open} onClose={onClose}>
            <Typography.Title level={4}>
                Create a new sub user for {adminUser?.email}:
            </Typography.Title>

            {adminUser?.["custom:stripeCustomerId"] ? (
                <Form onFinish={createUser}>
                    <Form.Item
                        label={<b>Email</b>}
                        className={"emailInput"}
                        name="email"
                        rules={[
                            { required: true, message: "Please enter an email" }
                        ]}
                        validateStatus={!domainsMatch ? "warning" : undefined}
                        help={
                            domainsMatch ? undefined : (
                                <>Caution: entered email domain doesn't match</>
                            )
                        }
                    >
                        <Input
                            type={"email"}
                            onChange={(e) =>
                                setCurrentEmailDomain(
                                    e.target.value.split("@")?.[1]
                                )
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label={<b>First Name</b>}
                        className={"emailInput"}
                        name="firstName"
                        rules={[
                            {
                                required: true,
                                message: "Please enter a first name"
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<b>Last Name</b>}
                        className={"emailInput"}
                        name="lastName"
                        rules={[
                            {
                                required: true,
                                message: "Please enter a last name"
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item className={"formButtons"}>
                        <Button type="primary" htmlType="submit">
                            Create
                        </Button>
                    </Form.Item>
                </Form>
            ) : (
                "This user needs a Stripe ID before sub-users can be added"
            )}
        </BaseModal>
    );
}
