import React, { useMemo } from "react";
import Countdown from "commonComponents/Countdown";
import { Popconfirm, Space, Tooltip } from "antd";
import CueImageSelector from "./CueImageSelector";
import { useStarterAutosavedInputProperty } from "helpers/hooks";
import RoundedButton from "commonComponents/RoundedButton";
import { getDateString, tooltipColour } from "helpers/starterHelpers";
import { createSelector } from "@reduxjs/toolkit";
import { useGetUserQuery } from "features/account/accountSlice";
import Icon, { LeftOutlined } from "@ant-design/icons";
import SvgIconWand from "assets/icons/IconWand";

function StarterHeader({
    fullscreen,
    makeFullscreen,
    title,
    setTitle,
    learningObjective,
    setLearningObjective,
    setStaleEdits,
    activateStarterTypeModal,
    selectedCueImage,
    setSelectedCueImage,
    showRecreateStarter,
    recreateStarter
}) {
    const selectDefaultCountdownForUser = useMemo(() => {
        return createSelector(
            (res) => res.data,
            (user) => user?.default_countdown
        );
    }, []);
    const { default_countdown: defaultCountdown } = useGetUserQuery(undefined, {
        selectFromResult: (result) => ({
            default_countdown: selectDefaultCountdownForUser(result)
        })
    });
    const dateString = getDateString();

    const [localTitle, titleChangeHandler] = useStarterAutosavedInputProperty(
        title,
        setTitle,
        setStaleEdits
    );

    const [localLearningObj, learningObjChangeHandler] =
        useStarterAutosavedInputProperty(
            learningObjective,
            setLearningObjective,
            setStaleEdits
        );

    const countdownFunctions = (
        <div className="functions">
            <CueImageSelector
                fullscreen={fullscreen}
                selectedKey={selectedCueImage}
                setSelectedKey={(val) => {
                    setSelectedCueImage(val);
                    setStaleEdits(true);
                }}
            />
            <div className="cntdwn-timer" data-tour={"timer"}>
                <Countdown
                    initialTimeMinutes={
                        defaultCountdown ? defaultCountdown / 60000 : 6
                    }
                    startCallback={() => makeFullscreen(true)}
                    hasDefaultCheckbox
                />
            </div>
        </div>
    );

    const changeTypeButtonStyle = {
        padding: "0.25rem 1rem",
        height: "fit-content",
        borderRadius: "6px"
    };

    return (
        <div className={"header " + (fullscreen ? "fullscreen" : "")}>
            <div className="switcher-date switcher-date--starter">
                {fullscreen ? (
                    <div className="date">{dateString}</div>
                ) : (
                    <div className={"starter-header-buttons"}>
                        <Space direction={"horizontal"}>
                            <Popconfirm
                                title={
                                    <>
                                        <b>Are you sure?</b>
                                        <p>
                                            Changing starter type will clear all
                                            questions in this current view.
                                        </p>
                                    </>
                                }
                                okText={"Yes, I want a fresh starter"}
                                onConfirm={() => activateStarterTypeModal()}
                                okButtonProps={{ style: changeTypeButtonStyle }}
                                cancelButtonProps={{
                                    style: changeTypeButtonStyle
                                }}
                                placement={"bottomLeft"}
                            >
                                <Tooltip
                                    title="Change Starter Type"
                                    color={tooltipColour}
                                >
                                    <RoundedButton
                                        className={"changeTypeButton"}
                                    >
                                        <LeftOutlined />
                                        <span className={"small-label"}>
                                            Change Type
                                        </span>
                                        Change Starter Type
                                    </RoundedButton>
                                </Tooltip>
                            </Popconfirm>
                            {showRecreateStarter && (
                                <Tooltip
                                    title="Randomise Question Topics"
                                    color={tooltipColour}
                                >
                                    <RoundedButton onClick={recreateStarter}>
                                        <Icon
                                            component={() => <SvgIconWand />}
                                        />
                                        <span className={"small-label"}>
                                            Randomise
                                        </span>
                                        Randomise Topics
                                    </RoundedButton>
                                </Tooltip>
                            )}
                        </Space>
                    </div>
                )}
            </div>

            {/*-- Title & Learning Objective Inputs ----------------------------------------------*/}
            <div className="title" data-tour={"title"}>
                <input
                    type="text"
                    placeholder={
                        !fullscreen ? "Click to add lesson title" : undefined
                    }
                    value={localTitle || ""}
                    onChange={titleChangeHandler}
                    className={fullscreen && !localTitle ? "hidden" : ""}
                />
                <input
                    className={`learning-obj ${
                        fullscreen && !localTitle ? "hidden" : ""
                    }`}
                    type="text"
                    placeholder={
                        !fullscreen ? "+ learning objective" : undefined
                    }
                    value={localLearningObj || ""}
                    onChange={learningObjChangeHandler}
                />
            </div>
            {countdownFunctions}
        </div>
    );
}

export default StarterHeader;
