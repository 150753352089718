import React, { useCallback, useEffect, useState } from "react";
import { Col, InputNumber, message, Row, Space, Spin, Table } from "antd";
import { apiGet, apiPut } from "helpers/api";
import { useCookies } from "react-cookie";

function AccountReferralsPage() {
    const [{ "XSRF-TOKEN": csrfToken }] = useCookies(["XSRF-TOKEN"]);
    const [usersReferralsData, setUsersReferralsData] = useState([]);
    const [loading, setLoading] = useState(true);

    const loadUsersData = useCallback(() => {
        setLoading(true);
        apiGet(
            "/api/referrals",
            (body) => {
                setUsersReferralsData(body.filter((u) => u?.referralCode));
                setLoading(false);
            },
            (err) => {
                message.error(err.message);
                setLoading(false);
            }
        );
    }, []);

    useEffect(() => {
        loadUsersData();
    }, [loadUsersData]);
    function stringSorter(prop) {
        return (a, b) => a[prop] && b[prop] && a[prop].localeCompare(b[prop]);
    }
    function numSorter(prop) {
        return (a, b) => a[prop] - b[prop];
    }

    const columns = [
        {
            title: "First Name",
            dataIndex: "forename",
            sorter: stringSorter("forename")
        },
        {
            title: "Surname",
            dataIndex: "surname",
            sorter: stringSorter("surname")
        },
        {
            title: "Email",
            dataIndex: "email",
            sorter: stringSorter("email")
        },
        {
            title: "School",
            dataIndex: "school",
            sorter: stringSorter("school")
        },
        {
            title: "Referral Code",
            dataIndex: "referralCode",
            sorter: stringSorter("referralCode")
        },
        {
            title: "Confirmed Referrals (Editable)",
            dataIndex: "numReferrals",
            sorter: numSorter("numReferrals"),
            render: (num_referrals, record) => (
                <InputNumber
                    min={0}
                    defaultValue={num_referrals}
                    onChange={(newVal) => {
                        apiPut(
                            `/api/set-referrals?userId=${encodeURIComponent(
                                record.id
                            )}&value=${newVal}`,
                            {},
                            csrfToken,
                            () => {
                                message.success("Success");
                                apiPut(
                                    "/api/invalidate_users_cache",
                                    {},
                                    csrfToken,
                                    () => {
                                        loadUsersData();
                                    }
                                );
                            }
                        );
                    }}
                />
            )
        }
    ];

    return (
        <div className={"metricsContainer"}>
            <Row className={"overallContainer"}>
                <Space direction={"horizontal"}>
                    <Col>
                        <b>Total Referrals:</b>{" "}
                        {loading ? (
                            <Spin />
                        ) : (
                            usersReferralsData.reduce(
                                (acc, u) => acc + (u?.numReferrals ?? 0),
                                0
                            )
                        )}
                    </Col>
                </Space>
            </Row>

            <Table
                columns={columns}
                dataSource={usersReferralsData}
                loading={loading}
                pagination={false}
                scroll={{
                    x: "100%",
                    y: "calc(95vh - 25rem)"
                }}
            />
        </div>
    );
}

export default AccountReferralsPage;
