import React, { useEffect, useState } from "react";
import { apiDelete, apiGet } from "helpers/api";
import { withCookies } from "react-cookie";
import { Button, Popconfirm, Table } from "antd";

function ManageSubTopicsForm({ urlStub, cookies }) {
    const [subtopics, setSubTopics] = useState([]);
    const [staleData, toggleStaleData] = useState(false);
    const [csrfToken] = useState(cookies.get("XSRF-TOKEN"));

    useEffect(() => apiGet("/api/subtopics", setSubTopics), [staleData]);

    const columns = [
        {
            title: "Name",
            dataIndex: "value",
            render: (value) => value.replace(/^\s+|\s+$/g, "_")
        },
        {
            title: "Topic (Parent)",
            dataIndex: "parent",
            render: (value) => value.replace(/^\s+|\s+$/g, "_")
        },
        {
            title: "Edit",
            dataIndex: "value",
            render: (value) => (
                <Button
                    type={"primary"}
                    className={"warning-btn"}
                    href={
                        urlStub +
                        "/edit-subtopic?name=" +
                        encodeURIComponent(value)
                    }
                >
                    Edit
                </Button>
            )
        },
        {
            title: "Delete",
            dataIndex: "value",
            render: (value) => (
                <Popconfirm
                    title={
                        <>
                            <b>Delete: </b>Are you sure?
                        </>
                    }
                    onConfirm={() => {
                        apiDelete(
                            "/api/subtopic?subtopic=" +
                                encodeURIComponent(value),
                            csrfToken
                        );
                        toggleStaleData((p) => !p);
                    }}
                    onCancel={() => {}}
                    okText="Delete"
                    cancelText="Cancel"
                >
                    <Button type={"primary"} danger={true}>
                        Delete
                    </Button>
                </Popconfirm>
            )
        }
    ];

    return (
        <>
            {`Total Sub-Topics: ${subtopics.length}`}
            <Table
                className={"adminTable"}
                dataSource={subtopics}
                columns={columns}
                pagination={false}
            />
        </>
    );
}

export default withCookies(ManageSubTopicsForm);
