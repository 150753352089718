import React, { useContext, useEffect, useRef, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer, Spin } from "antd";
import { Document, Image, Page, usePDF, View } from "@react-pdf/renderer";
import PrintedStarter from "./PrintedStarter";
import PrintedFeedbackPage from "./PrintedFeedbackPage";
import { toPng } from "html-to-image";
import { createPortal } from "react-dom";
import LessonContext from "../../context/LessonContext";
import PrintSettings from "./PrintSettings";

function asyncTimeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

const CHART_DELAY = 600;

const PrintButton = ({
    document,
    settingsRef,
    printError = null,
    delayRendering = false
}) => {
    const [instance, update] = usePDF({ document });

    const SetNotLoadingState = ({ loading, setLoading }) => {
        useEffect(() => {
            if (!setLoading) {
                return;
            }
            setLoading(loading);
        }, [loading, setLoading]);

        return null;
    };

    useEffect(() => {
        // Add a bit of delay to allow the charts to render/re-size before generating images
        setTimeout(() => update(document), [delayRendering ? CHART_DELAY : 0]);
    }, [document, delayRendering, update]);

    return (
        <>
            <SetNotLoadingState
                loading={instance.loading}
                setLoading={settingsRef?.current?.setLoading}
            />
            {instance.loading ? (
                <Spin />
            ) : printError || instance.error ? (
                <div>Unable to Print</div>
            ) : (
                <>
                    <a
                        href={instance.url}
                        disabled={!instance.url}
                        download={`MathsPlanner-${
                            new Date().toISOString().split("T")[0]
                        }.pdf`}
                    >
                        <Button disabled={!instance.url}>DOWNLOAD PDF</Button>
                    </a>
                    <a
                        href={instance.url}
                        target="_blank"
                        rel="noreferrer"
                        disabled={!instance.url}
                    >
                        <Button type={"primary"}>PRINT</Button>
                    </a>
                </>
            )}
        </>
    );
};

function LessonPrintModal({ isModalVisible, hideModal, isShowingPlenary }) {
    const {
        starterTitleState: [starterTitle],
        starterLearningObjectiveState: [learningObjective],
        starterChoicesState: [questionTopicChoices],
        starterGridDimensionsState: [
            { width: starterGridWidth, height: starterGridHeight }
        ],
        plenaryGridDimensionsState: {
            width: plenaryGridWidth,
            height: plenaryGridHeight
        },
        starterHasRecallTagsState: [hasRecallTags],
        starterCalculatorAllowedState: [starterCalculatorAllowedValues],
        plenaryCalculatorAllowedState: [plenaryCalculatorAllowedValues],
        plenaryFeedbackRef,
        starterSectionRef,
        plenarySectionRef,
        plenaryChoicesState: [plenaryQuestionTopicChoices]
    } = useContext(LessonContext);
    const starterQuestionComponents =
        starterSectionRef?.current?.getComponents() || [];
    const plenaryQuestionComponents =
        plenarySectionRef?.current?.getComponents() || [];
    const feedbackStatements = {
        www: plenaryFeedbackRef?.current?.getWwwStatements(),
        ebi: plenaryFeedbackRef?.current?.getEbiStatements()
    };
    function getExitCardTitle() {
        const subTopics = [
            ...new Set(
                plenaryQuestionTopicChoices
                    .slice(0, plenaryGridWidth * plenaryGridHeight)
                    .map((tc) => tc?.subtopic)
                    .filter((tc) => tc?.length > 0)
            )
        ];
        if (!subTopics || subTopics.length === 0) {
            return "";
        }
        return subTopics.join(" / ");
    }
    const plenaryTitle = "Exit Ticket: " + getExitCardTitle();
    const numberStarterQuestions = starterGridWidth * starterGridHeight;
    const recallTags = questionTopicChoices.map((qc) => qc?.recallTag);

    const printPlenary = isShowingPlenary;
    const [starterIncludes, setStarterIncludes] = useState({
        title: starterTitle && true,
        date: false,
        dateBox: true,
        nameBox: false,
        learningObjective: false,
        copies: 1
    });
    const [plenaryIncludes, setPlenaryIncludes] = useState({
        title: plenaryTitle && true,
        date: false,
        dateBox: true,
        nameBox: false,
        feedback: feedbackStatements,
        copies: 1
    });
    const [printAsStrip, setPrintAsStrip] = useState(false);

    useEffect(() => {
        setStarterIncludes((prev) => {
            const copy = Object.assign({}, prev);
            copy.title = starterTitle && true;
            return copy;
        });
        setPlenaryIncludes((prev) => {
            const copy = Object.assign({}, prev);
            copy.title = plenaryTitle && true;
            return copy;
        });
    }, [starterTitle, plenaryTitle]);

    const page1Ref = useRef();
    const page2Ref = useRef();
    const printError = useRef(false);

    const isPortrait =
        (!printPlenary && (starterIncludes?.copies === 2 || printAsStrip)) ||
        (printPlenary && plenaryIncludes?.copies === 2);

    const page1Content = (
        <div
            ref={page1Ref}
            className={
                "printedContent " + (isPortrait ? "portrait " : "landscape ")
            }
        >
            {!printPlenary ? (
                // Print Starter
                <div
                    className={
                        "printPage " +
                        ([1, 4].includes(starterIncludes?.copies) ? " " : "") +
                        (printAsStrip ? "strip " : "grid ") +
                        "copies-" +
                        starterIncludes.copies +
                        " qs-" +
                        numberStarterQuestions
                    }
                >
                    {Array.from(
                        { length: printAsStrip ? 2 : starterIncludes?.copies },
                        (v, k) => k + 1
                    ).map((n) => (
                        <PrintedStarter
                            key={n}
                            title={starterTitle}
                            numberQuestions={numberStarterQuestions}
                            learningObjective={learningObjective}
                            includes={starterIncludes}
                            hasRecallTags={hasRecallTags}
                            recallTags={recallTags}
                            questionComponents={starterQuestionComponents}
                            calculatorAllowedValues={
                                starterCalculatorAllowedValues
                            }
                            isPlenary={false}
                        />
                    ))}
                </div>
            ) : (
                // Print Plenary
                <>
                    <div
                        className={
                            "printPage " +
                            ([1, 4].includes(plenaryIncludes?.copies)
                                ? " "
                                : "") +
                            "copies-" +
                            plenaryIncludes.copies +
                            " qs-3"
                        }
                    >
                        {Array.from(
                            { length: plenaryIncludes?.copies },
                            (v, k) => k + 1
                        ).map((n) => (
                            <PrintedStarter
                                key={n}
                                title={plenaryTitle}
                                numberQuestions={3}
                                learningObjective={null}
                                includes={plenaryIncludes}
                                hasRecallTags={false}
                                recallTags={[]}
                                questionComponents={plenaryQuestionComponents}
                                calculatorAllowedValues={
                                    plenaryCalculatorAllowedValues
                                }
                                isPlenary={true}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );

    const page2Content = (
        <div
            ref={page2Ref}
            className={
                "printedContent " + (isPortrait ? "portrait " : "landscape ")
            }
        >
            {printPlenary && (
                <PrintedFeedbackPage
                    title={plenaryTitle}
                    feedbackStatements={feedbackStatements}
                />
            )}
        </div>
    );

    useEffect(() => {
        const htmlTag = document?.documentElement;
        if (!htmlTag) {
            return;
        }
        htmlTag.style.overflowX = "hidden";

        return () => (htmlTag.style.overflowX = "auto");
    }, []);

    const hasChart = (
        (printPlenary
            ? plenaryQuestionComponents
            : starterQuestionComponents) ?? []
    )?.some((qc) => qc?.chartData);

    const PrintedDocument = (
        <Document>
            {isModalVisible ? (
                <>
                    <Page
                        size="A4"
                        orientation={isPortrait ? "portrait" : "landscape"}
                    >
                        <View>
                            <Image
                                style={{
                                    width: "100vw",
                                    height: "100vh",
                                    objectFit: "contain"
                                }}
                                source={async () => {
                                    try {
                                        if (hasChart) {
                                            await asyncTimeout(CHART_DELAY);
                                        }
                                        const url = await toPng(
                                            page1Ref.current,
                                            {
                                                canvasWidth: isPortrait
                                                    ? 2480
                                                    : 3508,
                                                canvasHeight: isPortrait
                                                    ? 3508
                                                    : 2480,
                                                cacheBust: true
                                            }
                                        );
                                        if (!printPlenary) {
                                            printError.current = false;
                                        }
                                        return url;
                                    } catch (err) {
                                        printError.current = err;
                                    }
                                }}
                            ></Image>
                        </View>
                    </Page>
                    {printPlenary && (
                        <Page
                            size="A4"
                            orientation={isPortrait ? "portrait" : "landscape"}
                        >
                            <View>
                                <Image
                                    style={{
                                        width: "100vw",
                                        height: "100vh",
                                        objectFit: "contain"
                                    }}
                                    source={() =>
                                        toPng(page2Ref.current, {
                                            canvasWidth: isPortrait
                                                ? 2480
                                                : 3508,
                                            canvasHeight: isPortrait
                                                ? 3508
                                                : 2480,
                                            cacheBust: true
                                        })
                                            .then((url) => {
                                                printError.current = false;
                                                return url;
                                            })
                                            .catch((err) => {
                                                printError.current = err;
                                            })
                                    }
                                ></Image>
                            </View>
                        </Page>
                    )}
                </>
            ) : null}
        </Document>
    );

    const settingsRef = useRef(null);

    return (
        <>
            {isModalVisible &&
                createPortal(
                    <div
                        className={
                            "printedContentHiddenContainer " +
                            (isPortrait ? "portrait" : "landscape")
                        }
                    >
                        {page1Content}
                        {page2Content}
                    </div>,
                    document.body
                )}
            <Drawer
                title={"Print " + (printPlenary ? "Exit Ticket" : "Starter")}
                className={"printModal"}
                closable={false}
                destroyOnClose={true}
                onClose={hideModal}
                maskClosable={true}
                extra={
                    <Button type={"text"} onClick={hideModal}>
                        <CloseOutlined />
                    </Button>
                }
                open={isModalVisible}
                footer={
                    <div className={"printModalFooter"}>
                        <Button onClick={hideModal}>CANCEL</Button>
                        <div className={"printModalFooter__right"}>
                            <PrintButton
                                document={PrintedDocument}
                                printError={printError?.current}
                                settingsRef={settingsRef}
                                delayRendering={hasChart}
                            />
                        </div>
                    </div>
                }
                width={"min(590px, 90vw)"}
            >
                <PrintSettings
                    ref={settingsRef}
                    isPlenary={printPlenary}
                    starterIncludes={starterIncludes}
                    setStarterIncludes={setStarterIncludes}
                    plenaryIncludes={plenaryIncludes}
                    setPlenaryIncludes={setPlenaryIncludes}
                    printAsStrip={printAsStrip}
                    setPrintAsStrip={setPrintAsStrip}
                />
            </Drawer>
        </>
    );
}

export default LessonPrintModal;
