import { Tag, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { tooltipColour } from "helpers/starterHelpers";

export function OurLibraryCard({
    title,
    tag,
    colour,
    disabledDifficulties,
    selected,
    onClick,
    onDifficultySelect,
    ...otherProps
}) {
    const lowercaseDisabledDifficulties = disabledDifficulties.map((diff) =>
        diff.toLowerCase()
    );
    const difficultyOptions = ["easy", "medium", "hard", "mixed"].filter(
        (diff) => !lowercaseDisabledDifficulties.includes(diff)
    );
    const titleRef = useRef(null);
    const [enableTooltip, setEnableTooltip] = useState(false);

    useEffect(() => {
        if (!titleRef?.current) {
            return;
        }
        setEnableTooltip(
            titleRef?.current?.scrollWidth > titleRef?.current?.clientWidth
        );
    }, []);

    if (selected) {
        return (
            <div className={"draggable-save "} {...otherProps}>
                <span className={"save-item-tag-grid"}>
                    {difficultyOptions.map((opt) => (
                        <Tag
                            className={"our-library__difficulty_button"}
                            onClick={() => onDifficultySelect(opt)}
                        >
                            {opt}
                        </Tag>
                    ))}
                </span>
            </div>
        );
    }

    return (
        <div
            className={"draggable-save " + colour}
            onClick={onClick}
            {...otherProps}
        >
            <Tooltip
                trigger={enableTooltip ? "hover" : "none"}
                title={title}
                color={tooltipColour}
            >
                <div className="save-item-name" ref={titleRef}>
                    {title}
                </div>
            </Tooltip>
            <span className={"save-item-fn-row"}>
                <Tag
                    className={"saveItemTag"}
                    color={colour}
                    closable={false}
                    style={{ fontSize: "1rem", fontWeight: "600" }}
                >
                    {tag}
                </Tag>
            </span>
        </div>
    );
}
