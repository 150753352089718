import * as React from "react";

const SvgIconWand = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="20 20 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M28.889 74.644c-1.767.272-8.152-6.522-7.88-7.609.271-1.087 14.265-13.05 14.265-13.05s-.68-4.884 0-5.563c.68-.68 6.08 0 6.08 0s27.615-25.814 29.11-26.358c1.494-.544 4.483 2.513 3.668 3.94-.816 1.427-9.944 10.733-9.944 10.733.302 2.134.297 3.94 0 4.892-.298.95-3.142.36-4.42 0 0 0-29.113 32.743-30.88 33.015ZM46.144 25.46c-.544-.543-.951-3.26-1.495-3.26-.543 0-1.087 2.717-1.63 3.26-.544.544-3.125 1.36-3.125 1.631 0 .272 2.717.951 3.125 1.63.407.68 1.358 3.397 1.63 3.125.272-.271.985-2.445 1.495-3.125.51-.679 3.26-1.223 3.26-1.63 0-.408-2.717-1.087-3.26-1.63ZM32.63 27.608c-.915-.901-1.602-5.408-2.519-5.408-.916 0-1.832 4.507-2.748 5.408-.916.902-5.267 2.254-5.267 2.705 0 .45 4.58 1.577 5.267 2.704.687 1.127 1.933 5.183 2.748 5.183.816 0 1.661-4.056 2.52-5.183.859-1.127 5.497-2.028 5.497-2.704 0-.676-4.581-1.803-5.497-2.705ZM68.5 56.955c-.917-.901-1.604-5.408-2.52-5.408-.916 0-1.832 4.507-2.749 5.408-.916.902-5.267 2.254-5.267 2.705 0 .45 4.557 1.701 5.267 2.704.71 1.003 2.07 5.183 2.749 5.183.68 0 1.66-4.056 2.52-5.183.858-1.127 5.088-2.028 5.088-2.704 0-.676-4.173-1.803-5.089-2.705Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgIconWand;
