import React, { useContext, useRef, useState } from "react";
import QuestionCard from "features/lesson/QuestionCard";
import { Button, message, Space, Tooltip } from "antd";
import SvgIconFire from "assets/icons/IconFire";
import { getStepIndex, TourContext } from "features/tour/Tour";
import SketchModal from "../../SketchModal";
import TopicSelector from "features/lesson/TopicSelector";
import { tooltipColour } from "helpers/starterHelpers";
import { useGetTopicsTreeQuery } from "features/lesson/topicsSlice";

function ExtensionQuestion({
    extNumber = 0,
    questionFont,
    senBg,
    questionTopicChoices,
    extensionTopicChoices,
    setExtensionTopicChoices,
    fullscreen,
    currentNumberOfQuestions,
    isModal = false,
    modalShowSketch = undefined,
    setModalShowSketch = () => {}
}) {
    const [showAnswer, setShowAnswer] = useState(false);
    let cardRef = useRef();

    let [showSketch, setShowSketch] = useState(false);
    if (modalShowSketch !== undefined) {
        [showSketch, setShowSketch] = [modalShowSketch, setModalShowSketch];
    }
    const [questionComponent, setQuestionComponent] = useState(null);
    const [calculatorAllowed, setCalculatorAllowed] = useState(false);

    const tourContext = useContext(TourContext);
    const { data: allTopics } = useGetTopicsTreeQuery();

    function automaticallySelect() {
        const visibleQuestions = [
            ...questionTopicChoices.slice(0, currentNumberOfQuestions)
        ];

        // Upgrade one of the easy/medium questions if possible...
        let upgradeableQuestions = visibleQuestions.filter(
            (q) => q?.difficulty && q.difficulty !== "Hard"
        );
        // ... otherwise include the hard ones too
        if (upgradeableQuestions.length === 0) {
            upgradeableQuestions = visibleQuestions;
        }
        upgradeableQuestions = upgradeableQuestions
            .filter((p) => p?.subtopic)
            .map((q) => ({
                topicArea: q.topicArea,
                topic: q.topic,
                subtopic: q.subtopic
            }));
        let uniqueSubtopics = [
            ...new Set(upgradeableQuestions.map((q) => JSON.stringify(q)))
        ].map((qs) => JSON.parse(qs));
        if (
            uniqueSubtopics.length === 0 ||
            (uniqueSubtopics.length === 1 && !uniqueSubtopics[0].subtopic)
        ) {
            if (
                tourContext.isOpen &&
                tourContext.currentStep === getStepIndex("extensionAutomatic")
            ) {
                uniqueSubtopics = [
                    {
                        difficulty: "Easy",
                        subtopic: "Collecting Like Terms",
                        topic: "Expressions",
                        topicArea: "Algebra"
                    }
                ];
            } else {
                message.warning(
                    "Please choose some question topics before automatically generating a starter."
                );
                return;
            }
        }
        const choiceIndex = Math.floor(Math.random() * uniqueSubtopics.length);
        const choice = uniqueSubtopics[choiceIndex];
        const choiceDetails = allTopics
            .find((ta) => ta.value === choice.topicArea)
            .children.find((t) => t.value === choice.topic)
            .children.find((st) => st.value === choice.subtopic);
        const validDifficulties = choiceDetails.children.filter(
            (d) => !d.disabled
        );
        choice.difficulty = validDifficulties.pop().value;
        if (
            tourContext.isOpen &&
            tourContext.currentStep === getStepIndex("extensionAutomatic")
        ) {
            if (currentNumberOfQuestions < 6) {
                tourContext.setStep(getStepIndex("plenaryOpen"));
            } else {
                tourContext.setStep(getStepIndex("extensionClose"));
            }
        }
        setExtensionTopicChoices((prevState) => {
            const copy = [...prevState];
            copy[extNumber] = {
                ...choice,
                randomSeed: Math.floor(Math.random() * 10000)
            };
            return copy;
        });
    }

    const title = (text) => (
        <div className="ext-q-title">
            <SvgIconFire
                style={{ marginBottom: "-3px", marginRight: "-3px" }}
            />
            {" " + text}
        </div>
    );

    const topicChoice = extensionTopicChoices[extNumber];

    return (
        <>
            <QuestionCard
                questionNum={-1}
                ref={cardRef}
                title={title("Extension")}
                answerTitle={title("Ext. Answer")}
                width={3}
                height={2}
                extraClassNames={
                    (isModal ? "invisible" : "") + " extension extension-card"
                }
                isStarter={true}
                questionFont={questionFont}
                subtopic={topicChoice && topicChoice.subtopic}
                difficulty={topicChoice && topicChoice.difficulty}
                id={topicChoice && topicChoice.id}
                setId={(id) =>
                    extensionTopicChoices[extNumber] &&
                    setExtensionTopicChoices((prevState) => {
                        const copy = [...prevState];
                        copy[extNumber].id = id;
                        return copy;
                    })
                }
                setDifficulty={(diff) =>
                    extensionTopicChoices[extNumber] &&
                    setExtensionTopicChoices((prevState) => {
                        const copy = [...prevState];
                        copy[extNumber].difficulty = diff;
                        return copy;
                    })
                }
                calculatorAllowed={calculatorAllowed}
                setCalculatorAllowed={setCalculatorAllowed}
                fullscreen={fullscreen}
                setShowSketch={setShowSketch}
                showAnswer={showAnswer}
                setShowAnswer={setShowAnswer}
                randomSeed={topicChoice && topicChoice?.randomSeed}
                setRandomSeed={(value) =>
                    extensionTopicChoices[extNumber] &&
                    setExtensionTopicChoices((prevState) => {
                        const copy = [...prevState];
                        if (typeof value === "function") {
                            copy[extNumber].randomSeed = value(prevState);
                        } else {
                            copy[extNumber].randomSeed = value;
                        }
                        return copy;
                    })
                }
                getNewRandomSeed={() =>
                    extensionTopicChoices[extNumber] &&
                    setExtensionTopicChoices((prevState) => {
                        const copy = [...prevState];
                        copy[extNumber].randomSeed = Math.floor(
                            Math.random() * 10000
                        );
                        return copy;
                    })
                }
                setStaleEdits={() => {
                    /* Extension questions aren't saved -- do nothing */
                }}
                questionComponent={questionComponent}
                setQuestionComponent={setQuestionComponent}
                hasRecallTags={false}
                recallTagData={null}
                setRecallTagData={() => {}}
                topicSelector={
                    <Space
                        direction={"vertical"}
                        size={"small"}
                        style={{ width: "80%" }}
                    >
                        <Tooltip
                            title="We take a sub-topic you've already selected and level it up for a challenge!"
                            color={tooltipColour}
                            placement="right"
                        >
                            <Button
                                className={"mainButton"}
                                data-tour={"extensionAutomatic"}
                                onClick={automaticallySelect}
                            >
                                AUTO GENERATE
                            </Button>
                        </Tooltip>
                        <p style={{ marginBottom: "-5px", fontWeight: "500" }}>
                            OR
                        </p>
                        <TopicSelector
                            questionNum={extNumber + 1}
                            isStarter={true}
                            initialTopicChoice={
                                extensionTopicChoices[extNumber]
                            }
                            setQuestionTopicChoices={setExtensionTopicChoices}
                            setStaleEdits={() => {}}
                            clearQuestionComponent={() =>
                                setQuestionComponent(null)
                            }
                            cardRef={cardRef.current}
                            placement={
                                !isModal && extNumber > 0
                                    ? "topLeft"
                                    : "bottomLeft"
                            }
                        />
                    </Space>
                }
            />
            <SketchModal
                isModalVisible={showSketch}
                setIsModalVisible={setShowSketch}
                questionComponent={questionComponent}
                questionNumber={-1}
                questionFont={questionFont}
                senBg={senBg}
            />
        </>
    );
}

export default ExtensionQuestion;
