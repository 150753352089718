import { apiSlice } from "helpers/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTopicsTree: builder.query({
            query: () => "/all-topics-tree"
        })
    })
});

export const { useGetTopicsTreeQuery } = extendedApiSlice;
