import { Modal } from "antd";
import React, { useState } from "react";
import MockPapersSelector from "features/mockPapers/MockPapersSelector";
import MockPaperCTGStarterForm from "features/mockPapers/MockPaperCTGStarterForm";
import BaseLessonTypeModal from "./BaseLessonTypeModal";
import { useGetPaperQuery } from "features/mockPapers/mockPapersSlice";

function MockPaperStarterModal({
    visible,
    onClose,
    onSelect = ({ paperId, questionNumbers }) => {}
}) {
    const [selectedId, setSelectedId] = useState(null);

    const { data: { shortCode: paperCode } = { shortCode: undefined } } =
        useGetPaperQuery(selectedId, {
            skip: !selectedId
        });

    return (
        <>
            <Modal
                title={null}
                closable={true}
                maskClosable={true}
                open={visible && !selectedId}
                onCancel={() => {
                    setSelectedId(null);
                    onClose();
                }}
                footer={null}
                destroyOnClose={true}
                width={"100vw"}
                className={
                    "our-library__modal_wrapper mock_paper_starter_modal"
                }
            >
                <div className={"our-library__body"}>
                    <div className={"mock-papers-selector__side-panel"}>
                        <h1>
                            GCSE Classroom Mocks
                            <div className={"subtitle"}>
                                Close The Gap Starter
                            </div>
                        </h1>
                        <p>
                            Simply select the paper you would like to close the
                            gap on.
                        </p>

                        <p>
                            You will then be asked to pick which question
                            subtopics your class needs focus on.
                        </p>
                    </div>
                    <MockPapersSelector
                        onSelect={(paperId) => setSelectedId(paperId)}
                        showDefaultOptionsOnSelect={false}
                        includeBoosterSheets={false}
                    />
                </div>
            </Modal>
            <BaseLessonTypeModal
                open={visible && selectedId}
                setOpen={setSelectedId}
                title={`${paperCode ?? "Mock Paper"} Close-the-Gap Starter`}
                closable={true}
                destroyOnClose={true}
            >
                <MockPaperCTGStarterForm
                    paperId={selectedId}
                    open={visible && selectedId}
                    className={
                        "lesson_type_modal__accent_box mock_paper_starter__questions"
                    }
                    onSubmit={({ paperId, questionNumbers }) => {
                        setSelectedId(null);
                        onSelect({ paperId, questionNumbers });
                        onClose();
                    }}
                />
            </BaseLessonTypeModal>
        </>
    );
}

export default MockPaperStarterModal;
