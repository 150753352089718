import React, { useContext } from "react";
import { Button, Popconfirm } from "antd";
import { getStepIndex, TourContext } from "features/tour/Tour";
import {
    useDeleteClassMutation,
    useGetClassesQuery
} from "features/classTags/classesSlice";
import SvgIconDelete from "assets/icons/IconDelete";
import { ClassTitle } from "features/classTags/ClassNotesModal";

function ClassList({ onOpenNotesClick }) {
    const tourContext = useContext(TourContext);

    const { data: classes } = useGetClassesQuery();
    const [deleteClass] = useDeleteClassMutation();

    const handleOpenClick = (id) => () => {
        if (
            tourContext.isOpen &&
            tourContext.currentStep === getStepIndex("classTagOpen")
        ) {
            tourContext.nextStep();
        }
        onOpenNotesClick(id);
    };

    return (
        <div className="classTags">
            {classes?.length > 0 ? (
                <div className={"classList"}>
                    {classes.map((c) => (
                        <div
                            className={"classListItem " + c.colour}
                            key={"div" + c.id}
                            onClick={handleOpenClick(c.id)}
                        >
                            <div onClick={(e) => e.stopPropagation()}>
                                <ClassTitle classId={c.id} small />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Button
                                    size="small"
                                    type="link"
                                    style={{ marginLeft: ".5em" }}
                                    data-tour={"classTagOpen"}
                                    onClick={handleOpenClick(c.id)}
                                >
                                    OPEN CLASS NOTES
                                </Button>
                                <Popconfirm
                                    title={
                                        <>
                                            <b>Delete Class: </b>Are you sure?
                                        </>
                                    }
                                    onConfirm={(e) => {
                                        e.stopPropagation();
                                        deleteClass(c.id);
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                    okText="Delete"
                                    cancelText="Cancel"
                                >
                                    <Button
                                        size="small"
                                        type="link"
                                        className={"classListDelete"}
                                        onClick={(e) => e.stopPropagation()}
                                        icon={<SvgIconDelete />}
                                    />
                                </Popconfirm>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <img
                    src={
                        process.env.PUBLIC_URL +
                        "svgs/Illustration_Open_Box.svg"
                    }
                    alt={"Outline of an empty box"}
                    className={"emptyDashboardIcon"}
                />
            )}
        </div>
    );
}

export default ClassList;
