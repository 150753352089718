import React from "react";
import { Button, Form, Input, message } from "antd";
import { changePassword, passwordValidator } from "helpers/security";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useGetUserQuery } from "features/account/accountSlice";

function ChangePasswordPage({ setModalVisible }) {
    const [form] = Form.useForm();

    const { data: user } = useGetUserQuery();
    const loggedIn = Boolean(user);

    return !loggedIn ? (
        <div>Please log in.</div>
    ) : (
        <div className={"changePasswordContainer"}>
            <Form
                form={form}
                requiredMark={false}
                className={"changePasswordForm"}
                onFinish={async (e) => {
                    try {
                        await changePassword(e.oldPassword, e.newPassword);
                        message.success("Password successfully reset");
                        setModalVisible(false);
                        form.resetFields();
                    } catch (err) {
                        message.error(
                            "Unable to change password: " + err.message
                        );
                    }
                }}
            >
                <Form.Item
                    name="oldPassword"
                    label="Old password"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: "Please enter your old password"
                        }
                    ]}
                >
                    <Input.Password
                        iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                    />
                </Form.Item>
                <Form.Item
                    name="newPassword"
                    label="New password"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: "Please enter your new password"
                        },
                        () => ({
                            validator(_, value) {
                                return passwordValidator(value);
                            }
                        })
                    ]}
                    help={
                        <>
                            {"Your password must contain the following:"}
                            <ul
                                className="ant-form-item-explain"
                                style={{
                                    width: "fit-content",
                                    marginLeft: "auto",
                                    marginRight: "auto"
                                }}
                            >
                                <li>At least 8 characters</li>
                                <li>Numbers</li>
                                <li>Uppercase letters</li>
                                <li>Lowercase letters</li>
                            </ul>
                        </>
                    }
                >
                    <Input.Password
                        iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                    />
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    label="Re-Enter New Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: "Please confirm your password"
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    !value ||
                                    getFieldValue("newPassword") === value
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error(
                                        "The two passwords that you entered do not match"
                                    )
                                );
                            }
                        })
                    ]}
                >
                    <Input.Password
                        iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                    />
                </Form.Item>
                <Form.Item className={"formButtons"}>
                    <Button key="submit" type="primary" htmlType="submit">
                        Change Password
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default ChangePasswordPage;
