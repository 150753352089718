import * as React from "react";

const SvgSubAccountsIcon = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12.5 5a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM13.4 9a3 3 0 0 0-2.994 2.804l-.197 3A3 3 0 0 0 13.203 18h4.594a3 3 0 0 0 2.994-3.196l-.197-3A3 3 0 0 0 17.601 9h-4.202Z"
            fill="currentColor"
            fillOpacity={0.6}
        />
        <path
            d="M5.5 9a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.4 13a3 3 0 0 0-2.994 2.804l-.197 3A3 3 0 0 0 6.203 22h4.594a3 3 0 0 0 2.994-3.196l-.197-3A3 3 0 0 0 10.601 13H6.399Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgSubAccountsIcon;
