import * as React from "react";

const SvgIconSettings = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10.313 4.125c0-.621.503-1.125 1.124-1.125h1.126c.62 0 1.124.504 1.124 1.125l.136.945a1.125 1.125 0 0 0 1.788.741l.764-.573c.44-.44 1.152-.44 1.591 0l.796.796c.439.44.439 1.151 0 1.59l-.573.765a1.125 1.125 0 0 0 .74 1.788l.946.136c.621 0 1.125.503 1.125 1.124v1.126c0 .62-.504 1.124-1.125 1.124l-.945.136a1.125 1.125 0 0 0-.741 1.788l.573.764c.439.44.439 1.152 0 1.591l-.796.796c-.44.439-1.151.439-1.59 0l-.765-.573a1.125 1.125 0 0 0-1.788.74l-.136.946c0 .621-.503 1.125-1.124 1.125h-1.126a1.125 1.125 0 0 1-1.124-1.125l-.136-.945a1.125 1.125 0 0 0-1.788-.741l-.764.573c-.44.439-1.152.439-1.591 0l-.796-.796a1.125 1.125 0 0 1 0-1.59l.573-.765a1.125 1.125 0 0 0-.74-1.788l-.946-.136A1.125 1.125 0 0 1 3 12.563v-1.126c0-.62.504-1.124 1.125-1.124l.945-.136a1.125 1.125 0 0 0 .741-1.788l-.573-.764a1.125 1.125 0 0 1 0-1.591l.796-.796c.44-.44 1.151-.44 1.59 0l.765.573a1.125 1.125 0 0 0 1.788-.74l.136-.946Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <circle
            cx={12}
            cy={12}
            r={2}
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgIconSettings;
