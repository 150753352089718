import * as React from "react";

const SVGIconRainbowCircle = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="4 3.5 18 17.5"
        fill="none"
        {...props}
    >
        <defs>
            <linearGradient
                id="rainbow"
                x1={12.999}
                x2={12.999}
                y1={2.079}
                y2={22.079}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.068} stopColor="#DF7A76" />
                <stop offset={0.177} stopColor="#DB9F65" />
                <stop offset={0.271} stopColor="#E3D86F" />
                <stop offset={0.396} stopColor="#ACE887" />
                <stop offset={0.521} stopColor="#8CDFE6" />
                <stop offset={0.651} stopColor="#72B2E1" />
                <stop offset={0.781} stopColor="#795DDE" />
                <stop offset={0.917} stopColor="#D564AB" />
            </linearGradient>
        </defs>
        <circle
            cx={12.999}
            cy={12.079}
            r={9.5}
            fill="url(#rainbow)"
            stroke="#fff"
            transform="rotate(-89.622 13 12.079)"
        />
    </svg>
);
export default SVGIconRainbowCircle;
