import * as React from "react";

const SvgIconPrint = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M17 20H7v-7h10v7Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7 17H6a3 3 0 0 1-3-3v-3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v3a3 3 0 0 1-3 3h-1M7 7V4h10v3"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgIconPrint;
