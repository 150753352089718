import { Button, Modal } from "antd";
import * as React from "react";

function MockPapersAdvertModal({ visible, onClose }) {
    return (
        <Modal
            title={null}
            footer={null}
            closable
            width={"fit-content"}
            style={{ maxWidth: "100rem" }}
            destroyOnClose={true}
            open={visible}
            onCancel={onClose}
            centered
            className={"help-image-modal"}
        >
            <div className={"mock-papers-advert-button-wrapper"}>
                <img
                    src={`${process.env.PUBLIC_URL}/illustrations/mock-papers-graphic.png`}
                    alt={"Help Information"}
                    className={"help-image"}
                />
                <Button
                    className={"mock-papers-advert-button"}
                    type={"primary"}
                    href={
                        "https://www.mathsplanner.com/classroom-mock-papers-waiting-list/"
                    }
                >
                    Send me Free Brochure
                </Button>
            </div>
        </Modal>
    );
}

export default MockPapersAdvertModal;
