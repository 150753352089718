import React, { useContext, useMemo, useState } from "react";
import Timer from "react-compound-timerv2";
import { EditTwoTone } from "@ant-design/icons";
import {
    Button,
    Checkbox,
    InputNumber,
    notification,
    Popover,
    Space
} from "antd";
import SvgIconStop from "assets/icons/IconStop";
import SvgIconPlay from "assets/icons/IconPlay";
import SvgIconEdit from "assets/icons/IconEdit";
import SvgIconReload from "assets/icons/IconReload";
import { getStepIndex, TourContext } from "features/tour/Tour";
import { formatAsTimeString, formatAsTimeValue } from "helpers/miscHelpers";
import {
    useGetUserQuery,
    useSetDefaultCountdownMutation
} from "features/account/accountSlice";
import { createSelector } from "@reduxjs/toolkit";

function Countdown({
    initialTimeMinutes = 5,
    startCallback = () => {},
    endCallback = () => {},
    hasDefaultCheckbox = false
}) {
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [timerTime, setTimerTime] = useState(initialTimeMinutes * 60 * 1000);
    const [editTimeVal, setEditTimeVal] = useState(
        formatAsTimeString(timerTime)
    );
    const tourContext = useContext(TourContext);

    async function confirmTimeChange(setTime) {
        const timeVal = formatAsTimeValue(editTimeVal);
        setTimerTime(timeVal);
        setTime(timeVal);
        return timeVal;
    }

    // Time's Up Notification
    const openNotification = () => {
        if (!tourContext.isOpen) {
            notification.open({
                placement: "topLeft",
                message: "TIME'S UP!",
                description:
                    "Finish the question you are on and get ready to mark!",
                icon: <EditTwoTone twoToneColor="#52c41a" />,
                duration: 20
            });
        }
    };

    function TimeEditPopupContent(setTime, hasDefaultCheckbox = false) {
        const [usingDefault, setUsingDefault] = useState(false);
        const selectDefaultCountdownForUser = useMemo(() => {
            return createSelector(
                (res) => res.data,
                (user) => user?.default_countdown
            );
        }, []);
        const { default_countdown: defaultCountdown } = useGetUserQuery(
            undefined,
            {
                skip: !hasDefaultCheckbox,
                selectFromResult: (result) => ({
                    default_countdown: selectDefaultCountdownForUser(result)
                })
            }
        );
        const [setDefaultCountdown] = useSetDefaultCountdownMutation();

        const editTimeMatchesDefault =
            formatAsTimeValue(editTimeVal) === defaultCountdown;

        return (
            <Space
                direction={hasDefaultCheckbox ? "vertical" : "horizontal"}
                align={"center"}
                style={{ width: "100%" }}
            >
                <InputNumber
                    defaultValue={initialTimeMinutes}
                    min={1}
                    max={20}
                    formatter={(value) => `${value} mins`}
                    parser={(value) => value.replace(" mins", "")}
                    onChange={(val) => setEditTimeVal(val + ":00")}
                />
                {hasDefaultCheckbox && (
                    <Checkbox
                        checked={editTimeMatchesDefault || usingDefault}
                        disabled={editTimeMatchesDefault}
                        onChange={(e) => setUsingDefault(e.target.checked)}
                        style={{ marginLeft: "8px" }}
                    >
                        Set as default?
                    </Checkbox>
                )}
                <Button
                    onClick={() => {
                        confirmTimeChange(setTime).then((timeVal) => {
                            if (hasDefaultCheckbox && usingDefault) {
                                setDefaultCountdown(timeVal);
                            }
                        });
                        setShowEditPopup(false);
                    }}
                >
                    Set
                </Button>
            </Space>
        );
    }

    const timerBody = ({
        start,
        resume,
        pause,
        reset,
        getTimerState,
        setTime
    }) => (
        <>
            <div className="timer-main-controls">
                <div className={"timerText"}>
                    <Timer.Minutes />:
                    <Timer.Seconds
                        formatValue={(value) =>
                            value.toString().padStart(2, "0")
                        }
                    />
                </div>
            </div>

            <div className="timer-sec-controls">
                <SvgIconReload
                    className={"secondary"}
                    style={{ marginLeft: 6 }}
                    onClick={() => {
                        pause();
                        reset();
                    }}
                />
                {getTimerState() === "PLAYING" ? (
                    <SvgIconStop
                        style={{ marginLeft: 5, fontSize: "1.1em" }}
                        onClick={() => {
                            if (
                                tourContext.isOpen &&
                                tourContext.currentStep ===
                                    getStepIndex("timer")
                            ) {
                                tourContext.nextStep();
                            }
                            pause();
                        }}
                    />
                ) : (
                    <SvgIconPlay
                        style={{ marginLeft: 5, fontSize: "1.1em" }}
                        onClick={() => {
                            if (
                                tourContext.isOpen &&
                                tourContext.currentStep ===
                                    getStepIndex("timer")
                            ) {
                                tourContext.nextStep();
                            }
                            start();
                            resume();
                            startCallback();
                        }}
                    />
                )}
                <Popover
                    placement="topRight"
                    title="Change Timer"
                    overlayClassName={"timerPopover"}
                    open={showEditPopup}
                    content={() =>
                        TimeEditPopupContent(setTime, hasDefaultCheckbox)
                    }
                    onOpenChange={(state) => setShowEditPopup(state)}
                    trigger="click"
                >
                    <SvgIconEdit
                        className={"secondary"}
                        style={{ marginLeft: 6 }}
                    />
                </Popover>
            </div>
        </>
    );

    return (
        <>
            <Timer
                initialTime={timerTime}
                direction="backward"
                startImmediately={false}
                checkpoints={[
                    {
                        time: 0,
                        callback: () => {
                            openNotification();
                            endCallback();
                        }
                    }
                ]}
                lastUnit="m"
            >
                {timerBody}
            </Timer>
        </>
    );
}

export default Countdown;
