import { Modal } from "antd";
import * as React from "react";

function HelpImageModal({ imgSrc, visible, close }) {
    return (
        <Modal
            title={null}
            footer={null}
            closable
            width={"fit-content"}
            style={{ maxWidth: "95%" }}
            destroyOnClose={true}
            open={visible}
            onCancel={close}
            centered
            className={"help-image-modal"}
        >
            <img
                src={imgSrc}
                alt={"Help Information"}
                className={"help-image"}
            />
        </Modal>
    );
}

export default HelpImageModal;
