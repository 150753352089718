import React, { useCallback, useContext, useState } from "react";
import {
    Alert,
    Badge,
    Button,
    Divider,
    Form,
    Input,
    message,
    notification,
    Popover,
    Select,
    Slider,
    Space,
    Spin,
    Tag,
    Tooltip
} from "antd";
import { getStepIndex, TourContext } from "features/tour/Tour";
import Icon, {
    CloseOutlined,
    FullscreenExitOutlined,
    InfoCircleOutlined
} from "@ant-design/icons";
import { tooltipColour } from "helpers/starterHelpers";
import { useGetClassesQuery } from "features/classTags/classesSlice";
import { useDispatch } from "react-redux";
import { setSavesDrawerOpen } from "features/dashboard/dashboardSlice";
import { useHistory } from "react-router-dom";
import LessonContext, {
    setAllShowAnswersValue
} from "../context/LessonContext";

//SVG Custom Icons
import SvgIconPrint from "assets/icons/IconPrint";
import SvgIconSave from "assets/icons/IconSave";
import SvgIconLink from "assets/icons/IconLink";
import SvgIconCheckAll from "assets/icons/IconCheckAll";
import SvgFullScreenOpen from "assets/icons/FullScreenOpen";
import SvgIconValuesReload from "assets/icons/IconValuesReload";
import SvgIconReload from "assets/icons/IconReload";
import SvgIconFire from "assets/icons/IconFire";
import { ColourTags } from "features/classTags/ClassNotesModal";
import SVGIconRainbowCircle from "assets/icons/RainbowCircle";

function LessonFunctionButtons({
    fullscreen,
    makeFullscreen,
    disabled = false,
    activatePrintingModal = () => {},
    hidePrint = false,
    hideExtension = false,
    hideLink = false,
    redirectOnSave = true,
    onSave = (id) => {}
}) {
    const history = useHistory();
    const dispatch = useDispatch();
    const openSavesDrawer = useCallback(
        () => dispatch(setSavesDrawerOpen(true)),
        [dispatch]
    );
    const {
        questionFontSizeState: [questionFont, setQuestionFont],
        senBackgroundState: [questionBackground, setQuestionBackground],
        starterGridDimensionsState: [
            { width: gridWidth },
            setStarterGridDimensions
        ],
        isExistingSave,
        saveCurrentLessonStateAsNew,
        staleEditsState: [staleEdits, setStaleEdits],
        loadNameState: [loadName, setLoadName],
        isSharedSaveState: [isSharedSave],
        saveLocationStringState: [saveLocationString, setSaveLocationString],
        starterChoicesState: [starterQuestionTopicChoices],
        plenaryChoicesState: [plenaryQuestionTopicChoices],
        showStarterAnswersState: [showStarterAnswers, setShowStarterAnswers],
        showPlenaryAnswersState: [showPlenaryAnswers, setShowPlenaryAnswers],
        showStarterExtensionState: [showExtension, setShowExtension],
        starterCardRefs,
        plenaryCardRefs,
        isSaveable
    } = useContext(LessonContext);
    const setGridWidth = useCallback(
        (val) => setStarterGridDimensions((prev) => ({ ...prev, width: val })),
        [setStarterGridDimensions]
    );
    const saveIsInDrawer = Boolean(saveLocationString === "Saves Drawer");
    const hasAnyStarterQuestions = starterQuestionTopicChoices.some(
        (tc) => tc?.difficulty
    );
    const hasAnyPlenaryQuestions = plenaryQuestionTopicChoices.some(
        (tc) => tc?.difficulty
    );
    const isPlenaryVisible = window.location.hash === "#plenary";
    const currentShowAnswers = isPlenaryVisible
        ? showPlenaryAnswers
        : showStarterAnswers;
    const currentSetShowAnswers = isPlenaryVisible
        ? setShowPlenaryAnswers
        : setShowStarterAnswers;
    const showAllCurrentAnswers = useCallback(
        (val) => {
            setAllShowAnswersValue(currentSetShowAnswers, val);
        },
        [currentSetShowAnswers]
    );
    const allCurrentAnswersVisible =
        currentShowAnswers?.length &&
        currentShowAnswers.every((v) => v === true || v == null);
    const noCurrentAnswers =
        !currentShowAnswers || currentShowAnswers.length === 0;
    const refreshAllQuestionValues = useCallback(() => {
        const cardRefs = isPlenaryVisible ? plenaryCardRefs : starterCardRefs;
        if (!cardRefs?.current) {
            return;
        }
        cardRefs.current.forEach((ref) => ref.refreshValues());
    }, [isPlenaryVisible, plenaryCardRefs, starterCardRefs]);

    const refreshAllQuestionTemplates = useCallback(() => {
        const cardRefs = isPlenaryVisible ? plenaryCardRefs : starterCardRefs;
        if (!cardRefs?.current) {
            return;
        }
        cardRefs.current.forEach((ref) => ref.refreshTemplate());
    }, [isPlenaryVisible, plenaryCardRefs, starterCardRefs]);

    const [showFullscreenTooltip, setShowFullscreenTooltip] = useState(false);
    const [showFontSizeTooltip, setShowFontSizeTooltip] = useState(false);
    const [showFontSizeControl, setShowFontSizeControl] = useState(false);
    const [showSENTooltip, setShowSENTooltip] = useState(false);
    const [showSENControl, setShowSENControl] = useState(false);
    const [showSavePopup, setShowSavePopup] = useState(false);
    const [newSave, setNewSave] = useState(!isExistingSave);

    const { data: classes } = useGetClassesQuery();
    const tourContext = useContext(TourContext);

    const newSavePopup = (
        <div data-tour={"saveDetails"}>
            <Space direction="vertical" size="middle">
                <Form
                    className={"popoverForm"}
                    requiredMark={false}
                    onFinish={(val) => {
                        const saveColumn =
                            tourContext.isOpen &&
                            tourContext.currentStep ===
                                getStepIndex("saveDetails") &&
                            val.name &&
                            val.name !== ""
                                ? "monday"
                                : null;
                        saveCurrentLessonStateAsNew(
                            val.name,
                            val.classId,
                            saveColumn,
                            (id) => {
                                message.success("Saved");
                                setNewSave(false);
                                if (redirectOnSave) {
                                    history.replace(
                                        "/starter?id=" +
                                            encodeURIComponent(id) +
                                            window.location.hash
                                    );
                                    setShowSavePopup(false);
                                } else {
                                    setSaveLocationString("Saves Drawer");
                                    setLoadName(val.name);
                                }
                                onSave(id);
                            }
                        );
                    }}
                >
                    <Form.Item
                        name="name"
                        label={"Name"}
                        rules={[
                            {
                                required: true,
                                message: "Please enter a name"
                            }
                        ]}
                    >
                        <Input
                            placeholder="Give this starter a name!"
                            prefix={
                                <Tooltip title="e.g. Monday L1" color="#E82E8B">
                                    <InfoCircleOutlined
                                        style={{ color: "rgba(0,0,0,.45)" }}
                                    />
                                </Tooltip>
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="classId"
                        label={"Class Tag"}
                        initialValue={null}
                    >
                        <Select
                            popupClassName={"colourSelectDropdown"}
                            dropdownMatchSelectWidth={false}
                        >
                            <Select.Option key={"opt-none"} value={null}>
                                <div className={"tagWrapper clickable"}>
                                    <Tag>None</Tag>
                                </div>
                            </Select.Option>
                            {classes &&
                                classes.length > 0 &&
                                classes.map((c) => (
                                    <Select.Option key={c.id} value={c.id}>
                                        <div className={"tagWrapper clickable"}>
                                            <Tag color={c.colour}>{c.name}</Tag>
                                        </div>
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="middle"
                            style={{ float: "right" }}
                            // data-tour={"saveStarterButton"}
                        >
                            {!redirectOnSave ? "Save a Copy to Drawer" : "SAVE"}
                        </Button>
                    </Form.Item>
                </Form>
            </Space>
        </div>
    );

    const existingSavePopup = (
        <div>
            <Space direction="vertical" size="middle">
                {isSharedSave ? (
                    <Alert
                        message="To save edits to someone else's starter please save a new copy first."
                        type="warning"
                        showIcon
                    />
                ) : staleEdits ? (
                    <>
                        <Space direction={"horizontal"}>
                            <Alert
                                message="Your changes are being automatically saved, please wait..."
                                type="info"
                                showIcon
                            />
                            <Spin />
                        </Space>
                    </>
                ) : (
                    <Alert
                        message="All changes automatically saved!"
                        type="success"
                        showIcon
                    />
                )}
                {saveLocationString && (
                    <>
                        <div style={{ textAlign: "center" }}>
                            <p style={{ margin: 0 }}>
                                This lesson is saved{" "}
                                {saveIsInDrawer
                                    ? "in your"
                                    : "on your timetable:"}
                            </p>
                            <p style={{ margin: 0 }}>
                                <b>{saveLocationString}</b>
                            </p>
                            {!redirectOnSave && (
                                <p style={{ margin: 0 }}>
                                    as <b>"{loadName}"</b>
                                </p>
                            )}
                            <Button
                                type={"link"}
                                style={{ marginTop: "1rem", marginBottom: 0 }}
                                onClick={() => {
                                    history.push("/dashboard");
                                    if (saveIsInDrawer) {
                                        openSavesDrawer();
                                    }
                                }}
                            >
                                Take me to my{" "}
                                {saveIsInDrawer ? "drawer" : "timetable"}
                            </Button>
                        </div>
                        <Divider style={{ margin: 0 }} />
                    </>
                )}
                <Button
                    type="primary"
                    size="middle"
                    style={{ float: "right" }}
                    onClick={() => setNewSave(true)}
                >
                    Save a New Copy
                </Button>
            </Space>
        </div>
    );

    return (
        <div className="function-btns" data-tour={"starterSettings"}>
            {/*-- FullScreen Btn -----------------------------------------------------------*/}
            <Tooltip
                title={fullscreen ? "Exit Full Screen" : "Full Screen"}
                color={tooltipColour}
                placement="left"
                open={showFullscreenTooltip}
                onOpenChange={setShowFullscreenTooltip}
            >
                <Button
                    icon={
                        (document.fullscreenEnabled ||
                            document.webkitFullscreenEnabled ||
                            document.msFullscreenEnabled) &&
                        (fullscreen ? (
                            <FullscreenExitOutlined />
                        ) : (
                            <SvgFullScreenOpen />
                        ))
                    }
                    data-tour={"exitFullscreen"}
                    type="primary"
                    size="middle"
                    ghost="true"
                    onClick={() => {
                        if (tourContext.isOpen) {
                            if (
                                tourContext.currentStep ===
                                getStepIndex("exitFullscreen")
                            ) {
                                makeFullscreen(!fullscreen);
                                tourContext.nextStep();
                            }
                        } else {
                            makeFullscreen(!fullscreen);
                        }
                        setShowFullscreenTooltip(false);
                    }}
                />
            </Tooltip>

            {/*-- Share Link Btn -----------------------------------------------------------*/}
            {!hideLink && (
                <Tooltip
                    title="Copy Sharable Link"
                    color={tooltipColour}
                    placement="left"
                >
                    <Button
                        type="primary"
                        icon={<SvgIconLink />}
                        ghost="true"
                        size="middle"
                        onClick={() => {
                            // Create dummy input containing URL and select it
                            const dummy = document.createElement("input");
                            const text = window.location.href;
                            document.body.appendChild(dummy);
                            dummy.value = text;
                            dummy.select();
                            dummy.setSelectionRange(0, 99999);
                            document.execCommand("copy");
                            document.body.removeChild(dummy);

                            notification.open({
                                message: "Success",
                                duration: 1.5,
                                description: "Link copied to clipboard."
                            });
                        }}
                    />
                </Tooltip>
            )}

            {!isPlenaryVisible && (
                <div className={"grid-height-toggle-container"}>
                    <Tooltip
                        title="Switch to 4 Questions"
                        color={tooltipColour}
                        placement={"left"}
                    >
                        <Button
                            type={gridWidth === 2 ? "primary" : "default"}
                            onClick={() => {
                                setGridWidth(2);
                                setStaleEdits(true);
                            }}
                        >
                            4
                        </Button>
                    </Tooltip>

                    <Tooltip
                        title="Switch to 6 Questions"
                        color={tooltipColour}
                        placement={"left"}
                    >
                        <Button
                            type={gridWidth === 3 ? "primary" : "default"}
                            onClick={() => {
                                setShowExtension(false);
                                setGridWidth(3);
                                setStaleEdits(true);
                            }}
                        >
                            6
                        </Button>
                    </Tooltip>
                </div>
            )}

            {/*-- Save Starter Btn -----------------------------------------------------------*/}
            {isSaveable && (
                <Tooltip
                    title={
                        redirectOnSave ? "Save Template" : "Save Copy to Drawer"
                    }
                    color={tooltipColour}
                    placement="left"
                >
                    <Popover
                        overlayClassName={"newSavePopover"}
                        placement="leftTop"
                        content={newSave ? newSavePopup : existingSavePopup}
                        trigger="click"
                        open={showSavePopup}
                        onOpenChange={(visible) => {
                            if (tourContext.isOpen) {
                                if (
                                    tourContext.currentStep ===
                                    getStepIndex("saveOpen")
                                ) {
                                    setShowSavePopup((prev) => {
                                        if (!prev) {
                                            tourContext.nextStep();
                                        }
                                        return true;
                                    });
                                }
                            } else {
                                setShowSavePopup(visible);
                                if (!visible && redirectOnSave) {
                                    setNewSave(!isExistingSave);
                                }
                            }
                        }}
                    >
                        <Badge
                            color={staleEdits ? "orange" : "green"}
                            dot
                            offset={[-5, 4]}
                        >
                            <Button
                                style={{ marginTop: 4 }}
                                icon={<SvgIconSave />}
                                className={"btn-save-starter"}
                                data-tour={"saveOpen"}
                                disabled={disabled}
                                type="primary"
                                size="middle"
                                ghost="true"
                            />
                        </Badge>
                    </Popover>
                </Tooltip>
            )}

            {/*-- Print Btn -----------------------------------------------------------*/}
            {!hidePrint && (
                <Tooltip
                    title="Print Questions"
                    color={tooltipColour}
                    placement="left"
                >
                    <Button
                        size="middle"
                        disabled={disabled}
                        ghost="true"
                        type="primary"
                        onClick={() => {
                            if (!tourContext.isOpen) {
                                activatePrintingModal();
                            }
                        }}
                        icon={<SvgIconPrint />}
                    />
                </Tooltip>
            )}

            {/*-- Show All Answers Btn -----------------------------------------------------------*/}
            {allCurrentAnswersVisible ? (
                <Tooltip
                    title="Hide All Answers"
                    color={tooltipColour}
                    placement="left"
                >
                    <Button
                        size="middle"
                        ghost="true"
                        type="primary"
                        data-tour={"showAllAnswers"}
                        onClick={() => {
                            if (
                                tourContext.isOpen &&
                                tourContext.currentStep ===
                                    getStepIndex("showAllAnswers")
                            ) {
                                tourContext.nextStep();
                            }
                            showAllCurrentAnswers(false);
                        }}
                        aria-label={"Hide All Answers"}
                        icon={<CloseOutlined />}
                    />
                </Tooltip>
            ) : noCurrentAnswers ? (
                <Tooltip
                    title="Show All Answers"
                    color={tooltipColour}
                    placement="left"
                >
                    <Button
                        size="middle"
                        ghost="true"
                        type="primary"
                        data-tour={"showAllAnswers"}
                        aria-label={"Show All Answers"}
                        icon={<SvgIconCheckAll />}
                        disabled
                    />
                </Tooltip>
            ) : (
                <Tooltip
                    title="Show All Answers"
                    color={tooltipColour}
                    placement="left"
                >
                    <Button
                        size="middle"
                        ghost="true"
                        type="primary"
                        data-tour={"showAllAnswers"}
                        aria-label={"Show All Answers"}
                        icon={<SvgIconCheckAll />}
                        onClick={() => {
                            if (
                                tourContext.isOpen &&
                                tourContext.currentStep ===
                                    getStepIndex("showAllAnswers")
                            ) {
                                tourContext.nextStep();
                            }
                            showAllCurrentAnswers(true);
                        }}
                    />
                </Tooltip>
            )}

            <Tooltip
                title="Change All Values"
                color={tooltipColour}
                placement="left"
            >
                <Button
                    size="middle"
                    ghost="true"
                    type="primary"
                    icon={<Icon component={() => <SvgIconValuesReload />} />}
                    onClick={() => refreshAllQuestionValues(isPlenaryVisible)}
                    disabled={
                        isPlenaryVisible
                            ? !hasAnyPlenaryQuestions
                            : !hasAnyStarterQuestions
                    }
                />
            </Tooltip>
            <Tooltip
                title="Change All Questions"
                color={tooltipColour}
                placement="left"
            >
                <Button
                    size="middle"
                    ghost="true"
                    type="primary"
                    icon={<Icon component={() => <SvgIconReload />} />}
                    onClick={() =>
                        refreshAllQuestionTemplates(isPlenaryVisible)
                    }
                    disabled={
                        isPlenaryVisible
                            ? !hasAnyPlenaryQuestions
                            : !hasAnyStarterQuestions
                    }
                />
            </Tooltip>
            <Tooltip
                title="Set Font Size"
                color={tooltipColour}
                placement="left"
                trigger={!showFontSizeControl ? "hover" : "none"}
                open={!showFontSizeControl && showFontSizeTooltip}
                onOpenChange={setShowFontSizeTooltip}
            >
                <Popover
                    trigger={"click"}
                    open={showFontSizeControl}
                    placement="left"
                    content={
                        <Slider
                            onChange={(val) => {
                                setQuestionFont(val);
                                setStaleEdits(true);
                            }}
                            value={questionFont}
                            min={1}
                            max={20}
                            style={{ width: "15rem" }}
                        />
                    }
                    onOpenChange={(open) => {
                        setShowFontSizeControl(open);
                        if (open) {
                            setShowFontSizeTooltip(false);
                        }
                    }}
                >
                    <Button
                        size="middle"
                        ghost="true"
                        type="primary"
                        icon={<span>Aa</span>}
                    />
                </Popover>
            </Tooltip>
            <Tooltip
                title="Set SEN background"
                color={tooltipColour}
                placement="left"
                trigger={!showSENControl ? "hover" : "none"}
                open={!showSENControl && showSENTooltip}
                onOpenChange={setShowSENTooltip}
            >
                <Popover
                    trigger={"click"}
                    placement="left"
                    open={showSENControl}
                    content={
                        <ColourTags
                            colour={questionBackground}
                            onClick={(selected) => {
                                setQuestionBackground(selected);
                                setStaleEdits(true);
                            }}
                            includeNone
                        />
                    }
                    onOpenChange={(open) => {
                        setShowSENControl(open);
                        if (open) {
                            setShowSENTooltip(false);
                        }
                    }}
                >
                    <Button
                        size="middle"
                        ghost="true"
                        type="primary"
                        icon={<Icon component={SVGIconRainbowCircle} />}
                    />
                </Popover>
            </Tooltip>
            {!isPlenaryVisible && !hideExtension && (
                <Tooltip
                    title={
                        (showExtension ? "Hide" : "Show") +
                        " Extension Question(s)"
                    }
                    color={tooltipColour}
                    placement="left"
                >
                    <Button
                        id="btn-extension"
                        size="middle"
                        type="primary"
                        ghost="true"
                        disabled={disabled}
                        icon={
                            <Icon
                                component={() => (
                                    <SvgIconFire alt={"Extension Icon"} />
                                )}
                            />
                        }
                        onClick={() => {
                            if (
                                tourContext.isOpen &&
                                tourContext.currentStep ===
                                    getStepIndex("extensionOpen")
                            ) {
                                tourContext.nextStep();
                            }
                            setShowExtension((p) => !p);
                        }}
                    />
                </Tooltip>
            )}
        </div>
    );
}

export default LessonFunctionButtons;
