import {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState
} from "react";
import { useGetUserQuery } from "features/account/accountSlice";
import { getStarterDefaults } from "helpers/miscHelpers";
import {
    useGetSavedStartersQuery,
    useNewCustomStarterMutation
} from "features/timetable/savesSlice";
import { message } from "antd";
import { createSelector } from "@reduxjs/toolkit";
import { DEFAULT_PREFERENCES } from "helpers/starterHelpers";

const defaults = getStarterDefaults(null);

const LessonContext = createContext({
    starterGridDimensionsState: [
        { width: defaults.gridWidth, height: defaults.gridHeight },
        () => {}
    ],
    plenaryGridDimensionsState: {
        width: defaults.plenaryWidth,
        height: defaults.plenaryHeight
    },
    questionFontSizeState: [DEFAULT_PREFERENCES.questionFontSize, () => {}],
    senBackgroundState: [DEFAULT_PREFERENCES.questionBackground, () => {}],
    showStarterAnswersState: [false, () => {}],
    showPlenaryAnswersState: [false, () => {}],
    showStarterExtensionState: [false, () => {}],
    starterHasRecallTagsState: [DEFAULT_PREFERENCES.hasRecallTags, () => {}],
    isNewState: [true, () => {}],
    isSharedSaveState: [true, () => {}],
    staleEditsState: [false, () => {}],
    loadNameState: ["", () => {}],
    loadedFeedbackState: [
        {
            wwwTitle: null,
            ebiTitle: null,
            wwwStatements: null,
            ebiStatements: null
        },
        () => {}
    ],
    starterChoicesState: [],
    extensionChoicesState: [],
    starterCalculatorAllowedState: [[], () => {}],
    plenaryCalculatorAllowedState: [[], () => {}],
    plenaryChoicesState: [],
    saveCurrentLessonStateAsNew: () => {},
    isExistingSave: false,
    saveLocationStringState: [null, () => {}],
    plenaryFeedbackRef: null,
    starterSectionRef: null,
    plenarySectionRef: null,
    starterCardRefs: [],
    plenaryCardRefs: [],
    starterTitleState: [null, () => {}],
    starterCueImageState: [null, () => {}],
    starterLearningObjectiveState: [null, () => {}],
    showSettingsPanelState: [false, () => {}],
    isSaveable: true
});

export function setAllShowAnswersValue(setter, val) {
    setter((prev) => {
        let copy = [...prev];
        for (let i = 0; i < copy.length; i++) {
            if (copy[i] !== undefined && copy[i] !== null) {
                copy[i] = val;
            }
        }
        return copy;
    });
}

export function LessonContextProvider({
    loadId = null,
    saveable = true,
    children,
    ...props
}) {
    const { data: user } = useGetUserQuery();
    const userPreferences = getStarterDefaults(user);

    // ========================= General Lesson State =========================
    const questionFontSizeState = useState(userPreferences.fontSize);
    const senBackgroundState = useState(userPreferences.senBackground);
    const isExistingSave = Boolean(loadId);
    const isNewState = useState(false);
    const [staleEdits, setStaleEditsInner] = useState(false);
    const setStaleEdits = useMemo(
        () => (saveable ? setStaleEditsInner : () => {}),
        [saveable]
    );
    const loadNameState = useState("");
    const isSharedSaveState = useState(false);
    const saveLocationStringState = useState(null);
    const showSettingsPanelState = useState(false);

    // ========================= Starter State =========================
    const starterGridDimensionsState = useState({
        width: userPreferences?.gridWidth ?? 3,
        height: userPreferences.gridHeight
    });
    const starterChoicesState = useState(userPreferences.questionChoices);
    const extensionChoicesState = useState(userPreferences.extensionChoices);
    const starterCalculatorAllowedState = useState([]);
    const plenaryCalculatorAllowedState = useState([]);
    const starterTitleState = useState(null);
    const starterCueImageState = useState(userPreferences.cueImage);
    const starterLearningObjectiveState = useState(
        userPreferences.learningObjective
    );
    const starterHasRecallTagsState = useState(userPreferences.hasRecallTags);
    const showStarterAnswersState = useState([]);
    const showStarterExtensionState = useState(false);
    const starterCardRefs = useRef([]);
    const starterSectionRef = useRef();

    // ========================= Plenary State =========================
    const plenaryChoicesState = useState(
        userPreferences.plenaryQuestionChoices
    );
    const plenaryFeedbackRef = useRef();
    const plenaryCardRefs = useRef([]);
    const plenarySectionRef = useRef();
    const loadedFeedbackState = useState({
        wwwTitle: null,
        ebiTitle: null,
        wwwStatements: null,
        ebiStatements: null
    });
    const showPlenaryAnswersState = useState([]);

    // ========================= Lesson Save Functions =========================
    const getSaveNames = useMemo(() => {
        return createSelector(
            (res) => res.data,
            (savesTable) =>
                Object.values(savesTable || {})
                    .flat()
                    .filter((save) => save)
                    .map((s) => s.name.toUpperCase())
        );
    }, []);
    const { names: saveNames } = useGetSavedStartersQuery(undefined, {
        selectFromResult: (result) => ({
            names: getSaveNames(result)
        })
    });
    const [saveAsNew] = useNewCustomStarterMutation();
    const saveCurrentLessonStateAsNew = useCallback(
        (name, classId, timetableColumn, successCallback) => {
            if (saveNames.includes(name.toUpperCase())) {
                message.error("A save with that name already exists.");
                return;
            }
            const saveData = formatStarterStateForSave(
                {
                    title: starterTitleState[0],
                    learningObjective: starterLearningObjectiveState[0],
                    plenaryTitle: null,
                    feedbackRef: plenaryFeedbackRef,
                    questionTopicChoices: starterChoicesState[0],
                    plenaryQuestionTopicChoices: plenaryChoicesState[0],
                    starterGridWidth: starterGridDimensionsState[0].width,
                    starterGridHeight: starterGridDimensionsState[0].height,
                    hasPlenary: true,
                    hasRecallTags: starterHasRecallTagsState[0],
                    plenaryGridWidth: 3,
                    plenaryGridHeight: 1,
                    senBackground: senBackgroundState[0],
                    fontSize: questionFontSizeState[0],
                    cueImage: starterCueImageState[0]
                },
                name,
                classId,
                timetableColumn
            );
            saveAsNew(saveData)
                .unwrap()
                .then(async (body) => {
                    setStaleEdits(false);
                    successCallback(body.id);
                });
        },
        [
            saveNames,
            starterTitleState,
            starterLearningObjectiveState,
            starterChoicesState,
            plenaryChoicesState,
            starterGridDimensionsState,
            starterHasRecallTagsState,
            senBackgroundState,
            questionFontSizeState,
            starterCueImageState,
            saveAsNew,
            setStaleEdits
        ]
    );

    // ========================= Init Context State =========================
    const defaultState = useContext(LessonContext);
    const lessonState = useMemo(
        () => ({
            ...defaultState,
            starterGridDimensionsState,
            questionFontSizeState,
            senBackgroundState,
            showStarterAnswersState,
            showPlenaryAnswersState,
            showStarterExtensionState,
            starterHasRecallTagsState,
            isNewState,
            isSharedSaveState,
            staleEditsState: [staleEdits, setStaleEdits],
            loadedFeedbackState,
            loadNameState,
            starterChoicesState,
            plenaryChoicesState,
            extensionChoicesState,
            saveCurrentLessonStateAsNew,
            isExistingSave,
            saveLocationStringState,
            plenaryFeedbackRef,
            starterSectionRef,
            plenarySectionRef,
            starterCardRefs,
            plenaryCardRefs,
            starterTitleState,
            starterLearningObjectiveState,
            starterCueImageState,
            showSettingsPanelState,
            isSaveable: saveable,
            starterCalculatorAllowedState,
            plenaryCalculatorAllowedState
        }),
        [
            defaultState,
            starterGridDimensionsState,
            questionFontSizeState,
            senBackgroundState,
            showStarterAnswersState,
            showPlenaryAnswersState,
            showStarterExtensionState,
            starterHasRecallTagsState,
            isNewState,
            isSharedSaveState,
            staleEdits,
            setStaleEdits,
            loadedFeedbackState,
            loadNameState,
            starterChoicesState,
            plenaryChoicesState,
            extensionChoicesState,
            saveCurrentLessonStateAsNew,
            isExistingSave,
            saveLocationStringState,
            starterTitleState,
            starterLearningObjectiveState,
            starterCueImageState,
            showSettingsPanelState,
            saveable,
            starterCalculatorAllowedState,
            plenaryCalculatorAllowedState
        ]
    );

    return (
        <LessonContext.Provider value={lessonState} {...props}>
            {children}
        </LessonContext.Provider>
    );
}

export function formatStarterStateForSave(
    data,
    name,
    classId = undefined,
    timetableColumn = undefined,
    timetablePeriod = undefined
) {
    const wwwTitle = data.feedbackRef?.current?.wwwTitle;
    const ebiTitle = data.feedbackRef?.current?.ebiTitle;
    return {
        name: name,
        classId: classId,
        timetableColumn: timetableColumn || "backlog",
        userOrder: timetablePeriod === 0 ? 0 : timetablePeriod || null,
        title: data.title,
        learningObjective: data.learningObjective,
        plenaryTitle: data.plenaryTitle,
        wwwTitle: wwwTitle,
        ebiTitle: ebiTitle,
        wwwStatements: data.feedbackRef?.current
            ? data.feedbackRef.current.getWwwStatements()
            : null,
        ebiStatements: data.feedbackRef?.current
            ? data.feedbackRef.current.getEbiStatements()
            : null,
        questionChoices: data.questionTopicChoices,
        plenaryQuestionChoices: data.plenaryQuestionTopicChoices,
        gridWidth: data.starterGridWidth,
        gridHeight: data.starterGridHeight,
        hasPlenary: data.hasPlenary,
        hasRecallTags: data.hasRecallTags,
        plenaryWidth: data.plenaryGridWidth,
        plenaryHeight: data.plenaryGridHeight,
        fontSize: data.fontSize,
        senBackground: data.senBackground,
        cueImage: data.cueImage
    };
}

export default LessonContext;
