import { Button, Checkbox, Spin } from "antd";
import React, { useMemo, useState } from "react";
import { useGetPaperQuery } from "features/mockPapers/mockPapersSlice";
import PremiumFeatureModal from "commonComponents/PremiumFeatureModal";
import { useGetUserQuery } from "features/account/accountSlice";
import { hasPremiumAccess } from "helpers/security";

function MockPaperCTGStarterForm({
    paperId,
    className = "",
    open = true,
    onSubmit,
    loadingStarter = false
}) {
    const { data: user } = useGetUserQuery();
    const userHasPremiumAccess = hasPremiumAccess(user);
    const [showPremiumFeatureModel, setShowPremiumFeatureModal] =
        useState(false);
    const { data: paper, isFetching: loading } = useGetPaperQuery(paperId, {
        skip: !open
    });
    const questionOptions = useMemo(
        () =>
            paper?.questions.map(({ name, question }) => ({
                value: name,
                disabled:
                    !Boolean(question?.subTopics?.length) ||
                    question.subTopics.every((st) => st?.disabled),
                label: (
                    <div className={"question_num_and_subtopic_list__label"}>
                        <b>{name}</b>{" "}
                        {(question?.subTopics || [])
                            .map(({ value }) => value)
                            .join(" / ")}
                    </div>
                )
            })),
        [paper?.questions]
    );

    const [questionChoices, setQuestionChoices] = useState(null);

    if (!open) {
        return null;
    }

    return (
        <>
            <PremiumFeatureModal
                open={showPremiumFeatureModel}
                onClose={() => setShowPremiumFeatureModal(false)}
            />
            <div className={className}>
                <h3>Select up-to 6 questions</h3>
                {loading ? (
                    <Spin />
                ) : (
                    <Checkbox.Group
                        className={"question_num_and_subtopic_list"}
                        options={questionOptions}
                        value={questionChoices}
                        onChange={(list) => {
                            setQuestionChoices((prev) => {
                                if (
                                    prev?.length >= 6 &&
                                    list?.length > prev?.length
                                ) {
                                    return prev;
                                }
                                return list;
                            });
                        }}
                    />
                )}
            </div>
            {loadingStarter ? (
                <Spin />
            ) : (
                <Button
                    type="primary"
                    className={"actionButton"}
                    disabled={!questionChoices?.length}
                    onClick={() =>
                        userHasPremiumAccess
                            ? onSubmit({
                                  paperId: paperId,
                                  questionNumbers: questionChoices
                              })
                            : setShowPremiumFeatureModal(true)
                    }
                >
                    Generate Starter
                </Button>
            )}
        </>
    );
}

export default MockPaperCTGStarterForm;
