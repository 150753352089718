import { Flipped, Flipper } from "react-flip-toolkit";
import { Spin } from "antd";
import MockPapersGridCard from "./MockPapersGridCard";
import React, { useMemo } from "react";
import { useGetPaperGroupsQuery } from "features/mockPapers/mockPapersSlice";
import { createSelector } from "@reduxjs/toolkit";
import { getSaveColourStringFromArray } from "helpers/tagHelpers";

function MockPapersGridSection({ title, children }) {
    return (
        <Flipped flipId={title} spring={"stiff"} key={title}>
            <div className={"mock-papers-selector__grid-section"}>
                <h2>{title}</h2>
                {children}
            </div>
        </Flipped>
    );
}

const EMPTY_ARRAY = [];

const getColourKey = (set, isHigher) =>
    `${set?.examBoard}${set?.name}${isHigher ? "H" : "F"}`;

function MockPapersGrid({
    showingExamBoards = true,
    selectedExamBoard,
    selectedPaperGroup,
    fullscreen,
    makeFullscreen,
    onSelect = (id) => {},
    showDefaultOptionsOnSelect = true,
    hideBoosterSheets = false
}) {
    const getVisiblePapers = useMemo(() => {
        return createSelector(
            (res) => res.data,
            (res, selectedExamBoard) => selectedExamBoard?.value,
            (res, _, selectedPaperGroup) => selectedPaperGroup?.value,
            (res, _, __, showingExamBoards) => showingExamBoards,
            (
                boards,
                selectedExamBoard,
                selectedPaperGroup,
                showingExamBoards
            ) =>
                boards
                    ?.filter(
                        ({ name }) =>
                            !showingExamBoards || name === selectedExamBoard
                    )
                    ?.flatMap(({ name, sets }) => [
                        ...sets.map((s) => ({ ...s, examBoard: name }))
                    ])
                    ?.filter(
                        (group) =>
                            !selectedPaperGroup ||
                            group.name === selectedPaperGroup
                    ) || EMPTY_ARRAY
        );
    }, []);
    const getAllColourGroupKeys = useMemo(() => {
        return createSelector(
            (res) => res.data,
            (boards) =>
                boards
                    ?.flatMap(({ name, sets }) => [
                        ...sets.map((s) => ({ ...s, examBoard: name }))
                    ])
                    ?.flatMap((set) => [
                        getColourKey(set, true),
                        getColourKey(set, false)
                    ]) || EMPTY_ARRAY
        );
    }, []);

    const {
        visiblePapers: paperGroups,
        allGroupColourKeys: colourKeys,
        isLoading: loading
    } = useGetPaperGroupsQuery(undefined, {
        selectFromResult: (result) => ({
            visiblePapers: getVisiblePapers(
                result,
                selectedExamBoard,
                selectedPaperGroup,
                showingExamBoards
            ),
            allGroupColourKeys: getAllColourGroupKeys(result),
            isLoading: result.isLoading
        })
    });

    let nonBoosterPaperGroups, boosterPapers;
    if (showingExamBoards) {
        if (selectedExamBoard?.value === "FoundationBooster") {
            nonBoosterPaperGroups = [];
            boosterPapers = paperGroups?.flatMap(({ papers }) => papers);
        } else {
            nonBoosterPaperGroups = paperGroups;
            boosterPapers = [];
        }
    } else {
        nonBoosterPaperGroups = paperGroups?.filter(
            ({ name }) => name !== "FB"
        );
        boosterPapers = paperGroups
            ?.filter(({ name }) => name === "FB")
            ?.flatMap(({ papers }) => papers);
    }
    if (hideBoosterSheets) {
        boosterPapers = [];
    }

    return (
        <Flipper
            flipKey={selectedPaperGroup?.label ?? "All"}
            spring={"stiff"}
            className={"mock-papers-selector__grid"}
        >
            {loading ? (
                <Spin />
            ) : (
                <>
                    {nonBoosterPaperGroups?.map((group) => {
                        const higherPapers = group.papers
                            .filter((p) => p.higher)
                            .map((p) => (
                                <MockPapersGridCard
                                    key={p.id}
                                    id={p.id}
                                    code={p.shortCode}
                                    colour={getSaveColourStringFromArray(
                                        getColourKey(group, p.higher),
                                        colourKeys
                                    )}
                                    title={
                                        (p.higher ? "Higher " : "Foundation ") +
                                        p.name
                                    }
                                    fullscreen={fullscreen}
                                    makeFullscreen={makeFullscreen}
                                    useDefaultOptions={
                                        showDefaultOptionsOnSelect
                                    }
                                    onClick={onSelect}
                                />
                            ));
                        const foundationPapers = group.papers
                            .filter((p) => !p.higher)
                            .map((p) => (
                                <MockPapersGridCard
                                    key={p.id}
                                    id={p.id}
                                    code={p.shortCode}
                                    colour={getSaveColourStringFromArray(
                                        getColourKey(group, p.higher),
                                        colourKeys
                                    )}
                                    title={
                                        (p.higher ? "Higher " : "Foundation ") +
                                        p.name
                                    }
                                    fullscreen={fullscreen}
                                    makeFullscreen={makeFullscreen}
                                    useDefaultOptions={
                                        showDefaultOptionsOnSelect
                                    }
                                    onClick={onSelect}
                                />
                            ));
                        return (
                            <MockPapersGridSection
                                key={group.examBoard + group.name}
                                title={group.name}
                            >
                                <h3>Higher</h3>
                                <div
                                    className={
                                        "our-library__starters_container"
                                    }
                                >
                                    {higherPapers}
                                </div>
                                <h3>Foundation</h3>
                                <div
                                    className={
                                        "our-library__starters_container"
                                    }
                                >
                                    {foundationPapers}
                                </div>
                            </MockPapersGridSection>
                        );
                    })}
                    {Boolean(boosterPapers?.length) && (
                        <MockPapersGridSection
                            title={"Foundation Booster Sheets"}
                        >
                            <h3 aria-hidden={true} />
                            <div className={"our-library__starters_container"}>
                                {boosterPapers.map((sheet) => (
                                    <MockPapersGridCard
                                        key={sheet.id}
                                        id={sheet.id}
                                        code={sheet.shortCode}
                                        colour={"#a6e6ff"}
                                        title={sheet.name}
                                        isBooster
                                        fullscreen={fullscreen}
                                        makeFullscreen={makeFullscreen}
                                        useDefaultOptions={
                                            showDefaultOptionsOnSelect
                                        }
                                        onClick={onSelect}
                                    />
                                ))}
                            </div>
                        </MockPapersGridSection>
                    )}
                </>
            )}
        </Flipper>
    );
}

export default MockPapersGrid;
