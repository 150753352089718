import * as React from "react";

const SvgIconValuesReload = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                d="M20.535 17.777a8 8 0 0 1-14.766.88"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m17.682 18.381 2.852-.604.605 2.852M4.085 6.723a8 8 0 0 1 14.766-.88"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m6.937 6.119-2.852.604-.604-2.852"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.02 16V9.695l.553.572H4.585V9.21H7.28V16h-1.26Zm2.521 0v-.844l2.697-2.56c.226-.214.394-.402.504-.563.11-.162.181-.31.213-.447.04-.142.059-.274.059-.397 0-.31-.107-.55-.32-.718-.214-.175-.528-.262-.941-.262-.33 0-.63.058-.903.175a1.825 1.825 0 0 0-.688.533l-.883-.679c.265-.356.62-.63 1.067-.824.453-.2.957-.301 1.513-.301.492 0 .918.08 1.28.242.37.156.65.379.845.67.2.29.3.637.3 1.038 0 .22-.029.44-.087.66-.058.213-.168.439-.33.678-.162.24-.398.508-.708.805l-2.318 2.202-.262-.475h3.967V16H8.541Zm7.867.097c-.479 0-.947-.068-1.407-.204a3.526 3.526 0 0 1-1.144-.582l.543-.98c.246.201.543.363.893.486.349.122.714.184 1.096.184.452 0 .805-.09 1.057-.272a.895.895 0 0 0 .378-.756.884.884 0 0 0-.349-.738c-.233-.18-.608-.271-1.125-.271h-.621V12.1l1.94-2.308.165.475h-3.647V9.21h4.627v.844l-1.94 2.308-.66-.388h.378c.835 0 1.459.188 1.872.563.42.369.63.844.63 1.426 0 .382-.096.734-.29 1.057-.194.324-.492.585-.892.786-.395.194-.896.29-1.504.29Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default SvgIconValuesReload;
