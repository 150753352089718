import React from "react";
import { getChart, getImage } from "helpers/questionHelpers";
import EmptyCircle from "assets/icons/EmptyCircle";
import Icon from "@ant-design/icons";
import SvgCalculator from "assets/icons/CalculatorIcon";
import dayjs from "dayjs";

function PrintedStarter({
    includes,
    numberQuestions,
    questionComponents,
    title,
    calculatorAllowedValues,
    hasRecallTags,
    learningObjective,
    recallTags,
    isPlenary
}) {
    const questionNumbers = Array.from(
        { length: numberQuestions },
        (v, k) => k + 1
    );

    return (
        <div className={"printedSubPage"}>
            <div className={"printedHeader"}>
                <div className={"date"}>
                    {includes.dateBox &&
                        (includes?.date ? includes.date : dayjs()).format(
                            "DD.MM.YYYY"
                        )}
                </div>
                <div className={"titleContainer"}>
                    <div className={"title"}>{includes?.title && title}</div>
                </div>
                <div className={"name"}>{includes?.nameBox && "Name:"}</div>
            </div>
            <div className={"printedQuestionGrid questions-" + numberQuestions}>
                {questionNumbers.map((n) => (
                    <div key={n} className={"printedQuestionContainer"}>
                        <div className={"title"}>
                            {isPlenary ? `EQ${n}` : `Q${n}`}
                            <div className="calc-icon">
                                {(calculatorAllowedValues?.[n - 1] ??
                                    questionComponents[n - 1]
                                        ?.calculatorAllowed) && (
                                    <SvgCalculator />
                                )}
                            </div>
                        </div>
                        <div className={"questionWrapper"}>
                            <div className={"questionBody"}>
                                {questionComponents[n - 1]?.imageUrlSuffix &&
                                    getImage(
                                        questionComponents[n - 1]
                                            .imageUrlSuffix,
                                        "questionImage"
                                    )}
                                {questionComponents[n - 1]?.chartData &&
                                    getChart(
                                        questionComponents[n - 1]?.chartData,
                                        "questionImage",
                                        35
                                    )}
                                {questionComponents &&
                                    questionComponents[n - 1]?.question}
                            </div>
                            {isPlenary && (
                                <div className={"markingFooter"}>
                                    <div className={"mark"}>
                                        <p>Needs Work</p>
                                        <Icon component={EmptyCircle} />
                                    </div>
                                    <div className={"mark"}>
                                        <p>Correct</p>
                                        <Icon component={EmptyCircle} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PrintedStarter;
