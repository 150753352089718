import { useSelector } from "react-redux";
import { apiSlice } from "helpers/apiSlice";
import { message } from "antd";
import { useCallback, useEffect, useRef } from "react";

/**
 * Like hooks.useLoadingMessage but doesn't store a list of in-flight requests, instead relies on the calling component
 * to manage when to show/hide the message
 * @param key {string | number} a unique key to ensure only 1 spinner is displayed per hook (e.g. 1 spinner per API
 *                              endpoint)
 * @param messageContent {any} the content to show in the loading message
 * @returns {[function(): void, function(): void, boolean]} [showMessage, hideMessage, isOpen]
 */
export const useSimpleLoadingMessage = (key, messageContent = null) => {
    const loadingMessageKey = "loadingMessage" + key;
    const isOpen = useRef(false);

    const openMessage = useCallback(() => {
        isOpen.current = true;
        message.open({
            key: loadingMessageKey,
            type: "loading",
            content: messageContent,
            duration: 0
        });
    }, [loadingMessageKey, messageContent]);

    const closeMessage = useCallback(() => {
        if (isOpen?.current) {
            isOpen.current = false;
            message.destroy(loadingMessageKey);
        }
    }, [loadingMessageKey]);

    return [openMessage, closeMessage, Boolean(isOpen?.current)];
};

function ApiSpinner() {
    const {
        queries: isSomeQueryPending,
        mutations: isSomeMutationPending,
        deletes: isSomeDeletePending
    } = useSelector((state) => {
        const apiState = state[apiSlice.reducerPath];
        const queries = Object.values(apiState.queries);
        const mutations = Object.values(apiState.mutations);
        const deletes = mutations.filter(
            (query) => query.endpointName.substring(0, 6) === "delete"
        );
        return {
            queries: queries.some((query) => query.status === "pending"),
            mutations: mutations.some((query) => query.status === "pending"),
            deletes: deletes.some((query) => query.status === "pending")
        };
    });

    // FIXME Warning: Cannot update during an existing state transition (such as within `render`). Render methods should
    //       be a pure function of props and state; triggering nested component updates from render is not allowed. If
    //       necessary, trigger nested updates in componentDidUpdate.

    const [registerQuery, resolveQuery] = useSimpleLoadingMessage(
        "api-global-query",
        null
    );
    const [registerMutation, resolveMutation] = useSimpleLoadingMessage(
        "api-global-mutation",
        "Saving"
    );
    const [registerDeleteMutation, resolveDeleteMutation] =
        useSimpleLoadingMessage("api-global-mutation-delete", "Deleting");

    //  Maybe could do with some way of persisting the last message (so that mutation then load doesn't show 2 spinners)

    useEffect(() => {
        if (isSomeQueryPending && !isSomeMutationPending) {
            registerQuery();
        } else {
            resolveQuery();
        }

        if (isSomeMutationPending && !isSomeDeletePending) {
            registerMutation();
        } else {
            resolveMutation();
        }

        if (isSomeDeletePending) {
            registerDeleteMutation();
        } else {
            resolveDeleteMutation();
        }
    }, [
        isSomeDeletePending,
        isSomeMutationPending,
        isSomeQueryPending,
        registerDeleteMutation,
        registerMutation,
        registerQuery,
        resolveDeleteMutation,
        resolveMutation,
        resolveQuery
    ]);

    return null;
}

export default ApiSpinner;
