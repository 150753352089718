import React from "react";
import SvgMPLogoFull from "assets/icons/MPLogoFull";
import Icon from "@ant-design/icons";
import EmptyCircle from "assets/icons/EmptyCircle";
import { List } from "antd";
import { DEFAULT_TITLES } from "helpers/starterHelpers";

function FeedbackBox({ title, statements }) {
    const presentStatements = statements.filter((s) => s?.statement);
    return (
        <div className={"feedbackBox"}>
            <div className={"feedbackBoxTitle"}>{title}</div>
            {presentStatements?.length > 0 && (
                <>
                    <List
                        dataSource={presentStatements}
                        renderItem={(s) => (
                            <List.Item className={"feedbackStatement"}>
                                <Icon component={EmptyCircle} />
                                <span>{s.statement}</span>
                                {s?.question && s?.question !== "all" && (
                                    <span className={"questionId"}>
                                        {`(EQ${s.question})`}
                                    </span>
                                )}
                            </List.Item>
                        )}
                    />
                    <span className={"feedbackBoxSubtitle"}>
                        Additional Comment (If Required)
                    </span>
                </>
            )}
        </div>
    );
}

function PrintedFeedbackPage({
    title,
    wwwTitle = undefined,
    ebiTitle = undefined,
    feedbackStatements
}) {
    const { www: wwwStatements, ebi: ebiStatements } = feedbackStatements || {};

    return (
        <div className={"printPage feedbackPage"}>
            <div className={"printedSubPage"}>
                <div className={"feedbackHeader"}>
                    <div className={"feedbackBoxTitle"}>
                        {title} -- Feedback
                    </div>
                </div>
                <FeedbackBox
                    title={wwwTitle || DEFAULT_TITLES.www}
                    statements={wwwStatements || []}
                />
                <FeedbackBox
                    title={ebiTitle || DEFAULT_TITLES.ebi}
                    statements={ebiStatements || []}
                />
            </div>
            <div className={"printedSubPage"}>
                <SvgMPLogoFull className="glueLogo" />
                <div className={"gluePage"}>
                    <span className={"foldHere"}>FOLD HERE</span>
                    <span className={"glueHere"}>GLUE HERE</span>
                </div>
            </div>
        </div>
    );
}

export default PrintedFeedbackPage;
