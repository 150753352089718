import { Button } from "antd";
import React from "react";

/**
 * @param type {"default"|"primary"|"secondary"|"current"}
 * @param disabled {boolean}
 * @param children {ReactNode|ReactNode[]}
 * @param size {"small"|"middle"|"large"}
 * @param onClick
 * @param href
 * @param className
 * @param htmlProps {React.HTMLProps<HTMLButtonElement>}
 * @returns {JSX.Element}
 */
export default function RoundedButton({
    type = "default",
    disabled = false,
    children = undefined,
    size = "middle",
    onClick = () => {},
    href = undefined,
    className = "",
    ...htmlProps
}) {
    return (
        <Button
            onClick={(e) => {
                if (disabled) {
                    return;
                }
                onClick(e);
            }}
            size={size}
            href={href}
            disabled={disabled}
            className={`custom_button ${type} ${
                disabled ? " disabled " : ""
            } ${className}`}
            {...htmlProps}
        >
            {children}
        </Button>
    );
}
