import { apiSlice, mutationHeaders } from "helpers/apiSlice";
import {
    getLibraryStarterName,
    getStarterDefaults,
    getTimestampName
} from "helpers/miscHelpers";
import { DEFAULT_TITLES } from "helpers/starterHelpers";

const optimisticallyUpdateStarterAttribute = (
    starterId,
    attributeName,
    newValue,
    { dispatch, queryFulfilled }
) => {
    dispatch(
        apiSlice.util.updateQueryData(
            "getSavedStarters",
            undefined,
            (draftSaves) => {
                Object.values(draftSaves || {})
                    .flat()
                    .find((save) => save?.id === starterId)[attributeName] =
                    newValue;
                return draftSaves;
            }
        )
    );
    queryFulfilled.catch(() =>
        dispatch(
            apiSlice.util.invalidateTags([{ type: "Starters", id: starterId }])
        )
    );
};

/**
 * Format save-starter data ready for JSON transfer to backend
 * @param data{{
 *      name:string, title:string, classId:string, timetableColumn:string, userOrder:number,learningObjective:string,
 *      plenaryTitle:string|null, wwwTitle:string|null, ebiTitle:string|null, wwwStatements:Array<{ statement: string|null }>,
 *      ebiStatements:Array<{ statement: string|null, question: string|null }>, hasPlenary:boolean, hasRecallTags:boolean,
 * 		  questionChoices:Array<{topicArea: string|null, topic: string|null, subtopic: string|null, difficulty: string|null, recallTag: string|null, randomSeed: number|null}>,
 * 		  plenaryQuestionChoices:Array<{topicArea: string|null, topic: string|null, subtopic: string|null, difficulty: string|null, recallTag: string|null, randomSeed: number|null}|null>,
 * 			gridWidth:number, plenaryWidth:number, gridHeight:number, fontSize: number, senBackground: string|null, cueImage: string|null,
 *
 * 		  grades?:Array<number>, topics?:Array<string>,
 *
 * 		  subTopic?:string, difficulty?:string
 *}}
 * @returns {*&{name:string, title:string, classId:string, timetableColumn:string, userOrder:number, learningObjective:string,
 *              plenaryTitle:string, editedWWWTitle:string, editedEbiTitle:string, wwwStatements:string, ebiStatements:string,
 *              hasPlenary:boolean, hasRecallTags:boolean, questionChoices:Array<string>, plenaryQuestionChoices:Array<string>,
 *              gridWidth:number, plenaryWidth:number, gridHeight:number, fontSize: number, senBackground: string|null,
 *              cueImage: string|null}}
 */
function formatStarterDataForJSON(data) {
    return {
        ...data,
        ...(data?.wwwTitle &&
            data?.wwwTitle !== DEFAULT_TITLES.www && {
                editedWWWTitle: data.wwwTitle
            }),
        ...(data?.ebiTitle &&
            data?.ebiTitle !== DEFAULT_TITLES.ebi && {
                editedEBITitle: data.ebiTitle
            }),
        wwwStatements: JSON.stringify(data.wwwStatements),
        ebiStatements: JSON.stringify(data.ebiStatements),
        questionChoices: data.questionChoices.map((qc) => JSON.stringify(qc)),
        plenaryQuestionChoices: data.plenaryQuestionChoices.map((qc) =>
            JSON.stringify(qc)
        )
    };
}

export const extendedApiSlice = apiSlice
    .enhanceEndpoints({ addTagTypes: ["Starters"] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getLessonDetails: builder.query({
                query: (starterId) =>
                    `/saved-starter?id=${encodeURIComponent(starterId)}`
            }),
            getSavedStarters: builder.query({
                async queryFn(_, { dispatch }, extraOptions, fetchFn) {
                    const result = await fetchFn({
                        url: "/saved-starters-table"
                    });
                    if (result?.data) {
                        const numSaves = [...Object.entries(result.data)]
                            .flatMap(([, saves]) => saves)
                            .filter((notNull) => notNull).length;
                        if (numSaves === 0) {
                            dispatch(
                                apiSlice.endpoints.loadDefaultTimetableIfNeeded.initiate()
                            );
                        }
                        // Parse new value into a boolean and add dummy column
                        return {
                            data: Object.fromEntries([
                                ...Object.entries(result.data).map(
                                    ([colName, saves]) => [
                                        colName,
                                        [
                                            ...saves.map((save) =>
                                                save
                                                    ? {
                                                          ...save,
                                                          new: JSON.parse(
                                                              (
                                                                  save?.new ||
                                                                  false
                                                              ).toString()
                                                          )
                                                      }
                                                    : null
                                            )
                                        ]
                                    ]
                                ),
                                ["dummy", []]
                            ])
                        };
                    }
                    return result;
                },
                // The `LIST` id is a "virtual id" that allows invalidation of this query specifically when a new starter is added
                providesTags: (result) => [
                    ...Object.values(result || {})
                        .flat()
                        .map((save) => ({
                            type: "Starters",
                            id: save?.id
                        }))
                        .filter(({ id }) => id),
                    { type: "Starters", id: "LIST" }
                ]
            }),
            loadDefaultTimetableIfNeeded: builder.mutation({
                query: () => ({
                    url: "/load-default-timetable-for-new-user",
                    headers: mutationHeaders,
                    method: "PUT"
                }),
                invalidatesTags: (result, error, id) =>
                    result
                        ? [
                              { type: "Starters", id: "LIST" },
                              { type: "Classes", id: "LIST" }
                          ]
                        : []
            }),
            newCustomStarter: builder.mutation({
                query: (data) => ({
                    url: `/save-starter`,
                    body: JSON.stringify(formatStarterDataForJSON(data)),
                    headers: mutationHeaders,
                    method: "POST"
                }),
                invalidatesTags: [{ type: "Starters", id: "LIST" }]
            }),
            newGCSEStarter: builder.mutation({
                query: ({
                    grades,
                    topics,
                    numQs = 6,
                    timetableColumn,
                    timetablePeriod
                }) => ({
                    url: `/save-gcse-starter`,
                    body: JSON.stringify(
                        formatStarterDataForJSON({
                            ...getStarterDefaults(),
                            name: getTimestampName(),
                            timetableColumn: timetableColumn,
                            userOrder: timetablePeriod,
                            grades: grades,
                            topics: topics
                        })
                    ),
                    headers: mutationHeaders,
                    method: "POST"
                }),
                invalidatesTags: [{ type: "Starters", id: "LIST" }]
            }),
            newLibraryStarters: builder.mutation({
                query: ({
                    choices,
                    timetableColumn = null,
                    timetablePeriod = null
                }) => ({
                    url: `/save-library-starters`,
                    body: JSON.stringify(
                        choices.map((choice) =>
                            formatStarterDataForJSON({
                                ...getStarterDefaults(),
                                name: getLibraryStarterName(choice),
                                timetableColumn: timetableColumn || "backlog",
                                userOrder:
                                    timetablePeriod === 0
                                        ? 0
                                        : timetablePeriod || null,
                                subTopic: choice.subTopic,
                                difficulty: choice.difficulty
                            })
                        )
                    ),
                    headers: mutationHeaders,
                    method: "POST"
                }),
                invalidatesTags: [{ type: "Starters", id: "LIST" }]
            }),
            updateSavedStarter: builder.mutation({
                query: ({ id: starterId, data }) => ({
                    url: `/update-starter?id=${encodeURIComponent(starterId)}`,
                    body: JSON.stringify(formatStarterDataForJSON(data)),
                    headers: mutationHeaders,
                    method: "PUT"
                }),
                invalidatesTags: (result, error, { id }) => [
                    { type: "Starters", id }
                ]
            }),
            renameStarter: builder.mutation({
                query: ({ id: starterId, newName }) => ({
                    url: `/set-starter-name?id=${encodeURIComponent(
                        starterId
                    )}&name=${encodeURIComponent(newName)}`,
                    headers: mutationHeaders,
                    method: "PUT"
                }),
                onQueryStarted({ id: starterId, newName }, apiMethods) {
                    optimisticallyUpdateStarterAttribute(
                        starterId,
                        "name",
                        newName,
                        apiMethods
                    );
                },
                invalidatesTags: (result, error, { id }) => [
                    { type: "Starters", id }
                ]
            }),
            setStarterClassTag: builder.mutation({
                query: ({ id: starterId, newClassId }) => ({
                    url: `/set-starter-class-tag?id=${encodeURIComponent(
                        starterId
                    )}&classId=${encodeURIComponent(newClassId)}`,
                    headers: mutationHeaders,
                    method: "PUT"
                }),
                onQueryStarted({ id: starterId, newClassId }, apiMethods) {
                    optimisticallyUpdateStarterAttribute(
                        starterId,
                        "classId",
                        newClassId,
                        apiMethods
                    );
                },
                invalidatesTags: (result, error, { id }) => [
                    { type: "Starters", id }
                ]
            }),
            clearStarterClassTag: builder.mutation({
                query: (starterId) => ({
                    url: `/set-starter-class-tag?id=${encodeURIComponent(
                        starterId
                    )}`,
                    headers: mutationHeaders,
                    method: "PUT"
                }),
                onQueryStarted(starterId, apiMethods) {
                    optimisticallyUpdateStarterAttribute(
                        starterId,
                        "classId",
                        null,
                        apiMethods
                    );
                },
                invalidatesTags: (result, error, id) => [
                    { type: "Starters", id }
                ]
            }),
            duplicateStarter: builder.mutation({
                query: (starterId) => ({
                    url: `/duplicate-starter?id=${encodeURIComponent(
                        starterId
                    )}`,
                    headers: mutationHeaders,
                    method: "POST"
                }),
                invalidatesTags: [{ type: "Starters", id: "LIST" }]
            }),
            deleteStarter: builder.mutation({
                query: (starterId) => ({
                    url: `/saved-starter?id=${encodeURIComponent(starterId)}`,
                    method: "DELETE"
                }),
                invalidatesTags: (result, error, id) => [
                    { type: "Starters", id }
                ]
            }),
            reorderStartersRelative: builder.mutation({
                query: (orderedStarterIds) => ({
                    url: "/starter-orders",
                    body: JSON.stringify(orderedStarterIds),
                    headers: mutationHeaders,
                    method: "PUT"
                }),
                invalidatesTags: (result, error, ids) =>
                    (ids || []).map((id) => ({ type: "Starters", id }))
            }),
            moveStarters: builder.mutation({
                queryFn(updates, queryApi, extraOptions, fetchFn) {
                    return Promise.all(
                        updates.map(
                            ({ saveId, newColumn: newColumnId, newPeriod }) =>
                                fetchFn({
                                    url: "/move-fixed-timetable-save",
                                    body: JSON.stringify({
                                        saveId,
                                        newColumnId,
                                        newPeriod
                                    }),
                                    headers: mutationHeaders,
                                    method: "PUT"
                                })
                        )
                    );
                },
                invalidatesTags: (result, error, updates) =>
                    (updates || []).map(({ saveId: id }) => ({
                        type: "Starters",
                        id
                    }))
            })
        })
    });

export const {
    useGetLessonDetailsQuery,
    useGetSavedStartersQuery,
    useNewCustomStarterMutation,
    useNewGCSEStarterMutation,
    useNewLibraryStartersMutation,
    useUpdateSavedStarterMutation,
    useRenameStarterMutation,
    useSetStarterClassTagMutation,
    useClearStarterClassTagMutation,
    useDuplicateStarterMutation,
    useDeleteStarterMutation,
    useReorderStartersRelativeMutation,
    useMoveStartersMutation
} = extendedApiSlice;
