import { Button } from "antd";
import SvgIconReload from "assets/icons/IconReload";
import React from "react";

export function FullscreenAnswerHeader({
    fullscreen,
    makeFullscreen,
    showAnswers,
    setShowAnswers,
    newCallback
}) {
    return (
        <>
            <Button
                className={"mulBtn fullscreen"}
                type={"default"}
                onClick={() => {
                    makeFullscreen(!fullscreen);
                }}
            >
                {(document.fullscreenEnabled ||
                    document.webkitFullscreenEnabled ||
                    document.msFullscreenEnabled) &&
                fullscreen
                    ? "Exit Full Screen"
                    : "Full Screen Mode"}
            </Button>
            <Button
                className={"mulBtn answers"}
                type={"primary"}
                onClick={() => setShowAnswers((prev) => !prev)}
            >
                {showAnswers ? "Hide" : "Show"} Answers
            </Button>
            <Button
                className={"mulBtn reload"}
                icon={<SvgIconReload />}
                type={"text"}
                onClick={newCallback}
            />
        </>
    );
}
