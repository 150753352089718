import React, { useContext, useEffect, useState } from "react";
import { Button, Drawer, Modal } from "antd";
import { getChart, getImage } from "helpers/questionHelpers";
import { CloseOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { getStepIndex, TourContext } from "features/tour/Tour";
import { fontSizeToRem } from "../QuestionCard";
import { useSketch } from "helpers/hooks";

function SketchModal({
    isModalVisible,
    setIsModalVisible,
    questionComponent,
    questionNumber,
    questionFont,
    senBg = "",
    hasPrev = false,
    hasNext = false,
    onPrev,
    onNext
}) {
    const tourContext = useContext(TourContext);
    const [showAnswer, setShowAnswer] = useState(false);
    const { whiteboard, controls } = useSketch({
        resetArgs: [questionComponent]
    });
    const excludedControls = ["zoomIn", "zoomOut"];

    useEffect(() => {
        if (!tourContext.containsModalClose("sketch" + questionNumber)) {
            tourContext.addTourModalClose("sketch" + questionNumber, () =>
                setIsModalVisible(false)
            );
        }
    }, [tourContext, questionNumber, setIsModalVisible]);

    // TODO fix extension modal being dragged around when drawing

    return (
        <Drawer
            title={null}
            className={"sketchModal " + (tourContext.isOpen && "tourOpen")}
            closable={false}
            maskClosable={false}
            open={isModalVisible}
            destroyOnClose={false}
            onCancel={() => setIsModalVisible(false)}
            footer={
                <div className={"sketchModal-footer__wrapper"}>
                    {showAnswer ? (
                        <Button
                            type={"default"}
                            style={{ width: "13rem" }}
                            onClick={() => setShowAnswer(false)}
                        >
                            Hide Answer
                        </Button>
                    ) : (
                        <Button
                            type={"primary"}
                            style={{ width: "13rem" }}
                            onClick={() => setShowAnswer(true)}
                        >
                            Show Answer
                        </Button>
                    )}
                    <div className={"sketchModal-footer"}>
                        {Object.entries(controls)
                            .filter(([k]) => !excludedControls.includes(k))
                            .map(([, v]) => v)}
                    </div>
                    {(hasPrev || hasNext) && (
                        <div className={"sketchModal-footer__nav_btns"}>
                            <Button
                                type={"default"}
                                disabled={!hasPrev}
                                onClick={onPrev}
                            >
                                Prev. Q.
                            </Button>
                            <Button
                                type={"primary"}
                                disabled={!hasNext}
                                onClick={onNext}
                            >
                                Next Q.
                            </Button>
                        </div>
                    )}
                </div>
            }
            width={"100vw"}
        >
            <div className={"questionBackground"}>
                <div
                    className={"questionWrapper"}
                    style={{
                        fontSize: fontSizeToRem(questionFont)
                    }}
                >
                    {questionComponent &&
                        questionComponent.imageUrlSuffix &&
                        getImage(
                            questionComponent.imageUrlSuffix,
                            "questionImage"
                        )}
                    {questionComponent &&
                        questionComponent?.chartData &&
                        getChart(questionComponent.chartData, "questionImage")}
                    <span className={"questionText"}>
                        {questionComponent && questionComponent.question}
                    </span>
                </div>
                <Modal
                    className={"sketch-answer-modal " + senBg}
                    width={"fit-content"}
                    style={{ maxWidth: "80%", minWidth: "20%" }}
                    open={showAnswer}
                    onCancel={() => setShowAnswer(false)}
                    title={"Answer"}
                    footer={null}
                    closeable
                    centered
                >
                    <div
                        style={{
                            fontSize: fontSizeToRem(questionFont)
                        }}
                        className={
                            questionComponent?.answerChartData ? "hasChart" : ""
                        }
                    >
                        {questionComponent &&
                            questionComponent?.answerImageUrlSuffix &&
                            getImage(
                                questionComponent.answerImageUrlSuffix,
                                "questionImage"
                            )}
                        {questionComponent &&
                            questionComponent?.answerChartData &&
                            getChart(
                                questionComponent.answerChartData,
                                "questionImage"
                            )}
                        <span>
                            {questionComponent && (
                                <span className={"answer "}>
                                    {questionComponent.answer}
                                </span>
                            )}
                        </span>
                    </div>
                </Modal>
            </div>
            <div className={"canvasContainer"}>{whiteboard}</div>
            <Button
                className={"questionClose"}
                type={"text"}
                onClick={() => {
                    if (
                        tourContext.isOpen &&
                        tourContext.currentStep === getStepIndex("whiteboard")
                    ) {
                        tourContext.nextStep();
                    }
                    setIsModalVisible(false);
                }}
            >
                <CloseOutlined />
            </Button>
            <div className={"scrollButtons"}>
                <Button
                    onClick={() => {
                        document.querySelector(
                            ".sketchModal.ant-drawer-open .ant-drawer-body"
                        ).scrollTop -= 250;
                    }}
                >
                    <UpOutlined />
                </Button>
                <Button
                    onClick={() => {
                        document.querySelector(
                            ".sketchModal.ant-drawer-open .ant-drawer-body"
                        ).scrollTop += 250;
                    }}
                >
                    <DownOutlined />
                </Button>
            </div>
        </Drawer>
    );
}

export default SketchModal;
