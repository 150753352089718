import React from "react";

function LessonTypeOption({
    name,
    description,
    icon,
    colour,
    ...additionalProps
}) {
    return (
        <div
            className={"starter_type_selector__option " + colour}
            {...additionalProps}
        >
            <img src={icon} alt={`${name} icon`} />
            <div className={"starter_type_selector__option__text"}>
                <h2>{name}</h2>
                <p>{description}</p>
            </div>
        </div>
    );
}

export default LessonTypeOption;
