import { Modal } from "antd";
import ClassList from "./components/ClassList";
import React, { useState } from "react";
import NewClassForm from "./components/NewClassForm";
import ClassNotesModal from "features/classTags/ClassNotesModal";

function ClassesListModal({ isOpen, setIsOpen }) {
    const [selectedClassId, setSelectedClassId] = useState(null);

    return (
        <>
            {/* Needs to be outside <Modal> otherwise ReactQuill doesn't format loaded content properly */}
            <ClassNotesModal
                classId={selectedClassId}
                isModalVisible={Boolean(selectedClassId)}
                setIsModalVisible={setSelectedClassId}
            />
            <Modal
                title={"Manage your classes"}
                className={"classListModal"}
                data-no-dnd={"true"}
                width={"min(95%, 60rem)"}
                footer={null}
                open={isOpen}
                onCancel={() => setIsOpen(false)}
                closable
                centered
            >
                <NewClassForm />
                <div className={"classListModalWrapper"}>
                    <ClassList
                        selectedClassId={selectedClassId}
                        onOpenNotesClick={setSelectedClassId}
                    />
                </div>
            </Modal>
        </>
    );
}

export default ClassesListModal;
