import * as React from "react";

const SvgRequestFeatureIcon = (props) => (
    <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M15 21H8V11c2.917-2.258 3.056-4.925 4-5.92 1.5-1.58 2.964-1.208 3.584 0C16.203 6.29 16 7 15.5 8.5S14 11 14 11h5c1.657 0 3.035 1.348 2.883 2.998C21.487 18.295 19.934 21 15 21Z"
            fill="currentColor"
        />
        <path
            d="M3 21h3a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1Z"
            fill="currentColor"
            fillOpacity={0.6}
        />
    </svg>
);

export default SvgRequestFeatureIcon;
