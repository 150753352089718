import { Alert, DatePicker, Spin, Switch } from "antd";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import starterGridIllustration from "assets/illustrations/print-starter-grid-illustration.svg";
import starterStripIllustration from "assets/illustrations/print-starter-strip-illustration.svg";
import plenaryFrontIllustration from "assets/illustrations/print-exit-ticket-front-illustration.svg";
import plenaryBackIllustration from "assets/illustrations/print-exit-ticket-back-illustration.svg";
import { CheckCircleTwoTone } from "@ant-design/icons";
import dayjs from "dayjs";

function PrintLayoutIllustration({ src, alt, caption }) {
    return (
        <div className={"print-layout-option__illustration"}>
            <img src={src} alt={alt} />
            {caption && (
                <label className={"print-layout-option__illustration__caption"}>
                    {caption}
                </label>
            )}
        </div>
    );
}

function PrintLayoutOption({
    title = "Template",
    illustration = null,
    illustrationCaption = null,
    secondIllustration = null,
    secondIllustrationCaption = null,
    onClick = (e) => {},
    active = false,
    children = null,
    alert = null
}) {
    return (
        <div
            className={
                "print-layout-option " +
                (active ? "print-layout-option--active " : "")
            }
            onClick={onClick}
        >
            <h3 className={"print-layout-option__title"}>
                <span>{title}</span>
                {active && <CheckCircleTwoTone />}
            </h3>
            <div className={"print-layout-option__content"}>
                {illustration && (
                    <PrintLayoutIllustration
                        src={illustration}
                        alt={illustrationCaption ?? title}
                        caption={illustrationCaption}
                    />
                )}
                {secondIllustration && (
                    <PrintLayoutIllustration
                        src={secondIllustration}
                        alt={secondIllustrationCaption ?? title}
                        caption={secondIllustrationCaption}
                    />
                )}
                <div className={"print-layout-option__settings"}>
                    {children}
                </div>
            </div>
            {alert}
        </div>
    );
}

function PrintDateControl({
    disabled,
    dateIncluded,
    onChangeIncluded,
    dateValue,
    onChangeDate
}) {
    return (
        <>
            <div>
                <label>Date</label>
                <Switch
                    disabled={disabled}
                    checked={!disabled ? dateIncluded : false}
                    onChange={onChangeIncluded}
                />
            </div>
            <div>
                <DatePicker
                    disabled={disabled || !dateIncluded}
                    style={{ width: "100%" }}
                    value={dateValue}
                    onChange={onChangeDate}
                />
            </div>
        </>
    );
}

const PrintSettings = forwardRef(
    (
        {
            isPlenary,
            printAsStrip,
            setPrintAsStrip,
            starterIncludes,
            setStarterIncludes,
            plenaryIncludes,
            setPlenaryIncludes
        },
        ref
    ) => {
        const [loading, setLoading] = useState(true);

        useImperativeHandle(ref, () => ({
            setLoading: setLoading
        }));

        function onPropertyChange(property, value, setFunction) {
            setFunction((prevState) => {
                const copy = Object.assign({}, prevState);
                copy[property] = value;
                return copy;
            });
        }

        const onStarterChange = (property, value) =>
            onPropertyChange(property, value, setStarterIncludes);

        const onPlenaryChange = (property, value) =>
            onPropertyChange(property, value, setPlenaryIncludes);

        if (isPlenary) {
            const plenaryLowerControls = (
                <>
                    <div className={"plenaryDateControl"}>
                        <PrintDateControl
                            disabled={loading}
                            dateIncluded={plenaryIncludes?.dateBox}
                            onChangeIncluded={(val) => {
                                setLoading(true);
                                onPlenaryChange("dateBox", val);
                            }}
                            dateValue={plenaryIncludes?.date || dayjs()}
                            onChangeDate={(val) => {
                                setLoading(true);
                                onPlenaryChange("date", val);
                            }}
                        />
                    </div>
                    <Alert
                        type="warning"
                        message={
                            <span>
                                Print <strong>Double-Sided</strong> and{" "}
                                <strong>"Flip along short edge"</strong>
                            </span>
                        }
                        showIcon
                    />
                </>
            );
            return (
                <div className={"printSettingsSection"} ref={ref}>
                    <div className={"printSettingsControls"}>
                        {loading && (
                            <div
                                className={
                                    "printSettingsControls--loading-overlay"
                                }
                            >
                                <Spin />
                            </div>
                        )}
                        <PrintLayoutOption
                            title={"Feedback Template"}
                            active={true}
                            alert={plenaryLowerControls}
                            illustration={plenaryFrontIllustration}
                            illustrationCaption={"Front"}
                            secondIllustration={plenaryBackIllustration}
                            secondIllustrationCaption={"Back"}
                        />
                    </div>
                </div>
            );
        }

        const starterPrintMessage = (
            <Alert
                message={
                    <>
                        We recommend{" "}
                        <strong>displaying starters on your whiteboard</strong>{" "}
                        and allowing students to do workings in their books to{" "}
                        <strong>avoid additional printing!</strong>
                    </>
                }
                type="info"
                showIcon
            />
        );

        return (
            <div className={"printSettingsSection"} ref={ref}>
                {starterPrintMessage}
                <div className={"printSettingsControls"}>
                    {loading && (
                        <div
                            className={"printSettingsControls--loading-overlay"}
                        >
                            <Spin />
                        </div>
                    )}
                    <PrintLayoutOption
                        title={"Grid Template"}
                        active={!printAsStrip}
                        onClick={() => {
                            if (printAsStrip) {
                                setLoading(true);
                                setPrintAsStrip(false);
                            }
                        }}
                        illustration={starterGridIllustration}
                    >
                        {/*<div className={"printSettingsCopies"}>*/}
                        {/*    <p>Copies per page</p>*/}
                        {/*    <Select*/}
                        {/*        disabled={loading || printAsStrip}*/}
                        {/*        value={starterIncludes?.copies}*/}
                        {/*        onChange={(val) => {*/}
                        {/*            setLoading(true);*/}
                        {/*            onStarterChange("copies", val);*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <Select.Option value={1}>1</Select.Option>*/}
                        {/*        <Select.Option value={2}>2</Select.Option>*/}
                        {/*    </Select>*/}
                        {/*</div>*/}
                        <PrintDateControl
                            disabled={loading || printAsStrip}
                            dateIncluded={starterIncludes?.dateBox}
                            onChangeIncluded={(val) => {
                                setLoading(true);
                                onStarterChange("dateBox", val);
                            }}
                            dateValue={starterIncludes?.date || dayjs()}
                            onChangeDate={(val) => {
                                setLoading(true);
                                onStarterChange("date", val);
                            }}
                        />
                    </PrintLayoutOption>
                    <PrintLayoutOption
                        title={"Strip Template"}
                        active={printAsStrip}
                        onClick={() => {
                            if (!printAsStrip) {
                                setLoading(true);
                                setPrintAsStrip(true);
                            }
                        }}
                        illustration={starterStripIllustration}
                    >
                        <p>
                            <strong>2 copies</strong> on one sheet will
                            automatically be printed.
                        </p>
                        <PrintDateControl
                            disabled={loading || !printAsStrip}
                            dateIncluded={starterIncludes?.dateBox}
                            onChangeIncluded={(val) => {
                                setLoading(true);
                                onStarterChange("dateBox", val);
                            }}
                            dateValue={starterIncludes?.date || dayjs()}
                            onChangeDate={(val) => {
                                setLoading(true);
                                onStarterChange("date", val);
                            }}
                        />
                    </PrintLayoutOption>
                </div>
            </div>
        );
    }
);

export default PrintSettings;
