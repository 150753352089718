import { Alert, Button, Col, Form, Input, Modal, Row, Spin } from "antd";

import React, { useEffect, useState } from "react";
import { getStripePromise } from "helpers/payment";
import { apiGet, apiGetPublic } from "helpers/api";
import { withCookies } from "react-cookie";
import {
    useGetUserQuery,
    useLogOutUserMutation
} from "features/account/accountSlice";
import { userHasMockPapersAccess } from "helpers/security";
import { MOCK_PAPERS_PATH } from "app/App";
import { Link } from "react-router-dom";

// function DripForm() {
//     return (
//         <form
//             action="https://www.getdrip.com/forms/695561782/submissions"
//             method="post"
//             data-drip-embedded-form="695561782"
//             id="drip-ef-695561782"
//             className={"dripSubscriptionForm ant-form"}
//         >
//             <div data-drip-attribute="description" />
//
//             <div className={"ant-form-item"}>
//                 <input
//                     type="email"
//                     className={"ant-input"}
//                     id="drip-email"
//                     placeholder="Contact Email"
//                     name="fields[email]"
//                 />
//             </div>
//             <div style={{ display: "none" }} aria-hidden="true">
//                 <label htmlFor="website">Website</label>
//                 <input
//                     type="text"
//                     id="website"
//                     name="website"
//                     tabIndex="-1"
//                     autoComplete="false"
//                 />
//             </div>
//
//             <div className={"ant-form-item"}>
//                 <Button
//                     key="submit"
//                     htmlType="submit"
//                     type="default"
//                     data-drip-attribute="sign-up-button"
//                 >
//                     Send Contact Form
//                 </Button>
//             </div>
//         </form>
//     );
// }

export function DoubleEmailForm({
    loading = false,
    currentEmailDomain,
    onSubmit,
    placeholder = "Email",
    buttonText = "Submit"
}) {
    return (
        <Form
            className={"financeEmailForm"}
            requiredMark={false}
            onFinish={onSubmit}
        >
            <Form.Item
                name="email"
                rules={[
                    {
                        required: true,
                        message: "Please enter an email address"
                    },
                    {
                        type: "email",
                        message: "Please enter a valid email"
                    },
                    ...(currentEmailDomain
                        ? [
                              {
                                  pattern: RegExp(
                                      String.raw`^[^@]*@?$|.*@${currentEmailDomain.replace(
                                          /[-/\\^$*+?.()|[\]{}]/g,
                                          "\\$&"
                                      )}`,
                                      "g"
                                  ),
                                  warningOnly: true,
                                  message:
                                      "Caution: The email domain entered doesn't match your account email, please check you have entered it correctly."
                              }
                          ]
                        : [])
                ]}
            >
                <Input placeholder={placeholder} data-lpignore="true" />
            </Form.Item>
            <Form.Item
                name="confirm-email"
                dependencies={["email"]}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: "Please confirm the email address"
                    },
                    {
                        type: "email",
                        message: "Please enter a valid email"
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue("email") === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                new Error(
                                    "The two emails that you entered do not match"
                                )
                            );
                        }
                    })
                ]}
            >
                <Input
                    placeholder={`Confirm ${placeholder}`}
                    data-lpignore="true"
                />
            </Form.Item>
            <Form.Item>
                {loading ? (
                    <Spin />
                ) : (
                    <Button
                        key="submit"
                        htmlType="submit"
                        type="default"
                        disabled={loading}
                    >
                        {buttonText}
                    </Button>
                )}
            </Form.Item>
        </Form>
    );
}
function PaymentModal({
    cookies,
    isVisible,
    setIsVisible = () => {} /* Required if isEarlyPayment === true */,
    awaitingPayment,
    isSingleLicence = false,
    isSubSiteLicence = false,
    isNotForcedOpen = false
}) {
    const [csrfToken] = useState(cookies.get("XSRF-TOKEN"));

    const { data: user } = useGetUserQuery();
    const hasMockPapersAccess = userHasMockPapersAccess(user);
    const [logout] = useLogOutUserMutation();

    const [, setStripe] = useState(null);
    useEffect(() => setStripe(getStripePromise()), []);

    const [, setLoadingPrice] = useState(true);
    const [, setLoadingEarlyPrice] = useState(true);
    const [, setLoadingSinglePrice] = useState(true);
    const [, setSingleCost] = useState(null);
    const [, setSiteCost] = useState(null);
    const [, setEarlySiteCost] = useState(null);

    // function formatAsPrice(costObject) {
    //     if (!costObject || !costObject?.cost || !costObject?.interval) {
    //         return <span className="error">Unable to Retrieve Prices</span>;
    //     }
    //     const costPence = costObject.cost;
    //     const interval = costObject.interval;
    //     let cost = costPence.substring(0, costPence.length - 2);
    //     if (parseInt(costPence) % 100 !== 0) {
    //         cost = cost
    //             .concat(".")
    //             .concat(costPence.substring(costPence.length - 2));
    //     }
    //     return `£${cost} / ${interval}`;
    // }

    function convertToYearlyCost(costObject) {
        if (!costObject || !costObject?.cost || !costObject?.interval) {
            return null;
        }
        if (costObject.interval === "year") {
            return costObject;
        }
        if (costObject.interval === "month") {
            return {
                cost: (parseInt(costObject?.cost) * 12).toString(),
                interval: "year"
            };
        }
        console.error(
            "Unable to format cost: " + costObject + " as annual cost"
        );
    }

    useEffect(() => {
        let set = true;
        apiGetPublic(
            "/api/get-cost?priceId=" +
                process.env.REACT_APP_SINGLE_LICENCE_PRICE,
            (body) => {
                if (set) {
                    setLoadingSinglePrice(false);
                    setSingleCost(body);
                }
            }
        );
        return () => (set = false);
    }, []);

    useEffect(() => {
        let set = true;
        apiGet("/api/get-site-cost", (body) => {
            if (set) {
                setLoadingPrice(false);
                setSiteCost(convertToYearlyCost(body));
            }
        });
        apiGet("/api/get-early-site-cost", (body) => {
            if (set) {
                setLoadingEarlyPrice(false);
                setEarlySiteCost(convertToYearlyCost(body));
            }
        });
        return () => (set = false);
    }, []);

    return (
        <Modal
            title={null}
            className={"paymentModal"}
            closable={isNotForcedOpen}
            maskClosable={isNotForcedOpen}
            open={isVisible}
            onCancel={() => isNotForcedOpen && setIsVisible(false)}
            centered
            width={hasMockPapersAccess ? "min(120rem, 95%)" : "min(80rem, 95%)"}
            footer={null}
        >
            <div className={"trialHeader paymentHeader"}>
                <div className={"trialContainer paymentContainer"}>
                    <div className={"trialHeaderItems"}>
                        <div className="paymentHeader">
                            <div className={"trialHeaderText"}>
                                <h3 className={"trialHeaderText"}>
                                    {isNotForcedOpen
                                        ? "Sign Up"
                                        : "Premium Trial Expired"}
                                </h3>
                            </div>
                        </div>
                        {!isNotForcedOpen && (
                            <Button
                                onClick={() => logout(csrfToken)}
                                type={"text"}
                                className={"paymentLogout"}
                            >
                                Log Out
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            <Row>
                <Col xs={24} md={hasMockPapersAccess ? 15 : 24}>
                    <div className={"trialContainer paymentContainer"}>
                        <div>
                            {hasMockPapersAccess && (
                                <h1>Maths Planner Premium</h1>
                            )}
                            <div className={"siteFormContainer"}>
                                <div className={"siteForm"}>
                                    <h3>
                                        Contact us to discuss subscription
                                        options
                                    </h3>
                                    <p className={"name"}>
                                        <b>
                                            Chloe:{" "}
                                            <a
                                                href={
                                                    "mailto:chloe@mathsplanner.com"
                                                }
                                            >
                                                chloe@mathsplanner.com
                                            </a>
                                        </b>
                                    </p>
                                    <p>
                                        Send an email letting us know you’d like
                                        to discuss Maths Planner Premium options
                                        and Chloe will get back to you within 24
                                        hours.
                                    </p>
                                </div>
                                <Alert
                                    type={"warning"}
                                    message={
                                        <b>
                                            {isNotForcedOpen
                                                ? "Can't find a subtopic you're looking for?"
                                                : "Couldn't find a subtopic you needed?"}
                                        </b>
                                    }
                                    description={
                                        "All missing subtopics and improvements to current subtopics are currently being worked on and will be completed soon."
                                    }
                                    style={{ marginBottom: "-1rem" }}
                                    showIcon
                                />
                                <div>
                                    <b>
                                        Did you know your central school budget
                                        can help cover Maths Planner?
                                    </b>
                                    <p>
                                        Maths Planner helps your team implement
                                        best Ofsted practices and improve the
                                        knowledge recall for every pupil. if
                                        used effectively. Over time, this
                                        benefits the school performance and
                                        therefore central budget can cover the
                                        costs (or help).
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                {hasMockPapersAccess && (
                    <Col xs={24} md={9} style={{ position: "relative" }}>
                        <div className={"trialContainer paymentContainer"}>
                            <div className={"trialMocksInfo"}>
                                {hasMockPapersAccess && (
                                    <h1>MP Classroom Mocks</h1>
                                )}
                                <p>
                                    Your purchase of our Classroom Mocks bundle
                                    includes <b>lifetime access</b> to the
                                    papers, solutions and interactive
                                    walk-through functionality.
                                </p>
                                <Link to={MOCK_PAPERS_PATH}>
                                    <Button
                                        type={"primary"}
                                        className={"trialMocksButton"}
                                    >
                                        Go to my Classroom Mocks
                                    </Button>
                                </Link>
                                <p>
                                    In order to use our integrated Premium
                                    platform features alongside the mocks, you
                                    will need to subscribe to{" "}
                                    <b>Maths Planner Premium:</b>
                                </p>
                                <ul className={"trialFeaturesList"}>
                                    <li>
                                        Auto-generated closing the gap starters
                                    </li>
                                    <li>
                                        Per question topic closing the gap
                                        worksheets
                                    </li>
                                    <li>Two-week lesson planner timetable</li>
                                    <li>Custom starters</li>
                                    <li>GCSE starters</li>
                                    <li>Subtopic starters</li>
                                    <li>Actionable Exit Tickets</li>
                                    <li>
                                        Full access to ever-growing
                                        differentiated question database
                                        (included all solutions).
                                    </li>
                                    <li>Question whiteboard mode</li>
                                    <li>Your Classes and Class Notes</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                )}
            </Row>
        </Modal>
    );
}

export default withCookies(PaymentModal);
