import * as React from "react";

const SvgIconSave = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9 11a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9ZM8 16a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.914 2 21 6.086V19a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h10.914ZM5 5a1 1 0 0 1 1-1h2v1a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V4h3.086L19 6.914V19a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgIconSave;
