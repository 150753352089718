import * as React from "react";

const SvgIconDelete = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6 8v11a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V8M10 9v8M14 9v8M5 5h14M9 3h6"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgIconDelete;
