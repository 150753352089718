import React, { useEffect, useState } from "react";
import { apiDelete, apiGet } from "helpers/api";
import { withCookies } from "react-cookie";
import { Button, Popconfirm, Table } from "antd";

function ManageTopicAreasForm({ urlStub, cookies }) {
    const [topicAreas, setTopicAreas] = useState([]);
    const [staleData, toggleStaleData] = useState(false);
    const [csrfToken] = useState(cookies.get("XSRF-TOKEN"));

    useEffect(() => apiGet("/api/topic-areas", setTopicAreas), [staleData]);

    const columns = [
        {
            title: "Name",
            dataIndex: "value",
            render: (value) => value.replace(/^\s+|\s+$/g, "_")
        },
        {
            title: "Edit",
            dataIndex: "value",
            render: (value) => (
                <Button
                    type={"primary"}
                    className={"warning-btn"}
                    href={
                        urlStub +
                        "/edit-topic-area?name=" +
                        encodeURIComponent(value)
                    }
                >
                    Edit
                </Button>
            )
        },
        {
            title: "Delete",
            dataIndex: "value",
            render: (value) => (
                <Popconfirm
                    title={
                        <>
                            <b>Delete: </b>Are you sure?
                        </>
                    }
                    onConfirm={() => {
                        apiDelete(
                            "/api/topic-area?topicArea=" +
                                encodeURIComponent(value),
                            csrfToken
                        );
                        toggleStaleData((p) => !p);
                    }}
                    onCancel={() => {}}
                    okText="Delete"
                    cancelText="Cancel"
                >
                    <Button type={"primary"} danger={true}>
                        Delete
                    </Button>
                </Popconfirm>
            )
        }
    ];

    return (
        <Table
            className={"adminTable"}
            dataSource={topicAreas}
            columns={columns}
            pagination={false}
        />
    );
}

export default withCookies(ManageTopicAreasForm);
