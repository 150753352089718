import * as React from "react";

const SvgIconPlay = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 12a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm8-10C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Zm1.871 10L11 14.357V9.643L13.871 12Zm-2.554-4.685A1.354 1.354 0 0 0 10.428 7a1.362 1.362 0 0 0-.829.308C9.186 7.642 9 8.154 9 8.64v6.72c0 .486.186.998.6 1.332.21.171.495.301.828.308.34.007.649-.118.89-.315l4.091-3.36c.454-.373.591-.92.591-1.325 0-.406-.137-.952-.59-1.325l-4.093-3.36Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgIconPlay;
