import React, { useCallback, useEffect, useState } from "react";
import { apiDelete, apiGet, apiPost, apiPut } from "helpers/api";
import { withCookies } from "react-cookie";
import {
    Alert,
    Avatar,
    Button,
    message,
    Popconfirm,
    Popover,
    Switch,
    Table,
    Tag,
    Tooltip
} from "antd";
import {
    CheckCircleOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined
} from "@ant-design/icons";
import {
    getColoursObjectFromArray,
    getTagFromArray,
    tagColoursDiscrete
} from "helpers/tagHelpers";
import { getImage, TexComponent } from "helpers/questionHelpers";
import { lastUpdatedString, tooltipColour } from "helpers/starterHelpers";
import { formatAsDateString2 } from "helpers/miscHelpers";
import { useParams } from "react-router-dom";
import { ManageQuestionsBySubTopicCascader } from "../AdminDashboard";
import { chartTypes } from "../helpers/adminChartHelpers";

function ManageQuestionsForm({ urlStub, cookies }) {
    const { subtopic } = useParams();
    const issuesOnly = subtopic === "__issues__";
    const selectedSubTopic =
        !issuesOnly && subtopic ? decodeURIComponent(subtopic) : null;
    const [questions, setQuestions] = useState({
        lastUpdated: null,
        questions: []
    });
    const [topicAreaNames, setTopicAreaNames] = useState([]);
    const [topicNames, setTopicNames] = useState([]);
    const [topicTree, setTopicTree] = useState(null);
    const [showDevMode, setShowDevMode] = useState(false);
    const [currentRows, setCurrentRows] = useState(
        questions.questions?.filter((n) => n) || []
    );
    const [loading, setLoading] = useState(true);
    const [previousSelectedSubTopic, setPreviousSelectedSubTopic] =
        useState(selectedSubTopic);
    const [reloadQuestions, toggleReloadQuestion] = useState(true);
    const [csrfToken] = useState(cookies.get("XSRF-TOKEN"));
    const numErrors = questions.questions?.filter(
        (q) => q?.preview?.answer === "ERROR"
    ).length;
    const numIssues = questions.questions?.filter(
        (q) => q?.issueReported
    ).length;

    const insertQuestion = useCallback(
        (question) =>
            setQuestions((prev) => {
                const copy = Object.assign({}, prev);
                if (!copy?.questions) {
                    copy.questions = [];
                }
                const left = copy.questions.filter((q) => q.id > question.id);
                const right = copy.questions.filter((q) => q.id < question.id);
                copy.questions = left.concat([question]).concat(right);
                return copy;
            }),
        [setQuestions]
    );

    const removeQuestion = useCallback(
        (id) =>
            setQuestions((prev) => {
                const copy = Object.assign({}, prev);
                copy.questions = copy.questions.filter((q) => q.id !== id);
                return copy;
            }),
        [setQuestions]
    );

    // Used to refresh a single question by id (e.g. after updating the GCSE grade field)
    const loadSingleQuestion = (id) => {
        const hideLoading = message.loading(null, 0);
        apiGet(
            `/api/question?id=${id}`,
            (qs) => {
                insertQuestion(qs);
                hideLoading();
            },
            (err) => {
                message.error(err.message);
                hideLoading();
            }
        );
    };

    // Load questions if it has been more than 3s since the last update
    useEffect(() => {
        const lastUpdated = new Date(Date.parse(questions?.lastUpdated) || 0);
        if (
            previousSelectedSubTopic === selectedSubTopic &&
            Math.abs(lastUpdated - new Date()) / (1000 * 3) < 1
        ) {
            return; // Less than 3s since last update
        }
        setPreviousSelectedSubTopic(selectedSubTopic);

        (async () => {
            setLoading(true);
            const hideLoading = message.loading(null, 0);
            // Only load first page of results, otherwise the frontend is prohibitively slow to use
            await apiGet(
                issuesOnly
                    ? "/api/issue-questions"
                    : `/api/questions?page=0&lastUpdated=${encodeURIComponent(
                          lastUpdated.toISOString()
                      )}${
                          selectedSubTopic
                              ? `&subTopic=${encodeURIComponent(
                                    selectedSubTopic
                                )}`
                              : ""
                      }`,
                async (qs) => {
                    setQuestions((prev) => {
                        const copy = Object.assign({}, prev);
                        copy.questions = qs.questions;
                        // Doesn't update lastUpdated so that questions are always fetched according from time(0)
                        return copy;
                    });
                    hideLoading();
                    setLoading(false);
                },
                (err) => {
                    message.error(err.message);
                    hideLoading();
                    setLoading(false);
                }
            );
        })();
        // eslint-disable-next-line
    }, [reloadQuestions, selectedSubTopic]);

    useEffect(
        () => setCurrentRows(questions.questions?.filter((n) => n) || []),
        [questions]
    );

    useEffect(() => {
        setTopicTree([]);
        apiGet("/api/all-topics-tree", (body) => {
            setTopicTree(body);
        });
    }, []);

    useEffect(
        () =>
            apiGet("/api/topic-areas", (topics) =>
                setTopicAreaNames(topics.map((t) => t.value))
            ),
        []
    );
    useEffect(
        () =>
            apiGet("/api/topics", (topics) =>
                setTopicNames(topics.map((t) => t.value))
            ),
        []
    );

    function GCSETagClickable({ value, question }) {
        const [showList, setShowList] = useState(false);
        return (
            <Popover
                overlayClassName={"tagsList"}
                content={[...Array(9).keys()].map((i) => (
                    <div
                        className={"tagWrapper clickable"}
                        key={"modal-gcse-" + i}
                        onClick={() => {
                            apiPut(
                                "/api/set-question-gcse-level?id=" +
                                    encodeURIComponent(question.id) +
                                    "&level=" +
                                    encodeURIComponent(i + 1),
                                {},
                                csrfToken,
                                () => {
                                    loadSingleQuestion(question.id);
                                    setShowList(false);
                                    question.gcseLevel = i + 1;
                                }
                            );
                        }}
                    >
                        <Tag color={tagColoursDiscrete[i]}>{i + 1}</Tag>
                    </div>
                ))}
                open={showList}
                onOpenChange={setShowList}
                trigger="click"
            >
                {value ? (
                    <Tag
                        className={"clickable"}
                        color={tagColoursDiscrete[value - 1]}
                    >
                        {value}
                    </Tag>
                ) : (
                    <Tag
                        className="addClassTag clickable"
                        onClick={() => setShowList(true)}
                    >
                        <PlusOutlined />
                    </Tag>
                )}
            </Popover>
        );
    }

    const numCreators = questions?.questions
        ? new Set([
              ...questions.questions
                  .filter((n) => n)
                  .map((q) => q.creatorName)
                  .filter((n) => n)
          ]).size
        : 0;

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            render: (value, question) =>
                questions.questions
                    ? questions.questions.filter((n) => n).length -
                      questions.questions
                          .filter((n) => n)
                          .map((q) => q.id)
                          .indexOf(question.id)
                    : 0
        },
        {
            title: "Issue Reported",
            dataIndex: "issueReported",
            sorter: (a, b) =>
                new Date(a.issueReported || 0) > new Date(b.issueReported || 0),
            sortDirections: ["descend"],
            render: (value, question) =>
                value ? (
                    <Popconfirm
                        title={
                            <>
                                Has the issue been <b>resolved</b>?
                            </>
                        }
                        key={"issue-" + question.id}
                        onConfirm={() => {
                            apiPut(
                                "/api/resolve-issue?id=" +
                                    encodeURIComponent(question.id),
                                {},
                                csrfToken,
                                () => loadSingleQuestion(question.id)
                            );
                        }}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="Cancel"
                    >
                        <Alert
                            message={
                                !showDevMode
                                    ? lastUpdatedString(new Date(value))
                                    : new Date(value).toISOString()
                            }
                            type="error"
                        />
                    </Popconfirm>
                ) : (
                    ""
                )
        },
        {
            title: "Issue Reporter",
            dataIndex: "issueReporterId"
        },
        ...(numCreators > 1
            ? // Only super-admins should have more than 1 creator's questions returned from the backend
              // -- otherwise hide the column
              [
                  {
                      title: "Creator",
                      dataIndex: "creatorName",
                      render: (value, question) => {
                          const cols = getColoursObjectFromArray(value, [
                              ...new Set(
                                  questions.questions
                                      .filter((n) => n)
                                      .map((q) => q.creatorName)
                              )
                          ]);
                          return value ? (
                              <Tooltip
                                  title={value}
                                  color={tooltipColour}
                                  key={"avatar-" + question.id}
                              >
                                  <Avatar
                                      size={30}
                                      style={{
                                          backgroundColor: cols[4],
                                          fontSize: "1.5rem"
                                      }}
                                  >
                                      {value.split(" ").map((s) => s.charAt(0))}
                                  </Avatar>
                              </Tooltip>
                          ) : (
                              ""
                          );
                      },

                      filters: [
                          ...new Set(
                              questions.questions
                                  .filter((n) => n)
                                  .map((q) => q.creatorName)
                          )
                      ].map((t) => ({ text: t, value: t })),
                      onFilter: (value, record) =>
                          record.creatorName.indexOf(value) === 0
                  }
              ]
            : []),
        {
            title: "Created",
            dataIndex: "dateCreated",
            render: (value) =>
                value
                    ? !showDevMode
                        ? formatAsDateString2(new Date(value))
                        : new Date(value).toISOString()
                    : ""
        },
        {
            title: "Last Updated",
            dataIndex: "dateUpdated",
            render: (value) =>
                value
                    ? !showDevMode
                        ? lastUpdatedString(new Date(value))
                        : new Date(value).toISOString()
                    : ""
        },
        {
            title: "Sub Topic",
            dataIndex: "subTopic",
            filters: [...new Set([...currentRows.map((q) => q.subTopic)])].map(
                (t) => ({ text: t, value: t })
            ),
            onFilter: (value, record) => record.subTopic.indexOf(value) === 0,
            render: (value) =>
                selectedSubTopic ? (
                    value
                ) : (
                    <a
                        href={`${urlStub}/manage-question/${encodeURIComponent(
                            value
                        )}`}
                    >
                        {value}
                    </a>
                )
        },
        ...(new Set([
            ...currentRows.map((q) => q.imageUrlSuffix).filter((n) => n)
        ]).size > 0
            ? [
                  {
                      title: "Image",
                      dataIndex: "imageUrlSuffix",
                      render: (value, question) =>
                          value
                              ? getImage(
                                    value,
                                    "tableImage",
                                    "img-" + question.id
                                )
                              : ""
                  }
              ]
            : []),
        {
            title: "Q Preview",
            dataIndex: ["preview", "question"],
            filters: [{ text: "Errors", value: "ERROR" }],
            onFilter: (value, record) =>
                record.preview.answer.indexOf(value) === 0,
            render: (value, question) =>
                value && question.preview.answer !== "ERROR" ? (
                    <TexComponent tex={value} />
                ) : (
                    <Alert
                        message={value}
                        type="error"
                        key={"error-" + question.id}
                    />
                )
        },
        ...(new Set([
            ...currentRows.map((q) => q.imageUrlSuffix).filter((n) => n)
        ]).size > 0
            ? [
                  {
                      title: "Ans. Image",
                      dataIndex: "answerImageUrlSuffix",
                      render: (value, question) =>
                          value
                              ? getImage(
                                    value,
                                    "tableImage",
                                    "img-ans-" + question.id
                                )
                              : ""
                  }
              ]
            : []),
        ...(new Set([...currentRows.map((q) => q?.chartData).filter((n) => n)])
            .size > 0
            ? [
                  {
                      title: "Chart Type",
                      dataIndex: "chartData",
                      render: (chartData) =>
                          getTagFromArray(chartData?.type, chartTypes)
                  }
              ]
            : []),
        {
            title: "Ans. Preview",
            dataIndex: ["preview", "answer"],
            render: (value) =>
                value && value !== "ERROR" ? <TexComponent tex={value} /> : ""
        },
        ...(new Set([...currentRows.map((q) => q?.chartData).filter((n) => n)])
            .size > 0
            ? [
                  {
                      title: "Ans. Chart Type",
                      dataIndex: "answerChartData",
                      render: (chartData) =>
                          getTagFromArray(chartData?.type, chartTypes)
                  }
              ]
            : []),
        ...(showDevMode
            ? [
                  {
                      title: "Var. Gens",
                      dataIndex: "variableGeneratorIds",
                      render: JSON.stringify
                  },
                  {
                      title: "C'txt Gens",
                      dataIndex: "contextGeneratorIds",
                      render: JSON.stringify
                  },
                  {
                      title: "Ans. Gens",
                      dataIndex: "answerLambdaIds",
                      render: JSON.stringify
                  }
              ]
            : []),
        {
            title: "Topic Area",
            dataIndex: "topicArea",
            filters: [
                ...new Set(
                    questions.questions
                        ?.filter((n) => n)
                        ?.map((q) => q.topicArea)
                )
            ].map((t) => ({
                text: t,
                value: t
            })),
            onFilter: (value, record) => record.topicArea.indexOf(value) === 0,
            sorter: (a, b) => a.topicArea.localeCompare(b.topicArea),
            render: (value) =>
                (value && getTagFromArray(value, topicAreaNames, true)) || ""
        },
        {
            title: "Topic",
            dataIndex: "topic",
            filters: [
                ...new Set(
                    questions.questions?.filter((n) => n)?.map((q) => q.topic)
                )
            ].map((t) => ({
                text: t,
                value: t
            })),
            onFilter: (value, record) => record.topic.indexOf(value) === 0,
            sorter: (a, b) => a.topic.localeCompare(b.topic),
            render: (value) =>
                (value && getTagFromArray(value, topicNames)) || ""
        },
        {
            title: "Difficulty",
            dataIndex: "difficulty",
            filters: ["Easy", "Medium", "Hard"].map((t) => ({
                text: t,
                value: t
            })),
            onFilter: (value, record) => record.difficulty.indexOf(value) === 0,
            sorter: (a, b) => {
                const difficulties = ["Easy", "Medium", "Hard"];
                return (
                    difficulties.indexOf(a.difficulty) -
                    difficulties.indexOf(b.difficulty)
                );
            },
            render: (value, question) => {
                let col;
                switch (value) {
                    case "Easy":
                        col = "green";
                        break;
                    case "Medium":
                        col = "orange";
                        break;
                    case "Hard":
                        col = "red";
                        break;
                    default:
                        col = "grey";
                }
                return (
                    <Tag color={col} key={"diff" - +question.id}>
                        {value}
                    </Tag>
                );
            }
        },
        {
            title: "GCSE",
            dataIndex: "gcseLevel",
            filters: [
                ...[...Array(9).keys()].map((t) => ({
                    text: t + 1,
                    value: t + 1
                })),
                { text: "None", value: null }
            ],
            onFilter: (value, record) => record.gcseLevel === value,
            sorter: (a, b) => a.gcseLevel - b.gcseLevel,
            render: (value, record) => (
                <GCSETagClickable value={value} question={record} />
            )
        },
        {
            title: "Calc?",
            dataIndex: "calculatorAllowed",
            filters: [
                { text: "Yes", value: true },
                { text: "No", value: false }
            ],
            onFilter: (value, record) => record.calculatorAllowed === value,
            render: (allowed, question) =>
                allowed ? (
                    <CheckCircleOutlined
                        style={{ fontSize: "1.5rem" }}
                        key={"calc" - +question.id}
                    />
                ) : (
                    ""
                )
        },
        {
            title: "Skills?",
            dataIndex: "skillsQuestion",
            filters: [
                { text: "Yes", value: true },
                { text: "No", value: false }
            ],
            onFilter: (value, record) => record.skillsQuestion === value,
            render: (allowed, question) =>
                allowed ? (
                    <CheckCircleOutlined
                        style={{ fontSize: "1.5rem" }}
                        key={"skills" - +question.id}
                    />
                ) : (
                    ""
                )
        },
        {
            title: "Actions",
            dataIndex: "value",
            render: (value, question) => (
                <div className={"btnContainer"} key={"btns-" + question.id}>
                    <Button
                        size="small"
                        icon={<EditOutlined />}
                        href={
                            urlStub +
                            (question?.imageUrlSuffix ||
                            question?.answerImageUrlSuffix
                                ? "/edit-image-question"
                                : question?.chartData ||
                                    question?.answerChartData
                                  ? "/edit-chart-question"
                                  : "/edit-statement-question") +
                            "?id=" +
                            encodeURIComponent(question.id)
                        }
                    />
                    <Button
                        size="small"
                        icon={<CopyOutlined />}
                        onClick={() =>
                            apiPost(
                                "/api/duplicate-question?id=" +
                                    encodeURIComponent(question.id),
                                {},
                                csrfToken,
                                () => toggleReloadQuestion((p) => !p)
                            )
                        }
                    />
                    <Popconfirm
                        title={
                            <>
                                <b>Delete: </b>Are you sure?
                            </>
                        }
                        onConfirm={() => {
                            apiDelete(
                                "/api/question?id=" +
                                    encodeURIComponent(question.id),
                                csrfToken,
                                () => removeQuestion(question.id)
                            );
                        }}
                        onCancel={() => {}}
                        okText="Delete"
                        cancelText="Cancel"
                    >
                        <Button size="small" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </div>
            )
        }
    ];

    return (
        <>
            <div className={"adminHeader"}>
                <h1>{issuesOnly ? "Reported Issues" : "Question Database"}</h1>
                {!issuesOnly && (
                    <div className={"totalBox"}>
                        Total Questions:{" "}
                        <div className={"value"}>
                            {(currentRows && currentRows.length) ||
                                questions.questions?.filter((n) => n).length}
                        </div>
                    </div>
                )}
                {numErrors ? (
                    <div className={"errorsBox"}>
                        Errors: <div className={"value"}>{numErrors}</div>
                    </div>
                ) : undefined}
                {numIssues ? (
                    <div className={"errorsBox"}>
                        Reported Issues:{" "}
                        <div className={"value"}>{numIssues}</div>
                    </div>
                ) : undefined}
                {!issuesOnly && (
                    <div>
                        <ManageQuestionsBySubTopicCascader
                            topics={topicTree}
                            urlStub={urlStub}
                            onSubtopicChange={() => setLoading(true)}
                        />
                    </div>
                )}
                <div className={"devSwitch"}>
                    <Switch onChange={setShowDevMode} />
                    Dev Mode
                </div>
            </div>
            <Table
                className={"adminTable"}
                dataSource={questions.questions?.filter((n) => n)}
                columns={columns}
                rowKey={"id"}
                tableLayout={"auto"}
                pagination={false}
                scroll={{ y: "70vh" }}
                size={"small"}
                loading={!questions?.questions || loading}
                bordered
                onChange={(pn, fs, st, ex) =>
                    setCurrentRows(ex.currentDataSource)
                }
            />
        </>
    );
}

export default withCookies(ManageQuestionsForm);
