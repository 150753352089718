import { DEFAULT_PREFERENCES } from "helpers/starterHelpers";

export function GCF(n1, n2) {
    if (n2 === 0) {
        return n1;
    }
    return GCF(n2, n1 % n2);
}

export function getTimestampName() {
    const now = new Date(Date.now());
    const date = formatAsDateString(now, "/");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    return `${date} @ ${hours}:${minutes}:${seconds}`;
}

export function getLibraryStarterName({ difficulty, subTopic }) {
    if (difficulty === "mixed") {
        return subTopic;
    }
    const diff = difficulty.substring(0, difficulty === "medium" ? 3 : 4);
    return `${capitalizeFirstLetter(diff)}: ${subTopic}`;
}

export function getAsTimeFraction(minutes) {
    if (!minutes && parseInt(minutes) !== 0) {
        return <div className={"hours"}>N/A</div>;
    }
    minutes = parseInt(minutes);
    const hours = Math.floor(minutes / 60);
    const fractionMinutes = minutes - hours * 60;
    let numerator = fractionMinutes;
    let denominator = 60;
    let gcf = GCF(numerator, denominator);
    while (gcf !== 1) {
        numerator /= gcf;
        denominator /= gcf;
        gcf = GCF(numerator, denominator);
    }
    return (
        <div className={"hours"}>
            {(hours > 0 || fractionMinutes === 0) && hours}
            {fractionMinutes > 0 && (
                <div className={"fraction"}>
                    <div className={"numerator"}>{numerator}</div>
                    <div className={"denominator"}>{denominator}</div>
                </div>
            )}
        </div>
    );
}

export function formatAsTimeValue(timeString) {
    const vals = timeString.split(":").map((s) => parseInt(s));
    return vals.length === 2
        ? (vals[0] * 60 + vals[1]) * 1000
        : vals[0] * 60000;
}

export function formatAsTimeString(timeValue) {
    const seconds = timeValue / 1000;
    return seconds / 60 + ":" + (seconds % 60).toString().padStart(2, "0");
}

/** Format = 12.04.2022 */
export function formatAsDateString(date, sep = ".") {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}${sep}${month}${sep}${year}`;
}

/** Format = 12 Apr 2022 */
export function formatAsDateString2(date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
}

const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getStarterDefaults(user) {
    const starterGridWidth =
        user?.starter_preferences?.starterGridWidth ||
        DEFAULT_PREFERENCES.starterGridWidth;
    return {
        title: "",
        learningObjective: user?.starter_preferences?.hasLearningObjective
            ? ""
            : null,
        plenaryTitle: null,
        wwwStatements: [],
        ebiStatements: [],
        questionChoices: Array.from(
            {
                length: 2 * starterGridWidth
            },
            (v, k) => k
        ).map((i) => ({
            topicArea: null,
            topic: null,
            subtopic: null,
            difficulty: null,
            recallTag:
                starterGridWidth === 3
                    ? user?.starter_preferences?.recallTagsSixQs[i]
                    : user?.starter_preferences?.recallTagsFourQs[i],
            randomSeed: null
        })),
        plenaryQuestionChoices: [],
        extensionChoices: [],
        gridWidth: starterGridWidth,
        gridHeight: 2,
        hasPlenary: DEFAULT_PREFERENCES.hasPlenary,
        hasRecallTags: DEFAULT_PREFERENCES.hasRecallTags,
        plenaryWidth: DEFAULT_PREFERENCES.plenaryGridWidth,
        plenaryHeight: 1,
        fontSize:
            user?.starter_preferences?.questionFontSize ||
            DEFAULT_PREFERENCES.questionFontSize,
        senBackground:
            user?.starter_preferences?.questionBackground ||
            DEFAULT_PREFERENCES.questionBackground,
        cueImage: null
    };
}
