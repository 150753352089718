import React, { useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Chart } from "react-chartjs-2";
import { Image as KImage, Text as KText } from "react-konva";
import { overrideFontSize } from "helpers/chartHelpers";
import { Spin } from "antd";
import {
    mathJaxInitialisedSelector,
    onMathJaxLoaded
} from "helpers/utilsSlice";
import { useDispatch, useSelector } from "react-redux";

export const plenaryRecallTag = { colour: "purple", name: "Exit Ticket" };

export const recallTagOptions = [
    { colour: "green", name: "Today" },
    { colour: "pink", name: "Last Lesson" },
    { colour: "geekblue", name: "Last Week" },
    { colour: "red", name: "2 Weeks Ago" },
    { colour: "cyan", name: "This Term" },
    { colour: "blue", name: "Last Term" },
    { colour: "orange", name: "Random" },
    plenaryRecallTag
];

export const useMathJax = () => {
    const dispatch = useDispatch();
    const initialised = useSelector(mathJaxInitialisedSelector);

    const addScriptTag = (
        id,
        attributes = { type: "text/javascript" },
        text = undefined
    ) => {
        const head = document?.head;
        if (!document || !head || document.getElementById(id)) {
            return;
        }
        const srcEl = document.createElement("script");
        srcEl.setAttribute("id", id);
        Object.entries(attributes).forEach(([k, v]) =>
            srcEl.setAttribute(k, v)
        );
        if (text) {
            srcEl.text = text;
        }
        head.append(srcEl);
    };

    useEffect(() => {
        if (initialised) {
            return;
        }
        const additionalConfig = {
            tex: {
                packages: { "[-]": ["noundefined"] },
                formatError: (jax, error) => {
                    throw error;
                }
            },
            startup: {
                ready: () => {
                    try {
                        dispatch(onMathJaxLoaded());
                    } catch (e) {}
                    window.MathJax.startup.defaultReady();
                }
            }
        };
        if (window?.MathJax) {
            window.MathJax.config = additionalConfig;
        } else {
            window.MathJax = additionalConfig;
        }
        addScriptTag("MathJax-script", {
            type: "text/javascript",
            src: "https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg-full.js",
            async: ""
        });
    }, [dispatch, initialised]);
};

const useTex2Svg = (src = "", { onParseError = undefined } = {}) => {
    const [parsed, setParsed] = useState("");
    const [loading, setLoading] = useState(true);
    const initialised = useSelector(mathJaxInitialisedSelector);

    useEffect(() => {
        if (!src) {
            setLoading(false);
            setParsed(null);
            onParseError && onParseError(null); // onSuccess
            return;
        }
        if (!initialised || !window?.MathJax) {
            return;
        }
        (async () => {
            setLoading(true);
            try {
                await window.MathJax.startup.promise;
                if (!window?.MathJax?.tex2svgPromise) {
                    setLoading(false);
                    onParseError && onParseError("Error loading MathJax");
                    return;
                }
                const node = await window.MathJax.tex2svgPromise(src, {
                    display: false
                });
                if (node instanceof Error) {
                    throw node;
                }
                setParsed(node.outerHTML);
                setLoading(false);
                onParseError && onParseError(null); // onSuccess
            } catch (err) {
                setLoading(false);
                setParsed(src);
                onParseError && onParseError((err?.message ?? err).toString());
            }
        })();
    }, [initialised, onParseError, src]);

    return { parsed, loading };
};

const MathComponentLocal = React.memo(({ tex, onParseError = undefined }) => {
    const { parsed: content, loading } = useTex2Svg(tex, {
        onParseError
    });

    return (
        <span className="outer1">
            {loading ? <Spin /> : null}
            <span
                className="mjx"
                dangerouslySetInnerHTML={{ __html: content }}
            />
        </span>
    );
});

export function TexComponent({ tex, className = "", onParseError = () => {} }) {
    const elements = useMemo(() => {
        try {
            let formatAsTex = false;
            const sanitised = tex.replace(/\\\$/g, "¦");
            const split = sanitised.split(/\$/);
            let values = [];
            for (let i = 0; i < split.length; i++) {
                const s = split[i].replace(/¦/g, "$");
                if (!formatAsTex) {
                    const sLines = s.split("<br/>");
                    values.push({ val: sLines[0], isMath: formatAsTex });
                    const tail = sLines.slice(1);
                    for (const line of tail) {
                        values.push({ val: <br />, isMath: formatAsTex });
                        values.push({ val: line, isMath: formatAsTex });
                    }
                } else {
                    values.push({ val: s, isMath: formatAsTex });
                }
                formatAsTex = !formatAsTex;
            }
            return values.map((value, i) =>
                value.isMath ? (
                    <MathComponentLocal
                        tex={value.val}
                        onParseError={onParseError}
                    />
                ) : (
                    <span key={i}>{value.val}</span>
                )
            );
        } catch (err) {
            onParseError && onParseError((err?.message ?? err).toString());
        }
    }, [onParseError, tex]);

    return <div className={className}>{elements}</div>;
}

export function getImage(
    suffix,
    className = "",
    key = undefined,
    ref = undefined,
    onLoad = undefined
) {
    return (
        <img
            src={process.env.REACT_APP_IMAGE_DIRECTORY + suffix}
            ref={ref}
            key={key}
            alt={suffix}
            className={className}
            onLoad={onLoad}
        />
    );
}

const IMG_ERROR_FLAG = "ERROR";

export function StackedKonvaImages({ suffixes = [], maxWidth = null }) {
    const [images, setImages] = useState([]);

    useEffect(() => {
        if (!suffixes?.length) {
            return;
        }
        const loadingImages = suffixes.map((suffix, index) => {
            const loadingImage = new Image();
            loadingImage.src = process.env.REACT_APP_IMAGE_DIRECTORY + suffix;
            const handleLoad = () => {
                setImages((prev) => {
                    const copy = [...prev];
                    copy[index] = loadingImage;
                    return copy;
                });
            };
            const handleError = () => {
                setImages((prev) => {
                    const copy = [...prev];
                    copy[index] = IMG_ERROR_FLAG;
                    return copy;
                });
            };
            loadingImage.addEventListener("load", handleLoad);
            loadingImage.addEventListener("error", handleError);
            return [loadingImage, handleLoad, handleError];
        });
        return () =>
            loadingImages.forEach(([loadingImage, handleLoad, handleError]) => {
                loadingImage.removeEventListener("load", handleLoad);
                loadingImage.removeEventListener("error", handleError);
            });
    }, [suffixes]);

    if (!images?.some((s) => s)) {
        return (
            <KText
                text={"Loading..."}
                fontFamily={"Nunito Sans, sans-serif"}
                fontSize={18}
                fill={"#8c8c8c"}
                y={10}
            />
        );
    }

    const imagePadding = 20;

    const getActualImageWidth = (image) => {
        const srcWidth = image?.naturalWidth;
        let width;
        if (!maxWidth) {
            width = srcWidth;
        } else {
            width = Math.min(srcWidth, maxWidth);
        }
        return width;
    };

    const getActualImageHeight = (image) => {
        const srcWidth = image?.naturalWidth || 1; /* Prevents divideByZero */
        const srcHeight = image?.naturalHeight ?? 0;
        const width = getActualImageWidth(image);
        const scale = width / srcWidth;
        return scale * srcHeight;
    };

    const getYOffset = (prevImages) => {
        return (
            imagePadding +
                prevImages
                    ?.map((img) => imagePadding + getActualImageHeight(img))
                    ?.reduce((a, b) => a + b, 0) ?? 0
        );
    };

    return images.map((image, i) =>
        image !== IMG_ERROR_FLAG ? (
            <KImage
                image={image}
                height={getActualImageHeight(image)}
                width={getActualImageWidth(image)}
                y={getYOffset(images?.slice(0, i))}
            />
        ) : (
            <KText
                text={"Unable to Load Image"}
                fontFamily={"Nunito Sans, sans-serif"}
                fontSize={24}
                y={10}
                fill={"red"}
            />
        )
    );
}

export function getChart(chartData, className = "", fontSizeOverride = null) {
    if (!chartData?.type || !chartData?.data) {
        return null;
    }
    const type = chartData?.type || "bar";
    let options = chartData?.options || {};
    if (fontSizeOverride) {
        options = overrideFontSize(type, options, fontSizeOverride);
    }
    return (
        chartData?.data && (
            <ErrorBoundary fallback={<div>Something went wrong</div>}>
                <Chart
                    className={className}
                    type={type}
                    data={chartData?.data || {}}
                    options={options}
                    plugins={chartData?.plugins || []}
                />
            </ErrorBoundary>
        )
    );
}

export function filterCascader(inputValue, path) {
    if (path?.length > 3) {
        // Prevents difficulties (Easy/Medium/Hard) from showing up in search results
        return false;
    }
    return path.some((option) => {
        let label = option.label;
        if (typeof label !== "string") {
            label = option.value;
        }
        return label.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
    });
}

function highlightKeyword(str, keyword, prefixCls) {
    return str.split(keyword).map((node, index) =>
        index === 0
            ? node
            : [
                  <span
                      className={`${prefixCls}-menu-item-keyword`}
                      key="seperator"
                  >
                      {keyword}
                  </span>,
                  node
              ]
    );
}

export function renderCascader(inputValue, path, prefixCls, names) {
    return path.map((option, index) => {
        let label = option[names.label];
        if (typeof label !== "string") {
            label = option[names.value];
        }
        const i = label.toLowerCase().indexOf(inputValue.toLowerCase());
        const node =
            i > -1
                ? highlightKeyword(
                      label,
                      label.substring(i, i + inputValue.length),
                      prefixCls
                  )
                : label;
        return index === 0 ? node : [" / ", node];
    });
}

export function questionChoiceAsObject(choice) {
    if (!choice) {
        return null;
    }
    const len = choice.length;
    const difficulty = choice?.[len - 1];
    if (!["Easy", "Medium", "Hard"].includes(difficulty)) {
        // Difficulty is not selected yet -- fill as many of the fields as possible
        return {
            topicArea: choice?.[0],
            topic: choice?.[1],
            subtopic: choice?.[2],
            difficulty: choice?.[3]
        };
    }
    // Selecting from end allows for case where choice = ["subTopic", "difficulty"] with no topic/area
    return {
        topicArea: choice?.[len - 4],
        topic: choice?.[len - 3],
        subtopic: choice?.[len - 2],
        difficulty: difficulty
    };
}

export function questionChoiceAsArray(choice) {
    if (!choice || !choice.topicArea) {
        return [];
    }
    return [choice.topicArea, choice.topic, choice.subtopic, choice.difficulty];
}
