import * as React from "react";

const SvgIconEdit = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M17 4 4 17v4h4L21 8M17 4l4 4"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m14 7 4 4"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgIconEdit;
