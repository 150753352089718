import { Route } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import FreeResources from "pages/FreeResources";

export default function FreeResourcesSiteRoutes({
    fullscreen,
    makeFullscreen
}) {
    return (
        <Route>
            <Helmet>
                <title>MathsPlanner - Free Resources</title>
            </Helmet>
            <div className="wrap grey">
                <FreeResources
                    fullscreen={fullscreen}
                    makeFullscreen={makeFullscreen}
                />
            </div>
        </Route>
    );
}
