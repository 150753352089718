import React, { useEffect, useState } from "react";
import { Button, Card, Divider, Space } from "antd";
import { apiGet, apiPost } from "helpers/api";
import Title from "antd/lib/typography/Title";
import PaymentModal from "features/account/PaymentModal";
import { useGetUserQuery } from "features/account/accountSlice";
import { withCookies } from "react-cookie";
import { isUserAwaitingPayment } from "helpers/security";

function SubscriptionInfo({ cookies }) {
    const [renewalDate, setRenewalDate] = useState(null);
    const [trialEnd, setTrialEnd] = useState(null);
    const [error, setError] = useState(null);
    const [showEarlyPaymentModal, setShowEarlyPaymentModal] = useState(false);

    const [csrfToken] = useState(cookies.get("XSRF-TOKEN"));
    const { data: user } = useGetUserQuery();
    async function redirectToPortal() {
        // Get URL for and redirect to customer portal
        apiPost(
            "/api/create-customer-portal-session?redirectUrl=" +
                window.location.href,
            "",
            csrfToken,
            (response) =>
                response
                    .json()
                    .then((session) => (window.location.href = session.url)),
            setError
        );
    }

    useEffect(() => {
        setRenewalDate(null);

        apiGet("/api/renewal-date", (body) => {
            if (
                !body ||
                body === {} ||
                body === "" ||
                Object.keys(body).length === 0
            ) {
                setRenewalDate(null);
            } else {
                setRenewalDate(body.renewalDate);
            }
        });
    }, [user]);

    useEffect(() => {
        setTrialEnd(null);

        apiGet("/api/trial-end", (body) => {
            if (
                !body ||
                body === {} ||
                body === "" ||
                Object.keys(body).length === 0
            ) {
                setTrialEnd(null);
            } else {
                setTrialEnd(body.trialEnd);
            }
        });
    }, [user]);

    const loggedIn = user && true;

    const hasGroups = loggedIn && user["cognito:groups"];
    const paid =
        hasGroups &&
        (user["cognito:groups"].includes("paid") ||
            user["cognito:groups"].includes("admin"));
    const trial = hasGroups && user["cognito:groups"].includes("trial");
    const awaitingPayment = isUserAwaitingPayment(user);
    const subscriptionTypeEnumValue =
        loggedIn && (user["custom:subscriptionType"] || "None");
    const isSiteLicence = subscriptionTypeEnumValue === "Site";
    const isSubSiteLicence = subscriptionTypeEnumValue === "SubSite";

    let manageSubscriptionBtn;
    if (paid) {
        manageSubscriptionBtn = (
            <Button onClick={redirectToPortal}>
                Manage Subscription/Payment Info.
            </Button>
        );
    } else if (awaitingPayment) {
        manageSubscriptionBtn = (
            <p>
                An invoice has been sent to the finance department email you
                specified (this may take up to an hour), once payment has been
                made you will need to log out and log in again to gain access.
            </p>
        );
    } else {
        manageSubscriptionBtn = (
            <Space direction={"vertical"}>
                <p>
                    Once your trial has finished you will be able to manage your
                    payment details here
                </p>
                <Divider />
                <PaymentModal
                    isVisible={showEarlyPaymentModal}
                    setIsVisible={setShowEarlyPaymentModal}
                    isSingleLicence={!isSiteLicence}
                    isSubSiteLicence={isSubSiteLicence}
                    awaitingPayment={awaitingPayment}
                    isNotForcedOpen
                />
                <Button
                    onClick={() => setShowEarlyPaymentModal(true)}
                    type={"primary"}
                >
                    SIGN UP
                </Button>
            </Space>
        );
    }

    const subscriptionType = ((subscriptionTypeEnumValue) => {
        switch (subscriptionTypeEnumValue) {
            case "Single":
                return "Single Licence";
            case "Site":
                return "Site Licence";
            case "SubSite":
                return "Sub-user on Site Licence";
            case "Admin":
                return "Admin Licence";
            case "None":
            default:
                return "No Licence";
        }
    })(subscriptionTypeEnumValue);

    return (
        <Card className={"card-small subscriptionCard"}>
            <Title level={2}>Subscription Info.</Title>
            <Divider />
            <div className={"subscriptionStatusRow"}>
                <b className={"infoLabel"}>Subscription:</b>
                {paid ? (
                    <p className={"subscriptionActive infoValue"}>
                        {" "}
                        Active {renewalDate ? `- (Renews: ${renewalDate})` : ""}
                    </p>
                ) : trial ? (
                    <p className={"subscriptionTrial infoValue"}>
                        {" "}
                        Free Trial{" "}
                        {renewalDate || trialEnd
                            ? `- (Ends: ${renewalDate || trialEnd})`
                            : ""}
                    </p>
                ) : awaitingPayment ? (
                    <p className={"subscriptionTrial infoValue"}>
                        {" "}
                        Awaiting Invoice Payment{" "}
                        {trialEnd ? `- (Due: ${trialEnd})` : ""}
                    </p>
                ) : (
                    <p className={"subscriptionInactive infoValue"}>
                        {" "}
                        No Subscription
                    </p>
                )}
            </div>
            <div className={"subscriptionStatusRow"}>
                <b className={"infoLabel"}>Subscription Type:</b>{" "}
                <p className={"infoValue"}>{subscriptionType}</p>
            </div>
            <div className={"subscriptionStatusRow"}>
                <b className={"infoLabel"}>Payment Details:</b>{" "}
                <p className={"infoValue"}>{manageSubscriptionBtn}</p>
            </div>
            <div>
                <b className={"infoLabel"} />
                {error && (
                    <p className="text-danger">
                        Error. Unable to get payment information:{" "}
                        {error.message}
                    </p>
                )}
            </div>
        </Card>
    );
}

export default withCookies(SubscriptionInfo);
