import { Modal, notification } from "antd";
import * as React from "react";
import { CopyTwoTone } from "@ant-design/icons";

function PremiumFeatureModal({ open, onClose }) {
    const email = "chloe@mathsplanner.com";

    return (
        <Modal
            title={null}
            footer={null}
            closable
            width={"min(50rem, 95%)"}
            destroyOnClose={true}
            open={open}
            onCancel={onClose}
            centered
            className={"help-image-modal premium-feature-modal"}
        >
            <img
                src={process.env.PUBLIC_URL + "/svgs/MP_Plus_Logo.svg"}
                alt="Maths Planner +"
                className={"img-center"}
            />
            <div>
                <h1>This is a Premium feature...</h1>
                <p>Get in touch to discuss your subscription options</p>
            </div>
            <div
                className={"email-copy"}
                onClick={(e) => {
                    e.stopPropagation();
                    if (navigator?.clipboard?.writeText) {
                        navigator.clipboard.writeText(email);
                    } else {
                        // Legacy browser implementation
                        // Create dummy input containing URL and select it
                        const dummy = document.createElement("input");
                        document.body.appendChild(dummy);
                        dummy.value = email;
                        dummy.select();
                        dummy.setSelectionRange(0, 99999);
                        document.execCommand("copy");
                        document.body.removeChild(dummy);
                    }
                    notification.success({
                        message: "Success",
                        duration: 1.5,
                        description: "Email address copied to clipboard."
                    });
                }}
            >
                {email}
                <CopyTwoTone />
            </div>
        </Modal>
    );
}

export default PremiumFeatureModal;
