import React from "react";

const EmptyCircle = () => (
    <svg
        width="1em"
        height="1em"
        fill="none"
        stroke="currentColor"
        viewBox="-10 -10 120 120"
    >
        <circle strokeWidth={10} cx="50" cy="50" r="55" />
    </svg>
);

export default EmptyCircle;
