import { Button, message, Modal, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { apiGet } from "helpers/api";
import { getShuffledTagColoursContinuous } from "helpers/tagHelpers";
import { checkForDisabledTopics } from "helpers/starterHelpers";
import { CloseOutlined } from "@ant-design/icons";
import { OurLibraryButtonRow } from "features/ourLibrary/OurLibraryButtonRow";
import { OurLibraryMultiSelectPanel } from "./components/OurLibraryMultiSelectPanel";
import { SubTopicCards } from "./components/SubTopicCards";
import { OurLibraryFooter } from "./components/OurLibraryFooter";
import { OurLibrarySearch } from "./components/OurLibrarySearch";

const colours = getShuffledTagColoursContinuous();

function OurLibraryModal({
    isVisible,
    setIsVisible,
    onSelect = ({ subTopic, difficulty }) => {},
    useFooter = false
}) {
    const MAX_STARTERS = 1;

    // API data
    const [loading, setLoading] = useState(true);
    const [topicsTree, setTopicsTree] = useState([]);
    const [subTopics, setSubTopics] = useState([]);

    // User selections
    const [selectedTopicArea, setSelectedTopicArea] = useState(null);
    const [selectedTopicLabels, setSelectedTopicLabels] = useState([]);
    const [selectedSubTopic, setSelectedSubTopic] = useState(null);
    const [selectedStarters, setSelectedStarters] = useState([]);
    const [searchTerm, setSearchTerm] = useState(null);

    const clearFilters = useCallback(() => {
        setSelectedTopicArea(null);
        setSelectedTopicLabels([]);
        setSelectedSubTopic(null);
        setSearchTerm(null);
    }, []);

    const resetModal = useCallback(() => {
        clearFilters();
        setSelectedStarters([]);
    }, [clearFilters]);

    useEffect(() => resetModal(), [isVisible, resetModal]);

    const loadAndFormatTopics = useCallback(() => {
        setTopicsTree([]);
        apiGet(
            "/api/all-topics-tree",
            (body) => {
                let result = checkForDisabledTopics(body).filter(
                    (topicArea) => !topicArea?.disabled
                );
                const topicCounts = result.map(
                    (topicArea) => topicArea?.children?.length || 0
                );
                result = result.map((topicArea, i) => ({
                    ...topicArea,
                    children: topicArea.children.map((topic, j) => ({
                        ...topic,
                        colour: colours[
                            (topicCounts
                                .slice(0, i)
                                .reduce((a, b) => a + b, 0) +
                                j) %
                                colours.length
                        ]
                    }))
                }));
                setTopicsTree(result);
                setSubTopics(
                    result?.flatMap(
                        (topicArea) =>
                            topicArea?.children?.flatMap(
                                (topic) =>
                                    topic?.children?.map((subTopic) => ({
                                        ...subTopic,
                                        topic,
                                        topicArea,
                                        order: Math.random() /* Shuffle subTopics on load */,
                                        colour: topic.colour
                                    })) || []
                            ) || []
                    )
                );
                setSelectedTopicLabels([]);
                setLoading(false);
            },
            (err) => {
                message.error(err.message);
                setLoading(false);
            }
        );
    }, []);

    useEffect(() => {
        loadAndFormatTopics();
    }, [loadAndFormatTopics]);

    let visibleTopics =
        selectedTopicArea?.children ||
        topicsTree.flatMap((topicArea) => topicArea.children);
    if (searchTerm) {
        visibleTopics = visibleTopics.filter(
            (topic) =>
                topic.children.some((subTopic) =>
                    subTopic.label
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                ) ||
                topic.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    let visibleSubTopics = subTopics;
    if (searchTerm) {
        visibleSubTopics = visibleSubTopics.filter(
            (subTopic) =>
                subTopic.label
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                subTopic.topic.label
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
        );
    }
    if (selectedTopicLabels?.length) {
        visibleSubTopics = visibleSubTopics.filter((subTopic) =>
            selectedTopicLabels.includes(subTopic.topic.label)
        );
    } else if (selectedTopicArea) {
        visibleSubTopics = visibleSubTopics.filter(
            (subTopic) => subTopic.topicArea.label === selectedTopicArea.label
        );
    }
    visibleSubTopics = visibleSubTopics.sort((a, b) => a.order - b.order);

    return (
        <Modal
            title={null}
            closable={false}
            maskClosable={true}
            open={isVisible}
            onCancel={() => setIsVisible(false)}
            footer={null}
            destroyOnClose={true}
            width={"100vw"}
            className={"our-library__modal_wrapper"}
        >
            <div className={"our-library__body"}>
                <div className={"our-library__side_panel"}>
                    <h1>
                        Library{" "}
                        <div className={"subtitle"}>Sub-topic Starter</div>
                    </h1>
                    <OurLibrarySearch
                        value={searchTerm}
                        onValueChange={(value) => {
                            clearFilters();
                            if (value && value !== "") {
                                setSearchTerm(value);
                            }
                        }}
                        onClear={() => {
                            clearFilters();
                        }}
                    />

                    <h2>
                        Topics <span className={"subtitle"}>Multi-Select</span>
                    </h2>
                    {loading ? (
                        <Spin />
                    ) : (
                        <OurLibraryMultiSelectPanel
                            options={visibleTopics.filter((t) => !t?.disabled)}
                            selectedLabels={selectedTopicLabels}
                            toggleFromSelected={(topic) =>
                                setSelectedTopicLabels((selected) => {
                                    if (selected.includes(topic)) {
                                        return selected.filter(
                                            (t) => t !== topic
                                        );
                                    } else {
                                        return [...selected, topic];
                                    }
                                })
                            }
                        />
                    )}
                </div>
                <div className={"our-library__main_panel"}>
                    <div className={"our-library__header"}>
                        {loading ? (
                            <Spin />
                        ) : (
                            <OurLibraryButtonRow
                                showAllOption={true}
                                options={topicsTree.filter((t) => !t?.disabled)}
                                selected={selectedTopicArea}
                                setSelected={setSelectedTopicArea}
                                onClick={() => setSelectedTopicLabels([])}
                            />
                        )}
                        <Button
                            type={"text"}
                            onClick={() => setIsVisible(false)}
                            className={"our-library__close"}
                        >
                            Cancel <CloseOutlined />
                        </Button>
                    </div>
                    <div className={"our-library__content"}>
                        <h2>
                            {searchTerm
                                ? '"' + searchTerm + '"'
                                : selectedTopicArea?.label || "All Areas"}{" "}
                            <span className={"subtitle"}>
                                {searchTerm ? (
                                    visibleSubTopics.filter((t) => !t?.disabled)
                                        .length + " Search Results"
                                ) : (
                                    <>
                                        {selectedTopicLabels?.length > 0
                                            ? selectedTopicLabels
                                                  .slice(0, 3)
                                                  .reduce(
                                                      (a, b) => a + ", " + b
                                                  )
                                            : ""}
                                        {selectedTopicLabels?.length > 3
                                            ? " And " +
                                              (selectedTopicLabels?.length -
                                                  3) +
                                              " more..."
                                            : ""}
                                    </>
                                )}
                            </span>
                        </h2>
                        <SubTopicCards
                            disabled={selectedStarters.length >= MAX_STARTERS}
                            updateKey={
                                searchTerm +
                                selectedTopicArea?.label +
                                selectedTopicLabels.reduce((a, b) => a + b, "")
                            }
                            visibleSubTopics={visibleSubTopics.filter(
                                (t) => !t?.disabled
                            )}
                            loading={loading}
                            selectedSubTopic={selectedSubTopic}
                            setSelectedSubTopic={setSelectedSubTopic}
                            addToSelectedStarters={(newItem) => {
                                if (useFooter) {
                                    setSelectedStarters((prev) => {
                                        if (prev.length >= MAX_STARTERS) {
                                            return prev;
                                        }
                                        const copy = [...prev];
                                        copy.push(newItem);
                                        return copy;
                                    });
                                } else {
                                    onSelect({
                                        subTopic: newItem.subTopic.label,
                                        difficulty: newItem.difficulty
                                    });
                                    setIsVisible(false);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            {useFooter && (
                <OurLibraryFooter
                    selectedStarters={selectedStarters}
                    setSelectedStarters={setSelectedStarters}
                    numSlots={MAX_STARTERS}
                    onSubmit={() => {
                        onSelect({
                            subTopic: selectedStarters[0].subTopic.label,
                            difficulty: selectedStarters[0].difficulty
                        });
                        setIsVisible(false);
                    }}
                />
            )}
        </Modal>
    );
}

export default OurLibraryModal;
