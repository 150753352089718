import React, { useEffect, useState } from "react";

import { apiGet, apiPost, apiPut } from "helpers/api";
import { withCookies } from "react-cookie";
import { Button, Form, Input, Select } from "antd";

function AddTopicForm({ cookies, editing = null }) {
    const [form] = Form.useForm();
    const [topicAreas, setTopicAreas] = useState([]);
    const [csrfToken] = useState(cookies.get("XSRF-TOKEN"));

    useEffect(() => {
        if (editing) {
            apiGet(
                "/api/topic?topic=" + encodeURIComponent(editing),
                (body) => {
                    form.setFieldsValue({
                        topic: body.value,
                        topicArea: body.parent
                    });
                }
            );
        }
    }, [editing, form]);

    useEffect(
        () =>
            apiGet("/api/topic-areas", (body) => {
                setTopicAreas(
                    body.map((ta) => ({ label: ta.value, value: ta.value }))
                );
                if (!editing) {
                    form.setFieldsValue({ topicArea: body[0].value });
                }
            }),
        [editing, form]
    );

    function onSubmit(formData) {
        if (!editing) {
            apiPost("/api/add-topic", JSON.stringify(formData), csrfToken);
        } else {
            apiPut(
                "/api/update-topic?originalName=" + encodeURIComponent(editing),
                JSON.stringify(formData),
                csrfToken
            );
        }
    }

    return (
        <Form form={form} onFinish={onSubmit}>
            <Form.Item name="topic" label={"Topic"}>
                <Input required placeholder="E.g. Fractions" />
            </Form.Item>
            <Form.Item name="topicArea" label={"Topic Area"}>
                <Select>
                    {topicAreas.map((ta) => (
                        <Select.Option key={ta.value} value={ta.value}>
                            {ta.label}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item>
                <Button key="submit" type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}

export default withCookies(AddTopicForm);
