import { AutoComplete, Button, Form } from "antd";
import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import React from "react";

function AutoCompleteControl(field, props) {
    return (
        <Form.Item rules={[{ required: true }]} {...field} noStyle>
            <AutoComplete
                filterOption={(inputValue, option) =>
                    option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                }
                style={{
                    width: props?.usesLocalAdd
                        ? "calc(95% - 7rem)"
                        : "calc(95% - 3.5rem)",
                    marginRight: "2%",
                    marginLeft: "2%"
                }}
            >
                {props?.options.map((st) => (
                    <AutoComplete.Option key={st.value} value={st.value}>
                        {st.label}
                    </AutoComplete.Option>
                ))}
            </AutoComplete>
        </Form.Item>
    );
}

export function ListForm({
    name,
    label,
    subPrefix,
    topLevelControls = undefined,
    control = AutoCompleteControl,
    required,
    usesLocalAdd = false,
    onAdd = (added) => {},
    onRemove = (removed) => {},
    labelFunction = (index) => index,
    canBeEmpty = true,
    ...extraProps
}) {
    const controlProps = { usesLocalAdd, ...extraProps };
    const initialValue = canBeEmpty ? [] : [""];

    const showHeaderAdd = (fields) =>
        !usesLocalAdd || (fields.length === 0 && !initialValue?.length);

    return (
        <>
            {Boolean(label) && (
                <h2 className={"buttonTitleFormContainer"}>{label}</h2>
            )}
            {topLevelControls}
            <Form.List
                rules={[{ required }]}
                name={!topLevelControls ? name : [name, "children"]}
                {...(initialValue?.length ? { initialValue } : {})}
            >
                {(fields, { add, remove }) => (
                    <>
                        {showHeaderAdd(fields) && (
                            <h2 className={"buttonTitleFormContainer"}>
                                <Button
                                    type={"primary"}
                                    size={"small"}
                                    onClick={() => {
                                        add();
                                        onAdd(labelFunction(fields.length));
                                    }}
                                >
                                    Add
                                </Button>
                            </h2>
                        )}

                        {Boolean(
                            usesLocalAdd &&
                                fields?.length &&
                                !initialValue?.length
                        ) && (
                            <Form.Item
                                label={" "}
                                colon={false}
                                labelCol={{ offset: 2 }}
                                className={"listFormItem"}
                                style={{ marginBottom: 0 }}
                            >
                                <Button
                                    icon={<PlusCircleFilled />}
                                    type={"text"}
                                    onClick={() => {
                                        add(undefined, 0);
                                        onAdd(labelFunction(0));
                                    }}
                                />
                            </Form.Item>
                        )}
                        {fields.map((field, index) => (
                            <Form.Item
                                label={labelFunction(index)}
                                labelCol={{ offset: 1 }}
                                className={"listFormItem"}
                                rules={[{ required: true }]}
                                key={field.key}
                                colon={false}
                            >
                                {control(field, controlProps)}
                                {usesLocalAdd && (
                                    <Button
                                        icon={<PlusCircleFilled />}
                                        type={"text"}
                                        onClick={() => {
                                            add(undefined, index + 1);
                                            onAdd(labelFunction(index + 1));
                                        }}
                                    />
                                )}
                                {(index > 0 || canBeEmpty) && (
                                    <Button
                                        icon={<MinusCircleFilled />}
                                        type={"text"}
                                        onClick={() => {
                                            remove(field.name);
                                            onRemove(labelFunction(index));
                                        }}
                                    />
                                )}
                            </Form.Item>
                        ))}
                    </>
                )}
            </Form.List>
        </>
    );
}
