import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        drawerOpen: false,
        showNewSaveView: false
    },
    reducers: {
        setSavesDrawerOpen: {
            reducer: (state, action) => {
                state.drawerOpen = action.payload;
            },
            prepare: (val) => ({ payload: val })
        },
        setShowNewSaveView: {
            reducer: (state, action) => {
                state.showNewSaveView = action.payload;
            },
            prepare: (val) => ({ payload: val })
        }
    }
});

export const { setSavesDrawerOpen, setShowNewSaveView } =
    dashboardSlice.actions;

export default dashboardSlice;
