import { Avatar, Button, List } from "antd";
import React from "react";
import { useGetPaperQuery } from "features/mockPapers/mockPapersSlice";
import { DownloadOutlined } from "@ant-design/icons";
import { getS3FilePath } from "helpers/api";

// function forceDownload(blob, filename) {
//     let a = document.createElement("a");
//     a.download = filename;
//     a.href = blob;
//     // For Firefox https://stackoverflow.com/a/32226068
//     document.body.appendChild(a);
//     a.click();
//     a.remove();
// }
//
// // Current blob size limit is around 500MB for browsers
// function downloadResource(url, filename) {
//     if (!filename) filename = url.split("\\").pop().split("/").pop();
//     fetch(url, {
//         headers: new Headers({
//             Origin: window.location.origin
//         }),
//         mode: "cors"
//     })
//         .then((response) => response.blob())
//         .then((blob) => {
//             let blobUrl = window.URL.createObjectURL(blob);
//             forceDownload(blobUrl, filename);
//         })
//         .catch((e) => console.error(e));
// }

function MockPaperDownloadModalContents({ paperId, open }) {
    const { data: paper, isFetching: loading } = useGetPaperQuery(paperId, {
        skip: !open
    });

    if (!open) {
        return null;
    }
    return (
        <>
            <div className={"mock_papers_modal__wrapper"}>
                <h1>{paper?.shortCode}</h1>
                <p className={"lesson_type_modal__heading"}>PDF Downloads</p>
                <List
                    loading={loading}
                    dataSource={[
                        {
                            title: "Question Paper",
                            field: "paperDownloadLink",
                            fileNameSuffix: "Paper.pdf",
                            avatar: (
                                <Avatar
                                    style={{ backgroundColor: "#ec5bb2" }}
                                    shape={"square"}
                                >
                                    QS
                                </Avatar>
                            )
                        },
                        {
                            title: "Mark Scheme",
                            field: "markSchemeDownloadLink",
                            fileNameSuffix: "Mark Scheme.pdf",
                            avatar: (
                                <Avatar
                                    style={{ backgroundColor: "#2fccc4" }}
                                    shape={"square"}
                                >
                                    MS
                                </Avatar>
                            )
                        },
                        {
                            title: "Worked Solutions",
                            field: "workedSolutionsDownloadLink",
                            fileNameSuffix: "Worked Solutions.pdf",
                            avatar: (
                                <Avatar
                                    style={{ backgroundColor: "#7734d6" }}
                                    shape={"square"}
                                >
                                    WS
                                </Avatar>
                            )
                        },
                        {
                            title: "Question-Level Analysis (QLA) Markbook (.xls)",
                            field: "qlaDownloadLink",
                            fileNameSuffix: "QLA.xlsx",
                            avatar: (
                                <Avatar
                                    style={{ backgroundColor: "#21A366" }}
                                    shape={"square"}
                                >
                                    QLA
                                </Avatar>
                            )
                        }
                    ].filter((item) => paper?.[item.field])}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={item.avatar}
                                title={item.title}
                            />
                            <Button
                                type={"text"}
                                icon={<DownloadOutlined />}
                                href={getS3FilePath(paper?.[item.field])}
                                className={"download_link"}
                                target={"_blank"}
                                rel="noreferrer"
                                download={`${paper.shortCode}-${item.fileNameSuffix}`}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </>
    );
}

export default MockPaperDownloadModalContents;
