import * as React from "react";

const SvgMpLogoIcon = (props) => (
    <svg
        viewBox="0 0 135 175"
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeLinejoin: "round",
            strokeMiterlimit: 2
        }}
        width="1em"
        height="1em"
        {...props}
    >
        <path
            style={{
                fill: "none"
            }}
            d="M21290.7 579.617h421.409V901.87H21290.7z"
            transform="matrix(.31848 0 0 .54193 -6780.6 -314.1)"
        />
        <path
            d="M18980.5 7681.64c0-2.69-3-4.88-6.7-4.88h-70.4c-3.7 0-6.7 2.19-6.7 4.88v74.45c0 2.69 3 4.88 6.7 4.88h70.4c3.7 0 6.7-2.19 6.7-4.88v-74.45Z"
            style={{
                fill: "#fff"
            }}
            transform="matrix(1.36491 0 0 1.87591 -25781.846 -14392.57)"
        />
        <path
            d="M7829.16 1835.8c0-.73-.65-1.33-1.47-1.33h-84.29v25.02h84.29c.82 0 1.47-.6 1.47-1.33v-22.36Z"
            style={{
                fill: "#1660e7"
            }}
            transform="matrix(.679 0 0 .75564 -5248.429 -1355.878)"
        />
        <path
            d="M7829.16 1835.8c0-.36-.15-.69-.43-.94-.27-.25-.64-.39-1.04-.39h-84.29v25.02h84.29c.4 0 .77-.14 1.04-.39.28-.25.43-.59.43-.94v-22.36Z"
            style={{
                fill: "#1660e7"
            }}
            transform="matrix(.68028 0 0 .75564 -5258.419 -1326.408)"
        />
        <path
            d="M29052.3 13072.1c-.4-.4-1-.4-1.4 0-2.9 2.9-14 14-17 16.9-.1.2-.2.5-.2.7 0 .3.1.5.2.7 5.1 5 35.5 35.1 35.5 35.1l38.8-38.3v-36.4l-38.8 38.3s-13.8-13.8-17.1-17Z"
            style={{
                fill: "#1660e7"
            }}
            transform="matrix(1.00984 0 0 1.00984 -29269.895 -13088.416)"
        />
    </svg>
);

export default SvgMpLogoIcon;
