import React, { useState } from "react";
import { Button, Card, Divider, Modal } from "antd";
import Title from "antd/lib/typography/Title";
import ChangePasswordPage from "./ChangePasswordPage";
import { LockOutlined } from "@ant-design/icons";
import { useGetUserQuery } from "features/account/accountSlice";

function AccountInfo() {
    const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);

    const { data: user } = useGetUserQuery();
    return (
        <Card className={"card-small accountCard"}>
            <Title level={2}>Account Info.</Title>
            <Divider />
            <div className={"subscriptionStatusRow"}>
                <b className={"infoLabel"}>First Name:</b>{" "}
                <p className={"infoValue"}>{user && user.given_name}</p>
            </div>
            <div className={"subscriptionStatusRow"}>
                <b className={"infoLabel"}>Surname:</b>{" "}
                <p className={"infoValue"}>{user && user.family_name}</p>
            </div>
            <div className={"subscriptionStatusRow"}>
                <b className={"infoLabel"}>Email:</b>{" "}
                <p className={"infoValue"}>{user && user.email}</p>
            </div>
            <div className={"subscriptionStatusRow"}>
                <b className={"infoLabel"}>Login Details:</b>{" "}
                <p className={"infoValue"}>
                    <Button onClick={() => setIsPasswordModalVisible(true)}>
                        Change Password
                    </Button>
                    <Modal
                        title={
                            <Title level={3} style={{ marginBottom: 0 }}>
                                <LockOutlined />
                                Change Password
                            </Title>
                        }
                        closable={false}
                        destroyOnClose={true}
                        open={isPasswordModalVisible}
                        footer={
                            <Button
                                type="default"
                                className={"modalClose"}
                                onClick={() => {
                                    setIsPasswordModalVisible(false);
                                }}
                            >
                                Cancel
                            </Button>
                        }
                        centered
                        width={"min(900px, 80%)"}
                    >
                        <ChangePasswordPage
                            setModalVisible={setIsPasswordModalVisible}
                        />
                    </Modal>
                </p>
            </div>
        </Card>
    );
}

export default AccountInfo;
