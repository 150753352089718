export const chartTypes = [
    "bar",
    "bar (stacked)",
    "boxplot",
    "bubble",
    "doughnut",
    "line",
    "pie",
    "polarArea",
    "radar",
    "scatter",
    "venn (2)",
    "venn (3)"
];

export const chartConfigHelpText = (
    <>
        <b>Notes for Chart Configuration</b>
        <br />
        <p>
            Must be in strict JSON syntax. E.g.
            <ul>
                <li>* Keys must be strings</li>
                <li>* Strings must be written with double quotes</li>
                <li>* No trailing commas at the end of objects</li>
                <li>
                    * All values must be one of the following: [string, number,
                    object, array boolean, null]
                </li>
            </ul>
        </p>
        <p>
            Because of that, all variables (e.g. v0, v1 must be written as
            strings "v0", "v1").
        </p>
        <p>
            The entire object needs to be inside curly brackets ({"{"}...{"}"})
        </p>
    </>
);

export const getChartDataTemplate = (label) => {
    if (label === "bar") {
        return {
            labels: ["A", "B", "C"],
            datasets: [
                {
                    label: "Series 1",
                    data: [10, 20, 30],
                    backgroundColor: ["rgba(0, 0, 0, 0.1)"],
                    barPercentage: 0.9,
                    borderColor: ["rgba(0, 0, 0, 0.9)"],
                    borderWidth: 1,
                    borderRadius: 0
                }
            ]
        };
    } else if (label === "bar (stacked)") {
        return {
            labels: ["A", "B", "C", "D"],
            datasets: [
                {
                    label: "Series 1",
                    data: [10, 20, 30, 10],
                    backgroundColor: ["rgba(255, 99, 132, 0.3)"],
                    barPercentage: 0.9,
                    borderColor: ["rgba(255, 99, 132, 0.9)"],
                    borderWidth: 1,
                    borderRadius: 0
                },
                {
                    label: "Series 2",
                    data: [5, 10, 15, 10],
                    backgroundColor: ["rgba(75, 192, 192, 0.3)"],
                    barPercentage: 0.9,
                    borderColor: ["rgba(75, 192, 192, 0.9)"],
                    borderWidth: 1,
                    borderRadius: 0
                },
                {
                    label: "Series 3",
                    data: [20, 20, 20, 20],
                    backgroundColor: ["rgba(54, 162, 235, 0.3)"],
                    barPercentage: 0.9,
                    borderColor: ["rgba(54, 162, 235, 0.9)"],
                    borderWidth: 1,
                    borderRadius: 0
                }
            ]
        };
    } else if (label === "line") {
        return {
            labels: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July"
            ],
            datasets: [
                {
                    label: "Series 1",
                    data: [65, 59, 80, 81, 56, 55, 40],
                    borderColor: "rgba(255, 99, 132)",
                    backgroundColor: "rgba(255, 99, 132, 0.5)"
                }
            ]
        };
    } else if (label === "scatter") {
        return {
            labels: [],
            datasets: [
                {
                    label: "Series 1",
                    data: [
                        { x: 1.04, y: 1.079 },
                        { x: -0.06, y: 1.76 },
                        { x: 2.3, y: 2.69 },
                        { x: 4.64, y: 3.54 },
                        { x: 5.33, y: 5.99 },
                        { x: 7.97, y: 6.56 },
                        { x: 9.23, y: 8.89 }
                    ],
                    borderColor: "rgba(255, 99, 132)",
                    backgroundColor: "rgba(255, 99, 132, 0.5)"
                }
            ]
        };
    } else if (label === "doughnut" || label === "pie") {
        return {
            labels: ["A", "B", "C"],
            datasets: [
                {
                    label: "Series 1",
                    data: [10, 20, 30],
                    borderColor: [
                        "rgb(255, 99, 132)",
                        "rgb(255, 205, 86)",
                        "rgb(54, 162, 235)"
                    ],
                    backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(255, 205, 86, 0.2)",
                        "rgba(54, 162, 235, 0.2)"
                    ],
                    borderWidth: 1,
                    cutout: label === "doughnut" ? "50%" : 0,
                    rotation: 0,
                    circumference: 360
                }
            ]
        };
    } else if (label === "bubble") {
        return {
            labels: [],
            datasets: [
                {
                    label: "Series 1",
                    data: [
                        { x: 1.04, y: 1.079, r: 10 },
                        { x: 4.64, y: 7.54, r: 5 },
                        { x: 9.23, y: 11.89, r: 30 },
                        { x: 3.64, y: 2.54, r: 21 }
                    ],
                    borderColor: "rgba(255, 99, 132)",
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                    borderWidth: 2,
                    pointStyle: "circle"
                }
            ]
        };
    } else if (label === "radar") {
        return {
            labels: ["A", "B", "C", "D", "E"],
            datasets: [
                {
                    label: "Series 1",
                    data: [65, 29, 90, 81, 55],
                    fill: true,
                    borderColor: "rgba(255, 99, 132)",
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                    pointBackgroundColor: "rgb(255, 99, 132)",
                    pointBorderColor: "#fff"
                }
            ]
        };
    } else if (label === "polarArea") {
        return {
            labels: ["A", "B", "C"],
            datasets: [
                {
                    label: "Series 1",
                    data: [10, 20, 30],
                    borderColor: [
                        "rgb(255, 99, 132)",
                        "rgb(255, 205, 86)",
                        "rgb(54, 162, 235)"
                    ],
                    backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(255, 205, 86, 0.2)",
                        "rgba(54, 162, 235, 0.2)"
                    ],
                    borderWidth: 1,
                    circular: true
                }
            ]
        };
    } else if (label === "venn (2)") {
        return {
            labels: ["A", "B"],
            datasets: [
                {
                    label: "Series 1",
                    data: [
                        { sets: ["A"], value: 2 },
                        { sets: ["B"], value: 4 },
                        { sets: ["A", "B"], value: 1 }
                    ],
                    borderColor: [
                        "rgba(255, 99, 132)",
                        "rgba(75, 192, 192)",
                        "rgba(54, 162, 235)"
                    ],
                    backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                        "rgba(54, 162, 235, 0.2)"
                    ]
                }
            ]
        };
    } else if (label === "venn (3)") {
        return {
            labels: ["A", "B", "C"],
            datasets: [
                {
                    label: "Series 1",
                    data: [
                        { sets: ["A"], value: 2 },
                        { sets: ["B"], value: 0 },
                        { sets: ["C"], value: 1 },
                        { sets: ["A", "B"], value: 1 },
                        { sets: ["A", "C"], value: 0 },
                        { sets: ["B", "C"], value: 1 },
                        { sets: ["A", "B", "C"], value: 1 }
                    ],
                    borderColor: [
                        "rgba(255, 99, 132)",
                        "rgba(75, 192, 192)",
                        "rgba(54, 162, 235)",
                        "rgb(201, 203, 207)",
                        "rgb(255, 205, 86)",
                        "rgb(153, 102, 255)",
                        "rgb(100, 100, 100)"
                    ],
                    backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                        "rgba(54, 162, 235, 0.2)",
                        "rgb(201, 203, 207, 0.2)",
                        "rgb(255, 205, 86, 0.2)",
                        "rgb(153, 102, 255, 0.2)",
                        "rgb(100, 100, 100, 0.2)"
                    ]
                }
            ]
        };
    } else if (label === "boxplot") {
        return {
            labels: [""],
            datasets: [
                {
                    label: "Series 1",
                    data: [
                        {
                            min: 5,
                            q1: 12,
                            median: 14,
                            mean: 15,
                            q3: 17,
                            max: 22,
                            outliers: [1, 2, 24]
                        }
                    ],
                    borderColor: "rgba(255, 99, 132)",
                    backgroundColor: "rgba(255, 99, 132, 0.5)"
                }
            ]
        };
    }
    return {
        labels: ["A", "B", "C"],
        datasets: [
            {
                label: "Series 1",
                data: [10, 20, 30],
                borderColor: "rgba(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)"
            }
        ]
    };
};

export const getChartOptionsTemplate = (label) => {
    const DEFAULT_FONT_SIZE = 16;

    const defaults = {
        line: {
            scales: {
                x: {
                    type: "category",
                    title: {
                        display: false,
                        text: ""
                    },
                    ticks: {
                        font: {
                            size: DEFAULT_FONT_SIZE
                        }
                    }
                },
                y: {
                    type: "linear",
                    title: {
                        display: false,
                        text: ""
                    },
                    ticks: {
                        font: {
                            size: DEFAULT_FONT_SIZE
                        }
                    }
                }
            }
        },
        bar: {
            scales: {
                x: {
                    type: "category",
                    offset: true,
                    grid: {
                        offset: true
                    },
                    title: {
                        display: false,
                        text: ""
                    },
                    ticks: {
                        font: {
                            size: DEFAULT_FONT_SIZE
                        }
                    }
                },
                y: {
                    type: "linear",
                    beginAtZero: true,
                    title: {
                        display: false,
                        text: ""
                    },
                    ticks: {
                        font: {
                            size: DEFAULT_FONT_SIZE
                        }
                    }
                }
            }
        },
        "bar (stacked)": {
            scales: {
                x: {
                    type: "category",
                    offset: true,
                    grid: {
                        offset: true
                    },
                    title: {
                        display: false,
                        text: ""
                    },
                    ticks: {
                        font: {
                            size: DEFAULT_FONT_SIZE
                        }
                    },
                    stacked: true
                },
                y: {
                    type: "linear",
                    beginAtZero: true,
                    title: {
                        display: false,
                        text: ""
                    },
                    ticks: {
                        font: {
                            size: DEFAULT_FONT_SIZE
                        }
                    },
                    stacked: true
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: "top"
                }
            }
        },
        radar: {
            aspectRatio: 1,
            scales: {
                r: {
                    type: "radialLinear",
                    title: {
                        display: false,
                        text: ""
                    },
                    ticks: {
                        font: {
                            size: DEFAULT_FONT_SIZE
                        }
                    }
                }
            }
        },
        doughnut: {
            aspectRatio: 1,
            plugins: {
                legend: {
                    labels: {
                        font: {
                            size: DEFAULT_FONT_SIZE
                        }
                    }
                }
            }
        },
        "venn (2)": {
            aspectRatio: 1,
            plugins: {
                legend: {
                    display: false
                }
            },
            layout: {
                padding: {
                    top: 10,
                    bottom: 10,
                    right: 0,
                    left: 0
                }
            },
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: DEFAULT_FONT_SIZE
                        }
                    }
                },
                y: {
                    ticks: {
                        font: {
                            size: DEFAULT_FONT_SIZE
                        }
                    }
                }
            }
        },
        "venn (3)": {
            aspectRatio: 1,
            plugins: {
                legend: {
                    display: false
                }
            },
            layout: {
                padding: {
                    top: 10,
                    bottom: 10,
                    right: 0,
                    left: 0
                }
            },
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: DEFAULT_FONT_SIZE
                        }
                    }
                },
                y: {
                    ticks: {
                        font: {
                            size: DEFAULT_FONT_SIZE
                        }
                    }
                }
            }
        },
        polarArea: {
            aspectRatio: 1,
            plugins: {
                legend: {
                    labels: {}
                }
            },
            scales: {
                r: {
                    type: "radialLinear",
                    angleLines: {
                        display: false
                    },
                    beginAtZero: true,
                    grid: {
                        circular: true
                    },
                    pointLabels: {
                        display: false
                    },
                    startAngle: 0,
                    title: {
                        display: false,
                        text: ""
                    },
                    ticks: {
                        font: {
                            size: DEFAULT_FONT_SIZE
                        }
                    }
                }
            }
        },
        bubble: {
            scales: {
                x: {
                    type: "linear",
                    title: {
                        display: false,
                        text: ""
                    },
                    ticks: {
                        font: {
                            size: DEFAULT_FONT_SIZE
                        }
                    }
                },
                y: {
                    type: "linear",
                    title: {
                        display: false,
                        text: ""
                    },
                    ticks: {
                        font: {
                            size: DEFAULT_FONT_SIZE
                        }
                    }
                }
            }
        },
        pie: {
            aspectRatio: 1,
            plugins: {
                legend: {
                    labels: {
                        font: {
                            size: DEFAULT_FONT_SIZE
                        }
                    }
                }
            }
        },
        scatter: {
            interaction: {
                mode: "point"
            },
            scales: {
                x: {
                    type: "linear",
                    beginAtZero: true,
                    title: {
                        display: false,
                        text: ""
                    },
                    ticks: {
                        font: {
                            size: DEFAULT_FONT_SIZE
                        }
                    }
                },
                y: {
                    type: "linear",
                    beginAtZero: true,
                    title: {
                        display: false,
                        text: ""
                    },
                    ticks: {
                        font: {
                            size: DEFAULT_FONT_SIZE
                        }
                    }
                }
            }
        },
        boxplot: {
            indexAxis: "y",
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                    position: "top"
                }
            },
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: DEFAULT_FONT_SIZE
                        }
                    }
                }
            }
        }
    };

    return {
        responsive: true,
        ...(defaults?.[label] || {}),
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            },
            title: {
                text: "",
                display: false
            },
            layout: {
                padding: {
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0
                }
            },
            ...(defaults?.[label]?.plugins || {})
        }
    };
};

export const formFieldToChartData = (form) => {
    if (!form) {
        return undefined;
    }
    try {
        return {
            type: form.getFieldValue("chartType"),
            data: JSON.parse(form.getFieldValue("chartDataJSON") || "null"),
            options: JSON.parse(
                form.getFieldValue("chartOptionsJSON") || "null"
            )
        };
    } catch (e) {
        return undefined;
    }
};
