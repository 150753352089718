import React, { useEffect, useState } from "react";
import { apiDelete, apiGet, apiPost } from "helpers/api";
import { Button, Form, Input, message, Popconfirm, Spin, Table } from "antd";
import { useGetUserQuery } from "features/account/accountSlice";
import { withCookies } from "react-cookie";

function SubUsersForm({ inModal = false, cookies }) {
    const [staleData, toggleStaleData] = useState(false);
    const [subUsers, setSubUsers] = useState(null);
    const { data: user } = useGetUserQuery();
    const [csrfToken] = useState(cookies.get("XSRF-TOKEN"));
    const MAX_SUB_USERS = Number.isInteger(user?.["custom:maxSubUsers"])
        ? user["custom:maxSubUsers"]
        : 30;
    const [form] = Form.useForm();
    const [loadingData, setLoadingData] = useState(false);
    const [loadingPost, setLoadingPost] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);

    const domain = user.email.split("@")[1];

    useEffect(() => {
        setLoadingData(true);
        apiGet(
            "/api/get-sub-users",
            (body) => {
                setSubUsers(
                    body.map((u) => ({
                        key: u.email,
                        ...u
                    }))
                );
                setLoadingData(false);
            },
            (err) => {
                setLoadingData(false);
                message.error(err.message);
            }
        );
    }, [staleData]);

    function createUser(e) {
        setLoadingPost(true);
        apiPost(
            "/api/create-sub-user",
            JSON.stringify({
                email: e.emailPrefix + "@" + domain,
                firstName: e.firstName,
                lastName: e.lastName
            }),
            csrfToken,
            () => {
                message.success("Account created");
                form.resetFields();
                setLoadingPost(false);
                toggleStaleData((s) => !s);
            },
            (err) => {
                setLoadingPost(false);
                message.error(err.message);
            }
        );
    }

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email"
        },
        {
            title: "Delete",
            key: "delete",
            render: (text, record) => (
                <Popconfirm
                    title={
                        <>
                            <b>Delete Sub User: </b>Are you sure?
                        </>
                    }
                    onConfirm={() => {
                        setLoadingDelete(record.email);
                        apiDelete(
                            "/api/delete-sub-user?email=" +
                                encodeURIComponent(record.email),
                            csrfToken,
                            () => {
                                message.success("User deleted");
                                setLoadingDelete(false);
                                toggleStaleData((s) => !s);
                            },
                            (err) => {
                                setLoadingDelete(false);
                                message.error(err.message);
                            }
                        );
                    }}
                    onCancel={() => {}}
                    okText="Delete"
                    cancelText="Cancel"
                >
                    {loadingDelete === record.email ? (
                        <Spin />
                    ) : (
                        <Button type="danger">DELETE</Button>
                    )}
                </Popconfirm>
            )
        }
    ];

    return (
        <>
            <Form
                form={form}
                labelCol={
                    inModal
                        ? {
                              xs: {
                                  span: 24
                              },
                              sm: {
                                  span: 8
                              },
                              md: {
                                  span: 8
                              },
                              lg: {
                                  span: 7
                              }
                          }
                        : {
                              span: 5
                          }
                }
                requiredMark={false}
                className={"subUserForm"}
                onFinish={createUser}
            >
                <Form.Item
                    label={<b>Email</b>}
                    className={"emailInput"}
                    name="emailPrefix"
                    extra={
                        <>
                            {`To create an account with a different email domain (i.e. not @${domain}) please contact `}
                            <a href={"mailto:chloe@mathsplanner.com"}>
                                chloe@mathsplanner.com
                            </a>
                        </>
                    }
                    rules={[
                        { required: true, message: "Please enter an email" },
                        {
                            pattern: new RegExp(
                                "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+$"
                            ),
                            message:
                                "Please enter a valid email prefix e.g. 'username@example.com' => 'username'"
                        }
                    ]}
                >
                    <Input addonAfter={`@${domain}`} />
                </Form.Item>
                <Form.Item
                    label={<b>First Name</b>}
                    className={"emailInput"}
                    name="firstName"
                    rules={[
                        { required: true, message: "Please enter a first name" }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={<b>Last Name</b>}
                    className={"emailInput"}
                    name="lastName"
                    rules={[
                        { required: true, message: "Please enter a last name" }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    className={"formButtons"}
                    extra={
                        subUsers?.length < MAX_SUB_USERS ? (
                            <>
                                Users will receive an email containing a
                                temporary password and will be asked to change
                                it on first login. Please note: the temporary
                                password will expire after 7 days -- if you need
                                to reactivate an expired password, please
                                contact MathsPlanner Support.
                            </>
                        ) : (
                            <>
                                If you need to upgrade your subscription to
                                allow for more accounts, please contact{" "}
                                <a href={"mailto:chloe@mathsplanner.com"}>
                                    chloe@mathsplanner.com
                                </a>
                            </>
                        )
                    }
                >
                    {loadingPost ? (
                        <Spin />
                    ) : (
                        <>
                            {subUsers?.length < MAX_SUB_USERS ? (
                                <Button type="primary" htmlType="submit">
                                    CREATE ACCOUNT
                                </Button>
                            ) : (
                                <Button type="primary" disabled>
                                    {`Maximum ${MAX_SUB_USERS} department member accounts`}
                                </Button>
                            )}
                        </>
                    )}
                </Form.Item>
            </Form>
            <h3>Current Department Members</h3>
            <Table
                loading={loadingData}
                columns={columns}
                dataSource={subUsers}
                pagination={{ hideOnSinglePage: true }}
            />
        </>
    );
}

export default withCookies(SubUsersForm);
