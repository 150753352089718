import { Modal, Spin } from "antd";
import LessonTypeOption from "features/lesson/LessonTypeOption";
import printIconColour from "assets/icons/PrintIconColour.svg";
import interactiveIconColour from "assets/icons/InteractiveIconColour.svg";
import closeGapIconColour from "assets/icons/CloseGapIconColour.svg";
import toolsIconColour from "assets/icons/GCSEStarterIconColour.svg";
import { useHistory } from "react-router-dom";
import React, { useMemo, useState } from "react";
import MockPaperCloseTheGapModalContents from "./MockPaperCloseTheGapModalContents";
import MockPaperDownloadModalContents from "./MockPaperDownloadModalContents";
import { MOCK_PAPERS_PATH } from "app/App";
import { useGetPaperQuery } from "features/mockPapers/mockPapersSlice";
import { createSelector } from "@reduxjs/toolkit";
import { isUserAdmin } from "helpers/security";
import { useGetUserQuery } from "features/account/accountSlice";

const ModalContentsKey = {
    Options: 0,
    Downloads: 1,
    CloseGap: 2
};

function MockPaperOptionsModalContents({
    id,
    code,
    open,
    onClose,
    setModalContentsKey,
    boosterNumber = null
}) {
    const history = useHistory();
    const { data: user } = useGetUserQuery(undefined, {
        skip: !open
    });
    const isAdmin = isUserAdmin(user);

    const hasQuestionsDataSelector = useMemo(
        () =>
            createSelector(
                (res) => res.data,
                (paperData) => paperData?.questions?.some((q) => q?.question)
            ),
        []
    );
    const { hasQuestionsData, isFetching: loading } = useGetPaperQuery(id, {
        skip: !open,
        selectFromResult: (result) => ({
            hasQuestionsData: hasQuestionsDataSelector(result)
        })
    });

    if (!open) {
        return null;
    }

    if (loading) {
        return (
            <div className={"lesson_type_modal__wrapper"}>
                <h1>
                    {boosterNumber ? `Booster Sheet ${boosterNumber}` : code}
                </h1>
                <Spin />
            </div>
        );
    }

    return (
        <>
            <div className={"lesson_type_modal__wrapper"}>
                <h1>
                    {boosterNumber ? `Booster Sheet ${boosterNumber}` : code}
                </h1>
                <LessonTypeOption
                    name={"Download and Print"}
                    description={
                        boosterNumber
                            ? "Question and Answer sheets."
                            : "Papers, mark schemes and written solutions."
                    }
                    colour={"green"}
                    icon={printIconColour}
                    onClick={() =>
                        setModalContentsKey(ModalContentsKey.Downloads)
                    }
                />
                {isAdmin && (
                    <LessonTypeOption
                        name={"Exam Timer and Hints"}
                        description={
                            boosterNumber
                                ? "Display a timer for this activity."
                                : "Mock examination timer and optional question hints."
                        }
                        colour={"purple"}
                        icon={toolsIconColour}
                        onClick={() => {
                            history.push(
                                `${MOCK_PAPERS_PATH}/${encodeURIComponent(
                                    id
                                )}?mode=tools`
                            );
                            onClose();
                        }}
                    />
                )}
                {hasQuestionsData && (
                    <>
                        <LessonTypeOption
                            name={"Display to Class"}
                            description={`Interactively walk through each question and its ${
                                boosterNumber ? "answers" : "solutions"
                            }.`}
                            colour={"pink"}
                            icon={interactiveIconColour}
                            onClick={() => {
                                history.push(
                                    `${MOCK_PAPERS_PATH}/${encodeURIComponent(
                                        id
                                    )}?mode=interactive`
                                );
                                onClose();
                            }}
                        />
                        <LessonTypeOption
                            name={"Closing the Gap Starter"}
                            description={
                                "Simply select the desired questions from the paper."
                            }
                            colour={"blue"}
                            icon={closeGapIconColour}
                            onClick={() =>
                                setModalContentsKey(ModalContentsKey.CloseGap)
                            }
                        />
                    </>
                )}
            </div>
        </>
    );
}

function MockPaperOptionsModal({
    fullscreen,
    makeFullscreen,
    id,
    code,
    open,
    onClose,
    boosterNumber = null
}) {
    const [modalContentsKey, setModalContentsKey] = useState(
        ModalContentsKey.Options
    );

    return (
        <>
            <Modal
                className={"mock_papers_modal lesson_type_modal"}
                width={"min(55rem, 95%)"}
                footer={null}
                closable={true}
                maskClosable={true}
                centered
                open={open}
                onCancel={() => {
                    if (modalContentsKey === ModalContentsKey.Options) {
                        onClose();
                    } else {
                        setModalContentsKey(ModalContentsKey.Options);
                    }
                }}
            >
                <MockPaperOptionsModalContents
                    id={id}
                    code={code}
                    open={modalContentsKey === ModalContentsKey.Options}
                    onClose={onClose}
                    setModalContentsKey={setModalContentsKey}
                    boosterNumber={boosterNumber}
                />
                <MockPaperCloseTheGapModalContents
                    fullscreen={fullscreen}
                    makeFullscreen={makeFullscreen}
                    paperId={id}
                    code={code}
                    open={modalContentsKey === ModalContentsKey.CloseGap}
                />
                <MockPaperDownloadModalContents
                    paperId={id}
                    open={modalContentsKey === ModalContentsKey.Downloads}
                />
            </Modal>
        </>
    );
}

export default MockPaperOptionsModal;
