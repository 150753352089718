import { Chart as ChartJS, registerables } from "chart.js";
import { ArcSlice, VennDiagramController } from "chartjs-chart-venn";
import {
    BoxAndWiskers,
    BoxPlotController
} from "@sgratzl/chartjs-chart-boxplot";
import Annotations from "chartjs-plugin-annotation";

export const registerChartJSElements = () => {
    ChartJS.register(
        ...registerables,
        VennDiagramController,
        ArcSlice,
        BoxPlotController,
        BoxAndWiskers,
        Annotations
    );
    ChartJS.defaults.plugins.tooltip.enabled = false;
};

export const overrideFontSize = (type, options, fontSizePx) => {
    const fontProperties = {
        line: {
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: fontSizePx
                        }
                    }
                },
                y: {
                    ticks: {
                        font: {
                            size: fontSizePx
                        }
                    }
                }
            }
        },
        bar: {
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: fontSizePx
                        }
                    }
                },
                y: {
                    ticks: {
                        font: {
                            size: fontSizePx
                        }
                    }
                }
            }
        },
        radar: {
            scales: {
                r: {
                    ticks: {
                        font: {
                            size: fontSizePx
                        }
                    }
                }
            }
        },
        doughnut: {
            plugins: {
                legend: {
                    labels: {
                        font: {
                            size: fontSizePx
                        }
                    }
                }
            }
        },
        venn: {
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: fontSizePx
                        }
                    }
                },
                y: {
                    ticks: {
                        font: {
                            size: fontSizePx
                        }
                    }
                }
            }
        },
        polarArea: {
            scales: {
                r: {
                    ticks: {
                        font: {
                            size: fontSizePx
                        }
                    }
                }
            }
        },
        bubble: {
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: fontSizePx
                        }
                    }
                },
                y: {
                    ticks: {
                        font: {
                            size: fontSizePx
                        }
                    }
                }
            }
        },
        pie: {
            plugins: {
                legend: {
                    labels: {
                        font: {
                            size: fontSizePx
                        }
                    }
                }
            }
        },
        scatter: {
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: fontSizePx
                        }
                    }
                },
                y: {
                    ticks: {
                        font: {
                            size: fontSizePx
                        }
                    }
                }
            }
        },
        boxplot: {
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: fontSizePx
                        }
                    }
                }
            }
        }
    };

    return { ...options, ...(fontProperties?.[type] ?? {}) };
};
