import React, { useContext, useMemo, useState } from "react";
import {
    Badge,
    Button,
    Form,
    Input,
    notification,
    Popconfirm,
    Popover,
    Tooltip
} from "antd";
import {
    CheckCircleOutlined,
    ControlOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    LinkOutlined,
    TagOutlined
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { getStepIndex, TourContext } from "features/tour/Tour";
import { useGetClassesQuery } from "features/classTags/classesSlice";
import {
    useClearStarterClassTagMutation,
    useDeleteStarterMutation,
    useDuplicateStarterMutation,
    useRenameStarterMutation,
    useSetStarterClassTagMutation
} from "features/timetable/savesSlice";
import { createSelector } from "@reduxjs/toolkit";
import ClassesListModal from "features/classTags/ClassesListModal";
import RoundedButton from "commonComponents/RoundedButton";
import { tooltipColour } from "helpers/starterHelpers";
import { EditableClassTag } from "features/classTags/ClassTag";
import { useSelector } from "react-redux";

function isDefaultSave(saveData) {
    const defaults = [
        {
            name: "Lesson 1: Custom Starter",
            timetableColumn: "monday",
            timetablePeriod: "0"
        },
        {
            name: "Lesson 2: GCSE Starter 3-5",
            timetablePeriod: "2",
            timetableColumn: "tuesday"
        },
        {
            name: "Lesson 3: Subtopic Starter - Ratio Basics",
            timetablePeriod: "3",
            timetableColumn: "wednesday"
        },
        {
            name: "Lesson 4: GCSE Starter 7-9",
            timetablePeriod: "1",
            timetableColumn: "thursday"
        },
        {
            name: "Lesson 5: Exit Ticket",
            timetablePeriod: "2",
            timetableColumn: "friday"
        }
    ];
    return defaults.some(
        (def) =>
            saveData.name.includes(def.name) &&
            def.timetableColumn === saveData.timetableColumn &&
            def.timetablePeriod === saveData.timetablePeriod
    );
}

export function RenameForm({
    save,
    nameEditState,
    setNameEditState,
    onSubmit = () => {},
    onSuccess = () => {}
}) {
    const [renameStarter] = useRenameStarterMutation();

    const handleSubmitClick = () => {
        if (nameEditState !== save.name) {
            renameStarter({ id: save.id, newName: nameEditState })
                .unwrap()
                .then(onSuccess);
        }
        onSubmit();
    };

    return (
        <Form
            layout={"inline"}
            onClick={(e) => e.stopPropagation()}
            data-no-dnd={"true"}
        >
            <Form.Item>
                <Input
                    value={nameEditState}
                    onChange={(e) => setNameEditState(e.target.value)}
                    onPressEnter={handleSubmitClick}
                />
            </Form.Item>

            <Form.Item>
                <Button
                    type={"primary"}
                    icon={<CheckCircleOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleSubmitClick();
                    }}
                >
                    Confirm
                </Button>
            </Form.Item>
        </Form>
    );
}

function SaveItem({
    save,
    i,
    hasFunctions = true,
    interactive = true,
    onLoadStarter = () => {},
    onDuplicate = () => {},
    draggableRef = undefined,
    draggableStyle = {},
    isDragging = false,
    onRename = () => {},
    newBadgeOffset = 0,
    ...otherProps
}) {
    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const [editingName, setEditingName] = useState(false);
    const [nameEditState, setNameEditState] = useState(save.name);
    const [showClassTags, setShowClassTags] = useState(false);
    const [showClassesModal, setShowClassesModal] = useState(false);

    const selectClassForId = useMemo(() => {
        return createSelector(
            (res) => res.data,
            (res, classId) => classId,
            (classes, classId) =>
                classId &&
                classId !== "null" &&
                classes?.find((c) => c.id === classId)
        );
    }, []);
    const { class: classData } = useGetClassesQuery(undefined, {
        selectFromResult: (result) => ({
            class: selectClassForId(result, save?.classId)
        })
    });
    const inNewSaveView = useSelector(
        (state) => state.dashboard.showNewSaveView
    );

    const [duplicateSave] = useDuplicateStarterMutation();
    const [deleteSave] = useDeleteStarterMutation();
    const [setClassTag] = useSetStarterClassTagMutation();
    const [clearClassTag] = useClearStarterClassTagMutation();

    const expandButtons = true;
    const tourContext = useContext(TourContext);

    const history = useHistory();

    const isDefault = isDefaultSave(save);

    const moreOptions = useMemo(
        () => (
            <div className="saveitemoptions" data-no-dnd={"true"}>
                <span onClick={(e) => e.stopPropagation()}>
                    <Popover
                        content={
                            <RenameForm
                                save={save}
                                nameEditState={nameEditState}
                                setNameEditState={setNameEditState}
                                onSubmit={() => {
                                    setEditingName(false);
                                    setShowMoreOptions(false);
                                    onRename();
                                }}
                            />
                        }
                        trigger={interactive ? "click" : "none"}
                        open={editingName}
                        onOpenChange={(visible) => setEditingName(visible)}
                    >
                        <Button
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                setEditingName(true);
                                setNameEditState(save.name);
                            }}
                        >
                            <EditOutlined />
                            Rename
                        </Button>
                    </Popover>
                </span>
                <Button
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowClassTags(true);
                        setShowMoreOptions(false);
                    }}
                >
                    <TagOutlined />
                    Change Tag
                </Button>
                <Button
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        // Create dummy input containing URL and select it
                        const dummy = document.createElement("input");
                        const text =
                            window.location.origin +
                            "/starter?id=" +
                            encodeURIComponent(save.id);
                        document.body.appendChild(dummy);
                        dummy.value = text;
                        dummy.select();
                        dummy.setSelectionRange(0, 99999);
                        document.execCommand("copy");
                        document.body.removeChild(dummy);

                        notification.open({
                            message: "Success",
                            duration: 1.5,
                            description: "Link copied to clipboard."
                        });
                        setShowMoreOptions(false);
                    }}
                >
                    <LinkOutlined />
                    Copy Link
                </Button>
                {!expandButtons && (
                    <>
                        <Button
                            size="small"
                            onClick={() =>
                                duplicateSave(save.id)
                                    .unwrap()
                                    .then(() => {
                                        onDuplicate();
                                        setShowMoreOptions(false);
                                    })
                            }
                        >
                            <CopyOutlined />
                            Duplicate
                        </Button>
                        <Popconfirm
                            title={
                                <>
                                    <b>Delete Save: </b>Are you sure?
                                </>
                            }
                            onConfirm={(e) => {
                                if (!interactive) {
                                    return;
                                }
                                e.stopPropagation();
                                deleteSave(save.id)
                                    .unwrap()
                                    .then(() => setShowMoreOptions(false));
                            }}
                            trigger={interactive ? "click" : "none"}
                            onClick={(e) => e.stopPropagation()}
                            okText="Delete"
                            cancelText="Cancel"
                        >
                            <Button
                                size="small"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <DeleteOutlined />
                                Delete
                            </Button>
                        </Popconfirm>
                    </>
                )}
            </div>
        ),
        [
            deleteSave,
            duplicateSave,
            editingName,
            expandButtons,
            interactive,
            nameEditState,
            onDuplicate,
            onRename,
            save
        ]
    );

    const classColour = classData?.colour;

    const getItemStyle = (isDragging, draggableStyle) =>
        isDragging
            ? {
                  background: "transparent",
                  borderRadius: "8px",
                  cursor: "grab !important",
                  boxShadow: "7px 8px 10px -4px rgba(0, 0, 0, 0.2)",
                  ...draggableStyle
              }
            : { ...draggableStyle };

    const classTagComponent = useMemo(() => {
        return (
            <EditableClassTag
                classId={save?.classId}
                interactive={interactive}
                popoverPlacement={"right"}
                showList={showClassTags}
                setShowList={setShowClassTags}
                onClassSelect={(e, c) => {
                    if (hasFunctions) {
                        e.stopPropagation();
                        setClassTag({
                            id: save.id,
                            newClassId: c.id
                        })
                            .unwrap()
                            .then(() => setShowClassTags(false));
                    }
                }}
                extraContent={
                    <>
                        {showClassesModal && (
                            <>
                                {/*
                                 * Conditional rendering breaks Modal closing animation but improves render
                                 * performance as it prevents the need to render a modal for each save item
                                 */}
                                <ClassesListModal
                                    isOpen={showClassesModal}
                                    setIsOpen={setShowClassesModal}
                                />
                            </>
                        )}
                        <RoundedButton
                            type={"primary"}
                            onClick={() => {
                                setShowClassTags(false);
                                setShowClassesModal(true);
                            }}
                        >
                            + New Class
                        </RoundedButton>
                    </>
                }
                style={{ fontSize: "1rem", fontWeight: "600" }}
                className={"saveItemTag"}
                closable={hasFunctions && interactive}
                onClose={(e) => {
                    e.preventDefault();
                    clearClassTag(save.id);
                }}
            />
        );
    }, [
        clearClassTag,
        hasFunctions,
        interactive,
        save?.classId,
        save.id,
        setClassTag,
        showClassTags,
        showClassesModal
    ]);

    const saveItemButtons = useMemo(() => {
        const deleteButton = (
            <span onClick={(e) => e.stopPropagation()}>
                <Popconfirm
                    title={
                        <>
                            <b>Delete Save: </b>Are you sure?
                        </>
                    }
                    onConfirm={(e) => {
                        if (!interactive) {
                            return;
                        }
                        e.stopPropagation();
                        deleteSave(save.id);
                    }}
                    trigger={interactive ? "click" : "none"}
                    onCancel={() => {}}
                    okText="Delete"
                    cancelText="Cancel"
                >
                    <Tooltip
                        placement="bottom"
                        title={interactive ? "Delete Save" : undefined}
                        color={tooltipColour}
                    >
                        <button
                            type="link"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <DeleteOutlined />
                        </button>
                    </Tooltip>
                </Popconfirm>
            </span>
        );
        return (
            <div
                className="save-item-btns"
                data-tour={"saveOptions"}
                data-no-dnd={"true"}
            >
                {hasFunctions ? (
                    <>
                        <span onClick={(e) => e.stopPropagation()}>
                            <Tooltip
                                placement="bottom"
                                title={interactive ? "More Options" : undefined}
                                color={tooltipColour}
                            >
                                <Popover
                                    content={moreOptions}
                                    overlayClassName={"moreOptionsPopover"}
                                    trigger={interactive ? "click" : "none"}
                                    open={showMoreOptions}
                                    onOpenChange={(visible) =>
                                        setShowMoreOptions(visible)
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <button type="link">
                                        <ControlOutlined />
                                    </button>
                                </Popover>
                            </Tooltip>
                        </span>
                        {expandButtons && (
                            <>
                                <Tooltip
                                    placement="bottom"
                                    title={
                                        interactive
                                            ? "Duplicate Save"
                                            : undefined
                                    }
                                    color={tooltipColour}
                                >
                                    <button
                                        type="link"
                                        onClick={(e) => {
                                            if (!interactive) {
                                                return;
                                            }
                                            e.stopPropagation();
                                            duplicateSave(save.id)
                                                .unwrap()
                                                .then(() => {
                                                    onDuplicate();
                                                });
                                        }}
                                    >
                                        <CopyOutlined />
                                    </button>
                                </Tooltip>
                                {deleteButton}
                            </>
                        )}
                    </>
                ) : (
                    deleteButton
                )}
            </div>
        );
    }, [
        deleteSave,
        duplicateSave,
        expandButtons,
        hasFunctions,
        interactive,
        moreOptions,
        onDuplicate,
        save.id,
        showMoreOptions
    ]);

    const badgeOffset = [-10 + newBadgeOffset, 0];

    return (
        <div
            ref={draggableRef}
            className={
                "draggable-save-wrapper " +
                (isDragging ? "dragging " : "") +
                (inNewSaveView ? "blur " : "")
            }
            style={getItemStyle(isDragging, draggableStyle)}
            {...otherProps}
        >
            <Badge
                color={"#0058de"}
                offset={badgeOffset}
                count={save?.new ? "New" : 0 /* 0 hides badge */}
            >
                <div
                    className={
                        "draggable-save " +
                        classColour +
                        (!interactive ? " non_interactive " : "") +
                        (isDefault ? " default " : "")
                    }
                    onClick={() => {
                        if (!interactive) {
                            return;
                        }
                        if (!tourContext.isOpen) {
                            onLoadStarter();
                            history.push(
                                "/starter?id=" + encodeURIComponent(save.id)
                            );
                        } else if (
                            tourContext.currentStep ===
                            getStepIndex("openDashboardStarter")
                        ) {
                            tourContext.nextStep();
                        }
                    }}
                >
                    <div className="save-item-name">{save.name}</div>
                    <span
                        onClick={(e) => hasFunctions && e.stopPropagation()}
                        data-no-dnd={"true"}
                        className={"save-item-fn-row"}
                    >
                        {classTagComponent}
                        {saveItemButtons}
                    </span>
                </div>
            </Badge>
        </div>
    );
}

export default SaveItem;
