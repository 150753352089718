import * as React from "react";

const SvgIconUndo = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m16.75 5.146.501-.865-.502.865Zm3.11 11.674.867.498-.867-.498ZM8.25 19.948l.018 1a1 1 0 0 0 .983-1.017l-1 .017ZM5.14 8.274l-.867-.498.867.498Zm12.448 9.818a1 1 0 0 0 1.362 1.465l-1.361-1.465Zm-8.39-1.275a1 1 0 0 0-2 .033l2-.033ZM5.139 19a1 1 0 0 0 .033 2l-.034-2Zm11.11-12.989c3.586 2.082 4.819 6.699 2.745 10.31l1.734.997c2.62-4.563 1.067-10.4-3.476-13.037l-1.004 1.73ZM8.752 19.083C5.166 17 3.933 12.384 6.007 8.773l-1.734-.997c-2.62 4.563-1.067 10.399 3.476 13.036l1.004-1.73ZM6.007 8.773c2.072-3.61 6.657-4.842 10.24-2.762l1.004-1.73C12.705 1.642 6.894 3.211 4.273 7.776l1.734.996Zm12.986 7.549a7.535 7.535 0 0 1-1.404 1.77l1.361 1.465a9.534 9.534 0 0 0 1.777-2.24l-1.734-.995Zm-11.794.528.052 3.114 2-.033-.052-3.114-2 .033Zm1.035 2.098L5.137 19l.034 2 3.097-.052-.034-2Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgIconUndo;
