import React, { useEffect, useState } from "react";
import { Button, message, Popconfirm, Table } from "antd";
import { apiDelete, apiGet, apiPut } from "helpers/api";
import { useCookies } from "react-cookie";

function CreateAccountDraftsList({ pathName }) {
    const [{ "XSRF-TOKEN": csrfToken }] = useCookies(["XSRF-TOKEN"]);

    const [drafts, setDrafts] = useState([]);
    const [refetch, setRefetch] = useState(false);
    useEffect(() => {
        apiGet("/api/draft-users", setDrafts);
    }, [refetch]);

    const columns = [
        {
            title: "Name",
            dataIndex: "forename",
            render: (forename, { surname }) => `${forename} ${surname}`
        },
        {
            title: "Email",
            dataIndex: "email"
        },
        {
            title: "Edit Draft",
            dataIndex: "email",
            render: (email) => (
                <Button
                    type={"primary"}
                    className={"warning-btn"}
                    href={`${pathName}/edit?email=${encodeURIComponent(email)}`}
                >
                    Edit
                </Button>
            )
        },
        {
            title: "Create Account",
            dataIndex: "email",
            render: (email) => (
                <Button
                    type={"primary"}
                    onClick={() =>
                        apiPut(
                            `/api/activate-draft-user?email=${encodeURIComponent(
                                email
                            )}`,
                            {},
                            csrfToken,
                            () => {
                                setRefetch((prev) => !prev);
                                apiPut(
                                    "/api/invalidate_users_cache",
                                    {},
                                    csrfToken,
                                    () => {}
                                );
                            }
                        )
                    }
                >
                    Send Email >>
                </Button>
            )
        },
        {
            title: "Delete Draft",
            dataIndex: "email",
            render: (email) => (
                <Popconfirm
                    title={
                        <>
                            <b>Delete This Draft User: </b>
                            <br />
                            Are you sure?
                        </>
                    }
                    onConfirm={() =>
                        apiDelete(
                            `/api/draft-user?email=${encodeURIComponent(
                                email
                            )}`,
                            csrfToken,
                            () => setRefetch((prev) => !prev)
                        )
                    }
                    onCancel={() => {}}
                    okText="Delete"
                    cancelText="Cancel"
                >
                    <Button type={"primary"} danger={true}>
                        Delete
                    </Button>
                </Popconfirm>
            )
        }
    ];

    return (
        <>
            <h1>Pending Account Creation Drafts</h1>
            <div>
                <Popconfirm
                    title={
                        <>
                            <b>
                                This will create all {drafts?.length} accounts:{" "}
                            </b>
                            <br />
                            Are you sure?
                        </>
                    }
                    onConfirm={() =>
                        apiPut(
                            `/api/bulk-activate-draft-users`,
                            JSON.stringify(
                                drafts
                                    .map((user) => user?.email)
                                    .filter((d) => d)
                            ),
                            csrfToken,
                            (body) => {
                                if (body?.errors?.length) {
                                    if (body?.successful?.length) {
                                        message.warning(
                                            body.errors.length +
                                                " user(s) were unable to be activated: " +
                                                JSON.stringify(body.errors)
                                        );
                                    } else {
                                        message.error(
                                            "An error occurred on all draft users: " +
                                                JSON.stringify(body.errors)
                                        );
                                    }
                                } else {
                                    message.success("Success");
                                }
                                setRefetch((prev) => !prev);
                                apiPut(
                                    "/api/invalidate_users_cache",
                                    {},
                                    csrfToken,
                                    () => {}
                                );
                            }
                        )
                    }
                    onCancel={() => {}}
                    okText="Confirm"
                    cancelText="Cancel"
                    disabled={!drafts?.length}
                >
                    <Button type={"primary"}>
                        Bulk-Create All Accounts >>
                    </Button>
                </Popconfirm>
            </div>
            <Table
                className={"adminTable"}
                dataSource={drafts}
                columns={columns}
                pagination={false}
            />
        </>
    );
}

export default CreateAccountDraftsList;
