import React, { useEffect, useState } from "react";
import { apiDelete, apiGet } from "helpers/api";
import { Button, Popconfirm, Table } from "antd";
import { withCookies } from "react-cookie";

function ManageMockPapersForm({ urlStub, cookies }) {
    const [mockPapers, setMockPapers] = useState([]);
    const [staleData, toggleStaleData] = useState(false);
    const [csrfToken] = useState(cookies.get("XSRF-TOKEN"));

    useEffect(() => apiGet("/api/mock-papers", setMockPapers), [staleData]);

    const columns = [
        {
            title: "Exam Board",
            dataIndex: "examBoard"
        },
        {
            title: "Code",
            dataIndex: "shortCode"
        },
        {
            title: "Level",
            dataIndex: "isHigher",
            render: (isHigher) => (isHigher ? "Higher" : "Foundation")
        },
        {
            title: "Set",
            dataIndex: "group"
        },
        {
            title: "Paper Name",
            dataIndex: "name"
        },
        {
            title: "Edit",
            dataIndex: "id",
            render: (id) => (
                <Button
                    type={"primary"}
                    className={"warning-btn"}
                    href={
                        urlStub +
                        "/edit-mock-paper?id=" +
                        encodeURIComponent(id)
                    }
                >
                    Edit
                </Button>
            )
        },
        {
            title: "Delete",
            dataIndex: "id",
            render: (id) => (
                <Popconfirm
                    title={
                        <>
                            <b>Delete: </b>Are you sure?
                        </>
                    }
                    onConfirm={() => {
                        apiDelete(
                            "/api/mock-paper?id=" + encodeURIComponent(id),
                            csrfToken
                        );
                        toggleStaleData((p) => !p);
                    }}
                    onCancel={() => {}}
                    okText="Delete"
                    cancelText="Cancel"
                >
                    <Button type={"primary"} danger={true}>
                        Delete
                    </Button>
                </Popconfirm>
            )
        }
    ];

    return (
        <Table
            className={"adminTable"}
            dataSource={mockPapers}
            columns={columns}
            pagination={false}
        />
    );
}

export default withCookies(ManageMockPapersForm);
