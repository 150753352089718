import React, { useCallback, useEffect, useState } from "react";
import { apiDelete, apiGet, apiPost, apiPut } from "helpers/api";
import {
    Alert,
    Button,
    Checkbox,
    Col,
    Form,
    message,
    Popconfirm,
    Popover,
    Row,
    Space,
    Switch,
    Table,
    Tag,
    Tooltip
} from "antd";
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { withCookies } from "react-cookie";
import { useGetUserQuery } from "features/account/accountSlice";
import { isUserAdmin } from "helpers/security";
import { tooltipColour } from "helpers/starterHelpers";
import { RenewalDate, TrialEndDate } from "./components/userTables/RenewalDate";
import StatusGroupTag from "./components/userTables/StatusGroupTag";
import {
    AddStripeIdModal,
    ChangeAdminModal,
    EditMaxSubUsersModal,
    EditTrialModal,
    ManageAccountsModal,
    ManageAccountStatusModal,
    NewSubUserModal
} from "./components/userTables/AccountManagementModals";
import * as dayjs from "dayjs";
import {
    SCHOOL_NOT_PRESENT_MESSAGE,
    SchoolNameFormItem
} from "features/account/AccountFormPages";

function SchoolNameForm({ onSubmit }) {
    const [form] = Form.useForm();
    return (
        <Form
            form={form}
            onFinish={(formData) => {
                const schoolName =
                    formData.school === SCHOOL_NOT_PRESENT_MESSAGE
                        ? formData.customSchool
                        : formData.school;
                onSubmit(schoolName);
            }}
        >
            <SchoolNameFormItem required={true} form={form} />
            <Form.Item className={"formButtons"}>
                <Button htmlType={"submit"} type={"primary"}>
                    Confirm
                </Button>
            </Form.Item>
        </Form>
    );
}

export const statusGroups = ["paid", "trial", "awaitingPayment"];

function UserMetricsPage({ isAdminManagementPage = false, cookies }) {
    const [csrfToken] = useState(cookies.get("XSRF-TOKEN"));
    const { data: user } = useGetUserQuery();
    const isApplicationAdmin = isUserAdmin(user);
    const [users, setUsers] = useState([]);
    const [showSingle, setShowSingle] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const loadUsersData = useCallback(() => {
        setUsers([]);
        setError(null);
        setLoading(true);
        apiGet("/api/users", (body) => {
            setUsers(
                body
                    .map((record) => {
                        if (record["custom:subscriptionType"] === "Site") {
                            let subUsersData = [];
                            if (record?.["custom:stripeCustomerId"]) {
                                subUsersData = body.filter((u) => {
                                    const isSiteAdmin =
                                        (u?.sub || u?.id) ===
                                        (record?.sub || record?.id);
                                    const isSubUserOfSiteAdmin =
                                        u["custom:stripeSiteAdminId"] ===
                                        record["custom:stripeCustomerId"];
                                    return isSiteAdmin || isSubUserOfSiteAdmin;
                                });
                            }
                            return {
                                ...record,
                                subUsersCount: subUsersData.filter(
                                    (u) =>
                                        u["custom:subscriptionType"] ===
                                        "SubSite"
                                ).length,
                                subUserIds: subUsersData.map(
                                    (u) => u?.sub || u?.id
                                ),
                                savesCount: subUsersData
                                    .map((u) => u?.["saves_current"] || 0)
                                    .reduce((a, b) => a + b, 0)
                            };
                        }
                        return record;
                    })
                    .filter((record) => record.email)
            );
            setLoading(false);
            apiPut("/api/invalidate_users_cache", {}, csrfToken, () => {});
        });
    }, [csrfToken]);

    const showSuccessAndReload = useCallback(() => {
        message.success("Success");
        apiPut("/api/invalidate_users_cache", {}, csrfToken, () => {
            loadUsersData();
        });
    }, [csrfToken, loadUsersData]);

    useEffect(() => {
        loadUsersData();
    }, [loadUsersData]);

    const schoolData = users.filter(
        (u) => u["custom:subscriptionType"] === "Site"
    );
    const singleData = users.filter(
        (u) =>
            u["custom:subscriptionType"] === "Single" ||
            u["custom:subscriptionType"] === "Admin"
    );

    const singleColumns = [
        {
            title: "First Name",
            dataIndex: "given_name",
            sorter: stringSorter("given_name")
        },
        {
            title: "Surname",
            dataIndex: "family_name",
            sorter: stringSorter("family_name")
        },
        {
            title: "Email",
            dataIndex: "email",
            sorter: stringSorter("email")
        },
        {
            title: "Account Type",
            dataIndex: "custom:subscriptionType",
            sorter: stringSorter("custom:subscriptionType"),
            render: (type) => {
                let colour;
                switch (type) {
                    case "Site":
                        colour = "green";
                        type = "Site Admin";
                        break;
                    case "Admin":
                        colour = "geekblue";
                        type = "App Admin";
                        break;
                    case "Single":
                        colour = "pink";
                        break;
                    case "SubSite":
                        colour = "orange";
                        type = "Sub User";
                        break;
                    default:
                        colour = "default";
                        type = "null";
                }
                return (
                    <Tag color={colour} key={type}>
                        {type.toUpperCase()}
                    </Tag>
                );
            }
        },
        {
            title: "Date Created",
            dataIndex: "date_created",
            sorter: dateSorter("date_created"),
            render: (date) => date?.substring(0, 10)
        },
        // {
        //     title: "Last Access",
        //     dataIndex: "last_access",
        //     sorter: dateSorter("last_access"),
        //     render: (date) => {
        //         if (!date) {
        //             return null;
        //         }
        //         const now = new Date();
        //         const access = new Date(date);
        //         const diff = now.getTime() - access.getTime();
        //         return Math.round(diff / (1000 * 3600 * 24)) + "d ago";
        //     }
        // },
        {
            title: "Private Saves Opened",
            dataIndex: "private_saves_opened",
            sorter: numSorter("private_saves_opened")
        },
        {
            title: "Shared Saves Opened",
            dataIndex: "shared_saves_opened",
            sorter: numSorter("shared_saves_opened")
        },
        {
            title: "New Saves",
            dataIndex: "saves_new",
            sorter: numSorter("saves_new")
        },
        {
            title: "Saves Updates",
            dataIndex: "saves_updated",
            sorter: numSorter("saves_updated")
        },
        {
            title: "Saves Duplicated",
            dataIndex: "saves_duplicated",
            sorter: numSorter("saves_duplicated")
        },
        {
            title: "Saves Deleted",
            dataIndex: "saves_deleted",
            sorter: numSorter("saves_deleted")
        },
        {
            title: "Starter Class Tags Switched",
            dataIndex: "classes_changed",
            sorter: numSorter("classes_changed")
        }
    ];

    const expandedRowRender = (record) => {
        const subUsersData = users.filter((u) => {
            const id = u?.sub || u?.id;
            const recordId = record?.sub || record?.id;
            return recordId === id || record.subUserIds.includes(id);
        });

        return subUsersData.length > 0 ? (
            <Table
                className={"subTable"}
                key={record.id}
                columns={singleColumns}
                dataSource={subUsersData}
                pagination={false}
            />
        ) : null;
    };

    const [adminTableOpenModal, setAdminTableOpenModal] = useState([
        null,
        null
    ]);
    const adminTableModalKeys = {
        ADMIN_EMAIL: "email",
        STATUS_GROUPS: "status",
        TRIAL_END: "trial",
        STRIPE_ID: "stripe",
        ACCOUNTS: "manage",
        MAX_SUB_USERS: "subUsers",
        NEW_SUB_USER: "newSubUser"
    };

    const adminTableProps = {
        columns: [
            ...(showSingle
                ? [
                      {
                          title: "Name",
                          dataIndex: "given_name",
                          defaultSortOrder: "ascend",
                          sorter: stringSorter("given_name"),
                          render: (given_name, { family_name }) =>
                              `${given_name} ${family_name}`
                      }
                  ]
                : [
                      {
                          title: "School Name",
                          dataIndex: "custom:school",
                          defaultSortOrder: "ascend",
                          sorter: stringSorter("custom:school")
                      }
                  ]),
            {
                title: showSingle ? (
                    "Email"
                ) : (
                    <>
                        Admin Email{" "}
                        <span style={{ fontSize: "0.75em" }}>
                            (Click to edit)
                        </span>
                    </>
                ),
                dataIndex: "email",
                sorter: stringSorter("email"),
                render: (email, record) => (
                    <div
                        className={"clickable"}
                        onClick={() =>
                            setAdminTableOpenModal([
                                adminTableModalKeys.ADMIN_EMAIL,
                                record
                            ])
                        }
                    >
                        {email}
                    </div>
                )
            },
            {
                title: (
                    <>
                        Account Status{" "}
                        <span style={{ fontSize: "0.75em" }}>
                            (Click to edit)
                        </span>
                    </>
                ),
                dataIndex: "groups",
                render: (allGroups, record) => {
                    if (!allGroups) {
                        return;
                    }
                    let groups = allGroups.filter((g) =>
                        statusGroups.includes(g)
                    );
                    if (groups.length === 0) {
                        groups = ["expired"];
                    }
                    return (
                        <div
                            className={"clickable"}
                            onClick={() =>
                                setAdminTableOpenModal([
                                    adminTableModalKeys.STATUS_GROUPS,
                                    record
                                ])
                            }
                        >
                            {groups.map((group) => (
                                <StatusGroupTag groupName={group} />
                            ))}
                        </div>
                    );
                },
                sorter: (a, b) =>
                    (a.groups?.filter((g) => statusGroups.includes(g))?.length >
                    0
                        ? a.groups?.filter((g) => statusGroups.includes(g))[0]
                        : "Trial Expired"
                    ).localeCompare(
                        b.groups?.filter((g) => statusGroups.includes(g))
                            ?.length > 0
                            ? b.groups?.filter((g) =>
                                  statusGroups.includes(g)
                              )[0]
                            : "Trial Expired"
                    )
            },
            {
                title: "Mock Papers Access",
                dataIndex: "groups",
                render: (groups, record) => {
                    return (
                        <Space direction={"vertical"} align={"start"}>
                            <Checkbox
                                checked={groups.some(
                                    (g) => g === "aqaMockPapers"
                                )}
                                onChange={({ target: { checked } }) =>
                                    apiPut(
                                        `/api/set-group-membership?userEmail=${encodeURIComponent(
                                            record.email
                                        )}&group=aqaMockPapers&isMember=${checked}`,
                                        {},
                                        csrfToken,
                                        showSuccessAndReload
                                    )
                                }
                            >
                                AQA
                            </Checkbox>
                            <Checkbox
                                checked={groups.some(
                                    (g) => g === "edexcelMockPapers"
                                )}
                                onChange={({ target: { checked } }) =>
                                    apiPut(
                                        `/api/set-group-membership?userEmail=${encodeURIComponent(
                                            record.email
                                        )}&group=edexcelMockPapers&isMember=${checked}`,
                                        {},
                                        csrfToken,
                                        showSuccessAndReload
                                    )
                                }
                            >
                                Edexcel
                            </Checkbox>
                        </Space>
                    );
                }
            },
            {
                title: (
                    <>
                        Trial End{" "}
                        <span style={{ fontSize: "0.75em" }}>
                            (Click to edit)
                        </span>{" "}
                        <Tooltip
                            title={
                                <>
                                    <p>
                                        For accounts which are 'TRIAL' or 'TRIAL
                                        EXPIRED', this will show their trial end
                                        date (according to Cognito -- can be
                                        manually extended).
                                    </p>
                                    <p>
                                        For 'AWAITING PAYMENT' accounts, this
                                        shows when their access will end (when
                                        their first invoice is due).
                                    </p>
                                </>
                            }
                            color={tooltipColour}
                        >
                            <InfoCircleOutlined style={{ color: "gray" }} />
                        </Tooltip>
                    </>
                ),
                dataIndex: "custom:trialEnd",
                sorter: dateSorter("custom:trialEnd", (unixEndTime, record) =>
                    !record?.groups?.includes("paid")
                        ? parseInt(unixEndTime) * 1000
                        : 0
                ),
                render: (unixEndTime, record) =>
                    !record?.groups?.includes("paid") ? (
                        unixEndTime ? (
                            <div
                                className={"clickable"}
                                onClick={() =>
                                    setAdminTableOpenModal([
                                        adminTableModalKeys.TRIAL_END,
                                        record
                                    ])
                                }
                            >
                                <TrialEndDate unixTimestamp={unixEndTime} />
                            </div>
                        ) : (
                            <Button
                                onClick={() =>
                                    setAdminTableOpenModal([
                                        adminTableModalKeys.TRIAL_END,
                                        record
                                    ])
                                }
                            >
                                Add End Time
                            </Button>
                        )
                    ) : null
            },
            ...(showSingle
                ? [
                      {
                          title: (
                              <>
                                  Invalidate Password{" "}
                                  <Tooltip
                                      title={
                                          <p>
                                              Invalidating a user's password
                                              will mean that when the user next
                                              attempts to log in with their old
                                              password, they will be forced to
                                              change it using the standard
                                              password reset flow.
                                          </p>
                                      }
                                      color={tooltipColour}
                                  >
                                      <InfoCircleOutlined
                                          style={{ color: "gray" }}
                                      />
                                  </Tooltip>
                              </>
                          ),
                          dataIndex: "email",
                          render: (email) => (
                              <Popconfirm
                                  title={
                                      <>
                                          <b>
                                              Invalidate password for {email}:{" "}
                                          </b>
                                          Are you sure?
                                      </>
                                  }
                                  onConfirm={() =>
                                      apiPost(
                                          `/api/invalidate-user-password?userEmail=${encodeURIComponent(
                                              email
                                          )}`,
                                          {},
                                          csrfToken
                                      )
                                  }
                                  onCancel={() => {}}
                                  okText="Confirm Invalidation"
                                  cancelText="Cancel"
                              >
                                  <Button type={"default"}>
                                      Invalidate Pwd.
                                  </Button>
                              </Popconfirm>
                          )
                      },
                      {
                          title: (
                              <>
                                  Resend Temporary Password{" "}
                                  <Tooltip
                                      title={
                                          <p>
                                              This should be used for new users
                                              who have not logged in within 7
                                              days of receiving their temporary
                                              password and will send them a new
                                              email containing a new temporary
                                              password.
                                          </p>
                                      }
                                      color={tooltipColour}
                                  >
                                      <InfoCircleOutlined
                                          style={{ color: "gray" }}
                                      />
                                  </Tooltip>
                              </>
                          ),
                          dataIndex: "email",
                          render: (email) => (
                              <Popconfirm
                                  title={
                                      <>
                                          <b>Reset password for {email}: </b>Are
                                          you sure?
                                      </>
                                  }
                                  onConfirm={() =>
                                      apiPost(
                                          `/api/reset-user-password?userEmail=${encodeURIComponent(
                                              email
                                          )}`,
                                          {},
                                          csrfToken
                                      )
                                  }
                                  onCancel={() => {}}
                                  okText="Send New Password"
                                  cancelText="Cancel"
                              >
                                  <Button type={"default"}>Reset Pwd.</Button>
                              </Popconfirm>
                          )
                      },
                      {
                          title: "Make Site",
                          dataIndex: "email",
                          render: (email, record) =>
                              record?.["custom:school"] ? (
                                  <Popconfirm
                                      title={
                                          <>
                                              <b>
                                                  Convert {email} to a Site
                                                  Licence?{" "}
                                              </b>
                                              Are you sure?
                                          </>
                                      }
                                      onConfirm={() =>
                                          apiPut(
                                              `/api/convert-single-to-site?userEmail=${encodeURIComponent(
                                                  email
                                              )}&school=${encodeURIComponent(
                                                  record?.["custom:school"]
                                              )}`,
                                              {},
                                              csrfToken
                                          )
                                      }
                                      onCancel={() => {}}
                                      okText="Convert"
                                      cancelText="Cancel"
                                  >
                                      <Button
                                          type={"primary"}
                                          className={"warning-btn"}
                                      >
                                          Make Site
                                      </Button>
                                  </Popconfirm>
                              ) : (
                                  <Popover
                                      trigger={"click"}
                                      content={
                                          <>
                                              <b>
                                                  Convert {email} to a Site
                                                  Licence?
                                              </b>
                                              <SchoolNameForm
                                                  onSubmit={(schoolName) =>
                                                      apiPut(
                                                          `/api/convert-single-to-site?userEmail=${encodeURIComponent(
                                                              email
                                                          )}&school=${encodeURIComponent(
                                                              schoolName
                                                          )}`,
                                                          {},
                                                          csrfToken
                                                      )
                                                  }
                                              />
                                          </>
                                      }
                                  >
                                      <Button
                                          type={"primary"}
                                          className={"warning-btn"}
                                      >
                                          Make Site
                                      </Button>
                                  </Popover>
                              )
                      },
                      {
                          title: "Delete",
                          dataIndex: "email",
                          render: (email) => (
                              <Popconfirm
                                  title={
                                      <>
                                          <b>Delete {email}: </b>Are you sure?
                                      </>
                                  }
                                  onConfirm={() =>
                                      apiDelete(
                                          `/api/account?userEmail=${encodeURIComponent(
                                              email
                                          )}`,
                                          csrfToken
                                      )
                                  }
                                  onCancel={() => {}}
                                  okText="Delete"
                                  cancelText="Cancel"
                              >
                                  <Button type={"primary"} danger={true}>
                                      Delete
                                  </Button>
                              </Popconfirm>
                          )
                      }
                  ]
                : [
                      {
                          title: (
                              <>
                                  Stripe ID
                                  <br />{" "}
                                  <span style={{ fontSize: "0.75em" }}>
                                      (Opens in Stripe)
                                  </span>{" "}
                                  <Tooltip
                                      title={
                                          <>
                                              <p>
                                                  Allows the admin user to
                                                  manage their subscription
                                                  through their account page
                                              </p>
                                          </>
                                      }
                                      color={tooltipColour}
                                  >
                                      <InfoCircleOutlined
                                          style={{ color: "gray" }}
                                      />
                                  </Tooltip>
                              </>
                          ),
                          dataIndex: "custom:stripeCustomerId",
                          render: (stripeId, record) =>
                              stripeId ? (
                                  <div>
                                      <Button
                                          type={"link"}
                                          href={
                                              "https://dashboard.stripe.com/customers/" +
                                              stripeId
                                          }
                                          target={"_blank"}
                                          rel={"noreferrer"}
                                      >
                                          {stripeId.substring(0, 6)}...
                                      </Button>
                                      <Button
                                          type={"text"}
                                          icon={<EditOutlined />}
                                          onClick={() =>
                                              setAdminTableOpenModal([
                                                  adminTableModalKeys.STRIPE_ID,
                                                  record
                                              ])
                                          }
                                      />
                                  </div>
                              ) : (
                                  <Button
                                      onClick={() =>
                                          setAdminTableOpenModal([
                                              adminTableModalKeys.STRIPE_ID,
                                              record
                                          ])
                                      }
                                  >
                                      Add ID
                                  </Button>
                              )
                      },
                      {
                          title: (
                              <>
                                  Sub-users{" "}
                                  <span style={{ fontSize: "0.75em" }}>
                                      (Click to edit max. limit)
                                  </span>
                              </>
                          ),
                          dataIndex: "subUsersCount",
                          sorter: numSorter("subUsersCount"),
                          render: (subUsersCount, record) => (
                              <div
                                  className={"clickable"}
                                  onClick={() =>
                                      setAdminTableOpenModal([
                                          adminTableModalKeys.MAX_SUB_USERS,
                                          record
                                      ])
                                  }
                              >
                                  {subUsersCount} /{" "}
                                  {record?.["custom:maxSubUsers"] ?? 30}
                              </div>
                          )
                      },
                      {
                          title: "Manage Individual Account(s)",
                          dataIndex: "subUsersCount",
                          render: (subUsersCount, record) => (
                              <Button
                                  type={"primary"}
                                  onClick={() =>
                                      setAdminTableOpenModal([
                                          adminTableModalKeys.ACCOUNTS,
                                          record
                                      ])
                                  }
                              >
                                  Manage
                              </Button>
                          )
                      }
                  ])
        ],
        dataSource: showSingle ? singleData : schoolData,
        loading: loading
    };

    const siteLicenceTableProps = {
        columns: [
            {
                title: "School Name",
                dataIndex: "custom:school",
                defaultSortOrder: "ascend",
                sorter: stringSorter("custom:school")
            },
            {
                title: "Postcode",
                dataIndex: ["school", "postcode"],
                sorter: (a, b) =>
                    a.school?.postcode.localeCompare(b.school?.postcode)
            },
            {
                title: "Admin Email",
                dataIndex: "email",
                sorter: stringSorter("email")
            },
            {
                title: "No. Sub-users",
                dataIndex: "subUsersCount",
                sorter: numSorter("subUsersCount")
            },
            {
                title: "No. Saves",
                dataIndex: "savesCount",
                sorter: numSorter("savesCount")
            },
            {
                title: "Account Status",
                dataIndex: "groups",
                render: (allGroups) => {
                    if (!allGroups) {
                        return;
                    }
                    let groups = allGroups.filter((g) =>
                        statusGroups.includes(g)
                    );
                    if (groups.length === 0) {
                        groups = ["expired"];
                    }
                    return groups.map((group) => (
                        <StatusGroupTag groupName={group} />
                    ));
                },
                sorter: (a, b) =>
                    (a.groups?.filter((g) => statusGroups.includes(g))?.length >
                    0
                        ? a.groups?.filter((g) => statusGroups.includes(g))[0]
                        : "Trial Expired"
                    ).localeCompare(
                        b.groups?.filter((g) => statusGroups.includes(g))
                            ?.length > 0
                            ? b.groups?.filter((g) =>
                                  statusGroups.includes(g)
                              )?.[0]
                            : "Trial Expired"
                    )
            },
            isApplicationAdmin
                ? {
                      title: (
                          <>
                              {isApplicationAdmin && "Trial End / "}Renewal Date{" "}
                              {isApplicationAdmin && (
                                  <Tooltip
                                      title={
                                          <>
                                              <p>
                                                  For accounts which are
                                                  'TRIAL', this will show their
                                                  trial end date (according to
                                                  Cognito -- can be manually
                                                  extended).
                                              </p>
                                              <p>
                                                  For 'AWAITING PAYMENT'
                                                  accounts, this shows when
                                                  their first invoice is due.
                                              </p>
                                              <p>
                                                  For 'PAID' accounts this will
                                                  show when Stripe will issue a
                                                  new invoice/payment.
                                              </p>
                                              <p>
                                                  For 'TRIAL EXPIRED' accounts,
                                                  this will default back to the
                                                  Stripe information (ignoring
                                                  any manual extensions or
                                                  invoice due dates) so will
                                                  show the original trial end
                                                  date.
                                              </p>
                                          </>
                                      }
                                      color={tooltipColour}
                                  >
                                      <InfoCircleOutlined
                                          style={{ color: "gray" }}
                                      />
                                  </Tooltip>
                              )}
                          </>
                      ),
                      dataIndex: "renewalDate",
                      sorter: dateSorter("renewalDate"),
                      render: (dateString, record) => (
                          <RenewalDate
                              defaultDateString={dateString}
                              stripeId={record?.["custom:stripeCustomerId"]}
                          />
                      )
                  }
                : {}
        ],
        expandable: {
            expandedRowRender: expandedRowRender,
            rowExpandable: (record) => record.subUserIds.length
        },
        dataSource: schoolData,
        loading: loading
    };

    const siteLicenceTableFooter = (tableData) => {
        let totalSubUsers = 0;
        let totalSaves = 0;

        const singleSite = tableData?.length === 1;

        if (singleSite) {
            return <></>;
        }

        tableData.forEach((record) => {
            totalSubUsers += record["subUsersCount"];
            totalSaves += record["savesCount"];
        });

        return (
            <Table.Summary fixed>
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={4} align={"right"}>
                        <b>Average:</b>{" "}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                        {Math.round((totalSubUsers / tableData.length) * 100) /
                            100}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>
                        {Math.round((totalSaves / tableData.length) * 100) /
                            100}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6}> </Table.Summary.Cell>
                    <Table.Summary.Cell index={7}> </Table.Summary.Cell>
                </Table.Summary.Row>
            </Table.Summary>
        );
    };

    const singleLicenceTableProps = {
        columns: singleColumns,
        dataSource: singleData,
        loading: loading
    };

    const singleLicenceTableFooter = (tableData) => {
        // let averageAccess = 0;
        let totalPrivateSavesOpened = 0;
        let totalSharedSavesOpened = 0;
        let totalNewSaves = 0;
        let totalSavesUpdated = 0;
        let totalSavesDuplicated = 0;
        let totalSavesDeleted = 0;
        let totalClassesSwitched = 0;
        // let numAccess = 0;

        tableData.forEach((record) => {
            // const lastAccess = record["last_access"];
            // if (lastAccess) {
            //     const now = new Date();
            //     const access = new Date(lastAccess);
            //     const diff = now.getTime() - access.getTime();
            //     averageAccess += diff;
            //     numAccess += 1;
            // }
            totalPrivateSavesOpened += record["private_saves_opened"];
            totalSharedSavesOpened += record["shared_saves_opened"];
            totalNewSaves += record["saves_new"];
            totalSavesUpdated += record["saves_updated"];
            totalSavesDuplicated += record["saves_duplicated"];
            totalSavesDeleted += record["saves_deleted"];
            totalClassesSwitched += record["classes_changed"];
        });

        // averageAccess /= numAccess;

        return (
            <Table.Summary fixed>
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={5} align={"right"}>
                        <b>Average:</b>{" "}
                    </Table.Summary.Cell>
                    {/*<Table.Summary.Cell>*/}
                    {/*    {Math.round(averageAccess / (1000 * 3600 * 24)) +*/}
                    {/*        "d ago"}*/}
                    {/*</Table.Summary.Cell>*/}
                    <Table.Summary.Cell index={6}>
                        {Math.round(
                            (totalPrivateSavesOpened / tableData.length) * 100
                        ) / 100}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7}>
                        {Math.round(
                            (totalSharedSavesOpened / tableData.length) * 100
                        ) / 100}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7}>
                        {Math.round((totalNewSaves / tableData.length) * 100) /
                            100}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8}>
                        {Math.round(
                            (totalSavesUpdated / tableData.length) * 100
                        ) / 100}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9}>
                        {Math.round(
                            (totalSavesDuplicated / tableData.length) * 100
                        ) / 100}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10}>
                        {Math.round(
                            (totalSavesDeleted / tableData.length) * 100
                        ) / 100}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11}>
                        {Math.round(
                            (totalClassesSwitched / tableData.length) * 100
                        ) / 100}
                    </Table.Summary.Cell>
                </Table.Summary.Row>
            </Table.Summary>
        );
    };

    function stringSorter(prop) {
        return (a, b) => a[prop] && b[prop] && a[prop].localeCompare(b[prop]);
    }

    function numSorter(prop) {
        return (a, b) => a[prop] - b[prop];
    }

    function dateSorter(prop, fn = (x, record) => x) {
        return (a, b) =>
            new Date(fn(a?.[prop] || 0, a))?.getTime() -
            new Date(fn(b?.[prop] || 0, b))?.getTime();
    }

    return (
        <div className={"metricsContainer"}>
            <Row className={"overallContainer"}>
                <Space direction={"horizontal"}>
                    <Col>
                        <b>Total Accounts:</b>{" "}
                        {users.filter((user) => user?.email).length}
                    </Col>
                    {/*<Col>*/}
                    {/*    <Tooltip*/}
                    {/*        title={*/}
                    {/*            "Accounts which have accessed the application in the last 30 days."*/}
                    {/*        }*/}
                    {/*        color={tooltipColour}*/}
                    {/*    >*/}
                    {/*        <InfoCircleOutlined*/}
                    {/*            style={{ fontSize: "16pt", color: "gray" }}*/}
                    {/*        />*/}
                    {/*    </Tooltip>{" "}*/}
                    {/*    <b>Active accounts: </b>{" "}*/}
                    {/*    {*/}
                    {/*        users.filter((u) => {*/}
                    {/*            const lastAccess = u["last_access"];*/}
                    {/*            if (!lastAccess) {*/}
                    {/*                return false;*/}
                    {/*            }*/}
                    {/*            const access = new Date(lastAccess);*/}
                    {/*            return (*/}
                    {/*                (access - new Date()) /*/}
                    {/*                    (1000 * 36600 * 24) <=*/}
                    {/*                30*/}
                    {/*            );*/}
                    {/*        }).length*/}
                    {/*    }*/}
                    {/*</Col>*/}
                    {!isAdminManagementPage && (
                        <Col>
                            <b>Active Saves:</b>{" "}
                            {users
                                .map((u) => u["saves_current"])
                                .reduce((a, b) => a + b, 0)}
                        </Col>
                    )}
                    {singleData?.length > 0 && (
                        <Col className={"switchContainer"}>
                            <div>
                                Account Type:{" "}
                                <Switch
                                    unCheckedChildren={"Site"}
                                    checkedChildren={"Single"}
                                    defaultChecked={false}
                                    checked={showSingle}
                                    onChange={(checked) =>
                                        setShowSingle(checked)
                                    }
                                />
                            </div>
                        </Col>
                    )}
                </Space>
            </Row>

            {error && (
                <Alert type={"error"} message={"Error: " + error} showIcon />
            )}
            {isAdminManagementPage && (
                <>
                    <ChangeAdminModal
                        open={
                            adminTableOpenModal?.[0] ===
                            adminTableModalKeys.ADMIN_EMAIL
                        }
                        onClose={() => setAdminTableOpenModal([null, null])}
                        currentAdmin={adminTableOpenModal?.[1]}
                        subUsers={users.filter((u) => {
                            const id = u?.sub || u?.id;
                            const record = adminTableOpenModal?.[1];
                            const recordId = record?.sub || record?.id;
                            return (
                                recordId === id ||
                                record?.subUserIds?.includes(id)
                            );
                        })}
                        onSuccess={showSuccessAndReload}
                    />
                    <ManageAccountStatusModal
                        open={
                            adminTableOpenModal?.[0] ===
                            adminTableModalKeys.STATUS_GROUPS
                        }
                        onClose={() => setAdminTableOpenModal([null, null])}
                        currentAdmin={adminTableOpenModal?.[1]}
                        onSuccess={showSuccessAndReload}
                    />
                    <ManageAccountsModal
                        open={
                            adminTableOpenModal?.[0] ===
                            adminTableModalKeys.ACCOUNTS
                        }
                        onClose={() => setAdminTableOpenModal([null, null])}
                        accounts={[
                            adminTableOpenModal?.[1],
                            ...users.filter(
                                (u) =>
                                    adminTableOpenModal?.[1]?.subUserIds?.includes(
                                        u?.sub || u?.id
                                    ) && adminTableOpenModal?.[1]?.id !== u?.id
                            )
                        ]}
                        onNewSubUser={() =>
                            setAdminTableOpenModal((prev) => [
                                adminTableModalKeys.NEW_SUB_USER,
                                ...prev.slice(1)
                            ])
                        }
                        onDeleteSuccess={showSuccessAndReload}
                    />
                    <AddStripeIdModal
                        open={
                            adminTableOpenModal?.[0] ===
                            adminTableModalKeys.STRIPE_ID
                        }
                        onClose={() => setAdminTableOpenModal([null, null])}
                        adminUser={adminTableOpenModal?.[1]}
                        currentId={
                            adminTableOpenModal?.[1]?.[
                                "custom:stripeCustomerId"
                            ]
                        }
                        onSuccess={showSuccessAndReload}
                    />
                    <EditMaxSubUsersModal
                        open={
                            adminTableOpenModal?.[0] ===
                            adminTableModalKeys.MAX_SUB_USERS
                        }
                        onClose={() => setAdminTableOpenModal([null, null])}
                        currentMax={parseInt(
                            adminTableOpenModal?.[1]?.["custom:maxSubUsers"] ??
                                30
                        )}
                        adminUser={adminTableOpenModal?.[1]}
                        onSuccess={showSuccessAndReload}
                    />
                    <EditTrialModal
                        open={
                            adminTableOpenModal?.[0] ===
                            adminTableModalKeys.TRIAL_END
                        }
                        onClose={() => setAdminTableOpenModal([null, null])}
                        adminUser={adminTableOpenModal?.[1]}
                        currentTrialEnd={dayjs.unix(
                            parseInt(
                                adminTableOpenModal?.[1]?.["custom:trialEnd"] ??
                                    0
                            )
                        )}
                        onSuccess={showSuccessAndReload}
                    />
                    <NewSubUserModal
                        open={
                            adminTableOpenModal?.[0] ===
                            adminTableModalKeys.NEW_SUB_USER
                        }
                        onClose={() => setAdminTableOpenModal([null, null])}
                        adminUser={adminTableOpenModal?.[1]}
                        onSuccess={showSuccessAndReload}
                    />
                    <p>
                        <b>Note:</b> If extending a trial end date, check that
                        the account is still in the "trial" group. If not, they
                        will need to be added back in.
                    </p>
                    <p>
                        <b>Note:</b> If an account has an expired (red) trial
                        end date -- i.e. before today -- but they are still
                        marked as "trial" (or "awaiting payment") rather than
                        "trial expired", then they have not logged in since the
                        expiration of the trial. This will resolve itself when
                        they next log in (or you can manually remove them from
                        all groups if you want to make sure).
                    </p>
                </>
            )}
            <Table
                className={
                    Boolean(showSingle || isAdminManagementPage) &&
                    "singleTable"
                }
                {...(isAdminManagementPage
                    ? adminTableProps
                    : showSingle
                      ? singleLicenceTableProps
                      : siteLicenceTableProps)}
                rowKey="id"
                pagination={false}
                scroll={{
                    x: "100%",
                    y: isAdminManagementPage
                        ? "calc(95vh - 30rem)"
                        : "calc(95vh - 40rem)"
                }}
                summary={
                    isAdminManagementPage
                        ? null
                        : showSingle
                          ? singleLicenceTableFooter
                          : siteLicenceTableFooter
                }
            />
        </div>
    );
}

export default withCookies(UserMetricsPage);
