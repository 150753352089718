import React from "react";
import { Button, Popconfirm, Space, Tooltip } from "antd";
import RoundedButton from "commonComponents/RoundedButton";
import { getDateString } from "helpers/starterHelpers";
import Icon from "@ant-design/icons";
import SvgIconWand from "assets/icons/IconWand";
import { isEnvironmentContentCreationOnly } from "helpers/security";

function PlenaryHeader({
    fullscreen,
    makeFullscreen,
    autoDisabled,
    autoGenerateStarter,
    activatePrintingModal,
    activatePlenaryTypeModal
}) {
    const isContentEnvironment = isEnvironmentContentCreationOnly();
    const dateString = getDateString();

    const changeTypeButtonStyle = {
        padding: "0.25rem 1rem",
        height: "fit-content",
        borderRadius: "6px"
    };

    return (
        <div className={"header " + (fullscreen ? "fullscreen" : "")}>
            <div className="switcher-date">
                {fullscreen ? (
                    <div className="date">{dateString}</div>
                ) : (
                    <Space direction={"horizontal"}>
                        <Popconfirm
                            title={
                                <>
                                    <b>Are you sure?</b>
                                    <p>
                                        Changing exit card type will clear all
                                        questions in this current view.
                                    </p>
                                </>
                            }
                            okText={"Yes, I want a fresh exit ticket"}
                            onConfirm={() => activatePlenaryTypeModal()}
                            okButtonProps={{ style: changeTypeButtonStyle }}
                            cancelButtonProps={{ style: changeTypeButtonStyle }}
                            placement={"bottomLeft"}
                        >
                            <RoundedButton className={"changeTypeButton"}>
                                {"< Change Exit Ticket Type"}
                            </RoundedButton>
                        </Popconfirm>
                    </Space>
                )}
            </div>
            {!fullscreen && (
                <div className={"plenary-header-buttons"}>
                    <Button type="default" onClick={activatePrintingModal}>
                        Print Exit Ticket
                    </Button>
                    {!isContentEnvironment && (
                        <Tooltip
                            title={
                                autoDisabled
                                    ? "Please select 3 questions before generating a starter"
                                    : undefined
                            }
                            color={"red"}
                            overlayStyle={{ fontWeight: 600 }}
                        >
                            <Button
                                id="btn-extension"
                                type="primary"
                                data-tour={"autoStarter"}
                                onClick={
                                    !autoDisabled
                                        ? autoGenerateStarter
                                        : undefined
                                }
                                icon={
                                    <Icon component={() => <SvgIconWand />} />
                                }
                            >
                                Auto Generate Close the Gap Starter
                            </Button>
                        </Tooltip>
                    )}
                </div>
            )}
        </div>
    );
}

export default PlenaryHeader;
