import React, { useCallback, useEffect, useState } from "react";
import { apiGet } from "helpers/api";
import { Form, Input, message, Table } from "antd";

function AccountLookupPage() {
    const [usersLookupData, setUsersLookupData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState(null);

    const loadUsersData = useCallback(() => {
        setLoading(true);
        apiGet(
            "/api/account-lookup",
            (body) => {
                setUsersLookupData(body);
                setLoading(false);
            },
            (err) => {
                message.error(err.message);
                setLoading(false);
            }
        );
    }, []);

    useEffect(() => {
        loadUsersData();
    }, [loadUsersData]);
    function stringSorter(prop) {
        return (a, b) => a[prop] && b[prop] && a[prop].localeCompare(b[prop]);
    }

    const columns = [
        {
            title: "App. ID",
            dataIndex: "id",
            sorter: stringSorter("id")
        },
        {
            title: "Cognito ID",
            dataIndex: "cognitoId",
            sorter: stringSorter("cognitoId")
        },
        {
            title: "First Name",
            dataIndex: "forename",
            sorter: stringSorter("forename")
        },
        {
            title: "Surname",
            dataIndex: "surname",
            sorter: stringSorter("surname")
        },
        {
            title: "Email",
            dataIndex: "email",
            sorter: stringSorter("email")
        },
        {
            title: "School",
            dataIndex: "school",
            sorter: stringSorter("school")
        }
    ];

    return (
        <div className={"metricsContainer"}>
            <Form layout={"horizontal"}>
                <Form.Item label={"Filter"}>
                    <Input
                        placeholder={"Search for an App. ID"}
                        style={{ maxWidth: "30rem" }}
                        allowClear
                        onChange={(e) => setFilter(e.target.value)}
                    />
                </Form.Item>
            </Form>
            <Table
                columns={columns}
                dataSource={usersLookupData.filter(
                    (r) => !filter || r.id.includes(filter.trim())
                )}
                loading={loading}
                pagination={false}
                scroll={{
                    x: "100%",
                    y: "95vh"
                }}
            />
        </div>
    );
}

export default AccountLookupPage;
