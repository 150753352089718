import { apiSlice } from "helpers/apiSlice";

export const extendedApiSlice = apiSlice
    .enhanceEndpoints({ addTagTypes: ["Mocks"] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getPaperGroups: builder.query({
                query: () => "/paper-sets",
                transformResponse: (result) => {
                    return result?.map((examBoard) => {
                        let sortedSets = examBoard?.sets ?? [];
                        sortedSets = sortedSets
                            .slice()
                            .sort((a, b) => a.name.localeCompare(b.name));
                        return {
                            ...examBoard,
                            sets: sortedSets.map((set) => {
                                let sortedPapers = set?.papers ?? [];
                                if (set?.name === "FB") {
                                    const getSheetNumber = (paper) =>
                                        parseInt(
                                            paper.shortCode.match(
                                                /.+-([0-9]+)/
                                            )?.[1] ?? 0
                                        );
                                    sortedPapers = sortedPapers
                                        .slice()
                                        .sort(
                                            (a, b) =>
                                                getSheetNumber(a) -
                                                getSheetNumber(b)
                                        );
                                } else {
                                    sortedPapers = sortedPapers
                                        .slice()
                                        .sort((a, b) =>
                                            a.shortCode.localeCompare(
                                                b.shortCode
                                            )
                                        );
                                }
                                return {
                                    ...set,
                                    papers: sortedPapers
                                };
                            })
                        };
                    });
                },
                providesTags: (result) => [
                    ...(result?.flatMap(
                        ({ papers }) =>
                            papers?.map(({ id }) => ({ type: "Mocks", id })) ||
                            []
                    ) || []),
                    { type: "Mocks", id: "LIST" }
                ]
            }),
            getPaper: builder.query({
                query: (paperId) => `/paper?id=${encodeURIComponent(paperId)}`,
                providesTags: (result, error, id) => [{ type: "Mocks", id }]
            }),
            getNewPaperStarter: builder.query({
                query: ({ paperId, questionNumbers }) => ({
                    url: `/mock-paper-starter?mockPaperId=${encodeURIComponent(
                        paperId
                    )}&questionNumbers=${encodeURIComponent(questionNumbers)}`
                })
            }),
            getCloseGapQuestions: builder.query({
                query: (subTopic) => ({
                    url: `/library-starter?subTopic=${encodeURIComponent(
                        subTopic
                    )}&difficulty=mixed`
                })
            })
        })
    });

export const {
    useGetPaperGroupsQuery,
    useLazyGetNewPaperStarterQuery,
    useGetCloseGapQuestionsQuery,
    useGetPaperQuery
} = extendedApiSlice;
