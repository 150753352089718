import * as React from "react";

const SvgIconFire = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M7.896 10.385C9.966 8.147 10.38 4.846 10.38 3c2.781 2.215 4.334 7 4.5 9 1.192 0 1.957-1.615 2.453-2.538 2.484 9.23-2.556 11.076-5.536 11.538-5.464-.923-7.708-6.5-3.9-10.615Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.843 21c-4.745-.889-2.588-6.222-.431-8 0 3.111 2.588 2.667 2.588 5.333 0 1.778-1.15 2.519-2.157 2.667Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgIconFire;
