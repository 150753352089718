import React, { useState } from "react";
import { Button, Checkbox, message, Radio, Space } from "antd";

function GCSEStarterForm({ onSelect = ({ grades, topics, numQs }) => {} }) {
    const [gradeRangeChoice, setGradeRangeChoice] = useState("1-9");
    const [gradeCustomChoices, setGradeCustomChoices] = useState(null);
    const [numQuestions, setNumQuestions] = useState(6);

    const gradeRanges = [
        { label: "1-3", value: "1-3" },
        { label: "3-5", value: "3-5" },
        { label: "4-6", value: "4-6" },
        { label: "7-9", value: "7-9" },
        { label: "All Grades", value: "1-9" }
    ];

    const allGrades = Array.from({ length: 9 }, (v, k) => k + 1).map((k) => ({
        key: k,
        label: k,
        value: k
    }));

    function generateGCSEStarter() {
        const noGradeChoices =
            (!gradeRangeChoice?.length || gradeRangeChoice === {}) &&
            !gradeCustomChoices?.length;

        if (noGradeChoices) {
            message.warning("Please choose some grades");
            return;
        }
        let grades;
        if (gradeRangeChoice) {
            const range = gradeRangeChoice;
            const start = parseInt(range.charAt(0));
            const end = parseInt(range.charAt(2));
            grades = Array.from(
                { length: end - start + 1 },
                (v, k) => k + start
            );
        } else {
            grades = gradeCustomChoices;
        }

        const topics = [];
        onSelect({ grades, topics, numQs: numQuestions });
    }

    const NumQToggle = ({ disabled = false }) => (
        <div className={"gcse-num-q-toggle-container"}>
            <Button
                disabled={disabled}
                type={numQuestions === 4 ? "primary" : "default"}
                onClick={() => setNumQuestions(4)}
            >
                4
            </Button>
            <Button
                disabled={disabled}
                type={numQuestions === 6 ? "primary" : "default"}
                onClick={() => setNumQuestions(6)}
            >
                6
            </Button>
        </div>
    );

    return (
        <Space
            direction="vertical"
            className="stepsHeader"
            size={20}
            style={{ width: "100%" }}
        >
            <div className={"lesson_type_modal__accent_box"}>
                <Space direction="vertical" align="start" size={16}>
                    <Space direction="vertical" align="start" size={0}>
                        <h3>Select Grade Range</h3>
                        <Radio.Group
                            options={gradeRanges}
                            value={gradeRangeChoice}
                            onChange={(selected) => {
                                const c = selected.target.value;
                                setGradeRangeChoice(c);
                                setGradeCustomChoices(null);
                            }}
                        />
                    </Space>
                    <Space direction="horizontal" align="baseline" size={16}>
                        <NumQToggle disabled={!gradeRangeChoice?.length} />
                        <p
                            className={
                                "gcse-num-q-label " +
                                (!gradeRangeChoice?.length ? "disabled" : "")
                            }
                        >
                            Questions
                        </p>
                    </Space>
                </Space>
                <Button
                    type="primary"
                    className={"actionButton gcse-generate"}
                    disabled={!gradeRangeChoice?.length}
                    style={{ marginTop: "1.5rem" }}
                    onClick={generateGCSEStarter}
                >
                    Generate Starter
                </Button>
            </div>
            <p className="gcse_or">OR</p>
            <div className={"lesson_type_modal__accent_box"}>
                <Space direction="vertical" align="start" size={16}>
                    <Space direction="vertical" align="start" size={0}>
                        <h3>Select Custom Grades</h3>
                        <Checkbox.Group
                            className={"gcse_grade_options"}
                            options={allGrades}
                            value={gradeCustomChoices}
                            onChange={(list) => {
                                setGradeRangeChoice(null);
                                setGradeCustomChoices(list);
                            }}
                        />
                    </Space>
                    <Space direction="horizontal" align="baseline" size={16}>
                        <NumQToggle disabled={!gradeCustomChoices?.length} />
                        <p
                            className={
                                "gcse-num-q-label " +
                                (!gradeCustomChoices?.length ? "disabled" : "")
                            }
                        >
                            Questions
                        </p>
                    </Space>
                </Space>
                <Button
                    type="primary"
                    className={"actionButton gcse-generate"}
                    disabled={!gradeCustomChoices?.length}
                    style={{ marginTop: "1.5rem" }}
                    onClick={generateGCSEStarter}
                >
                    Generate Starter
                </Button>
            </div>
        </Space>
    );
}

export default GCSEStarterForm;
