import React from "react";
import SignInForm from "features/account/SignInForm";
import { isEnvironmentContentCreationOnly } from "helpers/security";

function PublicHomepage() {
    const isContentEnvironment = isEnvironmentContentCreationOnly();

    if (isContentEnvironment) {
        return (
            <div className="wrap">
                <div className="container-home">
                    <div className="content-hero-wrapper">
                        <div className="container-splash">
                            <img
                                src={
                                    process.env.PUBLIC_URL + "/svgs/MP_Logo.svg"
                                }
                                alt="Maths Planner"
                                className={"full-width"}
                            />
                            <div className="content-icons">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/svgs/vault-white.svg"
                                    }
                                    alt={"Icon of a vault"}
                                />
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/svgs/question-steps-white.svg"
                                    }
                                    alt={"Icon of a vault"}
                                />
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/svgs/infinite-white.svg"
                                    }
                                    alt={"Icon of a vault"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="content-container-login">
                        <div className="login-form">
                            <SignInForm />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="wrap">
            <div className="container-home">
                <div className="container-left">
                    <div className="container-splash">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/svgs/MP_Logo_Blue.svg"
                            }
                            alt="Maths Planner"
                        />
                        <div className="login-form">
                            <SignInForm
                                subTitleDescription={
                                    <p className={"login-subtitle"}>
                                        Don’t have an account?{" "}
                                        <a
                                            href={
                                                "https://www.mathsplanner.com/become-a-pilot-school/"
                                            }
                                        >
                                            Sign your department up in minutes
                                        </a>
                                    </p>
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="container-right">
                    <div className="hero">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/svgs/Auto_Gen_Illustration.svg"
                            }
                            alt="Illustration of 6 questions with a button below containg the words 'Auto Generate Starter'"
                        />
                        <h2>
                            We link your next starter to your previous exit
                            ticket in 1-click
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PublicHomepage;
