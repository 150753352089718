import { Badge, Popover, Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { EyeFilled, EyeOutlined } from "@ant-design/icons";
import { apiGet } from "helpers/api";
import { getImage, TexComponent } from "helpers/questionHelpers";
import { capitalizeFirstLetter } from "helpers/miscHelpers";

function OurLibrarySlotCard({ starter, onCloseClick }) {
    const noPreview = starter.difficulty.toLowerCase() === "mixed";

    const [loadingPreview, setLoadingPreview] = useState(false);
    const [previewComponent, setPreviewComponent] = useState(null);
    const [isPreviewing, setIsPreviewing] = useState(false);

    useEffect(() => {
        if (noPreview) {
            return;
        }
        setLoadingPreview(true);
        apiGet(
            `/api/resolved-question?subtopic=${encodeURIComponent(
                starter.subTopic.label
            )}&difficulty=${encodeURIComponent(
                capitalizeFirstLetter(starter.difficulty)
            )}&questionSeed=0&valueSeed=0`,
            (body) => {
                body.question = <TexComponent tex={body.question} />;
                setPreviewComponent(body);
                setLoadingPreview(false);
            }
        );
    }, [starter.difficulty, starter.subTopic.label, noPreview]);

    return (
        <Badge
            color={"#0058de"}
            style={{ cursor: "pointer" }}
            onClick={onCloseClick}
            count={"X"}
        >
            <Popover
                trigger={"hover"}
                open={!noPreview && isPreviewing}
                onOpenChange={setIsPreviewing}
                title={
                    <b>
                        Example {capitalizeFirstLetter(starter.difficulty)}{" "}
                        Question
                    </b>
                }
                content={
                    loadingPreview || !previewComponent ? (
                        <Spin tip={"Loading"} />
                    ) : (
                        <>
                            {previewComponent.imageUrlSuffix &&
                                getImage(
                                    previewComponent.imageUrlSuffix,
                                    "questionImage"
                                )}
                            {previewComponent.question}
                        </>
                    )
                }
                overlayStyle={{ zIndex: 2000 }}
            >
                <div
                    className={"draggable-save " + starter.subTopic.colour}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="save-item-name">
                        {starter.subTopic.label}
                    </div>
                    <span className={"save-item-fn-row"}>
                        <Tag
                            className={"saveItemTag"}
                            color={starter.subTopic.colour}
                            closable={false}
                            style={{
                                fontSize: "1rem",
                                fontWeight: "600"
                            }}
                        >
                            {starter.difficulty.toUpperCase()}
                        </Tag>
                        {!noPreview &&
                            (isPreviewing ? <EyeFilled /> : <EyeOutlined />)}
                    </span>
                </div>
            </Popover>
        </Badge>
    );
}

export function OurLibrarySavedSlots({
    selectedStarters,
    setSelectedStarters,
    numSlots
}) {
    return (
        <div
            className={
                "our-library__slots " +
                (numSlots === 1 ? "our-library__slots__single " : "")
            }
        >
            {selectedStarters.map((starter, i) => (
                <OurLibrarySlotCard
                    key={"slot" + i}
                    starter={starter}
                    onCloseClick={() => {
                        setSelectedStarters((prev) => {
                            const copy = [...prev];
                            copy.splice(i, 1);
                            return copy;
                        });
                    }}
                />
            ))}
            {[
                ...Array(Math.max(numSlots - selectedStarters.length, 0)).keys()
            ].map((i) => (
                <div
                    className={"our-library__slots__empty"}
                    key={"slot" + (selectedStarters.length + i + 1)}
                >
                    {i + selectedStarters.length + 1}
                </div>
            ))}
        </div>
    );
}
