import * as React from "react";

const SvgIconHome = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={"1em"}
        height="1em"
        viewBox={"0 0 19 19"}
        fill="none"
        {...props}
    >
        <path
            fill="currentColor"
            fillOpacity={0.1}
            d="M15.924 19H3.076C1.93 19 1 18.012 1 16.793V7.539c0-.689.303-1.338.819-1.756L8.408.451a1.98 1.98 0 0 1 2.551.03l6.259 5.302c.494.419.782 1.054.782 1.726v9.284C18 18.012 17.07 19 15.924 19Z"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M7.958.552a2.937 2.937 0 0 1 3.477.037l-.42.503.42-.503 6.499 4.943A2.657 2.657 0 0 1 19 7.644v8.655C19 17.791 17.733 19 16.17 19H2.83C1.267 19 0 17.79 0 16.299V7.672a2.66 2.66 0 0 1 1.116-2.149L7.958.552Zm2.637 1.043a1.538 1.538 0 0 0-1.821-.02L1.932 6.546a1.394 1.394 0 0 0-.584 1.126V16.3c0 .781.663 1.415 1.482 1.415h13.34c.819 0 1.482-.634 1.482-1.415V7.644c0-.43-.205-.838-.558-1.107l-6.499-4.942Z"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M5.085 13.188c.16-.19.492-.245.742-.124 1.659.804 2.821 1.133 3.866 1.122 1.04-.01 2.067-.36 3.458-1.11.241-.13.577-.088.75.095.171.182.115.436-.126.567-1.445.78-2.695 1.247-4.067 1.262-1.366.014-2.749-.422-4.46-1.25-.25-.122-.323-.373-.163-.563Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIconHome;
