import React from "react";
import ReactDOM from "react-dom";

import "./index.less";
import "./assets/styles/main.scss";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import reduxStore from "app/reduxStore";
import ApiSpinner from "commonComponents/ApiSpinner";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import isLeapYear from "dayjs/plugin/isLeapYear";
import "dayjs/locale/en";

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(isLeapYear);
dayjs.locale("en");

ReactDOM.render(
    <React.StrictMode>
        <CookiesProvider>
            <ReduxProvider store={reduxStore}>
                <ApiSpinner />
                <Router>
                    <App />
                </Router>
            </ReduxProvider>
        </CookiesProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
