import { Drawer } from "antd";
import React, { useState } from "react";
import {
    useGetPaperQuery,
    useLazyGetNewPaperStarterQuery
} from "features/mockPapers/mockPapersSlice";
import CloseGapStarterActivity from "features/lesson/CloseGapStarter";
import MockPaperCTGStarterForm from "features/mockPapers/MockPaperCTGStarterForm";

function MockPaperCloseTheGapModalContents({
    fullscreen,
    makeFullscreen,
    paperId,
    open
}) {
    const { data: paper } = useGetPaperQuery(paperId, {
        skip: !open
    });
    const code = paper?.shortCode;

    const [questionChoices, setQuestionChoices] = useState(null);

    const [
        generateStarter,
        {
            data: { questionChoices: starterQuestionChoices } = {
                questionChoices: null
            },
            error: starterError,
            isFetching: loadingStarter
        }
    ] = useLazyGetNewPaperStarterQuery();

    const [starterVisible, setStarterVisible] = useState(false);

    if (!open) {
        return null;
    }

    return (
        <>
            <>
                <div className={"mock_papers_modal__wrapper"}>
                    <h1>{code}</h1>
                    <p className={"lesson_type_modal__heading"}>
                        Closing the gap starter
                    </p>
                    <MockPaperCTGStarterForm
                        paperId={paperId}
                        open={open}
                        className={
                            "lesson_type_modal__accent_box mock_papers_modal__questions-box"
                        }
                        onSubmit={({ paperId, questionNumbers }) => {
                            setQuestionChoices(questionNumbers);
                            generateStarter({
                                paperId,
                                questionNumbers
                            })
                                .unwrap()
                                .then(() => {
                                    setStarterVisible(true);
                                });
                        }}
                        loadingStarter={loadingStarter}
                    />
                </div>
                <Drawer
                    title={null}
                    closable={false}
                    maskClosable={false}
                    keyboard={false}
                    className={"close-gap-starter__drawer"}
                    open={
                        starterQuestionChoices?.length &&
                        starterVisible &&
                        !loadingStarter &&
                        !starterError
                    }
                    onClose={() => setStarterVisible(false)}
                    width={"100vw"}
                    push={false}
                >
                    <CloseGapStarterActivity
                        fullscreen={fullscreen}
                        makeFullscreen={makeFullscreen}
                        name={`Paper ${code}`}
                        onBack={() => {
                            makeFullscreen(false);
                            setStarterVisible(false);
                        }}
                        initialTopicChoices={
                            starterError ? [] : starterQuestionChoices
                        }
                        initialGridWidth={questionChoices?.length > 4 ? 3 : 2}
                        saveable
                    />
                </Drawer>
            </>
        </>
    );
}

export default MockPaperCloseTheGapModalContents;
