import { Redirect, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Result } from "antd";
import AdminDashboard from "features/admin/AdminDashboard";
import AccountPage from "features/settings/AccountPage";
import PublicHomepage from "pages/PublicHomepage";
import { useGetUserQuery } from "features/account/accountSlice";
import { isUserAdmin } from "helpers/security";
import React from "react";

export default function ContentSiteRoutes({ fullscreen, makeFullscreen }) {
    const { data: user } = useGetUserQuery();
    const admin = isUserAdmin(user);
    const isAuthenticated = Boolean(user);

    return (
        <Switch>
            {admin && (
                <Route path="/admin">
                    <Helmet>
                        <title>MathsPlanner - Admin Dashboard</title>
                    </Helmet>
                    {isAuthenticated && (
                        <AdminDashboard
                            fullscreen={fullscreen}
                            makeFullscreen={makeFullscreen}
                        />
                    )}
                </Route>
            )}
            <Route path="/settings/account">
                <Helmet>
                    <title>MathsPlanner - Settings</title>
                </Helmet>
                {isAuthenticated && <AccountPage />}
            </Route>
            {isAuthenticated && admin && (
                <Redirect from="/" exact to="/admin" />
            )}

            <Route path="/" exact>
                <PublicHomepage />
            </Route>
            {admin && (
                <Route>
                    <Redirect from="/" to="/admin" />
                </Route>
            )}
            <Route>
                <Result
                    className={"warning404"}
                    status="403"
                    title={
                        "403: Sorry, you are not authorized to access this page"
                    }
                    subTitle={
                        <>
                            If you believe this to be an error, please contact:{" "}
                            <a href={"mailto:chloe@mathsplanner.com"}>
                                chloe@mathsplanner.com
                            </a>
                        </>
                    }
                />
            </Route>
        </Switch>
    );
}
