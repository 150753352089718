import * as React from "react";

const SvgClassNotesIcon = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6.552 5.247a2 2 0 0 1 2.585-1.15l7.467 2.87a2 2 0 0 1 1.15 2.585l-4.306 11.201a2 2 0 0 1-2.585 1.15l-7.467-2.87a2 2 0 0 1-1.15-2.585L6.553 5.247Z"
            fill="currentColor"
            fillOpacity={0.6}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.224 5.401a1.818 1.818 0 0 0 1.325-2.605l2.068-.348a2 2 0 0 1 2.304 1.64l1.992 11.833a2 2 0 0 1-1.64 2.305l-7.89 1.328a2 2 0 0 1-2.303-1.64L8.088 6.08a2 2 0 0 1 1.64-2.305l2.392-.402A1.82 1.82 0 0 0 14.224 5.4Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgClassNotesIcon;
