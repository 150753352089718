import * as React from "react";

const SvgQuickTablesIcon = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.667 22.667a2.667 2.667 0 0 0 2.666 2.666h21.334a2.667 2.667 0 0 0 2.666-2.666V9.333a2.667 2.667 0 0 0-2.666-2.666H5.333a2.667 2.667 0 0 0-2.666 2.666v13.334Zm4-10.667a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667ZM12 10.666a1.333 1.333 0 1 1-2.667 0 1.333 1.333 0 0 1 2.667 0ZM14.667 12a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667Z"
            fill="currentColor"
            fillOpacity={0.6}
        />
        <path
            d="m26.04 24.693-6.88 5.157a1.333 1.333 0 0 1-2.093-.741l-2.614-10.381c-.284-1.13.915-2.055 1.936-1.494l9.493 5.224c.849.467.932 1.654.157 2.235Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgQuickTablesIcon;
