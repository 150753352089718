import {
    createAsyncThunk,
    createSelector,
    createSlice
} from "@reduxjs/toolkit";
import canvasSize from "canvas-size";

export const INITIAL_CANVAS_AREA = "init";

export const calculateCanvasArea = createAsyncThunk(
    "utils/calculateCanvasArea",
    async (arg, { dispatch }) => {
        dispatch(onFirstCanvasUpdate());
        try {
            const maxDimensions = await canvasSize.maxArea({
                max: 5000,
                usePromise: true
            });
            return maxDimensions.width * maxDimensions.height;
        } catch (e) {
            return 32767;
        }
    },
    {
        condition: (arg, { getState }) => {
            const state = getState();
            const maxArea = state.utils.maxCanvasArea;
            const calcualationInProgress = state.utils.firstCanvasUpdateStarted;
            return maxArea === INITIAL_CANVAS_AREA && !calcualationInProgress;
        }
    }
);

const utilsSlice = createSlice({
    name: "utils",
    initialState: {
        maxCanvasArea: INITIAL_CANVAS_AREA,
        firstCanvasUpdateStarted: false,
        mathJaxInitialised: Boolean(window?.MathJax?.tex2svgPromise)
    },
    reducers: {
        onFirstCanvasUpdate: (state) => {
            state.firstCanvasUpdateStarted = true;
        },
        onMathJaxLoaded: (state) => {
            state.mathJaxInitialised = true;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(calculateCanvasArea.fulfilled, (state, action) => {
            state.maxCanvasArea = action.payload;
        });
    }
});

export const maxCanvasAreaSelector = createSelector(
    (state) => state.utils.maxCanvasArea,
    (maxCanvasArea) => maxCanvasArea
);

export const mathJaxInitialisedSelector = createSelector(
    (state) => state.utils.mathJaxInitialised,
    (init) => init
);

export const { onFirstCanvasUpdate, onMathJaxLoaded } = utilsSlice.actions;
export default utilsSlice;
