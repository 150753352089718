import React, { useState } from "react";
import MockPaperQuestionPreviewModal from "./MockPaperQuestionPreviewModal";
import { Button, Cascader, Form, Input, InputNumber, Radio } from "antd";
import { EyeFilled, EyeOutlined } from "@ant-design/icons";
import { ListForm } from "../ListForm";
import { filterCascader, renderCascader } from "helpers/questionHelpers";

function MockPaperQuestionForm({ field, subTopics, formFieldName, form }) {
    const [isPreviewing, setIsPreviewing] = useState(null);

    const { name, ...fieldProps } = field;
    return (
        <>
            <MockPaperQuestionPreviewModal
                isModalVisible={isPreviewing}
                onClose={() => setIsPreviewing(false)}
                questionNum={field?.name + 1}
                questionData={form.getFieldValue(formFieldName)}
            />
            <div className={"mockPaperQuestionFormContainer"}>
                <div className={"rowFormContainer"}>
                    <Form.Item
                        {...fieldProps}
                        name={[name, "marks"]}
                        rules={[{ required: true }]}
                    >
                        <InputNumber placeholder={"Marks"} min={1} />
                    </Form.Item>
                    <Form.Item
                        {...fieldProps}
                        noStyle
                        name={[name, "difficulty"]}
                    >
                        <Radio.Group
                            className={"solidRadioButtons"}
                            buttonStyle="solid"
                            style={{ display: "flex" }}
                        >
                            <Radio.Button value={"Easy"}>Easy</Radio.Button>
                            <Radio.Button value={"Medium"}>Medium</Radio.Button>
                            <Radio.Button value={"Hard"}>Hard</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Button
                        icon={isPreviewing ? <EyeFilled /> : <EyeOutlined />}
                        type={"text"}
                        className={"mockPaperQuestionPreview"}
                        onClick={() => setIsPreviewing(true)}
                    />
                </div>

                <div className={"mockPaperListField"}>
                    <ListForm
                        name={[name, "subTopics"]}
                        labelFunction={(index) => `Sub Topic ${index + 1}`}
                        control={(field) => (
                            <Form.Item
                                {...field}
                                className={"simpleListFormItem"}
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            !value?.[0] || value?.[2]
                                                ? Promise.resolve()
                                                : Promise.reject(
                                                      new Error(
                                                          "Select a Sub-Topic"
                                                      )
                                                  )
                                    }
                                ]}
                            >
                                <Cascader
                                    popupClassName={"topicsPopup"}
                                    options={subTopics}
                                    placeholder="Search or Select Sub Topic"
                                    showSearch={{
                                        filter: filterCascader,
                                        render: renderCascader
                                    }}
                                    changeOnSelect={true}
                                    className="topicSelector"
                                />
                            </Form.Item>
                        )}
                        usesLocalAdd
                        canBeEmpty={true}
                    />
                </div>
                <div className={"mockPaperListField"}>
                    <ListForm
                        name={[name, "questionUrls"]}
                        labelFunction={(index) => `Question URL ${index + 1}`}
                        control={(field) => (
                            <Form.Item
                                {...field}
                                className={"simpleListFormItem"}
                            >
                                <Input
                                    placeholder={`E.g. triangles/sine-rule-${
                                        field.name + 1
                                    }.svg`}
                                    prefix={
                                        process.env.REACT_APP_IMAGE_DIRECTORY
                                    }
                                />
                            </Form.Item>
                        )}
                        usesLocalAdd
                        canBeEmpty={false}
                    />
                </div>

                <div className={"mockPaperListField"}>
                    <ListForm
                        name={[name, "markSchemeUrls"]}
                        labelFunction={(index) =>
                            `Mark Scheme URL ${index + 1}`
                        }
                        control={({ index, ...field }) => (
                            <Form.Item
                                {...field}
                                className={"simpleListFormItem"}
                            >
                                <Input
                                    placeholder={`E.g. triangles/sine-rule-${
                                        field.name + 1
                                    }-ms.svg`}
                                    prefix={
                                        process.env.REACT_APP_IMAGE_DIRECTORY
                                    }
                                />
                            </Form.Item>
                        )}
                        usesLocalAdd
                        canBeEmpty={false}
                    />
                </div>

                <div className={"mockPaperListField"}>
                    <ListForm
                        name={[name, "solutionUrls"]}
                        labelFunction={(index) =>
                            `Worked Solution URL ${index + 1}`
                        }
                        control={({ index, ...field }) => (
                            <Form.Item
                                {...field}
                                className={"simpleListFormItem"}
                            >
                                <Input
                                    placeholder={`E.g. triangles/sine-rule-${
                                        field.name + 1
                                    }-ws.svg`}
                                    prefix={
                                        process.env.REACT_APP_IMAGE_DIRECTORY
                                    }
                                />
                            </Form.Item>
                        )}
                        usesLocalAdd
                        canBeEmpty={false}
                    />
                </div>
            </div>
        </>
    );
}

export default MockPaperQuestionForm;
