import { AccountDetailsPage } from "features/account/AccountFormPages";
import React, { useEffect, useState } from "react";
import {
    Checkbox,
    DatePicker,
    Form,
    Input,
    message,
    Radio,
    Space,
    Switch
} from "antd";
import { useCookies } from "react-cookie";
import { apiGet, apiPost, apiPut } from "helpers/api";
import dayjs from "dayjs";

function NewAccountForm({ editEmail = null }) {
    const [{ "XSRF-TOKEN": csrfToken }] = useCookies(["XSRF-TOKEN"]);
    const [isSingle, setIsSingle] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (!editEmail) {
            return;
        }
        apiGet(
            `/api/draft-user?email=${encodeURIComponent(editEmail)}`,
            (body) => {
                form.setFieldsValue({
                    forename: body.forename,
                    surname: body.surname,
                    email: body.email,
                    school: body.school,
                    trialEnd: body.trialEnd ? dayjs.unix(body.trialEnd) : null,
                    stripeId: body.stripeId
                });
                setIsSingle(!body.site);
            }
        );
    }, [editEmail, form]);

    const createAccount = ({
        forename,
        surname,
        email,
        schoolName,
        trialEnd,
        stripeId,
        groups
    }) => {
        const body = JSON.stringify({
            isSiteLicence: !isSingle,
            firstName: forename,
            lastName: surname,
            email: email,
            school: schoolName ?? undefined,
            trialEnd: trialEnd ?? undefined,
            stripeId: stripeId ?? undefined,
            groups: groups ?? []
        });
        editEmail
            ? apiPut(
                  `/api/edit-draft-user?email=${encodeURIComponent(editEmail)}`,
                  body,
                  csrfToken
              )
            : apiPost("/api/add-draft-user", body, csrfToken, () => {
                  message.success("Success");
                  form.resetFields();
              });
    };

    return (
        <>
            <h2 className={"createAccountSwitch"}>
                Enter the {isSingle ? "User's" : "Site-Admin's"} Details:
            </h2>
            <Space className={"createAccountSwitch"}>
                <label htmlFor={"singleLicence"}>Single Licence?</label>
                <Switch
                    id={"singleLicence"}
                    checked={isSingle}
                    onChange={setIsSingle}
                    unCheckedChildren={"School"}
                    checkedChildren={"Single"}
                    style={{ minWidth: "8rem" }}
                />
            </Space>
            <AccountDetailsPage
                form={form}
                isSingleLicence={isSingle}
                isAdminCreation
                submitText={`${editEmail ? "Edit" : "Create"} Draft User`}
                onSubmit={(formData) => {
                    createAccount({
                        forename: formData.forename,
                        surname: formData.surname,
                        email: formData.email,
                        schoolName: formData.schoolName,
                        trialEnd: formData?.trialEnd
                            ? dayjs(formData?.trialEnd).unix()
                            : null,
                        stripeId: formData?.stripeId,
                        groups: [formData.groups, ...(formData.groups2 ?? [])]
                    });
                }}
                extraFormFields={
                    <>
                        <Form.Item
                            name="trialEnd"
                            label="Trial End"
                            extra={
                                <>
                                    If this is not set, it will be set to the
                                    default trial length (
                                    {process.env?.REACT_APP_TRIAL_LENGTH || 21}{" "}
                                    days) when the account is activated
                                </>
                            }
                        >
                            <DatePicker picker={"date"} showTime />
                        </Form.Item>
                        <Form.Item
                            name="stripeId"
                            label="Stripe ID"
                            extra={
                                <>
                                    <p>
                                        This can be added at a later date if the
                                        invoice has not yet been created/paid.
                                    </p>
                                    <p>
                                        This allows users to create sub-users
                                        and manage their Stripe subscription
                                        through the billing portal.
                                    </p>
                                </>
                            }
                        >
                            <Input placeholder={"cus_NzudWZhXw0Cm8z"} />
                        </Form.Item>
                        <Form.Item
                            name="groups"
                            label="Group Membership"
                            initialValue={"trial"}
                        >
                            <Radio.Group>
                                <Space direction="vertical">
                                    <Radio value={"trial"}>trial</Radio>
                                    <Radio value={"awaitingPayment"}>
                                        awaitingPayment
                                    </Radio>
                                    <Radio value={"paid"}>paid</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name="groups2"
                            label="Papers Access"
                            initialValue={[]}
                        >
                            <Checkbox.Group>
                                <Space direction="vertical">
                                    <Checkbox value={"aqaMockPapers"}>
                                        AQA
                                    </Checkbox>
                                    <Checkbox value={"edexcelMockPapers"}>
                                        Edexcel
                                    </Checkbox>
                                </Space>
                            </Checkbox.Group>
                        </Form.Item>
                    </>
                }
            />
        </>
    );
}

export default NewAccountForm;
