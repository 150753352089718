import React from "react";
import GCSEStarterForm from "./GCSEStarterForm";
import BaseLessonTypeModal from "./BaseLessonTypeModal";

function GCSEStarterModal({
    isModalVisible,
    setIsModalVisible,
    onSelect = ({ grades, topics, numQs }) => {}
}) {
    return (
        <BaseLessonTypeModal
            title={"GCSE (1-9) Starter"}
            open={isModalVisible}
            setOpen={setIsModalVisible}
            destroyOnClose={true}
            closable={true}
        >
            <p>You pick the grades, we provide the questions.</p>
            <GCSEStarterForm
                onSelect={({ grades, topics, numQs }) => {
                    setIsModalVisible(false);
                    onSelect({ grades, topics, numQs });
                }}
            />
        </BaseLessonTypeModal>
    );
}

export default GCSEStarterModal;
