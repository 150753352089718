import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getIdToken, redirectToLogin } from "helpers/security";
import { Cookies } from "react-cookie";
import { isRejectedWithValue } from "@reduxjs/toolkit";
import { message } from "antd";

export const mutationHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-XSRF-TOKEN": new Cookies().get("XSRF-TOKEN")
};

const rtkQueryErrorLogger = (api) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        if (action?.payload?.status === 401) {
            redirectToLogin();
            return next(action);
        }
        let messageContent;
        if (action?.payload?.data) {
            const errorData = action?.payload?.data;
            if (
                errorData?.message &&
                errorData.message !== "No message available"
            ) {
                messageContent = errorData.message;
            } else if (errorData?.error) {
                const status = action?.payload?.status
                    ? `${action.payload.status}:`
                    : "";
                messageContent = `${status} ${errorData.error}`;
            }
        } else {
            messageContent = action?.error?.message || undefined;
        }
        if (action?.payload?.status === 500) {
            message.error("An error occurred");
        } else if (messageContent) {
            message.error(messageContent);
        } else {
            message.error();
        }
    }

    return next(action);
};

// Define our single API slice object
export const apiSlice = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "/api",
        prepareHeaders: async (headers) => {
            const token = await getIdToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
        credentials: "include"
    }),
    tagTypes: [],
    endpoints: () => ({})
});

export const additionalApiMiddleware = [rtkQueryErrorLogger];
