import * as React from "react";

const SvgIconSettingsTwo = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5 4.333c0-.92.746-1.667 1.667-1.667h1.666c.92 0 1.667.747 1.667 1.667l.2 1.4a1.667 1.667 0 0 0 2.65 1.098l1.132-.849c.65-.65 1.706-.65 2.357 0l1.178 1.179c.651.65.651 1.706 0 2.357l-.848 1.132a1.667 1.667 0 0 0 1.097 2.65l1.4.2c.921 0 1.667.746 1.667 1.666v1.667c0 .92-.746 1.667-1.666 1.667l-1.4.2a1.667 1.667 0 0 0-1.098 2.65l.848 1.132c.651.65.651 1.706 0 2.357l-1.178 1.178c-.651.651-1.706.651-2.357 0l-1.132-.849a1.667 1.667 0 0 0-2.65 1.098l-.2 1.4c0 .921-.746 1.667-1.667 1.667h-1.666c-.92 0-1.667-.746-1.667-1.666l-.2-1.401a1.667 1.667 0 0 0-2.65-1.098l-1.132.85c-.65.65-1.706.65-2.357 0l-1.178-1.18a1.667 1.667 0 0 1 0-2.356l.849-1.132a1.667 1.667 0 0 0-1.098-2.65l-1.4-.2c-.921 0-1.667-.746-1.667-1.667v-1.666c0-.921.746-1.667 1.666-1.667l1.401-.2a1.667 1.667 0 0 0 1.098-2.65l-.85-1.132a1.667 1.667 0 0 1 0-2.357l1.18-1.178c.65-.651 1.705-.651 2.356 0l1.132.848c1.014.76 2.47.157 2.65-1.097l.2-1.4ZM16 22.667a6.667 6.667 0 1 0 0-13.334 6.667 6.667 0 0 0 0 13.333Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 20a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0-2.667a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666Z"
            fill="currentColor"
            fillOpacity={0.6}
        />
    </svg>
);

export default SvgIconSettingsTwo;
