import { Spin } from "antd";
import { OurLibraryButtonRow } from "features/ourLibrary/OurLibraryButtonRow";
import React, { useEffect, useMemo, useState } from "react";
import MockPapersGrid from "./components/MockPapersGrid";
import { useGetPaperGroupsQuery } from "features/mockPapers/mockPapersSlice";
import { createSelector } from "@reduxjs/toolkit";

const EMPTY_ARRAY = [];

const getShowExamBoards = (boards) => boards?.length > 2;

function MockPapersSelector({
    fullscreen = false,
    makeFullscreen = () => {},
    onSelect = (id) => {},
    showDefaultOptionsOnSelect = true,
    includeBoosterSheets = true
}) {
    const [selectedPaperGroup, setSelectedPaperGroup] = useState(null);
    const [selectedExamBoard, setSelectedExamBoard] = useState(null);
    const getExamBoardNames = useMemo(() => {
        return createSelector(
            (res) => res.data,
            (boards) => boards?.map(({ name }) => name) || EMPTY_ARRAY
        );
    }, []);

    const getSetNames = useMemo(() => {
        return createSelector(
            (res) => res.data,
            (res, selectedExamBoard) => selectedExamBoard?.value,
            (boards, selectedExamBoard) =>
                boards
                    ?.filter(
                        ({ name }) =>
                            name !== "FoundationBooster" &&
                            (!getShowExamBoards(boards) ||
                                name === selectedExamBoard)
                    )
                    ?.flatMap(({ name, sets }) => [
                        ...sets.map((s) => ({ ...s, examBoard: name }))
                    ])
                    ?.map(({ name }) => name)
                    ?.filter((v, i, a) => a.indexOf(v) === i)
                    ?.sort() || EMPTY_ARRAY
        );
    }, []);

    const {
        groups: groupNames,
        examBoards: examBoardNames,
        isLoading: loading
    } = useGetPaperGroupsQuery(undefined, {
        selectFromResult: (result) => ({
            examBoards: getExamBoardNames(result),
            groups: getSetNames(result, selectedExamBoard),
            isLoading: result.isLoading
        })
    });

    useEffect(() => {
        if (examBoardNames?.length) {
            const defaultBoard = examBoardNames.sort()[0];
            setSelectedExamBoard({ value: defaultBoard, label: defaultBoard });
        }
    }, [examBoardNames]);

    const showExamBoards = getShowExamBoards(examBoardNames);

    const FBSheetsLabel = "Foundation Booster Sheets";

    const examBoardFilterOptions = showExamBoards
        ? [...examBoardNames]
              .filter(
                  (name) => includeBoosterSheets || name !== "FoundationBooster"
              )
              .sort()
              .map((v) =>
                  v === "FoundationBooster"
                      ? { value: v, label: FBSheetsLabel }
                      : {
                            value: v,
                            label: v
                        }
              )
        : [];

    const setFilterOptions = [...groupNames]
        .map((v) => ({
            value: v,
            label: v
        }))
        .concat(
            showExamBoards || !includeBoosterSheets
                ? []
                : [{ value: "FB", label: FBSheetsLabel }]
        );

    return (
        <div className={"our-library__main_panel"}>
            <div className={"our-library__header"}>
                {loading ? (
                    <Spin />
                ) : (
                    <div>
                        <OurLibraryButtonRow
                            showAllOption={false}
                            options={examBoardFilterOptions}
                            selected={selectedExamBoard}
                            setSelected={setSelectedExamBoard}
                            onClick={() => setSelectedPaperGroup(null)}
                        />
                        <OurLibraryButtonRow
                            showAllOption={true}
                            options={setFilterOptions}
                            selected={selectedPaperGroup}
                            setSelected={setSelectedPaperGroup}
                        />
                    </div>
                )}
            </div>
            <div className={"our-library__content"}>
                <MockPapersGrid
                    showingExamBoards={showExamBoards}
                    selectedExamBoard={selectedExamBoard}
                    selectedPaperGroup={selectedPaperGroup}
                    hideBoosterSheets={!includeBoosterSheets}
                    fullscreen={fullscreen}
                    makeFullscreen={makeFullscreen}
                    onSelect={onSelect}
                    showDefaultOptionsOnSelect={showDefaultOptionsOnSelect}
                />
            </div>
        </div>
    );
}

export default MockPapersSelector;
