import ExtensionQuestion from "./starter/extension/ExtensionQuestion";
import QuestionCard from "../QuestionCard";
import TopicSelector from "features/lesson/TopicSelector";
import React, { memo, useCallback, useMemo, useState } from "react";
import SketchModal from "./SketchModal";

const QuestionGridCell = memo(
    ({
        number,
        questionFont,
        fullscreen,
        senBg,
        isStarter,
        hasRecallTags,
        gridWidth,
        gridHeight,
        calculatorAllowedValues,
        setCalculatorAllowedValues,
        questionTopicChoices,
        setQuestionTopicChoices,
        questionComponent,
        setQuestionComponent,
        setStaleEdits,
        sketchIndex,
        setSketchIndex,
        validIndexes,
        showAnswers,
        setShowAnswers,
        cardRefs
    }) => {
        const index = number - 1;

        const setProperty = useMemo(
            () =>
                (property, value, triggerSave = false) => {
                    setQuestionTopicChoices((prevState) => {
                        const copy = [
                            ...prevState.map((obj) => Object.assign({}, obj))
                        ];
                        if (!copy?.[index]) {
                            copy[index] = {};
                        }
                        if (typeof value === "function") {
                            copy[index][property] = value(prevState);
                        } else {
                            if (value === copy[index][property]) {
                                return prevState;
                            }
                            copy[index][property] = value;
                        }
                        if (triggerSave) {
                            setStaleEdits(true);
                        }
                        return copy;
                    });
                },
            [index, setQuestionTopicChoices, setStaleEdits]
        );

        const setId = useCallback((v) => setProperty("id", v), [setProperty]);
        const setDifficulty = useCallback(
            (v) => setProperty("difficulty", v),
            [setProperty]
        );
        const setRandomSeed = useCallback(
            (v) => setProperty("randomSeed", v),
            [setProperty]
        );
        const setRecallTagData = useCallback(
            (v) => setProperty("recallTag", v, true),
            [setProperty]
        );
        const setCalculatorAllowed = useMemo(
            () => (value) =>
                setCalculatorAllowedValues((prevState) => {
                    const copy = [...prevState];
                    if (typeof value === "function") {
                        copy[index] = value(prevState[index]);
                    } else {
                        if (value === copy[index]) {
                            return prevState;
                        }
                        copy[index] = value;
                    }
                    return copy;
                }),
            [index, setCalculatorAllowedValues]
        );
        const getNewRandomSeed = useCallback(() => {
            if (!questionTopicChoices?.[index]) {
                return;
            }
            setQuestionTopicChoices((prevState) => {
                const copy = [...prevState];
                copy[index].randomSeed = Math.floor(Math.random() * 10000);
                setStaleEdits(true);
                return copy;
            });
        }, [
            index,
            questionTopicChoices,
            setQuestionTopicChoices,
            setStaleEdits
        ]);
        const setShowAnswer = useCallback(
            (val) =>
                setShowAnswers((prev) => {
                    const copy = [...prev];
                    copy[index] = val;
                    return copy;
                }),
            [index, setShowAnswers]
        );

        const clearQuestionComponent = useCallback(
            () => setQuestionComponent(null),
            [setQuestionComponent]
        );

        const topicSelector = useMemo(() => {
            return (
                <TopicSelector
                    questionNum={number}
                    isStarter={isStarter}
                    initialTopicChoice={questionTopicChoices[index]}
                    setQuestionTopicChoices={setQuestionTopicChoices}
                    setStaleEdits={setStaleEdits}
                    clearQuestionComponent={clearQuestionComponent}
                    cardRef={cardRefs.current?.[index]}
                />
            );
            // eslint-disable-next-line
        }, [
            cardRefs,
            clearQuestionComponent,
            index,
            isStarter,
            number,
            // eslint-disable-next-line
            JSON.stringify(questionTopicChoices), // This is fine as a dependency (https://github.com/facebook/react/issues/14476#issuecomment-471199055)
            setQuestionTopicChoices,
            setStaleEdits
        ]);

        return (
            <>
                <QuestionCard
                    ref={(el) => {
                        if (
                            el &&
                            cardRefs?.current &&
                            cardRefs?.current?.[index] !== el
                        ) {
                            cardRefs.current[index] = el;
                        }
                    }}
                    questionNum={number}
                    isStarter={isStarter}
                    title={isStarter ? `Q${number}` : `EQ${number}`}
                    answerTitle={`A${number}`}
                    width={gridWidth}
                    height={gridHeight}
                    extraClassNames={senBg}
                    questionFont={questionFont}
                    subtopic={questionTopicChoices[index]?.subtopic}
                    id={questionTopicChoices[index]?.id}
                    setId={setId}
                    difficulty={questionTopicChoices[index]?.difficulty}
                    setDifficulty={setDifficulty}
                    randomSeed={questionTopicChoices[index]?.randomSeed}
                    setRandomSeed={setRandomSeed}
                    getNewRandomSeed={getNewRandomSeed}
                    calculatorAllowed={
                        calculatorAllowedValues?.[index] ?? false
                    }
                    setCalculatorAllowed={setCalculatorAllowed}
                    setStaleEdits={setStaleEdits}
                    fullscreen={fullscreen}
                    questionComponent={questionComponent}
                    setQuestionComponent={setQuestionComponent}
                    recallTagData={questionTopicChoices[index]?.recallTag}
                    setRecallTagData={setRecallTagData}
                    hasRecallTags={hasRecallTags}
                    setShowSketch={(val) => setSketchIndex(val ? index : null)}
                    showAnswer={showAnswers[index]}
                    setShowAnswer={setShowAnswer}
                    topicSelector={topicSelector}
                />
                {/* Instantiated for each cell to keep state between questions */}
                <SketchModal
                    isModalVisible={sketchIndex === index}
                    setIsModalVisible={(val) =>
                        setSketchIndex(val ? index : null)
                    }
                    questionComponent={questionComponent}
                    questionNumber={number}
                    questionFont={questionFont}
                    senBg={senBg}
                    hasNext={
                        validIndexes.indexOf(index) < validIndexes.length - 1
                    }
                    hasPrev={validIndexes.indexOf(index) > 0}
                    onNext={() =>
                        setSketchIndex(
                            validIndexes[validIndexes.indexOf(index) + 1]
                        )
                    }
                    onPrev={() =>
                        setSketchIndex(
                            validIndexes[validIndexes.indexOf(index) - 1]
                        )
                    }
                />
            </>
        );
    }
);

const QuestionGridExtensionCell = memo(
    ({
        number,
        questionFont,
        senBg,
        fullscreen,
        numQuestions,
        questionTopicChoices,
        extensionTopicChoices,
        setExtensionTopicChoices
    }) => {
        return (
            <ExtensionQuestion
                extNumber={number}
                questionFont={questionFont}
                senBg={senBg}
                fullscreen={fullscreen}
                questionTopicChoices={questionTopicChoices}
                extensionTopicChoices={extensionTopicChoices}
                setExtensionTopicChoices={setExtensionTopicChoices}
                currentNumberOfQuestions={numQuestions}
            />
        );
    }
);

export function QuestionGrid({
    cardRefs = [],
    width,
    height,
    isStarter,
    questionFont,
    senBg,
    questionTopicChoices,
    setQuestionTopicChoices,
    calculatorAllowedValues,
    setCalculatorAllowedValues,
    extensionTopicChoices = [],
    setExtensionTopicChoices = () => {},
    showAnswers,
    setShowAnswers,
    questionComponents,
    setQuestionComponents,
    hasRecallTags = false,
    fullscreen = false,
    showExtension = false,
    setStaleEdits = () => {}
}) {
    const questionNumbers = useMemo(() => {
        const numberOfCells = width * height;
        let questionNumbers = Array.from(
            { length: numberOfCells },
            (v, k) => k + 1
        );
        if (showExtension && width === 2) {
            questionNumbers.splice(2, 0, "ext1");
            questionNumbers.splice(5, 0, "ext2");
        }
        return questionNumbers;
    }, [height, showExtension, width]);

    const [showSketchIndex, setShowSketchIndex] = useState(null);

    const convertExtensionIdToIndex = useCallback(
        (id) => parseInt(id.toString().substring(3)) - 1,
        []
    );

    const validIndexes = useMemo(
        () =>
            questionComponents
                .map((v, i) => (Boolean(v?.question) ? i : null))
                .filter((n) => n || n === 0),
        [questionComponents]
    );

    const gridCells = useMemo(
        () =>
            questionNumbers.map((n) =>
                n.toString().substring(0, 3) === "ext" ? (
                    <QuestionGridExtensionCell
                        key={n}
                        number={convertExtensionIdToIndex(n)}
                        numQuestions={width * height}
                        questionFont={questionFont}
                        senBg={senBg}
                        fullscreen={fullscreen}
                        questionTopicChoices={questionTopicChoices}
                        extensionTopicChoices={extensionTopicChoices}
                        setExtensionTopicChoices={setExtensionTopicChoices}
                    />
                ) : (
                    <QuestionGridCell
                        key={"q" + n}
                        gridWidth={showExtension ? 3 : width}
                        gridHeight={height}
                        number={n}
                        questionFont={questionFont}
                        fullscreen={fullscreen}
                        senBg={senBg}
                        isStarter={isStarter}
                        hasRecallTags={hasRecallTags}
                        questionTopicChoices={questionTopicChoices}
                        setQuestionTopicChoices={setQuestionTopicChoices}
                        calculatorAllowedValues={calculatorAllowedValues}
                        setCalculatorAllowedValues={setCalculatorAllowedValues}
                        questionComponent={questionComponents[n - 1]}
                        setQuestionComponent={
                            setQuestionComponents[n - 1] || (() => {})
                        }
                        setStaleEdits={setStaleEdits}
                        sketchIndex={showSketchIndex}
                        setSketchIndex={setShowSketchIndex}
                        validIndexes={validIndexes}
                        showAnswers={showAnswers}
                        setShowAnswers={setShowAnswers}
                        cardRefs={cardRefs}
                    />
                )
            ),
        [
            questionNumbers,
            convertExtensionIdToIndex,
            width,
            height,
            questionFont,
            senBg,
            fullscreen,
            questionTopicChoices,
            extensionTopicChoices,
            setExtensionTopicChoices,
            showExtension,
            isStarter,
            hasRecallTags,
            setQuestionTopicChoices,
            calculatorAllowedValues,
            setCalculatorAllowedValues,
            questionComponents,
            setQuestionComponents,
            setStaleEdits,
            showSketchIndex,
            showAnswers,
            setShowAnswers,
            cardRefs,
            validIndexes
        ]
    );

    return (
        <div className="grid-container">
            <div
                className="question-container"
                data-tour={isStarter ? "questionGrid" : "plenaryQuestions"}
            >
                {gridCells}
            </div>
        </div>
    );
}
