import * as React from "react";

const SvgFullScreenOpen = (props) => (
    <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9 9 4 4M8 4H4v4M9 15l-5 5M8 20H4v-4M15 9l5-5M16 4h4v4M15 15l5 5M16 20h4v-4"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgFullScreenOpen;
