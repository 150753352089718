import React, { useCallback, useContext, useMemo, useState } from "react";
import customStarterIconColour from "assets/icons/CustomStarterIconColour.svg";
import gcseStarterIconColour from "assets/icons/GCSEStarterIconColour.svg";
import subtopicStarterIconColour from "assets/icons/SubtopicStarterIconColour.svg";
import mockPaperStarterIconColour from "assets/icons/MockPaperStarterIconColour.svg";
import GCSEStarterModal from "./GCSEStarterModal";
import OurLibraryModal from "features/ourLibrary/OurLibraryModal";
import SaveDetailsForm from "./SaveDetailsForm";
import {
    useGetSavedStartersQuery,
    useNewCustomStarterMutation
} from "features/timetable/savesSlice";
import { createSelector } from "@reduxjs/toolkit";
import { getStarterDefaults } from "helpers/miscHelpers";
import { useHistory, useLocation } from "react-router-dom";
import { useGetUserQuery } from "features/account/accountSlice";
import BaseLessonTypeModal from "./BaseLessonTypeModal";
import LessonTypeOption from "features/lesson/LessonTypeOption";
import LessonContext from "../../context/LessonContext";
import { userHasMockPapersAccess } from "helpers/security";
import MockPapersAdvertModal from "features/mockPapers/MockPapersAdvertModal";
import MockPaperStarterModal from "./MockPaperStarterModal";

export const STARTER_TYPES = {
    GCSE: "gcse",
    SUB_TOPIC: "topic",
    MOCK_PAPER: "mock-paper"
};

function SelectStarterTypeModal({
    open,
    setOpen,
    saveId = null,
    createGCSEStarter,
    createLibraryStarter,
    createMockPaperStarter,
    onSelect
}) {
    const history = useHistory();
    const location = useLocation();
    const { data: user } = useGetUserQuery();
    const hasMockPapersAccess = userHasMockPapersAccess(user);
    const [newCustomStarter] = useNewCustomStarterMutation();
    const { isSaveable } = useContext(LessonContext);

    const [showNewGCSEStarter, setShowNewGCSEStarter] = useState(false);
    const [showNewLibraryStarter, setShowNewLibraryStarter] = useState(false);
    const [showNewMockPaperStarter, setShowNewMockPaperStarter] =
        useState(false);
    const [showMockPapersSalesModal, setShowMockPapersSalesModal] =
        useState(false);

    const selectSaveForId = useMemo(() => {
        return createSelector(
            (res) => res.data,
            (res, saveId) => saveId,
            (savesTable, saveId) =>
                saveId &&
                Object.values(savesTable || {})
                    ?.flat()
                    ?.filter((s) => s)
                    ?.find((s) => s.id === saveId)
        );
    }, []);
    const { save: saveDetails } = useGetSavedStartersQuery(undefined, {
        selectFromResult: (result) => ({
            save: selectSaveForId(result, saveId)
        })
    });

    const newStarterModals = (
        <>
            <GCSEStarterModal
                isModalVisible={showNewGCSEStarter}
                setIsModalVisible={setShowNewGCSEStarter}
                onSelect={({ grades, topics, numQs }) => {
                    createGCSEStarter({ grades, topics, numQs });
                    setOpen(false);
                    onSelect({
                        type: STARTER_TYPES.GCSE,
                        options: { grades, topics, numQs }
                    });
                }}
            />
            <OurLibraryModal
                isVisible={showNewLibraryStarter}
                setIsVisible={setShowNewLibraryStarter}
                onSelect={({ subTopic, difficulty }) => {
                    createLibraryStarter({ subTopic, difficulty });
                    setOpen(false);
                    onSelect({
                        type: STARTER_TYPES.SUB_TOPIC,
                        options: { subTopic, difficulty }
                    });
                }}
            />
            <MockPaperStarterModal
                visible={showNewMockPaperStarter}
                onClose={() => setShowNewMockPaperStarter(false)}
                onSelect={({ paperId, questionNumbers }) => {
                    createMockPaperStarter({ paperId, questionNumbers });
                    setOpen(false);
                    onSelect({
                        type: STARTER_TYPES.MOCK_PAPER,
                        options: { paperId, questionNumbers }
                    });
                }}
            />
            <MockPapersAdvertModal
                visible={showMockPapersSalesModal}
                onClose={() => setShowMockPapersSalesModal(false)}
            />
        </>
    );

    const handleNewSaveFromForm = useCallback(
        ({ name, classId }) => {
            newCustomStarter({
                ...getStarterDefaults(user),
                name: name,
                classId: classId,
                timetableColumn: "backlog"
            })
                .unwrap()
                .then((res) => {
                    history.replace({
                        pathname: location.pathname,
                        search: `?id=${res.id}`
                    });
                });
        },
        [history, location.pathname, newCustomStarter, user]
    );

    return (
        <>
            {newStarterModals}
            <BaseLessonTypeModal
                open={open}
                setOpen={setOpen}
                title={"Select Starter Template"}
            >
                <div className={"lesson_type_modal__options"}>
                    <LessonTypeOption
                        name={"Custom Starter"}
                        description={
                            "Select the topics you want for each question."
                        }
                        colour={"blue"}
                        icon={customStarterIconColour}
                        onClick={() => {
                            setOpen(false);
                            onSelect();
                        }}
                    />
                    <LessonTypeOption
                        name={"GCSE Starter"}
                        description={
                            "Simply choose grades and we'll provide random topics."
                        }
                        colour={"purple"}
                        icon={gcseStarterIconColour}
                        onClick={() => setShowNewGCSEStarter(true)}
                    />
                    <LessonTypeOption
                        name={"Subtopic Starter"}
                        description={
                            "Browse and select from our differentiated subtopic library."
                        }
                        colour={"pink"}
                        icon={subtopicStarterIconColour}
                        onClick={() => setShowNewLibraryStarter(true)}
                    />
                    <LessonTypeOption
                        name={"Mock Paper Close the Gap Starter"}
                        description={
                            "Simply choose one of our mock papers to get started."
                        }
                        colour={"yellow"}
                        icon={mockPaperStarterIconColour}
                        onClick={() =>
                            hasMockPapersAccess
                                ? setShowNewMockPaperStarter(true)
                                : setShowMockPapersSalesModal(true)
                        }
                    />
                </div>
                {isSaveable && (
                    <SaveDetailsForm
                        saveId={saveId}
                        saveDetails={saveDetails}
                        isDropdown
                        onSaveNew={handleNewSaveFromForm}
                    />
                )}
            </BaseLessonTypeModal>
        </>
    );
}

export default SelectStarterTypeModal;
