import React from "react";
import Countdown from "commonComponents/Countdown";
import { FullscreenAnswerHeader } from "commonComponents/FullscreenAnswerHeader";

function MultiplicationHeader(props) {
    return (
        <div
            className={
                "multiplicationHeader " +
                (props.fullscreen ? "fullscreen " : "")
            }
        >
            <div className="cntdwn-timer">
                <Countdown
                    initialTimeMinutes={5}
                    startCallback={() => props.makeFullscreen(true)}
                />
            </div>
            <div className="multiplicationHeaderRight">
                <FullscreenAnswerHeader {...props} />
            </div>
        </div>
    );
}

export default MultiplicationHeader;
