import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    Button,
    Card,
    Input,
    InputNumber,
    List,
    Popconfirm,
    Popover,
    Slider,
    Tag
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { recallTagOptions } from "helpers/questionHelpers";
import { ColourTags } from "features/classTags/ClassNotesModal";
import { fontSizeToRem } from "features/lesson/QuestionCard";
import {
    useGetUserQuery,
    useResetDefaultFeedbackTitlesMutation,
    useSetDefaultCountdownMutation,
    useSetDefaultFeedbackTitleMutation,
    useSetStarterPreferencesMutation
} from "features/account/accountSlice";
import { createSelector } from "@reduxjs/toolkit";
import { DEFAULT_PREFERENCES, DEFAULT_TITLES } from "helpers/starterHelpers";

function PreferencesForm() {
    const [localPreferences, setLocalPreferences] = useState({
        ...DEFAULT_PREFERENCES
    });

    const selectStarterPreferences = useMemo(() => {
        return createSelector(
            (res) => res.data,
            (user) => user?.starter_preferences
        );
    }, []);
    const selectDefaultCountdownForUser = useMemo(() => {
        return createSelector(
            (res) => res.data,
            (user) => user?.default_countdown
        );
    }, []);
    const selectDefaultFeedbackTitlesForUser = useMemo(() => {
        return createSelector(
            (res) => res.data,
            (user) => ({
                www: user?.default_www_title,
                ebi: user?.default_ebi_title
            })
        );
    }, []);
    const {
        starter_preferences: userPreferences,
        default_countdown: defaultCountdown,
        titles: {
            www: defaultWWWTitle = DEFAULT_TITLES.www,
            ebi: defaultEbiTitle = DEFAULT_TITLES.ebi
        }
    } = useGetUserQuery(undefined, {
        selectFromResult: (result) => ({
            starter_preferences: selectStarterPreferences(result),
            default_countdown: selectDefaultCountdownForUser(result),
            titles: selectDefaultFeedbackTitlesForUser(result)
        })
    });

    const [updateStarterPreferences] = useSetStarterPreferencesMutation();
    const [staleData, setStaleData] = useState(false);
    const [setDefaultCountdown] = useSetDefaultCountdownMutation();
    const [setDefaultTitle] = useSetDefaultFeedbackTitleMutation();
    const [resetDefaultTitles] = useResetDefaultFeedbackTitlesMutation();
    const [editedWWW, setEditedWWW] = useState(defaultWWWTitle);
    const [editedEBI, setEditedEBI] = useState(defaultEbiTitle);
    const [editedCountdown, setEditedCountdown] = useState(defaultCountdown);
    const editedCountdownRef = useRef(editedCountdown);
    const countdownRequestInFlight = useRef(false);
    editedCountdownRef.current = editedCountdown;
    useEffect(() => {
        setEditedWWW(defaultWWWTitle);
    }, [defaultWWWTitle]);
    useEffect(() => {
        setEditedEBI(defaultEbiTitle);
    }, [defaultEbiTitle]);
    useEffect(() => {
        setEditedCountdown(defaultCountdown);
    }, [defaultCountdown]);
    useEffect(() => {
        // Decouples local and server value with 500ms delay, and uses editedCountdownRef to bypass closure state
        if (
            editedCountdown !== defaultCountdown &&
            editedCountdown !== 360000 &&
            !countdownRequestInFlight.current
        ) {
            setTimeout(() => {
                setDefaultCountdown(editedCountdownRef.current);
                countdownRequestInFlight.current = false;
            }, 500);
            countdownRequestInFlight.current = true;
        }
    }, [defaultCountdown, editedCountdown, setDefaultCountdown]);

    const resetAllSettings = () => {
        setLocalPreferences({ ...DEFAULT_PREFERENCES });
        resetDefaultTitles();
        setDefaultCountdown(360000);
        setStaleData(true);
    };

    const minsToMS = (mins) => mins * 60000;
    const msToMins = (ms) => ms / 60000;

    useEffect(() => {
        if (!staleData) {
            return;
        }
        setStaleData(false);
        updateStarterPreferences(localPreferences);
    }, [staleData, localPreferences, updateStarterPreferences]);

    useEffect(() => {
        if (userPreferences) {
            setLocalPreferences(userPreferences);
            setStaleData(false);
        }
    }, [userPreferences]);

    const setRecallTagSixQs = (i) => {
        return (tagData) =>
            setLocalPreferences((prevState) => {
                const copy = Object.assign({}, prevState);
                copy.recallTagsSixQs = [...copy.recallTagsSixQs];
                copy.recallTagsSixQs[i] = tagData;
                setStaleData(true);
                return copy;
            });
    };
    const setRecallTagFourQs = (i) => {
        return (tagData) =>
            setLocalPreferences((prevState) => {
                const copy = Object.assign({}, prevState);
                copy.recallTagsFourQs = [...copy.recallTagsFourQs];
                copy.recallTagsFourQs[i] = tagData;
                setStaleData(true);
                return copy;
            });
    };

    const setPreference = (field) => {
        return (val) =>
            setLocalPreferences((prevState) => {
                const copy = Object.assign({}, prevState);
                copy[field] = val;
                setStaleData(true);
                return copy;
            });
    };

    const getTagCell = (sixQs) => (n) => {
        const tags = sixQs
            ? localPreferences?.recallTagsSixQs
            : localPreferences?.recallTagsFourQs;
        let tag;
        if (tags?.[n - 1]) {
            tag = (
                <Tag
                    color={tags[n - 1].colour}
                    closable="true"
                    style={{
                        fontSize: "1rem",
                        fontWeight: "600"
                    }}
                    onClose={(e) => {
                        e.preventDefault();
                        sixQs
                            ? setRecallTagSixQs(n - 1)(null)
                            : setRecallTagFourQs(n - 1)(null);
                    }}
                >
                    {tags[n - 1].name}
                </Tag>
            );
        } else {
            tag = (
                <Tag className="addRecallTag clickable">
                    <PlusOutlined /> Recall Tag
                </Tag>
            );
        }
        return (
            <Popover
                key={n}
                overlayClassName={"tagsList"}
                content={recallTagOptions.map((t) => (
                    <div
                        className={"tagWrapper clickable"}
                        key={t.name}
                        onClick={() => {
                            sixQs
                                ? setRecallTagSixQs(n - 1)(t)
                                : setRecallTagFourQs(n - 1)(t);
                        }}
                    >
                        <Tag color={t.colour}>{t.name}</Tag>
                    </div>
                ))}
                trigger="click"
            >
                <div className={"defaultTagCell"}>
                    {`Q${n}: `}
                    {tag}
                </div>
            </Popover>
        );
    };

    return (
        <div>
            <Popconfirm
                title={
                    <div style={{ maxWidth: "35rem" }}>
                        Are you sure you want to{" "}
                        <b>reset all your template settings</b> to their default
                        values?
                    </div>
                }
                onConfirm={resetAllSettings}
                onCancel={() => {}}
                okText="Reset"
                cancelText="Cancel"
            >
                <Button className={"preferencesFormReset"} danger>
                    Reset All Settings to Defaults
                </Button>
            </Popconfirm>
            <div
                className={"preferencesFormWrapper"}
                data-tour={"starterDefaults"}
            >
                <List size="large" className="grid-settings">
                    {/*-- Grid Size ----------------------*/}
                    <List.Item>
                        <h3>Starter Grid Size</h3>
                        <div className="gridSize-btns">
                            <Button
                                size="middle"
                                type={
                                    localPreferences?.starterGridWidth === 2
                                        ? "primary"
                                        : "default"
                                }
                                onClick={() =>
                                    setPreference("starterGridWidth")(2)
                                }
                            >
                                2 &#xD7; 2
                            </Button>
                            <Button
                                size="middle"
                                type={
                                    localPreferences?.starterGridWidth === 3
                                        ? "primary"
                                        : "default"
                                }
                                onClick={() =>
                                    setPreference("starterGridWidth")(3)
                                }
                            >
                                3 &#xD7; 2
                            </Button>
                        </div>
                    </List.Item>

                    {/*-- SEN Overlay ----------------------*/}
                    <List.Item className={"senColourContainer"}>
                        <h3>SEN Overlay</h3>
                        <ColourTags
                            colour={localPreferences?.questionBackground}
                            onClick={(selected) =>
                                setPreference("questionBackground")(selected)
                            }
                            includeNone
                        />
                    </List.Item>
                    <List.Item>
                        <h3>Question Font Size</h3>
                        <div className={"fontSettingContainer"}>
                            <p
                                style={{
                                    marginBottom: 0,
                                    fontSize: fontSizeToRem(
                                        localPreferences?.questionFontSize
                                    )
                                }}
                            >
                                Aa
                            </p>
                            <Slider
                                style={{ width: "45%" }}
                                onChange={setPreference("questionFontSize")}
                                value={localPreferences?.questionFontSize}
                                min={1}
                                max={20}
                            />
                        </div>
                    </List.Item>

                    <List.Item>
                        <h3>Lesson Timer Duration</h3>
                        <InputNumber
                            value={msToMins(editedCountdown)}
                            min={1}
                            max={20}
                            formatter={(value) => `${value} mins`}
                            parser={(value) => value.replace("mins", "").trim()}
                            onChange={(val) =>
                                setEditedCountdown(minsToMS(val))
                            }
                        />
                    </List.Item>

                    <List.Item>
                        <h3>WWW Feedback Title</h3>
                        <Input
                            value={editedWWW}
                            onChange={(e) => setEditedWWW(e.target.value)}
                            style={{
                                maxWidth: "15rem",
                                marginLeft: "auto",
                                marginRight: "3rem"
                            }}
                        />
                        <Button
                            type={"primary"}
                            disabled={
                                !editedWWW || editedWWW === defaultWWWTitle
                            }
                            onClick={() =>
                                setDefaultTitle({
                                    type: "www",
                                    value: editedWWW
                                })
                            }
                        >
                            Save
                        </Button>
                    </List.Item>

                    <List.Item>
                        <h3>EBI Feedback Title</h3>
                        <Input
                            value={editedEBI}
                            onChange={(e) => setEditedEBI(e.target.value)}
                            style={{
                                maxWidth: "15rem",
                                marginLeft: "auto",
                                marginRight: "3rem"
                            }}
                        />
                        <Button
                            type={"primary"}
                            disabled={
                                !editedEBI || editedEBI === defaultEbiTitle
                            }
                            onClick={() =>
                                setDefaultTitle({
                                    type: "ebi",
                                    value: editedEBI
                                })
                            }
                        >
                            Save
                        </Button>
                    </List.Item>
                </List>
                <List size="large" className="grid-settings">
                    <List.Item>
                        <Card
                            title={<h3>2x2 Starter Recall Tags</h3>}
                            bordered={false}
                            className={"defaultTagGridContainer"}
                        >
                            <div className={"defaultTagGrid two"}>
                                {Array.from({ length: 4 }, (v, k) => k + 1).map(
                                    getTagCell(false)
                                )}
                            </div>
                        </Card>
                    </List.Item>
                    <List.Item>
                        <Card
                            title={<h3>3x2 Starter Recall Tags</h3>}
                            bordered={false}
                            className={"defaultTagGridContainer"}
                        >
                            <div className={"defaultTagGrid three"}>
                                {Array.from({ length: 6 }, (v, k) => k + 1).map(
                                    getTagCell(true)
                                )}
                            </div>
                        </Card>
                    </List.Item>
                </List>
            </div>
        </div>
    );
}

export default PreferencesForm;
