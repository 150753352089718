import * as React from "react";

const SvgTimetableIconAlt = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fill="#fff"
            fillOpacity={0.6}
            fillRule="evenodd"
            d="M10 6.667A2 2 0 0 0 11.886 4h8.228a2 2 0 1 0 3.772 0h2.78a2.667 2.667 0 0 1 2.667 2.667V24a4 4 0 0 1-4 4H6.667a4 4 0 0 1-4-4V6.667A2.667 2.667 0 0 1 5.333 4h2.78A2 2 0 0 0 10 6.667Z"
            clipRule="evenodd"
        />
        <path
            fill="#fff"
            d="M8.667 16a2 2 0 1 1 0-4h14.666a2 2 0 1 1 0 4H8.667ZM8.667 22.666a2 2 0 1 1 0-4H18a2 2 0 1 1 0 4H8.667Z"
        />
    </svg>
);

export default SvgTimetableIconAlt;
