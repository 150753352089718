import { Tag } from "antd";
import React from "react";

export const colors = {
    "red-1": "#fff1f0",
    "red-2": "#ffccc7",
    "red-3": "#ffa39e",
    "red-4": "#ff7875",
    "red-5": "#ff4d4f",
    "red-6": "#f5222d",
    "red-7": "#cf1322",
    "red-8": "#a8071a",
    "red-9": "#a8071a",
    "red-10": "#5c0011",

    "volcano-1": "#fff2e8",
    "volcano-2": "#ffd8bf",
    "volcano-3": "#ffbb96",
    "volcano-4": "#ff9c6e",
    "volcano-5": "#ff7a45",
    "volcano-6": "#fa541c",
    "volcano-7": "#d4380d",
    "volcano-8": "#ad2102",
    "volcano-9": "#ad2102",
    "volcano-10": "#610b00",

    "orange-1": "#fff7e6",
    "orange-2": "#ffe7ba",
    "orange-3": "#ffd591",
    "orange-4": "#ffc069",
    "orange-5": "#ffa940",
    "orange-6": "#fa8c16",
    "orange-7": "#d46b08",
    "orange-8": "#ad4e00",
    "orange-9": "#873800",
    "orange-10": "#612500",

    "gold-1": "#fffbe6",
    "gold-2": "#fff1b8",
    "gold-3": "#ffe58f",
    "gold-4": "#ffd666",
    "gold-5": "#ffc53d",
    "gold-6": "#faad14",
    "gold-7": "#d48806",
    "gold-8": "#ad6800",
    "gold-9": "#874d00",
    "gold-10": "#613400",

    "yellow-1": "#feffe6",
    "yellow-2": "#ffffb8",
    "yellow-3": "#fffb8f",
    "yellow-4": "#fff566",
    "yellow-5": "#ffec3d",
    "yellow-6": "#fadb14",
    "yellow-7": "#d4b106",
    "yellow-8": "#ad8b00",
    "yellow-9": "#876800",
    "yellow-10": "#614700",

    "lime-1": "#fcffe6",
    "lime-2": "#f4ffb8",
    "lime-3": "#eaff8f",
    "lime-4": "#d3f261",
    "lime-5": "#bae637",
    "lime-6": "#a0d911",
    "lime-7": "#7cb305",
    "lime-8": "#5b8c00",
    "lime-9": "#3f6600",
    "lime-10": "#254000",

    "green-1": "#f6ffed",
    "green-2": "#d9f7be",
    "green-3": "#b7eb8f",
    "green-4": "#95de64",
    "green-5": "#73d13d",
    "green-6": "#52c41a",
    "green-7": "#389e0d",
    "green-8": "#237804",
    "green-9": "#135200",
    "green-10": "#092b00",

    "cyan-1": "#e6fffb",
    "cyan-2": "#b5f5ec",
    "cyan-3": "#87e8de",
    "cyan-4": "#5cdbd3",
    "cyan-5": "#36cfc9",
    "cyan-6": "#13c2c2",
    "cyan-7": "#08979c",
    "cyan-8": "#006d75",
    "cyan-9": "#00474f",
    "cyan-10": "#00474f",

    "blue-1": "#e6f3ff",
    "blue-2": "#bdddff",
    "blue-3": "#94c4ff",
    "blue-4": "#6ba9ff",
    "blue-5": "#4085f5",
    "blue-6": "#1660e7",
    "blue-7": "#0a44c2",
    "blue-8": "#002c9c",
    "blue-9": "#001d75",
    "blue-10": "#00114f",

    "geekblue-1": "#f0f5ff",
    "geekblue-2": "#d6e4ff",
    "geekblue-3": "#adc6ff",
    "geekblue-4": "#85a5ff",
    "geekblue-5": "#597ef7",
    "geekblue-6": "#2f54eb",
    "geekblue-7": "#1d39c4",
    "geekblue-8": "#10239e",
    "geekblue-9": "#061178",
    "geekblue-10": "#030852",

    "purple-1": "#f9f0ff",
    "purple-2": "#efdbff",
    "purple-3": "#d3adf7",
    "purple-4": "#b37feb",
    "purple-5": "#9254de",
    "purple-6": "#722ed1",
    "purple-7": "#531dab",
    "purple-8": "#391085",
    "purple-9": "#22075e",
    "purple-10": "#120338",

    "magenta-1": "#fff0f6",
    "magenta-2": "#ffd6e7",
    "magenta-3": "#ffadd2",
    "magenta-4": "#ff85c0",
    "magenta-5": "#f759ab",
    "magenta-6": "#eb2f96",
    "magenta-7": "#c41d7f",
    "magenta-8": "#9e1068",
    "magenta-9": "#780650",
    "magenta-10": "#520339",

    "gray-1": "#ffffff",
    "gray-2": "#fafafa",
    "gray-3": "#f5f5f5",
    "gray-4": "#f0f0f0",
    "gray-5": "#d9d9d9",
    "gray-6": "#bfbfbf",
    "gray-7": "#8c8c8c",
    "gray-8": "#595959",
    "gray-9": "#434343",
    "gray-10": "#262626",
    "gray-11": "#1f1f1f",
    "gray-12": "#141414",
    "gray-13": "#000000"
};

export const tagColoursContinuous = [
    "red",
    "orange",
    "yellow",
    "green",
    "cyan",
    "blue",
    "purple",
    "pink"
];

export const getShuffledTagColoursContinuous = () =>
    tagColoursContinuous
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);

export const tagColoursDiscrete = [
    "cyan",
    "red",
    "green",
    "volcano",
    "blue",
    "magenta",
    "orange",
    "purple",
    "lime",
    "gray",
    "yellow",
    "geekblue",
    "pink"
];

export const saveColoursDiscrete = [
    "orange",
    "purple",
    "cyan",
    "red",
    "green",
    "pink",
    "yellow",
    "blue"
];

// Takes a value and an array and returns a colour tag corresponding to the position of the value within the array
// E.g. value = "Number" arrayOfItems = List of topic area names
export function getTagFromArray(value, arrayOfItems, reverse = false) {
    if (value === null || value === undefined) {
        return null;
    }
    const index = arrayOfItems.indexOf(value);
    const colString = getTagColourStringFromArray(value, arrayOfItems, reverse);
    return index >= 0 ? (
        <Tag color={colString} key={"t-" + index}>
            {value}
        </Tag>
    ) : (
        <Tag>{value}</Tag>
    );
}

// Takes a value and an array and returns a colour corresponding to the position of the value within the array
// E.g. value = "Number" arrayOfItems = List of topic area names
export function getTagColourStringFromArray(
    value,
    arrayOfItems,
    reverse = false
) {
    const numCols = tagColoursDiscrete.length;
    const index = arrayOfItems.indexOf(value);
    return tagColoursDiscrete[
        // Allows for both positive and negative (reverse) modulo wrapping
        (numCols + ((reverse ? numCols - 1 - index : index) % numCols)) %
            numCols
    ];
}

// Takes a value and an array and returns a colour corresponding to the position of the value within the array
// E.g. value = "Number" arrayOfItems = List of topic area names
export function getSaveColourStringFromArray(
    value,
    arrayOfItems,
    reverse = false
) {
    const numCols = saveColoursDiscrete.length;
    const index = arrayOfItems.indexOf(value);
    return saveColoursDiscrete[
        // Allows for both positive and negative (reverse) modulo wrapping
        (numCols + ((reverse ? numCols - 1 - index : index) % numCols)) %
            numCols
    ];
}

export function getColoursObjectFromArray(
    value,
    arrayOfItems,
    reverse = false
) {
    const colString = getTagColourStringFromArray(value, arrayOfItems, reverse);
    return [...Array(11).keys()].map((i) => colors[`${colString}-${i}`]);
}

export function areRecallTagsEqual(tag1, tag2) {
    return Boolean(tag1?.colour === tag2?.colour && tag1?.name === tag2?.name);
}
