import * as React from "react";

const SvgNewStarterIcon = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            clipPath="url(#a)"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="currentColor"
        >
            <path
                d="M22 19a2 2 0 0 1-2 2h-8v-4a1 1 0 0 0 1-1v-3h3a1 1 0 0 0 0-2h-3V8a1 1 0 0 0-1-1V3h8a2 2 0 0 1 2 2v14Z"
                fillOpacity={0.6}
            />
            <path d="M2 19a2 2 0 0 0 2 2h8v-4a1 1 0 0 1-1-1v-3H8a1 1 0 1 1 0-2h3V8a1 1 0 0 1 1-1V3H4a2 2 0 0 0-2 2v14Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="currentColor" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default SvgNewStarterIcon;
