import React, { useContext, useEffect, useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import ExtensionQuestion from "./ExtensionQuestion";
import { getStepIndex, TourContext } from "features/tour/Tour";

function ExtensionModal({
    isModalVisible,
    setIsModalVisible,
    fullscreen,
    questionFont,
    senBg,
    questionTopicChoices,
    extensionTopicChoices,
    setExtensionTopicChoices,
    randomSeed,
    setRandomSeed
}) {
    const tourContext = useContext(TourContext);
    const [showSketch, setShowSketch] = useState(false);

    const [modalWidth, setModalWidth] = useState(456);
    const [modalHeight, setModalHeight] = useState(300);
    const [modalLeft, setModalLeft] = useState(10);

    useEffect(updateModalSize, [fullscreen, tourContext.isOpen]);

    function updateModalSize() {
        const viewWidth = window.innerWidth;
        const sideBarWidth = 0;
        const rightBarWidth = 50;
        const gridGap = 0;
        const questionWidth =
            (viewWidth - sideBarWidth - rightBarWidth) / 3 - 2 * gridGap;
        const leftPadding = 0;
        setModalWidth(Math.max(questionWidth, 300));
        setModalHeight(
            !tourContext.isOpen
                ? window.innerHeight / 2 - 52
                : window.innerHeight / 2 - 120
        );
        setModalLeft(
            Math.min(
                -viewWidth / 2 + sideBarWidth + questionWidth / 2 + leftPadding,
                viewWidth / 2 - 1.5 * questionWidth - leftPadding
            )
        );
    }

    window.addEventListener("resize", updateModalSize);

    return (
        <Modal
            style={{ top: "100px", left: `${modalLeft}px`, cursor: "grab" }}
            bodyStyle={{
                height: `${modalHeight}px`,
                display: "flex"
            }}
            width={`${modalWidth}px`}
            mask={false}
            closable={true}
            open={isModalVisible}
            className={"extension modal"}
            onCancel={() => {
                if (
                    tourContext.isOpen &&
                    tourContext.currentStep === getStepIndex("extensionClose")
                ) {
                    tourContext.nextStep();
                }
                setIsModalVisible(false);
            }}
            footer={null}
            modalRender={(modal) => (
                <Draggable disabled={showSketch}>{modal}</Draggable>
            )}
        >
            <div className={"extension-card"}>
                <ExtensionQuestion
                    questionFont={questionFont}
                    senBg={senBg}
                    fullscreen={fullscreen}
                    questionTopicChoices={questionTopicChoices}
                    extensionTopicChoices={extensionTopicChoices}
                    setExtensionTopicChoices={setExtensionTopicChoices}
                    currentNumberOfQuestions={6}
                    isModal={true}
                    modalShowSketch={showSketch}
                    setModalShowSketch={setShowSketch}
                />
            </div>
        </Modal>
    );
}

export default ExtensionModal;
