import { Button } from "antd";
import SaveDetailsForm from "./SaveDetailsForm";
import { useCallback, useContext } from "react";
import BaseLessonTypeModal from "./BaseLessonTypeModal";
import LessonContext from "../../context/LessonContext";

function AutoStarterSaveModal({
    open,
    setOpen,
    saveCurrentStarterAsNew,
    onStarterSave
}) {
    const { isSaveable } = useContext(LessonContext);
    const handleNewSaveFromForm = useCallback(
        ({ name, classId }) => {
            saveCurrentStarterAsNew(name, classId, null, onStarterSave);
        },
        [saveCurrentStarterAsNew, onStarterSave]
    );

    return (
        <BaseLessonTypeModal
            open={open}
            setOpen={setOpen}
            title={"Close the gap starter"}
        >
            <div>
                <p>
                    Use at the beginning of the lesson you plan to hand back the
                    exit ticket.
                </p>
                <ul>
                    <li>
                        Students should focus on the EQ questions relevant to
                        them.
                    </li>
                </ul>
            </div>
            {isSaveable && (
                <div>
                    <SaveDetailsForm
                        defaultNamePrefix={"Exit Ticket: "}
                        isDropdown={false}
                        onSaveNew={handleNewSaveFromForm}
                    />
                </div>
            )}
            <Button type={"default"} danger onClick={() => setOpen(false)}>
                I don't want to save this lesson template
            </Button>
        </BaseLessonTypeModal>
    );
}

export default AutoStarterSaveModal;
