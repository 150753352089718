import { createContext, useContext, useEffect, useRef, useState } from "react";
import { message, Switch } from "antd";
import { apiPut } from "helpers/api";
import { withCookies } from "react-cookie";
import { TourContext } from "features/tour/Tour";

export const TwoWeekContext = createContext({ weekBSelected: false });

export function TwoWeekContextProvider({
    active = true,
    initWeekBSelected,
    children
}) {
    const mondayARefs = useRef([]);
    const mondayBRefs = useRef([]);
    const [weekBSelected, setWeekBSelected] = useState(initWeekBSelected);

    useEffect(() => {
        setWeekBSelected(initWeekBSelected);
    }, [initWeekBSelected]);

    return (
        <TwoWeekContext.Provider
            value={{
                weekBSelected,
                setWeekBSelected,
                mondayARefs,
                mondayBRefs,
                syncWeekScroll: (inline = undefined, timeout = 0) => {
                    if (!active) {
                        return;
                    }
                    const refs = weekBSelected ? mondayBRefs : mondayARefs;
                    if (!refs?.current || refs.current.length === 0) {
                        return;
                    }
                    new Promise((res) => setTimeout(res, timeout)).then(() =>
                        refs.current.forEach((el) => {
                            if (weekBSelected) {
                                el.parentNode.scroll({
                                    left: el.scrollWidth,
                                    behavior: "smooth"
                                });
                            } else {
                                el.parentNode.scroll({
                                    left: 0,
                                    behavior: "smooth"
                                });
                            }
                        })
                    );
                },
                switchToWeekA: () => {
                    if (!active) {
                        return;
                    }
                    setWeekBSelected(false);
                },
                switchToWeekB: () => {
                    if (!active) {
                        return;
                    }
                    setWeekBSelected(true);
                }
            }}
        >
            {children}
        </TwoWeekContext.Provider>
    );
}

export const WeekABSwitch = withCookies(function SwitchWithCookies({
    cookies
}) {
    const { weekBSelected, switchToWeekA, switchToWeekB } =
        useContext(TwoWeekContext);
    const tourContext = useContext(TourContext);

    const [csrfToken] = useState(cookies.get("XSRF-TOKEN"));

    return (
        <div className={"timetableControlContainer weekSwitchContainer"}>
            <span className={!weekBSelected ? "selected" : ""}>WEEK A</span>
            <Switch
                checked={weekBSelected}
                disabled={tourContext.isOpen}
                onChange={(bSelected) => {
                    const hideLoading = message.loading("Saving", 0);
                    bSelected ? switchToWeekB() : switchToWeekA();
                    // not handled by RTK Query since week-b state is managed by content
                    apiPut(
                        "/api/set_week_b?value=" +
                            encodeURIComponent(bSelected),
                        {},
                        csrfToken,
                        () => {
                            hideLoading();
                        }
                    );
                }}
            />
            <span className={weekBSelected ? "selected" : ""}>WEEK B</span>
        </div>
    );
});
