import React, { useEffect, useState } from "react";
import {
    Route,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch
} from "react-router-dom";
import { Badge, Button, message, Row, Spin } from "antd";
import NewAccountForm from "./components/NewAccountForm";
import CreateAccountDraftsList from "./components/CreateAccountDraftsList";
import ManageAccountsPage from "./components/ManageAccountsPage";
import { apiGet } from "helpers/api";
import AccountReferralsPage from "./components/AccountReferralsPage";
import AccountLookupPage from "./components/AccountLookupPage";

function UserAdminDashboardControls() {
    let match = useRouteMatch();
    const [drafts, setDrafts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        apiGet(
            "/api/draft-users",
            (body) => {
                setDrafts(body);
                setLoading(false);
            },
            (err) => {
                message.error(err.message);
                setLoading(false);
            }
        );
    }, []);

    return (
        <div className="content-creator-container">
            <section className="content-welcome">
                <div className="welcome--text">
                    <h1>User Accounts Portal</h1>
                </div>
            </section>
            <section className="content-col">
                <div className="content-col-left">
                    <div className="create-content">
                        <div className="content-new-manage">
                            <p>New Account</p>
                            <div className="content--nav">
                                <a
                                    className="content--btn"
                                    href={`${match.url}/new`}
                                >
                                    CREATE NEW
                                </a>
                                <a
                                    className="content--btn-2"
                                    href={`${match.url}/drafts`}
                                >
                                    <Badge
                                        color={"orange"}
                                        offset={[-4, 8]}
                                        count={
                                            loading ? <Spin /> : drafts?.length
                                        }
                                    >
                                        PENDING...
                                    </Badge>
                                </a>
                            </div>
                        </div>

                        <div className="content-new-manage">
                            <p>Manage Accounts</p>
                            <div className="content--nav">
                                <a
                                    className="content--btn"
                                    href={`${match.url}/manage`}
                                >
                                    OPEN
                                </a>
                            </div>
                        </div>

                        <div className="content-new-manage">
                            <p>ID Lookup</p>
                            <div className="content--nav">
                                <a
                                    className="content--btn"
                                    href={`${match.url}/lookup`}
                                >
                                    OPEN
                                </a>
                            </div>
                        </div>

                        <div className="content-new-manage">
                            <p>Referrals Scheme</p>
                            <div className="content--nav">
                                <a
                                    className="content--btn"
                                    href={`${match.url}/referrals`}
                                >
                                    OPEN
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

function UserAdminDashboard() {
    let match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    function BackButton(props) {
        return match.isExact ? null : (
            <Button onClick={history.goBack} {...props}>
                Back
            </Button>
        );
    }

    return (
        <>
            <Row>
                <BackButton type="secondary" />
            </Row>
            <Switch>
                <Route path={`${match.path}/new`}>
                    <NewAccountForm />
                </Route>
                <Route path={`${match.path}/edit`}>
                    <NewAccountForm
                        editEmail={new URLSearchParams(location.search).get(
                            "email"
                        )}
                    />
                </Route>
                <Route path={`${match.path}/drafts`}>
                    <CreateAccountDraftsList pathName={match.path} />
                </Route>
                <Route path={`${match.path}/manage`}>
                    <ManageAccountsPage />
                </Route>
                <Route path={`${match.path}/lookup`}>
                    <AccountLookupPage />
                </Route>
                <Route path={`${match.path}/referrals`}>
                    <AccountReferralsPage />
                </Route>
                <Route path={match.path} exact={true}>
                    <UserAdminDashboardControls />
                </Route>
            </Switch>
        </>
    );
}

export default UserAdminDashboard;
