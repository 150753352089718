import * as React from "react";

const SvgReportIssueIcon = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8 6a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z"
            fill="currentColor"
            fillOpacity={0.6}
        />
        <path
            d="M6 13H4a1 1 0 1 1 0-2H6.34a6.003 6.003 0 0 1 11.317 0H20a1 1 0 0 1 0 2h-2v3c0 .093-.002.185-.006.277l2.322.774a1 1 0 1 1-.633 1.898l-2.116-.706A6.008 6.008 0 0 1 13 21.917V11a1 1 0 0 0-2 0v10.917a6.008 6.008 0 0 1-4.567-3.674l-2.117.706a1 1 0 0 1-.632-1.898l2.322-.774A6.101 6.101 0 0 1 6 16v-3Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgReportIssueIcon;
