import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import debounce from "lodash.debounce";
import { Button, message, Tooltip } from "antd";
import { useLocation } from "react-router-dom";
import { ColourTags } from "features/classTags/ClassNotesModal";
import { tooltipColour } from "helpers/starterHelpers";
import Icon, {
    BorderOutlined,
    LineOutlined,
    ZoomInOutlined,
    ZoomOutOutlined
} from "@ant-design/icons";
import SvgIconEdit from "assets/icons/IconEdit";
import EmptyCircle from "assets/icons/EmptyCircle";
import SvgIconUndo from "assets/icons/IconUndo";
import SvgIconRedo from "assets/icons/IconRedo";
import SvgIconGrid from "assets/icons/IconGrid";
import SvgIconDelete from "assets/icons/IconDelete";
import { Circle, Layer, Line, Rect, Stage } from "react-konva";
import { useDispatch, useSelector } from "react-redux";
import {
    calculateCanvasArea,
    INITIAL_CANVAS_AREA,
    maxCanvasAreaSelector
} from "helpers/utilsSlice";

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function useLocalStorage(name, initialValue) {
    const [state, setState] = useState(
        () => JSON.parse(localStorage.getItem(name)) || initialValue
    );
    useEffect(
        () => localStorage.setItem("questions", JSON.stringify(state)),
        [state]
    );
    return [state, setState];
}

/**
 * Use for the local state of a controlled <input/> component which mirrors a property in a Starter which is autosaved
 * E.g. [title, setTitle]
 *
 * @param parentProperty The parent value in Starter which triggers an autosave when changed
 * @param setParentProperty The setter for parentProperty
 * @param setStaleEdits Function to trigger autosave
 * @param delay Delay before triggering sync with parent
 * @returns [localProperty, inputOnChangeEventHandler]
 */
export function useStarterAutosavedInputProperty(
    parentProperty,
    setParentProperty,
    setStaleEdits,
    delay = 1000
) {
    const [localProperty, setLocalProperty] = useState(parentProperty);
    const debouncedSetLocalProperty = useMemo(
        () =>
            debounce((val) => {
                setParentProperty(val);
                setStaleEdits(true);
            }, delay),
        [setParentProperty, setStaleEdits, delay]
    );

    // If parent value changes, then update local value to match
    useEffect(() => {
        setLocalProperty(parentProperty);
        debouncedSetLocalProperty.cancel();
    }, [parentProperty, debouncedSetLocalProperty]);

    // When local value changes, sync with parent
    useEffect(
        () => {
            if (localProperty !== parentProperty && localProperty) {
                debouncedSetLocalProperty(localProperty);
            }
        },
        // eslint-disable-next-line
        [debouncedSetLocalProperty, localProperty]
    );

    return [
        localProperty,
        (e) => {
            if (e.target.value === "") {
                setLocalProperty(e.target.value);
                setParentProperty(e.target.value);
                setStaleEdits(true);
            } else {
                setLocalProperty(e.target.value);
            }
        }
    ];
}

const usePrevious = (value, initialValue) => {
    const ref = useRef(initialValue);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

/**
 * From https://stackoverflow.com/a/59843241
 */
export const useEffectDebugger = (
    effectHook,
    dependencies,
    dependencyNames = [],
    hookName = undefined
) => {
    const previousDeps = usePrevious(dependencies, []);

    const changedDeps = dependencies.reduce((accum, dependency, index) => {
        if (dependency !== previousDeps[index]) {
            const keyName = dependencyNames[index] || index;
            return {
                ...accum,
                [keyName]: {
                    before: previousDeps[index],
                    after: dependency
                }
            };
        }

        return accum;
    }, {});

    if (Object.keys(changedDeps).length) {
        console.log(
            "[use-effect-debugger]{" + (hookName || "") + "} ",
            changedDeps
        );
    }

    useEffect(effectHook, [...dependencies, effectHook]);
};

/**
 * Displays a single loading spinner with optional message (allows prevention of duplicated spinners for multiple
 * in-flight requests to the same endpoint)
 *
 * @param key {string | number} a unique key to ensure only 1 spinner is displayed per hook (e.g. 1 spinner per API
 *                              endpoint)
 * @param messageContent {any} the content to show in the loading message
 * @returns {[((function(): void)|*),((function(): void)|*)]} [registerNewRequest, resolveRequest]
 */
export const useLoadingMessage = (key, messageContent = null) => {
    const inFlightRequests = useRef([]);
    const loadingMessageKey = "loadingMessage" + key;

    const checkInFlightRequests = useCallback(() => {
        if (inFlightRequests.current.length) {
            message.open({
                key: loadingMessageKey,
                type: "loading",
                content: messageContent,
                duration: 0
            });
        } else {
            message.destroy(loadingMessageKey);
        }

        return () => message.destroy(loadingMessageKey);
    }, [inFlightRequests, loadingMessageKey, messageContent]);

    const dispatchInFlightRequest = useCallback(() => {
        inFlightRequests.current.push([true]);
        checkInFlightRequests();
    }, [inFlightRequests, checkInFlightRequests]);

    const resolveInFlightRequest = useCallback(() => {
        inFlightRequests.current.pop();
        checkInFlightRequests();
    }, [inFlightRequests, checkInFlightRequests]);

    return [dispatchInFlightRequest, resolveInFlightRequest];
};

export const useSketch = ({
    resetArgs = [],
    children = undefined,
    height = null
}) => {
    const [tool, setTool] = useState("free");
    const [lines, setLines] = useState([]);
    const [colour, setColour] = useState("black");
    const [showGrid, setShowGrid] = useState(false);
    const [historyPosition, setHistoryPosition] = useState(0);
    const isDrawing = useRef(false);
    const [scale, setScale] = useState(1.0);

    const reset = useCallback(() => {
        setLines(() => []);
        setHistoryPosition(0);
        isDrawing.current = false;
    }, []);

    useEffect(reset, [reset, ...resetArgs]);

    const handleMouseDown = (e) => {
        isDrawing.current = true;
        const pos = e.target.getStage().getPointerPosition();
        const historyPos = historyPosition;
        setLines((prevState) => [
            ...prevState.slice(0, historyPos),
            { tool, points: [pos.x / scale, pos.y / scale], colour }
        ]);
        setHistoryPosition((prevState) => prevState + 1);
    };

    const handleMouseMove = (e) => {
        // no drawing - skipping
        if (!isDrawing.current) {
            return;
        }
        const stage = e.target.getStage();
        const point = stage.getPointerPosition();
        setLines((prevState) => {
            let lastLine = prevState[prevState.length - 1];
            if (lastLine.tool === "free") {
                lastLine.points = lastLine.points.concat([
                    point.x / scale,
                    point.y / scale
                ]);
                prevState.splice(prevState.length - 1, 1, lastLine);
                return prevState.concat();
            } else if (
                lastLine.tool === "line" ||
                lastLine.tool === "circle" ||
                lastLine.tool === "rectangle"
            ) {
                if (lastLine.points.length > 2) {
                    lastLine.points = [
                        lastLine.points[0],
                        lastLine.points[1],
                        point.x / scale,
                        point.y / scale
                    ];
                } else {
                    lastLine.points = lastLine.points.concat([
                        point.x / scale,
                        point.y / scale
                    ]);
                }
                prevState.splice(prevState.length - 1, 1, lastLine);
                return prevState.concat();
            }
            return prevState;
        });
    };

    const handleMouseUp = () => {
        isDrawing.current = false;
    };

    function getCircleProps(points) {
        const centreX = (points[2] + points[0]) / 2;
        const centreY = (points[3] + points[1]) / 2;
        const xDiff = points[2] - points[0];
        const yDiff = points[3] - points[1];
        const radius = Math.sqrt(xDiff * xDiff + yDiff * yDiff) / 2;
        return {
            x: centreX,
            y: centreY,
            radius
        };
    }

    function undo() {
        setHistoryPosition((prev) => Math.max(prev - 1, 0));
    }

    function redo() {
        setHistoryPosition((prev) => Math.min(prev + 1, lines.length));
    }

    function zoomIn() {
        setScale((prev) => Math.min(2, prev + 0.1));
    }

    function zoomOut() {
        setScale((prev) => Math.max(0.5, prev - 0.1));
    }

    const whiteboard = (
        <Stage
            width={window?.innerWidth || 1000}
            height={height ?? 2 * (window?.innerHeight || 1000)}
            scaleX={scale}
            scaleY={scale}
            onMouseDown={handleMouseDown}
            onTouchStart={handleMouseDown}
            onMousemove={handleMouseMove}
            onTouchMove={handleMouseMove}
            onMouseup={handleMouseUp}
            onTouchEnd={handleMouseUp}
            style={{
                backgroundRepeat: "repeat",
                backgroundSize: "50% auto",
                backgroundImage: showGrid
                    ? `url(${process.env.PUBLIC_URL + "/svgs/grid-paper.jpg"})`
                    : "none"
            }}
        >
            <Layer>{children}</Layer>
            <Layer>
                {lines.slice(0, historyPosition).map((line, i) => {
                    switch (line.tool) {
                        case "free":
                        case "eraser":
                        case "line":
                            return line.points.length < 4 ? null : (
                                <Line
                                    key={i}
                                    points={line.points}
                                    stroke={line.colour}
                                    strokeWidth={4}
                                    tension={0.5}
                                    lineCap="round"
                                    globalCompositeOperation={
                                        line.tool === "eraser"
                                            ? "destination-out"
                                            : "source-over"
                                    }
                                />
                            );
                        case "circle":
                            return line.points.length < 4 ? null : (
                                <Circle
                                    key={i}
                                    {...getCircleProps(line.points)}
                                    stroke={line.colour}
                                    strokeWidth={4}
                                    fillEnabled={false}
                                    lineCap="round"
                                />
                            );
                        case "rectangle":
                            return line.points.length < 4 ? null : (
                                <Rect
                                    key={i}
                                    x={line.points[0]}
                                    y={line.points[1]}
                                    width={line.points[2] - line.points[0]}
                                    height={line.points[3] - line.points[1]}
                                    stroke={line.colour}
                                    strokeWidth={4}
                                    fillEnabled={false}
                                    lineCap="round"
                                />
                            );
                        default:
                            return null;
                    }
                })}
            </Layer>
        </Stage>
    );

    const controls = {
        colours: (
            <div className={"colourOptions"}>
                <ColourTags
                    colour={colour}
                    onClick={(col) => setColour(col)}
                    options={["black", "red", "blue", "green", "white"]}
                />
            </div>
        ),
        pen: (
            <Tooltip title="Freehand Pen" color={tooltipColour} placement="top">
                <Button
                    type={tool === "free" ? "primary" : "text"}
                    onClick={() => setTool("free")}
                >
                    <Icon component={() => <SvgIconEdit />} />
                </Button>
            </Tooltip>
        ),
        line: (
            <Tooltip title="Line Tool" color={tooltipColour} placement="top">
                <Button
                    type={tool === "line" ? "primary" : "text"}
                    onClick={() => setTool("line")}
                >
                    <LineOutlined />
                </Button>
            </Tooltip>
        ),
        circle: (
            <Tooltip title="Circle Tool" color={tooltipColour} placement="top">
                <Button
                    type={tool === "circle" ? "primary" : "text"}
                    onClick={() => setTool("circle")}
                >
                    <Icon component={EmptyCircle} />
                </Button>
            </Tooltip>
        ),
        rect: (
            <Tooltip
                title="Rectangle Tool"
                color={tooltipColour}
                placement="top"
            >
                <Button
                    type={tool === "rectangle" ? "primary" : "text"}
                    onClick={() => setTool("rectangle")}
                >
                    <BorderOutlined />
                </Button>
            </Tooltip>
        ),
        grid: (
            <Tooltip
                title={(showGrid ? "Hide" : "Show") + " Grid Background"}
                color={tooltipColour}
                placement="top"
            >
                <Button
                    type={showGrid ? "primary" : "text"}
                    onClick={() => setShowGrid((prevState) => !prevState)}
                >
                    <Icon component={() => <SvgIconGrid />} />
                </Button>
            </Tooltip>
        ),
        undo: (
            <Tooltip title="Undo" color={tooltipColour} placement="top">
                <Button
                    disabled={historyPosition < 1}
                    type={"text"}
                    onClick={undo}
                >
                    <Icon component={() => <SvgIconUndo />} />
                </Button>
            </Tooltip>
        ),
        redo: (
            <Tooltip title="Redo" color={tooltipColour} placement="top">
                <Button
                    type={"text"}
                    disabled={historyPosition >= lines.length}
                    onClick={redo}
                >
                    <Icon component={() => <SvgIconRedo />} />
                </Button>
            </Tooltip>
        ),
        zoomIn: (
            <Tooltip title="Zoom In" color={tooltipColour} placement="top">
                <Button type={"text"} onClick={zoomIn}>
                    <ZoomInOutlined />
                </Button>
            </Tooltip>
        ),
        zoomOut: (
            <Tooltip title="Zoom Out" color={tooltipColour} placement="top">
                <Button type={"text"} onClick={zoomOut}>
                    <ZoomOutOutlined />
                </Button>
            </Tooltip>
        ),
        clear: (
            <Tooltip
                title={"Clear Drawings"}
                color={tooltipColour}
                placement="top"
            >
                <Button type={"text"} onClick={reset}>
                    <Icon component={() => <SvgIconDelete />} />
                </Button>
            </Tooltip>
        )
    };

    return {
        reset,
        whiteboard,
        controls
    };
};

export const useMaxCanvasSize = () => {
    const maxArea = useSelector(maxCanvasAreaSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        if (maxArea === INITIAL_CANVAS_AREA) {
            dispatch(calculateCanvasArea());
        }
    }, [maxArea, dispatch]);

    return {
        maxArea:
            maxArea && maxArea !== INITIAL_CANVAS_AREA ? maxArea : 268435456
    };
};
