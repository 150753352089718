import { Tag } from "antd";
import React from "react";

export default function StatusGroupTag({ groupName }) {
    let colour;
    let group = groupName;
    switch (group) {
        case "paid":
            colour = "green";
            break;
        case "trial":
            colour = "orange";
            break;
        case "awaitingPayment":
            colour = "geekblue";
            group = "awaiting payment";
            break;
        case "admin":
            colour = "cyan";
            break;
        case "expired":
            colour = "red";
            group = "trial expired";
            break;
        default:
            group = groupName;
    }
    return (
        <Tag color={colour} key={group}>
            {group.toUpperCase()}
        </Tag>
    );
}
