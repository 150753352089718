import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import {
    confirmPasswordReset,
    forceNewPassword,
    isEnvironmentContentCreationOnly,
    passwordValidator,
    requestPasswordResetCode,
    signIn
} from "helpers/security";
import { withCookies } from "react-cookie";

function SignInForm({ cookies, subTitleDescription = undefined }) {
    const [step, setStep] = useState(0);
    const [newPasswordUser, setNewPasswordUser] = useState(null);
    const [, setVerificationLoginDetails] = useState(null);

    const isContentEnvironment = isEnvironmentContentCreationOnly();
    const defaultForm = (
        <Form
            requiredMark={false}
            size="large"
            layout="vertical"
            className={"signInForm"}
            onFinish={async (e) => {
                try {
                    const response = await signIn(
                        e.email,
                        e.password,
                        true,
                        "/dashboard"
                    );
                    if (response?.status === "NEW_PASSWORD_REQUIRED") {
                        setNewPasswordUser(response.user);
                        setStep(3);
                    } else if (response?.status === "VERIFICATION_REQUIRED") {
                        setVerificationLoginDetails({
                            email: e.email,
                            password: e.password,
                            isSingle: response.user.isSingleLicence
                        });
                        setStep(4);
                    }
                } catch (err) {
                    message.error("Unable to sign in: " + err.message);
                }
            }}
        >
            <Form.Item
                label="Email"
                name="email"
                rules={[
                    { required: true, message: "Please enter an email" },
                    {
                        type: "email",
                        message: "Please enter a valid email"
                    }
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Password"
                className={"noBottomMargin"}
                name="password"
                rules={[{ required: true, message: "Please enter a password" }]}
            >
                <Input.Password
                    iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                />
            </Form.Item>
            <Form.Item className={"formButtons"}>
                {/* eslint-disable-next-line*/}
                <a onClick={() => setStep(1)}>Forgot password?</a>
            </Form.Item>
            <Form.Item>
                {/*<Button key="cancel" type="primary" onClick={() => setVisible(false)}>Cancel</Button>*/}
                <Button
                    key="submit"
                    type="primary"
                    htmlType="submit"
                    size="large"
                >
                    Log In
                </Button>
            </Form.Item>
        </Form>
    );

    const passwordResetForm = (
        <>
            <p>Please enter your email to request a password reset code.</p>
            <Form
                requiredMark={false}
                className={"requestResetForm"}
                onFinish={async (e) => {
                    try {
                        await requestPasswordResetCode(e.email);
                        setStep(2);
                    } catch (err) {
                        message.error(
                            "Unable to request password reset: " + err.message
                        );
                    }
                }}
            >
                <Form.Item
                    name="email"
                    rules={[
                        { required: true, message: "Please enter an email" },
                        {
                            type: "email",
                            message: "Please enter a valid email"
                        }
                    ]}
                >
                    <Input placeholder="Email" />
                </Form.Item>
                <Form.Item className={"formButtons"}>
                    <Button key="submit" type="primary" htmlType="submit">
                        Request Reset Code
                    </Button>
                </Form.Item>
                <Form.Item className={"formButtons"}>
                    {/* eslint-disable-next-line*/}
                    <a onClick={() => setStep(2)}>I have a code</a>
                </Form.Item>
            </Form>
            <br />
            <p
                className={"ant-form-item-explain"}
                style={{
                    fontSize: "14px",
                    maxWidth: "450px",
                    marginLeft: "auto"
                }}
            >
                Note for sub-accounts: if you are unable to log in for the first
                time with your assigned temporary password, it may have expired.
                Please contact MathsPlanner Support for more information
            </p>
            <Button onClick={() => setStep(0)}>Back to Sign In</Button>
        </>
    );

    const newPasswordForm = (
        <>
            <Form
                requiredMark={false}
                className={"requestResetForm"}
                onFinish={async (e) => {
                    try {
                        await confirmPasswordReset(e.email, e.code, e.password);
                        message.success("Successfully reset password");
                        setStep(0);
                    } catch (err) {
                        message.error(
                            "Unable to reset password: " + err.message
                        );
                    }
                }}
            >
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        { required: true, message: "Please enter an email" },
                        {
                            type: "email",
                            message: "Please enter a valid email"
                        }
                    ]}
                >
                    <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                    name="code"
                    label="Code (from email)"
                    rules={[
                        {
                            required: true,
                            message: "Please enter your verification code"
                        }
                    ]}
                >
                    <Input placeholder="Code" />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="New Password"
                    hasFeedback
                    rules={[
                        { required: true, message: "Password is required" },
                        () => ({
                            validator(_, value) {
                                return passwordValidator(value);
                            }
                        })
                    ]}
                    help={
                        <>
                            {"Your password must contain the following:"}
                            <ul className="ant-form-item-explain">
                                <li>At least 8 characters</li>
                                <li>Numbers</li>
                                <li>Uppercase letters</li>
                                <li>Lowercase letters</li>
                            </ul>
                        </>
                    }
                >
                    <Input.Password
                        placeholder="Password"
                        iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                    />
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    label="Re-enter Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: "Please confirm your password"
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    !value ||
                                    getFieldValue("password") === value
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error(
                                        "The two passwords that you entered do not match"
                                    )
                                );
                            }
                        })
                    ]}
                >
                    <Input.Password
                        placeholder="Re-enter password"
                        iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                    />
                </Form.Item>
                <Form.Item className={"formButtons"}>
                    <Button key="submit" type="primary" htmlType="submit">
                        Set New Password
                    </Button>
                </Form.Item>
                <Form.Item className={"formButtons"}>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => setStep(1)}>Request a new code</a>
                </Form.Item>
            </Form>
            <Button onClick={() => setStep(0)}>Back to Sign In</Button>
        </>
    );

    const forcePasswordForm = (
        <>
            <Form
                requiredMark={false}
                className={"requestResetForm"}
                onFinish={async (e) => {
                    try {
                        await forceNewPassword(newPasswordUser, e.password);
                        window.location.reload();
                    } catch (err) {
                        message.error("Unable to set password: " + err.message);
                    }
                }}
            >
                <Form.Item
                    name="password"
                    label="New Password"
                    hasFeedback
                    rules={[
                        { required: true, message: "Password is required" },
                        () => ({
                            validator(_, value) {
                                return passwordValidator(value);
                            }
                        })
                    ]}
                    help={
                        <>
                            {"Your password must contain the following:"}
                            <ul className="ant-form-item-explain">
                                <li>At least 8 characters</li>
                                <li>Numbers</li>
                                <li>Uppercase letters</li>
                                <li>Lowercase letters</li>
                            </ul>
                        </>
                    }
                >
                    <Input.Password
                        placeholder="Password"
                        iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                    />
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    label="Re-enter Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: "Please confirm your password"
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    !value ||
                                    getFieldValue("password") === value
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error(
                                        "The two passwords that you entered do not match"
                                    )
                                );
                            }
                        })
                    ]}
                >
                    <Input.Password
                        placeholder="Re-enter password"
                        iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                    />
                </Form.Item>
                <Form.Item className={"formButtons"}>
                    <Button key="submit" type="primary" htmlType="submit">
                        Set New Password
                    </Button>
                </Form.Item>
            </Form>
            <Button onClick={() => setStep(0)}>Back to Sign In</Button>
        </>
    );

    function getForm() {
        switch (step) {
            case 0:
                return defaultForm;
            case 1:
                return passwordResetForm;
            case 2:
                return newPasswordForm;
            case 3:
                return forcePasswordForm;
            case 4:
                return verificationForm;
            default:
                return <div>Something went wrong!</div>;
        }
    }

    const verificationForm = (
        <>
            <h2>
                An issue has occurred and we need to verify your email address
                before you can log in. Please contact{" "}
                <a href={"mailto:chloe@mathsplanner.com"}>
                    chloe@mathsplanner.com
                </a>
            </h2>

            <Button onClick={() => setStep(0)}>Back to Sign In</Button>
        </>
    );

    return (
        <>
            {step !== 4 && (
                <>
                    <h2>
                        {step === 0
                            ? isContentEnvironment
                                ? "Login"
                                : "Teacher Login"
                            : "Reset Password"}
                    </h2>
                    {step === 0 && subTitleDescription}
                </>
            )}
            {getForm()}
        </>
    );
}

export default withCookies(SignInForm);
