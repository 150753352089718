import { Spin } from "antd";

export default function LoadingSpinner() {
    return (
        <div
            style={{
                height: "100%",
                display: "grid",
                placeContent: "center"
            }}
        >
            <Spin />
        </div>
    );
}
