import * as React from "react";

const SvgIconLink = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m15.604 15.448-.692-.722.692.722Zm-4.798 4.598.692.722-.692-.723Zm-.003-9.19.693.721-.693-.72Zm-4.816 4.59.694.72-.694-.72Zm.01 4.6.691-.723-.692.723Zm9.607-9.194-.692.723.692-.723ZM8.4 8.551l-.692-.722.692.722Zm4.797-4.597-.691-.722.691.722Zm4.812 4.6.694.72-.694-.72Zm-4.809 4.596.692.722-.692-.722Zm-4.8 0 .692-.722-.692.722Zm9.604-9.196-.692.722.692-.722Zm-3.093 10.772-4.798 4.597 1.384 1.445 4.798-4.598-1.384-1.444Zm-3.76-2.818.344-.33-1.386-1.442-.344.33 1.386 1.442Zm-4.47 4.257.392-.379-1.39-1.438-.392.378 1.39 1.44Zm.006 3.159a2.163 2.163 0 0 1-.007-3.159l-1.389-1.438a4.163 4.163 0 0 0 .012 6.04l1.384-1.444Zm3.426 0c-.941.901-2.485.901-3.426 0l-1.384 1.444c1.715 1.643 4.479 1.643 6.194 0l-1.384-1.445Zm4.798-7.75a2.155 2.155 0 0 1 0 3.152l1.384 1.444a4.154 4.154 0 0 0 0-6.04l-1.384 1.445Zm1.384-1.444c-1.714-1.641-4.475-1.639-6.186.005l1.386 1.442c.938-.9 2.477-.902 3.416-.002l1.384-1.444Zm-7.203-.857 4.797-4.597-1.383-1.444-4.798 4.597 1.384 1.444Zm8.224-1.44-.36.347 1.386 1.44.36-.346-1.386-1.441Zm-4.31 4.119-.498.476 1.384 1.444.497-.476-1.383-1.444Zm-3.914.476a2.157 2.157 0 0 1 0-3.155L7.709 7.829a4.157 4.157 0 0 0 0 6.043l1.384-1.444Zm-1.384 1.444c1.712 1.641 4.472 1.641 6.184 0l-1.384-1.444c-.938.9-2.478.9-3.416 0l-1.384 1.444Zm9.604-9.196c.915.876.916 2.279.004 3.157l1.387 1.44a4.16 4.16 0 0 0-.008-6.041l-1.383 1.444Zm1.384-1.444c-1.714-1.643-4.476-1.643-6.19 0l1.383 1.444c.94-.901 2.482-.901 3.423 0l1.384-1.444Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgIconLink;
