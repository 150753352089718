import { Button, Modal, Table } from "antd";
import SvgIconLink from "assets/icons/IconLink";

function ExternalResourcesModal({
    isVisible,
    setIsModalVisible,
    onClose = () => {}
}) {
    const resources = [
        {
            name: "June 2023 Predicted",
            questionsLink:
                "https://www.mathsplanner.com/wp-content/uploads/2023/05/Edexcel-June-2023-Predicted-Paper-2F-Maths-Planner-1.pdf",
            solutionsLink:
                "https://www.mathsplanner.com/wp-content/uploads/2023/05/Edexcel-June-2023-Predicted-Paper-2F-Maths-Planner-Solutions.pdf",
            level: "Foundation",
            board: "Edexcel",
            paper: "Paper 2"
        },
        {
            name: "June 2023 Predicted",
            questionsLink:
                "https://www.mathsplanner.com/wp-content/uploads/2023/05/Edexcel-June-2023-Predicted-Paper-2H-Maths-Planner-1.pdf",
            solutionsLink:
                "https://www.mathsplanner.com/wp-content/uploads/2023/05/Edexcel-June-2023-Predicted-Paper-2H-Maths-Planner-Solutions.pdf",
            level: "Higher",
            board: "Edexcel",
            paper: "Paper 2"
        },
        {
            name: "June 2023 Predicted",
            questionsLink:
                "https://www.mathsplanner.com/wp-content/uploads/2023/05/AQA-June-2023-Predicted-Paper-2F-Maths-Planner.pdf",
            solutionsLink:
                "https://www.mathsplanner.com/wp-content/uploads/2023/05/AQA-June-2023-Predicted-Paper-2F-Maths-Planner-Solutions.pdf",
            level: "Foundation",
            board: "AQA",
            paper: "Paper 2"
        },
        {
            name: "June 2023 Predicted",
            questionsLink:
                "https://www.mathsplanner.com/wp-content/uploads/2023/05/AQA-June-2023-Predicted-Paper-2H-Maths-Planner.pdf",
            solutionsLink:
                "https://www.mathsplanner.com/wp-content/uploads/2023/05/AQA-June-2023-Predicted-Paper-2H-Maths-Planner-Solutions.pdf",
            level: "Higher",
            board: "AQA",
            paper: "Paper 2"
        },
        {
            name: "June 2023 Predicted",
            questionsLink:
                "https://www.mathsplanner.com/wp-content/uploads/2023/06/3F-Edexcel-June-2023-Predicted-Paper-Maths-Planner.pdf",
            solutionsLink:
                "https://www.mathsplanner.com/wp-content/uploads/2023/06/3F-Edexcel-June-2023-Predicted-Paper-Solutions-Maths-Planner.pdf",
            level: "Foundation",
            board: "Edexcel",
            paper: "Paper 3"
        },
        {
            name: "June 2023 Predicted",
            questionsLink:
                "https://www.mathsplanner.com/wp-content/uploads/2023/06/3H-Edexcel-June-2023-Predicted-Paper-Maths-Planner.pdf",
            solutionsLink:
                "https://www.mathsplanner.com/wp-content/uploads/2023/06/3H-Edexcel-June-2023-Predicted-Paper-Solutions-Maths-Planner.pdf",
            level: "Higher",
            board: "Edexcel",
            paper: "Paper 3"
        }
    ].map((resource) => ({
        ...resource,
        key: `${resource.name}-${resource.board}-${resource.level}-${resource.paper}`
    }));

    const columns = [
        {
            title: "Exam Board",
            dataIndex: "board"
        },
        {
            title: "Name",
            dataIndex: "name"
        },
        {
            title: "",
            dataIndex: "paper"
        },
        {
            title: "Tier",
            dataIndex: "level"
        },
        {
            title: "Paper",
            dataIndex: "questionsLink",
            align: "center",
            render: (link) =>
                link ? (
                    <Button
                        href={link}
                        type={"link"}
                        icon={<SvgIconLink />}
                        target={"_blank"}
                        rel="noreferrer"
                    />
                ) : undefined
        },
        {
            title: "Solutions",
            dataIndex: "solutionsLink",
            align: "center",
            render: (link) =>
                link ? (
                    <Button
                        href={link}
                        type={"link"}
                        icon={<SvgIconLink />}
                        target={"_blank"}
                        rel="noreferrer"
                    />
                ) : undefined
        }
    ];

    return (
        <Modal
            title={null}
            footer={null}
            closable
            width={"fit-content"}
            style={{ maxWidth: "95%" }}
            open={isVisible}
            onCancel={() => {
                setIsModalVisible(false);
                onClose();
            }}
            centered
            className={"external-resources-modal"}
        >
            <Table
                dataSource={resources}
                columns={columns}
                pagination={false}
                onRow={({ questionsLink }) => ({
                    style: questionsLink ? { cursor: "pointer" } : {},
                    onClick: () =>
                        questionsLink && window.open(questionsLink, "_blank")
                })}
            />
        </Modal>
    );
}

export default ExternalResourcesModal;
