import { Modal, Space } from "antd";
import { getImage } from "helpers/questionHelpers";
import React from "react";

function MockPaperQuestionPreviewModal({
    isModalVisible = false,
    onClose,
    questionNum,
    questionData = {}
}) {
    return (
        <Modal
            title={null}
            footer={null}
            closable
            className={"mockPaperPreviewModal"}
            width={"fit-content"}
            style={{ maxWidth: "min(95%, 130rem)" }}
            open={isModalVisible}
            onCancel={() => {
                onClose();
            }}
            centered
        >
            <h1>Question {questionNum} Preview</h1>
            <Space direction={"vertical"} size={"large"}>
                <Space direction={"vertical"} size={"small"}>
                    <b>Question:</b>
                    {questionData?.questionUrls?.[0]
                        ? questionData?.questionUrls.map((q) =>
                              getImage(q, "mockPaperPreviewImage")
                          )
                        : "No image(s) specified"}
                </Space>

                <Space direction={"vertical"} size={"small"}>
                    <b>Mark Scheme:</b>
                    {questionData?.markSchemeUrls?.[0]
                        ? questionData?.markSchemeUrls.map((q) =>
                              getImage(q, "mockPaperPreviewImage")
                          )
                        : "No image(s) specified"}
                </Space>

                <Space direction={"vertical"} size={"small"}>
                    <b>Worked Solution:</b>
                    {questionData?.solutionUrls?.[0]
                        ? questionData?.solutionUrls.map((q) =>
                              getImage(q, "mockPaperPreviewImage")
                          )
                        : "No image(s) specified"}
                </Space>
            </Space>
        </Modal>
    );
}

export default MockPaperQuestionPreviewModal;
