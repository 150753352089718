import { useGetClassesQuery } from "features/classTags/classesSlice";
import { Popover, Tag } from "antd";
import { createSelector } from "@reduxjs/toolkit";
import { PlusOutlined } from "@ant-design/icons";
import React, { useMemo } from "react";

export function EditableClassTag({
    classId,
    showList,
    setShowList,
    onClassSelect = (e, c) => {},
    interactive = true,
    noClassesContent = undefined,
    extraContent = undefined,
    popoverPlacement = "bottom",
    ...classTagProps
}) {
    const { data: classes } = useGetClassesQuery();

    const classesList =
        classes && classes.length > 0 ? (
            <>
                {classes.map((c) => (
                    <div
                        className={"tagWrapper clickable"}
                        key={"modal-" + c.id}
                        data-no-dnd={"true"}
                        onClick={(e) => onClassSelect(e, c)}
                    >
                        <Tag color={c.colour}>{c.name}</Tag>
                    </div>
                ))}
                <div
                    style={{
                        marginTop: "1rem"
                    }}
                >
                    {extraContent}
                </div>
            </>
        ) : (
            <div
                style={{
                    width: "200px",
                    textAlign: "center"
                }}
            >
                <p
                    style={{
                        marginBottom: "1rem",
                        fontSize: "0.9em"
                    }}
                >
                    You have no classes yet!
                </p>
                {noClassesContent}
                <div
                    style={{
                        marginTop: "1rem"
                    }}
                >
                    {extraContent}
                </div>
            </div>
        );

    return (
        <Popover
            overlayClassName={"tagsList"}
            data-no-dnd={"true"}
            content={classesList}
            trigger="click"
            open={interactive && showList}
            onOpenChange={(visible) => setShowList(visible)}
            placement={popoverPlacement}
        >
            {classId && classId !== "null" ? (
                <ClassTag classId={classId} {...classTagProps} />
            ) : (
                <AddClassTagButton
                    onClick={(e) => {
                        if (interactive) {
                            e.stopPropagation();
                            setShowList(true);
                        }
                    }}
                />
            )}
        </Popover>
    );
}

export function ClassTag({ classId, suffix = undefined, ...props }) {
    const selectClassForId = useMemo(() => {
        return createSelector(
            (res) => res.data,
            (res, classId) => classId,
            (classes, classId) =>
                classId &&
                classId !== "null" &&
                classes?.find((c) => c.id === classId)
        );
    }, []);
    const { class: classData } = useGetClassesQuery(undefined, {
        selectFromResult: (result) => ({
            class: selectClassForId(result, classId)
        })
    });

    if (!classData) {
        return null;
    }

    return (
        <Tag color={classData.colour} {...props}>
            {classData.name}
            {suffix}
        </Tag>
    );
}

function AddClassTagButton({ className = undefined, ...props }) {
    return (
        <Tag className={"addClassTag clickable " + className} {...props}>
            <PlusOutlined /> CLASS
        </Tag>
    );
}
