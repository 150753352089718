import * as React from "react";
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState
} from "react";
import SvgIconEdit from "assets/icons/IconEdit";
import { Button, Checkbox, Form, Input, List, Popover, Select } from "antd";
import Icon, {
    DownloadOutlined,
    EditOutlined,
    MinusCircleOutlined,
    PrinterOutlined,
    QuestionCircleOutlined
} from "@ant-design/icons";
import debounce from "lodash.debounce";
import SvgIconWand from "assets/icons/IconWand";
import HelpImageModal from "commonComponents/HelpImageModal";
import {
    useGetUserQuery,
    useSetDefaultFeedbackTitleMutation
} from "features/account/accountSlice";
import { createSelector } from "@reduxjs/toolkit";
import { DEFAULT_TITLES } from "helpers/starterHelpers";

function Card({ title, extraTitle = undefined, colour, children, ...props }) {
    return (
        <div
            className={"card plenary-feedback-card card-w3 card-h1 "}
            {...props}
        >
            <div className={"question-card card__face " + colour}>
                <div className={"plenary-feedback-card-header"}>
                    <h1 className={"plenary-feedback-card-title"}>{title}</h1>
                    {extraTitle}
                </div>
                {children}
            </div>
        </div>
    );
}

function FeedbackTitleHeader({ title, setTitle, setDefault, setStaleEdits }) {
    const [showEditForm, setShowEditForm] = useState(false);
    const editTitleForm = (
        <Form
            initialValues={{ title: title, default: false }}
            className={"editTitleForm"}
            layout={"vertical"}
            onFinish={(formData) => {
                if (formData?.title) {
                    setStaleEdits(true);
                    setTitle(formData.title);
                    if (formData?.default) {
                        setDefault(formData.title);
                    }
                    setShowEditForm(false);
                }
            }}
        >
            <Form.Item name={"title"} required>
                <Input style={{ maxWidth: "15rem" }} />
            </Form.Item>
            <Form.Item name={"default"} valuePropName={"checked"} required>
                <Checkbox>Set as default?</Checkbox>
            </Form.Item>
            <Form.Item>
                <Button key="submit" type="primary" htmlType="submit">
                    Change
                </Button>
            </Form.Item>
        </Form>
    );

    return (
        <>
            {title}
            <Popover
                content={editTitleForm}
                open={showEditForm}
                onOpenChange={setShowEditForm}
                title="Change Feedback Name"
                trigger="click"
            >
                <Button
                    type={"text"}
                    className={"edit-btn"}
                    size={"small"}
                    icon={<SvgIconEdit />}
                />
            </Popover>
        </>
    );
}

function DynamicFeedbackFormCard({
    colour,
    title,
    setTitle,
    setDefaultTitle,
    fieldName,
    onHelp = undefined,
    form,
    formLengths,
    setFormLengths,
    setStaleEdits,
    initFormValues,
    initialLoadedFeedback,
    initialFormLength,
    includeDropdowns = false
}) {
    const maxFields = 6;

    const debouncedSetStaleEdits = useMemo(
        () => debounce(setStaleEdits, 1000),
        [setStaleEdits]
    );

    const updateFormLengths = (formFields) => {
        setFormLengths({
            wwwStatements: formFields?.wwwStatements?.length,
            ebiStatements: formFields?.ebiStatements?.length
        });
    };

    return (
        <Card
            colour={colour}
            title={
                <FeedbackTitleHeader
                    title={title}
                    setTitle={setTitle}
                    setDefault={setDefaultTitle}
                    setStaleEdits={setStaleEdits}
                />
            }
            extraTitle={
                <>
                    {onHelp && <QuestionCircleOutlined onClick={onHelp} />}
                    <Button
                        className={"add-new-btn"}
                        size={"small"}
                        type={"primary"}
                        onClick={() =>
                            form.setFieldsValue(
                                (() => {
                                    const prev = form.getFieldsValue();
                                    const copy = Object.assign({}, prev);
                                    if (copy[fieldName].length < maxFields) {
                                        copy[fieldName].push(
                                            initFormValues[fieldName]
                                        );
                                    }
                                    updateFormLengths(copy);
                                    setStaleEdits(true);
                                    return copy;
                                })()
                            )
                        }
                        disabled={formLengths[fieldName] >= maxFields}
                    >
                        ADD NEW
                    </Button>
                </>
            }
        >
            <Form
                form={form}
                initialValues={{
                    wwwStatements: initialLoadedFeedback?.wwwStatements?.length
                        ? initialLoadedFeedback?.wwwStatements
                        : Array(initialFormLength).fill(
                              initFormValues.wwwStatements
                          ),
                    ebiStatements: initialLoadedFeedback?.ebiStatements?.length
                        ? initialLoadedFeedback?.ebiStatements
                        : Array(initialFormLength).fill(
                              initFormValues.ebiStatements
                          )
                }}
                onValuesChange={(changedFields, formFields) => {
                    debouncedSetStaleEdits(true);
                    if (
                        formFields?.wwwStatements?.length !==
                            formLengths.wwwStatements ||
                        formFields?.ebiStatements?.length !==
                            formLengths.ebiStatements
                    ) {
                        updateFormLengths(formFields);
                    }
                }}
            >
                <Form.List name={fieldName}>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...field }, index) => (
                                <Form.Item
                                    className={"listFormItem"}
                                    key={fieldName + key}
                                    colon={false}
                                >
                                    <Form.Item
                                        {...field}
                                        name={[name, "statement"]}
                                        noStyle
                                    >
                                        <Input
                                            type={"text"}
                                            className={"feedback-input"}
                                            key={"input-" + fieldName + key}
                                            autoComplete={"none"}
                                            data-lpignore="true"
                                        />
                                    </Form.Item>
                                    {includeDropdowns && (
                                        <Form.Item
                                            {...field}
                                            name={[name, "question"]}
                                            key={"dr" + key}
                                            noStyle
                                        >
                                            <Select
                                                className={"feedback-select"}
                                                getPopupContainer={() =>
                                                    document.getElementById(
                                                        "app"
                                                    )
                                                }
                                            >
                                                <Select.Option value={"1"}>
                                                    EQ1
                                                </Select.Option>
                                                <Select.Option value={"2"}>
                                                    EQ2
                                                </Select.Option>
                                                <Select.Option value={"3"}>
                                                    EQ3
                                                </Select.Option>
                                                <Select.Option value={"all"}>
                                                    All Qs
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                    )}
                                    {index > 0 ? (
                                        <Button
                                            icon={<MinusCircleOutlined />}
                                            type={"text"}
                                            onClick={() => {
                                                setStaleEdits(true);
                                                remove(name);
                                            }}
                                        />
                                    ) : (
                                        <span className={"buttonPlaceholder"} />
                                    )}
                                </Form.Item>
                            ))}
                        </>
                    )}
                </Form.List>
            </Form>
            <p className={"bottomText"}>
                There will also be space to hand-write additional comments
            </p>
        </Card>
    );
}

const PlenaryFeedbackCards = forwardRef((props, ref) => {
    const {
        autoDisabled,
        autoGenerateStarter,
        activatePrintingModal,
        initialLoadedFeedback,
        setStaleEdits
    } = props;

    const [visibleHelpSource, setVisibleHelpSource] = useState(null);
    const [setDefaultTitle] = useSetDefaultFeedbackTitleMutation();

    const selectDefaultFeedbackTitlesForUser = useMemo(() => {
        return createSelector(
            (res) => res.data,
            (user) => ({
                www: user?.default_www_title,
                ebi: user?.default_ebi_title
            })
        );
    }, []);
    const {
        titles: { www: defaultWWWTitle, ebi: defaultEbiTitle }
    } = useGetUserQuery(undefined, {
        selectFromResult: (result) => ({
            titles: selectDefaultFeedbackTitlesForUser(result)
        })
    });
    const [wwwTitle, setWwwTitle] = useState(
        initialLoadedFeedback?.wwwTitle || defaultWWWTitle || DEFAULT_TITLES.www
    );
    const [ebiTitle, setEbiTitle] = useState(
        initialLoadedFeedback?.ebiTitle || defaultEbiTitle || DEFAULT_TITLES.ebi
    );
    useEffect(
        () =>
            setWwwTitle(
                initialLoadedFeedback?.wwwTitle ||
                    defaultWWWTitle ||
                    DEFAULT_TITLES.www
            ),
        [defaultWWWTitle, initialLoadedFeedback?.wwwTitle]
    );
    useEffect(
        () =>
            setEbiTitle(
                initialLoadedFeedback?.ebiTitle ||
                    defaultEbiTitle ||
                    DEFAULT_TITLES.ebi
            ),
        [defaultEbiTitle, initialLoadedFeedback?.ebiTitle]
    );
    // const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);

    const [form] = Form.useForm();
    const initialFormLength = 3;
    const [formLengths, setFormLengths] = useState({
        wwwStatements: initialFormLength,
        ebiStatements: initialFormLength
    });
    const initFormValues = useMemo(
        () => ({
            wwwStatements: null,
            ebiStatements: { statement: null, question: "all" }
        }),
        []
    );

    useEffect(
        () =>
            form.setFieldsValue(
                (() => {
                    const prev = form.getFieldsValue();
                    const copy = Object.assign({}, prev);
                    copy.wwwStatements = initialLoadedFeedback?.wwwStatements
                        ?.length
                        ? initialLoadedFeedback?.wwwStatements
                        : Array(initialFormLength).fill(
                              initFormValues.wwwStatements
                          );
                    return copy;
                })()
            ),
        [
            form,
            initialLoadedFeedback?.wwwStatements,
            initFormValues.wwwStatements
        ]
    );
    useEffect(
        () =>
            form.setFieldsValue(
                (() => {
                    const prev = form.getFieldsValue();
                    const copy = Object.assign({}, prev);
                    copy.ebiStatements = initialLoadedFeedback?.ebiStatements
                        ?.length
                        ? initialLoadedFeedback?.ebiStatements
                        : Array(initialFormLength).fill(
                              initFormValues.ebiStatements
                          );
                    return copy;
                })()
            ),
        [
            form,
            initialLoadedFeedback?.ebiStatements,
            initFormValues.ebiStatements
        ]
    );

    useImperativeHandle(ref, () => ({
        getWwwStatements: () => form.getFieldsValue()?.wwwStatements,
        getEbiStatements: () => form.getFieldsValue()?.ebiStatements,
        wwwTitle,
        ebiTitle
    }));

    const explanationContent = (
        <div className={"plenary-feedback-explanation"}>
            <List
                bordered={false}
                dataSource={[
                    {
                        title: "How-to use Maths Planner exit tickets",
                        icon: <QuestionCircleOutlined />,
                        onClick: () =>
                            setVisibleHelpSource(
                                process.env.REACT_APP_IMAGE_DIRECTORY +
                                    "how-to-content/how_to_exit_ticket_system.svg"
                            )
                    },
                    {
                        title: "How-to write actionable feedback statements",
                        icon: <EditOutlined />,
                        onClick: () =>
                            setVisibleHelpSource(
                                process.env.REACT_APP_IMAGE_DIRECTORY +
                                    "how-to-content/how_to_write_effective_feedback_statments.svg"
                            )
                    },
                    {
                        title: "Download Exit Ticket Tracker .xls",
                        icon: <DownloadOutlined />,
                        href:
                            process.env.REACT_APP_IMAGE_DIRECTORY +
                            "how-to-content/Maths%20Planner%20Exit%20Ticket%20Performance%20Analysis.xlsx"
                    },
                    {
                        title: "Print Exit Ticket",
                        icon: <PrinterOutlined />,
                        onClick: activatePrintingModal
                    },
                    {
                        title: "Auto Generate Close the Gap Starter",
                        icon: <Icon component={() => <SvgIconWand />} />,
                        onClick: !autoDisabled
                            ? autoGenerateStarter
                            : undefined,
                        disabled: autoDisabled
                    }
                ]}
                renderItem={({ title, icon, ...buttonProps }) => {
                    return (
                        <List.Item>
                            <List.Item.Meta
                                avatar={icon}
                                title={
                                    <Button type={"link"} {...buttonProps}>
                                        {title}
                                    </Button>
                                }
                            />
                        </List.Item>
                    );
                }}
            />
        </div>
    );

    return (
        <div className="grid-container">
            <div className="question-container">
                <HelpImageModal
                    imgSrc={visibleHelpSource}
                    visible={Boolean(visibleHelpSource)}
                    close={() => setVisibleHelpSource(null)}
                />
                <Card colour={"blue"} title={"EXIT TICKETS QUICK GUIDE"}>
                    {explanationContent}
                </Card>
                <DynamicFeedbackFormCard
                    colour={"teal"}
                    title={wwwTitle}
                    setTitle={setWwwTitle}
                    setDefaultTitle={(val) =>
                        setDefaultTitle({ type: "www", value: val })
                    }
                    fieldName={"wwwStatements"}
                    onHelp={() =>
                        setVisibleHelpSource(
                            process.env.REACT_APP_IMAGE_DIRECTORY +
                                "how-to-content/how_to_write_effective_feedback_statments.svg"
                        )
                    }
                    form={form}
                    formLengths={formLengths}
                    setFormLengths={setFormLengths}
                    setStaleEdits={setStaleEdits}
                    initFormValues={initFormValues}
                    initialLoadedFeedbac={initialLoadedFeedback}
                    initialFormLength={initialFormLength}
                />
                <DynamicFeedbackFormCard
                    colour={"gold"}
                    title={ebiTitle}
                    setTitle={setEbiTitle}
                    setDefaultTitle={(val) =>
                        setDefaultTitle({ type: "ebi", value: val })
                    }
                    fieldName={"ebiStatements"}
                    onHelp={() =>
                        setVisibleHelpSource(
                            process.env.REACT_APP_IMAGE_DIRECTORY +
                                "how-to-content/how_to_write_effective_feedback_statments.svg"
                        )
                    }
                    includeDropdowns
                    form={form}
                    formLengths={formLengths}
                    setFormLengths={setFormLengths}
                    setStaleEdits={setStaleEdits}
                    initFormValues={initFormValues}
                    initialLoadedFeedbac={initialLoadedFeedback}
                    initialFormLength={initialFormLength}
                />
            </div>
        </div>
    );
});

export default PlenaryFeedbackCards;
