import React, { useEffect, useState } from "react";
import {
    CheckOutlined,
    CloseOutlined,
    QuestionCircleOutlined,
    TeamOutlined,
    UserOutlined
} from "@ant-design/icons";
import {
    Alert,
    Button,
    Divider,
    Form,
    Input,
    message,
    Select,
    Space,
    Spin,
    Tooltip
} from "antd";
import {
    getIdToken,
    resendSignUp,
    signIn,
    verifySignUp
} from "helpers/security";
import { apiGetPublic } from "helpers/api";
import { tooltipColour } from "helpers/starterHelpers";

export function SelectLicencePage({ setIsSingleLicence, advance }) {
    const [singleCost, setSingleCost] = useState(null);
    const [siteCost, setSiteCost] = useState(null);

    useEffect(() => {
        let set = true;
        apiGetPublic(
            "/api/get-cost?priceId=" +
                process.env.REACT_APP_SINGLE_LICENCE_PRICE,
            (body) => {
                if (set) {
                    setSingleCost(body);
                }
            }
        );
        return () => (set = false);
    }, []);

    useEffect(() => {
        let set = true;
        apiGetPublic(
            "/api/get-cost?priceId=" +
                process.env.REACT_APP_SITE_TIER_ONE_PRICE,
            (body) => {
                if (set) {
                    setSiteCost(body);
                }
            }
        );
        return () => (set = false);
    }, []);

    function formatAsPrice(costObject) {
        if (!costObject || !costObject.cost || !costObject.interval) {
            return (
                <div className="plan-price error">
                    Unable to Retrieve Prices
                </div>
            );
        }
        const costPence = costObject.cost;
        const interval = costObject.interval;
        let cost = costPence.substring(0, costPence.length - 2);
        if (parseInt(costPence) % 100 !== 0) {
            cost.concat(".").concat(costPence.substring(costPence.length - 2));
        }
        return (
            <div className="plan-price">
                <span className="plan-price-amount">
                    <span className="plan-price-currency">£</span>
                    {cost}
                </span>
                / {interval}
            </div>
        );
    }

    const checkcolor = "#1660e7";

    return (
        <>
            <Alert
                className={"trialAlert"}
                message={
                    (process.env?.REACT_APP_TRIAL_LENGTH || 21) +
                    " Day Free Trial"
                }
                description={
                    "We don't ask for any payment details and there's no obligation to sign up. Use Maths Planner in your classroom to reduce your workload, improve student recall and decide if it fits your needs. Simply choose to sign up after your trial ends."
                }
                type="warning"
                showIcon
                style={{ marginTop: "1em" }}
            />

            {/* Pricing Table */}
            <div
                className="pricing-table is-comparative"
                style={{ paddingBottom: "2em", marginBottom: "3em" }}
            >
                <div className="pricing-plan is-features">
                    <div className="plan-header">Features</div>
                    <div className="plan-price">
                        <span className="plan-price-amount">&nbsp;</span>
                    </div>
                    <div className="plan-items" style={{ fontWeight: "600" }}>
                        <div className="plan-item">
                            Full Question Bank{" "}
                            <Tooltip
                                placement="right"
                                color={tooltipColour}
                                title={
                                    "Full access to our question bank of KS3 and GCSE topics. Differentiated by levels easy, medium & hard OR by GCSE Grade (1-9)"
                                }
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </div>
                        <div className="plan-item">
                            Starters / Plenaries{" "}
                            <Tooltip
                                placement="right"
                                color={tooltipColour}
                                title={
                                    "Quickly create bespoke starters and plenaries using our customisable teacher ready whiteboard template. Including the date, title (LO), timer, SEN overlays and much more... Just click and teach! "
                                }
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </div>
                        <div className="plan-item">
                            Quick Times Tables{" "}
                            <Tooltip
                                placement="right"
                                color={tooltipColour}
                                title={
                                    "Instantly test your students times table knoweldge with our whiteboard ready click and quiz template. We've also included printable template sheets for your students to stick in for the term!"
                                }
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </div>
                        <div className="plan-item">
                            Instant Answers{" "}
                            <Tooltip
                                placement="right"
                                color={tooltipColour}
                                title={
                                    "Stop wasting time creating or looking for answers... All questions in our bank auto generate the answers for quick and seamless marking!"
                                }
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </div>
                        <div className="plan-item">
                            Unlimited Saves{" "}
                            <Tooltip
                                placement="right"
                                color={tooltipColour}
                                title={
                                    "Never lose what you've made online again! You can save anything you make to your account - simply give it a name and tag a class (optional)"
                                }
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </div>
                        <div className="plan-item">
                            Weekly Timetable{" "}
                            <Tooltip
                                placement="right"
                                color={tooltipColour}
                                title={
                                    "Manage any of your saves on your dashboard timetable for quick access. Simply drag and drop them into days you plan to use the save (i.e Tuesday)"
                                }
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </div>
                        <div className="plan-item">
                            Class Tags / Notes{" "}
                            <Tooltip
                                placement="right"
                                color={tooltipColour}
                                title={
                                    "Quickly create class name tags to assign to your saves so you know what class it was made for! You can also add notes and reminders to a class tag."
                                }
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </div>
                        <div className="plan-item">
                            Sub-Accounts for Department Members{" "}
                            <Tooltip
                                placement="right"
                                color={tooltipColour}
                                title={
                                    "Create upto 15 accounts for the staff in your department. They will all need their own login so you all have unique timetables and saves!"
                                }
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </div>
                        <div className="plan-item">
                            Sharable Saves{" "}
                            <Tooltip
                                placement="right"
                                color={tooltipColour}
                                title={
                                    "Parallel classes in the department? You can copy a sharable link of a save you've made and email it to another member of staff to use!"
                                }
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </div>
                    </div>
                    <div className="plan-footer" />
                </div>
                <div className="pricing-plan is-active">
                    <div className="plan-header">School / Instituition</div>
                    {formatAsPrice(siteCost)}
                    <div className="plan-items">
                        <div className="plan-item" data-feature="Question Bank">
                            <CheckOutlined style={{ color: checkcolor }} />
                        </div>
                        <div
                            className="plan-item"
                            data-feature="Starters / Plenaries"
                        >
                            <CheckOutlined style={{ color: checkcolor }} />
                        </div>
                        <div
                            className="plan-item"
                            data-feature="Quick Times Tables"
                        >
                            <CheckOutlined style={{ color: checkcolor }} />
                        </div>
                        <div
                            className="plan-item"
                            data-feature="Instant Answers"
                        >
                            <CheckOutlined style={{ color: checkcolor }} />
                        </div>
                        <div
                            className="plan-item"
                            data-feature="Unlimited Saves"
                        >
                            <CheckOutlined style={{ color: checkcolor }} />
                        </div>
                        <div
                            className="plan-item"
                            data-feature="Weekly Timetable"
                        >
                            <CheckOutlined style={{ color: checkcolor }} />
                        </div>
                        <div
                            className="plan-item"
                            data-feature="Class Tags / Notes"
                        >
                            <CheckOutlined style={{ color: checkcolor }} />
                        </div>
                        <div className="plan-item" data-feature="Sub-Accounts">
                            <CheckOutlined style={{ color: checkcolor }} />
                        </div>
                        <div
                            className="plan-item"
                            data-feature="Sharable Saves"
                        >
                            <CheckOutlined style={{ color: checkcolor }} />
                        </div>
                    </div>
                    <div className="plan-footer">
                        <Button
                            type={"primary"}
                            size="large"
                            onClick={() => {
                                setIsSingleLicence(false);
                                advance();
                            }}
                        >
                            <TeamOutlined className={"planIcon"} /> Start Free
                            Trial
                        </Button>
                    </div>
                </div>

                <div className="pricing-plan is-warning">
                    <div className="plan-header">Individual Teacher</div>
                    {formatAsPrice(singleCost)}
                    <div className="plan-items">
                        <div className="plan-item" data-feature="Question Bank">
                            <CheckOutlined style={{ color: checkcolor }} />
                        </div>
                        <div
                            className="plan-item"
                            data-feature="Starters / Plenaries"
                        >
                            <CheckOutlined style={{ color: checkcolor }} />
                        </div>
                        <div
                            className="plan-item"
                            data-feature="Quick Times Tables"
                        >
                            <CheckOutlined style={{ color: checkcolor }} />
                        </div>
                        <div
                            className="plan-item"
                            data-feature="Instant Answers"
                        >
                            <CheckOutlined style={{ color: checkcolor }} />
                        </div>
                        <div
                            className="plan-item"
                            data-feature="Unlimited Saves"
                        >
                            <CheckOutlined style={{ color: checkcolor }} />
                        </div>
                        <div
                            className="plan-item"
                            data-feature="Weekly Timetable"
                        >
                            <CheckOutlined style={{ color: checkcolor }} />
                        </div>
                        <div
                            className="plan-item"
                            data-feature="Class Tags / Notes"
                        >
                            <CheckOutlined style={{ color: checkcolor }} />
                        </div>
                        <div className="plan-item" data-feature="Sub-Accounts">
                            <CloseOutlined style={{ color: "#f5222d" }} />
                        </div>
                        <div
                            className="plan-item"
                            data-feature="Sharable Saves"
                        >
                            <CloseOutlined style={{ color: "#f5222d" }} />
                        </div>
                    </div>
                    <div className="plan-footer">
                        <Button
                            type={"primary"}
                            size="large"
                            onClick={() => {
                                setIsSingleLicence(true);
                                advance();
                            }}
                        >
                            <UserOutlined /> Start Free Trial
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export const SCHOOL_NOT_PRESENT_MESSAGE = "My school is not in the list";

export function SchoolNameFormItem({ required = false, form }) {
    const [schools, setSchools] = useState([]);
    const [schoolSelectOpen, setSchoolSelectOpen] = useState(false);
    const [currentSchoolName, setCurrentSchoolName] = useState(null);
    const [schoolAccountExists, setSchoolAccountExists] = useState(false);

    useEffect(() => {
        apiGetPublic("/api/schools", (body) => {
            const parsed = body.map((s) => ({
                value: s.urn,
                label: s.name
            }));
            parsed.sort((a, b) => a.label.localeCompare(b.label));
            setSchools(parsed);
        });
    }, []);

    // Check if the selected school already has an account associated to it
    useEffect(() => {
        if (!currentSchoolName) {
            return;
        }
        apiGetPublic(
            `/api/school-has-account?name=${encodeURIComponent(
                currentSchoolName
            )}`,
            (exists) => {
                setSchoolAccountExists(exists);
            }
        );
    }, [currentSchoolName]);

    return (
        <>
            <Form.Item
                name="school"
                label="School"
                rules={[{ required: required, message: "School is required" }]}
                validateStatus={schoolAccountExists ? "warning" : undefined}
                help={
                    schoolAccountExists ? (
                        <>
                            This school already has an account with us, maybe
                            someone else in your department has previously
                            signed up? If you would like to know more, please
                            contact us.
                        </>
                    ) : undefined
                }
            >
                <Select
                    className={"schoolSelect"}
                    data-lpignore="true"
                    showSearch
                    allowClear={!required}
                    placeholder={"Start typing your school name"}
                    open={schoolSelectOpen}
                    onDropdownVisibleChange={setSchoolSelectOpen}
                    options={schools
                        .map((s) => ({
                            title: s.label,
                            value: s.label
                        }))
                        .concat([
                            {
                                title: SCHOOL_NOT_PRESENT_MESSAGE,
                                value: -1
                            }
                        ])}
                    onChange={(e) => setCurrentSchoolName(e)}
                    dropdownRender={(menu) => (
                        <div>
                            {menu}
                            <Divider style={{ margin: 0 }} />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    padding: "1rem",
                                    columnGap: "1rem"
                                }}
                            >
                                <Button
                                    onClick={() => {
                                        form?.setFieldsValue({
                                            school: SCHOOL_NOT_PRESENT_MESSAGE
                                        });
                                        setSchoolSelectOpen(false);
                                    }}
                                >
                                    {SCHOOL_NOT_PRESENT_MESSAGE}
                                </Button>
                            </div>
                        </div>
                    )}
                />
            </Form.Item>
            {form?.getFieldValue("school") === SCHOOL_NOT_PRESENT_MESSAGE && (
                <Form.Item
                    style={{ marginLeft: "150px" }}
                    name="customSchool"
                    label="School Name"
                    rules={[
                        {
                            required:
                                required &&
                                form?.getFieldValue("school") ===
                                    SCHOOL_NOT_PRESENT_MESSAGE,
                            message: "School Name is required"
                        }
                    ]}
                >
                    <Input
                        data-lpignore="true"
                        onChange={(e) => setCurrentSchoolName(e.target.value)}
                    />
                </Form.Item>
            )}
        </>
    );
}

export function AccountDetailsPage({
    form = null,
    isSingleLicence,
    onSubmit = (formData) => {},
    isAdminCreation = false,
    submitText = "Register",
    extraFormFields = undefined
}) {
    const isSite = !isSingleLicence;
    const [currentEmailDomain, setCurrentEmailDomain] = useState(null);
    const [domainAccountExists, setDomainAccountExists] = useState(false);

    const [newForm] = Form.useForm();
    if (!form) {
        form = newForm;
    }

    // Check if the entered email domain already has an account associated to it
    useEffect(() => {
        if (!currentEmailDomain) {
            return;
        }
        apiGetPublic(
            `/api/email-domain-has-account?domain=${encodeURIComponent(
                currentEmailDomain
            )}`,
            (exists) => {
                setDomainAccountExists(exists);
            }
        );
    }, [currentEmailDomain]);

    return (
        <>
            <Form
                form={form}
                requiredMark={true}
                size="large"
                className={"createAccountForm"}
                onFinish={async (formData) => {
                    const schoolName =
                        formData.school === SCHOOL_NOT_PRESENT_MESSAGE
                            ? formData.customSchool
                            : formData.school;
                    onSubmit({ ...formData, schoolName });
                }}
            >
                <Form.Item
                    name="forename"
                    label="First Name"
                    rules={[
                        { required: true, message: "First Name is required" }
                    ]}
                >
                    <Input
                        {...(isAdminCreation ? { autoComplete: "none" } : {})}
                    />
                </Form.Item>
                <Form.Item
                    name="surname"
                    label="Last Name"
                    rules={[
                        { required: true, message: "Last Name is required" }
                    ]}
                >
                    <Input
                        {...(isAdminCreation ? { autoComplete: "none" } : {})}
                    />
                </Form.Item>
                <SchoolNameFormItem required={isSite} form={form} />
                <Form.Item
                    name="email"
                    label={isSite ? "School Email" : "Email"}
                    rules={[
                        { required: true, message: "Email is required" },
                        {
                            type: "email",
                            message: "Please enter a valid email"
                        }
                    ]}
                    extra={
                        isSite &&
                        !isAdminCreation &&
                        "The email address entered here will be the account admin for the school. Note: all teacher sub-accounts are required to have the same email suffix as the account admin (e.g. @exampleschool.com)."
                    }
                    validateStatus={domainAccountExists ? "warning" : undefined}
                    help={
                        domainAccountExists ? (
                            <>
                                This email domain already has an account with
                                us, maybe someone else in your department has
                                previously signed up? If you would like to know
                                more, please contact us.
                            </>
                        ) : undefined
                    }
                >
                    <Input
                        placeholder="user123@exampleschool.com"
                        {...(isAdminCreation ? { autoComplete: "none" } : {})}
                        onChange={(e) =>
                            setCurrentEmailDomain(
                                e.target.value.split("@")?.[1]
                            )
                        }
                    />
                </Form.Item>
                {extraFormFields}
                <Form.Item
                    className={"formButtons"}
                    extra={
                        !isAdminCreation && (
                            <span className={"ant-form-item-explain"}>
                                By clicking "Register" you confirm that you have
                                read and agree to our{" "}
                                <a
                                    href={
                                        "https://www.mathsplanner.com/app-terms-of-service"
                                    }
                                    target={"_blank"}
                                    rel={"noreferrer"}
                                >
                                    Terms of Service
                                </a>{" "}
                                and{" "}
                                <a
                                    href={
                                        "https://www.mathsplanner.com/privacy-policy/"
                                    }
                                    target={"_blank"}
                                    rel={"noreferrer"}
                                >
                                    Privacy Policy
                                </a>
                            </span>
                        )
                    }
                >
                    <Button key="submit" type="primary" htmlType="submit">
                        {submitText}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

export function VerificationPage({
    loginDetails,
    advance,
    showSpinner,
    accessedFromSignIn = false
}) {
    if (!accessedFromSignIn) {
        window.onbeforeunload = () => true;
    }

    return (
        <div className="verifycodwrap">
            <h1>We’ve sent a Verification Code to your email</h1>
            <Space direction={"vertical"} size="large">
                {!accessedFromSignIn && (
                    <Alert
                        message="Warning: Please don't close this page"
                        description="If you close this page before entering your code you will need to login via the homepage to enter the code."
                        type="warning"
                        showIcon
                    />
                )}

                <p>
                    Please enter the code from the email to verify your email
                    address:
                </p>
                <Form
                    requiredMark={false}
                    layout="inline"
                    onFinish={async (e) => {
                        try {
                            await verifySignUp(loginDetails.email, e.code);
                            let id = null;
                            while (id == null) {
                                await signIn(
                                    loginDetails.email,
                                    loginDetails.password,
                                    false
                                );
                                id = getIdToken();
                            }
                            window.onbeforeunload = null;
                            advance();
                        } catch (err) {
                            message.error(
                                "Unable to verify email: " + err.message
                            );
                        }
                    }}
                >
                    <Form.Item
                        name="code"
                        rules={[
                            {
                                required: true,
                                message: "Please enter your verification code"
                            }
                        ]}
                    >
                        <Input size="large" placeholder="e.g. 123456" />
                    </Form.Item>
                    <Form.Item>
                        {showSpinner ? (
                            <Spin />
                        ) : (
                            <Button
                                size="large"
                                key="submit"
                                htmlType="submit"
                                type="primary"
                            >
                                Confirm
                            </Button>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="link"
                            size="large"
                            onClick={async () => {
                                try {
                                    await resendSignUp(loginDetails.email);
                                } catch (err) {
                                    message.error(
                                        "Unable to resend code: " + err.message
                                    );
                                }
                            }}
                        >
                            Resend Code
                        </Button>
                    </Form.Item>
                </Form>
                {!showSpinner && (
                    <span className={"ant-form-item-explain"}>
                        By clicking "Confirm" you give your consent for
                        receiving occasional emails about the service. You can
                        unsubscribe at any time via the link in the emails.
                    </span>
                )}
            </Space>
        </div>
    );
}
