import React, { useEffect, useState } from "react";

import { apiGet, apiPost, apiPut } from "helpers/api";
import { withCookies } from "react-cookie";
import { Button, Form, Input } from "antd";

function AddTopicAreaForm({ cookies, editing = null }) {
    const [form] = Form.useForm();
    const [csrfToken] = useState(cookies.get("XSRF-TOKEN"));

    useEffect(() => {
        if (editing) {
            apiGet(
                "/api/topic-area?topicArea=" + encodeURIComponent(editing),
                (body) => {
                    form.setFieldsValue({ topicArea: body.value });
                }
            );
        }
    }, [editing, form]);

    function onSubmit(formData) {
        if (!editing) {
            apiPost("/api/add-topic-area", JSON.stringify(formData), csrfToken);
        } else {
            apiPut(
                "/api/update-topic-area?originalName=" +
                    encodeURIComponent(editing),
                JSON.stringify(formData),
                csrfToken
            );
        }
    }

    return (
        <Form form={form} onFinish={onSubmit}>
            <Form.Item name="topicArea" label={"Topic Area"}>
                <Input required placeholder="E.g. Geometry & Algebra" />
            </Form.Item>

            <Form.Item>
                <Button key="submit" type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}

export default withCookies(AddTopicAreaForm);
