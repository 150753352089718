import { useCustomSwitch } from "commonComponents/CustomSwitch";
import { Select } from "antd";
import React from "react";
import Countdown from "commonComponents/Countdown";
import { FullscreenAnswerHeader } from "commonComponents/FullscreenAnswerHeader";

function SkillsActivity({ fullscreen, makeFullscreen }) {
    const [higherSwitch] = useCustomSwitch("LOWER", "HIGHER", true);

    return (
        <div className={"skills"}>
            <div className={"skills-header"}>
                <div className={"skills-header-group"}>
                    <h1>Skills Drill</h1>
                    {higherSwitch}
                </div>
                <div className={"skills-header-group"}>
                    <Select
                        className={"timetableControlContainer"}
                        defaultValue={"SELECT TOPICS"}
                    />
                    <FullscreenAnswerHeader
                        fullscreen={fullscreen}
                        makeFullscreen={makeFullscreen}
                        newCallback={() => {}}
                        showAnswers={false}
                        setShowAnswers={() => {}}
                    />
                </div>
            </div>
            <div className={"skills-content"}>
                <div className="cntdwn-timer">
                    <Countdown
                        initialTimeMinutes={5}
                        startCallback={() => makeFullscreen(true)}
                    />
                </div>
                <ol>
                    <li>Find 19 -- 16</li>
                    <li>Find 19 -- 16</li>
                    <li>Find 19 -- 16</li>
                    <li>Find 19 -- 16</li>
                    <li>Find 19 -- 16</li>
                    <li>Find 19 -- 16</li>
                    <li>Find 19 -- 16</li>
                    <li>Find 19 -- 16</li>
                    <li />
                    <li />
                </ol>
            </div>
        </div>
    );
}

export default SkillsActivity;
