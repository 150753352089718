import { Button } from "antd";
import React from "react";

export function OurLibraryMultiSelectPanel({
    options,
    selectedLabels,
    toggleFromSelected,
    onClick = () => {}
}) {
    return (
        <div className={"our-library__button_panel "}>
            {options.map((t) => (
                <Button
                    key={t.label}
                    label={t.label}
                    onClick={() => {
                        if (t?.disabled) {
                            return;
                        }
                        toggleFromSelected(t.label);
                        onClick(t.label);
                    }}
                    disabled={t?.disabled}
                    className={
                        "our-library__topic_button square " +
                        (t.colour + " ") +
                        (selectedLabels.includes(t.label) && !t?.disabled
                            ? "current "
                            : "") +
                        (t?.disabled ? "disabled " : "")
                    }
                >
                    {t.label}
                </Button>
            ))}
        </div>
    );
}
