import { Button, Input } from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import React from "react";

export function OurLibrarySearch({ value, onValueChange, onClear }) {
    return (
        <Input.Group compact className={"our-library__quick_search"}>
            <Input
                style={{
                    width: "calc(100% - 32px)",
                    height: "100%"
                }}
                placeholder="Quick search"
                value={value}
                onChange={(e) => {
                    const value = e.target.value;
                    onValueChange(value);
                }}
            />
            <Button
                icon={!value ? <SearchOutlined /> : <CloseOutlined />}
                onClick={onClear}
            />
        </Input.Group>
    );
}
