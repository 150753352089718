import { useState } from "react";
import { Select } from "antd";

function SelectWithInput({ value = null, onChange, options, ...props }) {
    const [current, setCurrent] = useState(value);
    const [localOptions, setLocalOptions] = useState(options || []);

    const addValueToList = (currentValue) => {
        if (
            currentValue &&
            Object?.entries(currentValue)?.length &&
            !options.map((opt) => opt?.label || opt).includes(currentValue)
        ) {
            setLocalOptions([
                ...options,
                { label: currentValue, value: currentValue }
            ]);
        } else {
            setLocalOptions(options);
        }
    };

    const handleSearch = (changedValue) => {
        setLocalOptions((prev) => {
            if (prev.map((opt) => opt?.label || opt).includes(changedValue)) {
                return prev;
            }
            return [...prev, { label: changedValue, value: changedValue }];
        });
    };

    const handleFocus = () => {
        addValueToList(value);
    };
    const handleChange = (changedValue) => {
        setCurrent(changedValue);
        onChange?.(changedValue);
        addValueToList(changedValue);
    };

    return (
        <Select
            showSearch
            value={value || current}
            filterOption={true}
            onSearch={handleSearch}
            onChange={handleChange}
            onFocus={handleFocus}
            options={localOptions}
            {...props}
        />
    );
}

export default SelectWithInput;
