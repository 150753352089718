import { apiSlice, mutationHeaders } from "helpers/apiSlice";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React from "react";
import { Auth } from "aws-amplify";
import { getIdToken, redirectToLogin } from "helpers/security";
import { DEFAULT_TITLES } from "helpers/starterHelpers";

function invalidateSessionAndLogOut(userData, dispatch) {
    if (userData.invalidate && userData.invalidate !== "") {
        Modal.warning({
            title: "Notification",
            icon: <ExclamationCircleTwoTone twoToneColor="#faad14" />,
            content: userData.invalidate,
            footer: <Button>Ok</Button>,
            onOk: async () =>
                await dispatch(apiSlice.endpoints.logOutUser.initiate())
        });
    } else {
        dispatch(apiSlice.endpoints.logOutUser.initiate()).then(
            window.location.reload
        );
    }
}

const optimisticallyUpdateUserAttribute = (
    attributeName,
    newValue,
    { dispatch, queryFulfilled }
) => {
    dispatch(
        apiSlice.util.updateQueryData("getUser", undefined, (draftUser) => {
            draftUser[attributeName] = newValue;
            return draftUser;
        })
    );
    queryFulfilled.catch(() =>
        dispatch(apiSlice.util.invalidateTags(["User"]))
    );
};

export const extendedApiSlice = apiSlice
    .enhanceEndpoints({ addTagTypes: ["User"] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getUser: builder.query({
                async queryFn(_, { dispatch }, extraOptions, fetchFn) {
                    if (!(await getIdToken())) {
                        return { data: null };
                    }
                    const body = await fetchFn({
                        url: "/user"
                    });
                    if (
                        body?.data &&
                        body?.data !== {} &&
                        Object.keys(body?.data).length > 0 &&
                        !body?.data?.invalidate
                    ) {
                        return body;
                    }
                    invalidateSessionAndLogOut(body.data, dispatch);
                    return { data: null };
                },
                providesTags: ["User"]
            }),
            logOutUser: builder.mutation({
                async queryFn(_, queryApi, extraOptions, fetchFn) {
                    await Auth.signOut();
                    const idToken = await getIdToken();
                    if (idToken == null) {
                        redirectToLogin();
                    }
                },
                invalidatesTags: ["User"]
            }),
            setTourVisited: builder.mutation({
                query: (val) => ({
                    url: `/set_tour_visited?value=${Boolean(val)}`,
                    headers: mutationHeaders,
                    method: "PUT"
                }),
                onQueryStarted(newValue, apiMethods) {
                    optimisticallyUpdateUserAttribute(
                        "tour_visited",
                        newValue,
                        apiMethods
                    );
                },
                invalidatesTags: ["User"]
            }),
            setTimetablePeriods: builder.mutation({
                query: (value) => ({
                    url: `/set_timetable_periods?value=${value}`,
                    headers: mutationHeaders,
                    method: "PUT"
                }),
                onQueryStarted(newValue, apiMethods) {
                    optimisticallyUpdateUserAttribute(
                        "timetable_periods",
                        newValue,
                        apiMethods
                    );
                },
                invalidatesTags: ["User", { type: "Starters", id: "LIST" }]
            }),
            setDefaultCountdown: builder.mutation({
                query: (value) => ({
                    url: `/set_default_countdown?value=${value}`,
                    headers: mutationHeaders,
                    method: "PUT"
                }),
                onQueryStarted(newValue, apiMethods) {
                    optimisticallyUpdateUserAttribute(
                        "default_countdown",
                        newValue,
                        apiMethods
                    );
                },
                invalidatesTags: ["User"]
            }),
            setDefaultFeedbackTitle: builder.mutation({
                query: ({ type, value }) => ({
                    url: `/set_default_feedback_title/${type}?value=${encodeURIComponent(
                        value
                    )}`,
                    headers: mutationHeaders,
                    method: "PUT"
                }),
                onQueryStarted(arg, apiMethods) {
                    optimisticallyUpdateUserAttribute(
                        `default_${arg.type}_title`,
                        arg.value,
                        apiMethods
                    );
                },
                invalidatesTags: ["User"]
            }),
            resetDefaultFeedbackTitles: builder.mutation({
                query: () => ({
                    url: `/reset_default_feedback_titles`,
                    headers: mutationHeaders,
                    method: "PUT"
                }),
                onQueryStarted(arg, apiMethods) {
                    optimisticallyUpdateUserAttribute(
                        `default_www_title`,
                        DEFAULT_TITLES.www,
                        apiMethods
                    );
                    optimisticallyUpdateUserAttribute(
                        `default_ebi_title`,
                        DEFAULT_TITLES.ebi,
                        apiMethods
                    );
                },
                invalidatesTags: ["User"]
            }),
            setShowNameOnDashboard: builder.mutation({
                query: (val) => ({
                    url: `/set_show_name?value=${Boolean(val)}`,
                    headers: mutationHeaders,
                    method: "PUT"
                }),
                onQueryStarted(newValue, apiMethods) {
                    optimisticallyUpdateUserAttribute(
                        "show_name",
                        newValue,
                        apiMethods
                    );
                },
                invalidatesTags: ["User"]
            }),
            setShowSaturdayOnTimetable: builder.mutation({
                query: (val) => ({
                    url: `/set_show_saturday?value=${Boolean(val)}`,
                    headers: mutationHeaders,
                    method: "PUT"
                }),
                onQueryStarted(newValue, apiMethods) {
                    optimisticallyUpdateUserAttribute(
                        "show_saturday",
                        newValue,
                        apiMethods
                    );
                },
                invalidatesTags: ["User"]
            }),
            setStarterPreferences: builder.mutation({
                query: (newPreferences) => ({
                    url: "/update_starter_preferences",
                    body: JSON.stringify(newPreferences),
                    headers: mutationHeaders,
                    method: "PUT"
                })
            })
        })
    });

export const {
    useGetUserQuery,
    useLogOutUserMutation,
    useSetTourVisitedMutation,
    useSetTimetablePeriodsMutation,
    useSetShowNameOnDashboardMutation,
    useSetShowSaturdayOnTimetableMutation,
    useSetDefaultCountdownMutation,
    useSetDefaultFeedbackTitleMutation,
    useResetDefaultFeedbackTitlesMutation,
    useSetStarterPreferencesMutation
} = extendedApiSlice;
