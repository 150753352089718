import { OurLibrarySavedSlots } from "./OurLibrarySavedSlots";
import { Button } from "antd";
import React from "react";

export function OurLibraryFooter({
    selectedStarters,
    setSelectedStarters,
    onSubmit,
    numSlots
}) {
    return (
        <div className={"our-library__footer"}>
            <h2
                style={{
                    flexBasis:
                        "17rem" /* Width of title + subtitle on 1 line to favour no line break*/
                }}
            >
                Selected{" "}
                <span className={"subtitle"} style={{ whiteSpace: "nowrap" }}>
                    Max: {numSlots}
                </span>
            </h2>
            <OurLibrarySavedSlots
                selectedStarters={selectedStarters}
                setSelectedStarters={setSelectedStarters}
                numSlots={numSlots}
            />
            <div className={"our-library__footer__buttons"}>
                <Button
                    type={"default"}
                    onClick={() => setSelectedStarters([])}
                >
                    CLEAR {numSlots === 1 ? "" : "ALL"}
                </Button>
                <Button type={"primary"} onClick={onSubmit}>
                    SAVE
                </Button>
            </div>
        </div>
    );
}
