import React, { useEffect, useMemo, useState } from "react";
import {
    BrowserRouter as Switch,
    matchPath,
    Route,
    useHistory,
    useLocation,
    useRouteMatch
} from "react-router-dom";
import AddQuestionForm from "./components/AddQuestionForm";
import AddTopicAreaForm from "./components/AddTopicAreaForm";
import AddTopicForm from "./components/AddTopicForm";
import AddSubTopicForm from "./components/AddSubTopicForm";
import ManageTopicAreasForm from "./components/ManageTopicAreasForm";
import ManageTopicsForm from "./components/ManageTopicsForm";
import ManageSubTopicsForm from "./components/ManageSubTopicsForm";
import ManageQuestionsForm from "./components/ManageQuestionsForm";
import { Button, Cascader, Popover, Radio, Row, Space } from "antd";
import { getName, isEnvironmentContentCreationOnly } from "helpers/security";
import { filterCascader, renderCascader } from "helpers/questionHelpers";
import { removeDifficultiesFromTopicsTree } from "helpers/starterHelpers";
import { Helmet } from "react-helmet-async";
import StandaloneQuestionCard from "features/lesson/StandaloneQuestionCard";
import { useGetUserQuery } from "features/account/accountSlice";
import { useGetTopicsTreeQuery } from "features/lesson/topicsSlice";
import AddMockPaperForm from "./components/mockPapers/AddMockPaperForm";
import ManageMockPapersForm from "./components/mockPapers/ManageMockPapersForm";
import { useQuery } from "helpers/hooks";
import UserAdminDashboard from "features/accountsAdmin/UserAdminDashboard";
import { ReadFilled } from "@ant-design/icons";
import LessonActivity from "features/lesson/Lesson";

export function ManageQuestionsBySubTopicCascader({
    urlStub,
    onSubtopicChange = () => {}
}) {
    const history = useHistory();
    const { data: topics } = useGetTopicsTreeQuery();
    const topicsNoDifficulties = useMemo(
        () => removeDifficultiesFromTopicsTree(topics, true),
        [topics]
    );
    return (
        <Cascader
            popupClassName={"topicsPopup"}
            options={topicsNoDifficulties}
            placeholder="Search or Select Sub Topic"
            showSearch={{
                filter: filterCascader,
                render: renderCascader
            }}
            changeOnSelect={true}
            onChange={(selected) => {
                if (selected?.[2]) {
                    onSubtopicChange();
                    history.push(
                        `${urlStub}/manage-question/${encodeURIComponent(
                            selected[2]
                        )}`
                    );
                }
            }}
            className="topicSelector"
        />
    );
}

function DashboardButtons() {
    let match = useRouteMatch();
    const isContentEnvironment = isEnvironmentContentCreationOnly();
    const { data: user } = useGetUserQuery();

    const [name, setName] = useState(null);

    useEffect(() => {
        (async () => getName().then(setName))();
    }, []);

    return (
        <div className="content-creator-container">
            <section className="content-welcome">
                <img
                    src={process.env.PUBLIC_URL + "/svgs/welcome-teacher.svg"}
                    alt={"Icon of a teacher"}
                />
                <div className="welcome--text">
                    <h1>
                        Hi{user && name && name !== "null" ? " " + name : ""}!
                    </h1>
                    <p>Content Creator Dashboard</p>
                </div>
            </section>
            <section className="content-col">
                <div className="content-col-left">
                    <div className="content-stats">
                        <h3>Your Creative Work</h3>
                        <p>Here's how much content you have made</p>

                        <div className="creator--stat">
                            <p>Topics Created</p>
                            <h4>{user?.topics_created}</h4>
                        </div>
                        <div className="creator--stat">
                            <p>Sub-Topics Created</p>
                            <h4>{user?.sub_topics_created}</h4>
                        </div>
                        <div className="creator--stat">
                            <p>Questions Created</p>
                            <h4>{user?.questions_created}</h4>
                        </div>

                        <div className="motivation--message">
                            <p>
                                Keep up the great work{" "}
                                {user && name && name !== "null"
                                    ? " " + name
                                    : ""}
                                !
                            </p>
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/svgs/thumb-up.svg"
                                }
                                alt={"Thumbs up"}
                            />
                        </div>
                    </div>

                    <div className="create-content">
                        <div className="create--header">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/svgs/question-bank.svg"
                                }
                                alt={"Icon of a bank"}
                            />
                            <div className="create--header-text">
                                <h3>Create Content</h3>
                                <p>Manage or start creating new questions</p>
                            </div>
                        </div>
                        <div className="content-new-manage">
                            <p>Questions</p>
                            <div className="content--nav">
                                <Popover
                                    overlayClassName={"question-popover"}
                                    content={
                                        <Radio.Group>
                                            <Radio.Button>
                                                <a
                                                    className="content--btn-2"
                                                    href={`${match.url}/add-statement-question`}
                                                >
                                                    Text Question
                                                </a>
                                            </Radio.Button>
                                            <Radio.Button>
                                                <a
                                                    className="content--btn-2"
                                                    href={`${match.url}/add-image-question`}
                                                >
                                                    Image Question
                                                </a>
                                            </Radio.Button>
                                            <Radio.Button>
                                                <a
                                                    className="content--btn-2"
                                                    href={`${match.url}/add-chart-question`}
                                                >
                                                    Chart Question
                                                </a>
                                            </Radio.Button>
                                        </Radio.Group>
                                    }
                                    trigger="click"
                                >
                                    {/*eslint-disable-next-line*/}
                                    <a className="content--btn">ADD NEW</a>
                                </Popover>
                                <Popover
                                    overlayClassName={"question-popover"}
                                    content={
                                        <Space direction={"horizontal"}>
                                            <Button
                                                type={"default"}
                                                href={`${match.path}/manage-question`}
                                            >
                                                All SubTopics
                                            </Button>
                                            <Button
                                                type={"default"}
                                                danger
                                                href={`${match.path}/manage-question/__issues__`}
                                            >
                                                All Issues
                                            </Button>
                                            <ManageQuestionsBySubTopicCascader
                                                urlStub={match.path}
                                            />
                                        </Space>
                                    }
                                    trigger="click"
                                >
                                    {/*eslint-disable-next-line*/}
                                    <a className="content--btn-2">MANAGE</a>
                                </Popover>
                            </div>
                        </div>

                        <div className="content-new-manage">
                            <p>Sub-Topics</p>
                            <div className="content--nav">
                                <a
                                    className="content--btn"
                                    href={`${match.url}/add-subtopic`}
                                >
                                    ADD NEW
                                </a>
                                <a
                                    className="content--btn-2"
                                    href={`${match.url}/manage-subtopic`}
                                >
                                    MANAGE
                                </a>
                            </div>
                        </div>

                        <div className="content-new-manage">
                            <p>Topics</p>
                            <div className="content--nav">
                                <a
                                    className="content--btn"
                                    href={`${match.url}/add-topic`}
                                >
                                    ADD NEW
                                </a>
                                <a
                                    className="content--btn-2"
                                    href={`${match.url}/manage-topic`}
                                >
                                    MANAGE
                                </a>
                            </div>
                        </div>

                        <div className="content-new-manage">
                            <p>Topic Areas</p>
                            <div className="content--nav">
                                <a
                                    className="content--btn"
                                    href={`${match.url}/add-topic-area`}
                                >
                                    ADD NEW
                                </a>
                                <a
                                    className="content--btn-2"
                                    href={`${match.url}/manage-topic-area`}
                                >
                                    MANAGE
                                </a>
                            </div>
                        </div>

                        <div className="content-new-manage">
                            <p>Mock Papers</p>
                            <div className="content--nav">
                                <a
                                    className="content--btn"
                                    href={`${match.url}/add-mock-paper`}
                                >
                                    ADD NEW
                                </a>
                                <a
                                    className="content--btn-2"
                                    href={`${match.url}/manage-mock-papers`}
                                >
                                    MANAGE
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-col-right">
                    <a
                        href={process.env?.REACT_APP_NOTION_DOCS}
                        target="_blank"
                        rel="noreferrer noopener"
                        style={{ width: "inherit" }}
                    >
                        <div
                            className="content--link--card"
                            onMouseOver={(e) =>
                                (e.currentTarget.children[0].src =
                                    process.env.PUBLIC_URL +
                                    "/svgs/notion-hover.svg")
                            }
                            onMouseOut={(e) =>
                                (e.currentTarget.children[0].src =
                                    process.env.PUBLIC_URL + "/svgs/notion.svg")
                            }
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL + "/svgs/notion.svg"
                                }
                                alt={"Notion logo"}
                            />
                            <div className="link--text">
                                <h3>Notion Dashboard</h3>
                                <p>
                                    Manage tasks, question db, and find support
                                    documentation
                                </p>
                            </div>
                        </div>
                    </a>

                    <a
                        href="https://app.clockify.me/tracker"
                        target="_blank"
                        rel="noreferrer noopener"
                        style={{ width: "inherit" }}
                    >
                        <div
                            className="content--link--card"
                            onMouseOver={(e) =>
                                (e.currentTarget.children[0].src =
                                    process.env.PUBLIC_URL +
                                    "/svgs/clockify-hover.svg")
                            }
                            onMouseOut={(e) =>
                                (e.currentTarget.children[0].src =
                                    process.env.PUBLIC_URL +
                                    "/svgs/clockify.svg")
                            }
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/svgs/clockify.svg"
                                }
                                alt={"Clockify Logo"}
                            />
                            <div className="link--text">
                                <h3>Clockify Dashboard</h3>
                                <p>Log your working hours and tasks</p>
                            </div>
                        </div>
                    </a>

                    <a
                        href={process.env?.REACT_APP_SLACK}
                        target="_blank"
                        rel="noreferrer noopener"
                        style={{ width: "inherit" }}
                    >
                        <div className="content--link--card">
                            <img
                                src={process.env.PUBLIC_URL + "/svgs/slack.svg"}
                                alt={"Slack Logo"}
                            />
                            <div className="link--text">
                                <h3>Slack Channel</h3>
                                <p>
                                    Discuss project ideas or concerns with the
                                    team
                                </p>
                            </div>
                        </div>
                    </a>

                    {isContentEnvironment && (
                        <a
                            href={"/admin/starter-preview"}
                            style={{ width: "inherit" }}
                        >
                            <div className="content--link--card">
                                <ReadFilled />
                                <div className="link--text">
                                    <h3>Starter Preview</h3>
                                    <p>
                                        Create a preview of 4-6 questions at
                                        once
                                    </p>
                                </div>
                            </div>
                        </a>
                    )}

                    <div style={{ height: "35rem", maxWidth: "60rem" }}>
                        <StandaloneQuestionCard />
                    </div>
                </div>
            </section>
        </div>
    );
}

function AdminDashboard({ fullscreen, makeFullscreen }) {
    let match = useRouteMatch();
    const history = useHistory();

    const location = useLocation();
    const isAccountsPage = matchPath(location.pathname, {
        path: `${match.path}/accounts`,
        exact: false
    });
    const isContentEnvironment = isEnvironmentContentCreationOnly();

    function BackButton(props) {
        return match.isExact || isAccountsPage ? null : (
            <Button onClick={history.goBack} {...props}>
                Back
            </Button>
        );
    }

    const query = useQuery();

    return (
        <div className={"adminDashboard"}>
            <Row>
                <BackButton type="secondary" />
            </Row>
            <Switch>
                <Route path={`${match.path}/accounts`}>
                    <Helmet>
                        <title>MathsPlanner - Manage User Accounts</title>
                    </Helmet>
                    <UserAdminDashboard />
                </Route>
                <Route path={`${match.path}/add-statement-question`}>
                    <Helmet>
                        <title>MathsPlanner - Add Statement Question</title>
                    </Helmet>
                    <AddQuestionForm />
                </Route>
                <Route path={`${match.path}/add-image-question`}>
                    <Helmet>
                        <title>MathsPlanner - Add Image Question</title>
                    </Helmet>
                    <AddQuestionForm isImage={true} />
                </Route>
                <Route path={`${match.path}/add-chart-question`}>
                    <Helmet>
                        <title>MathsPlanner - Add Chart Question</title>
                    </Helmet>
                    <AddQuestionForm isChart={true} />
                </Route>
                <Route path={`${match.path}/add-mock-paper`}>
                    <Helmet>
                        <title>MathsPlanner - Add Mock Paper</title>
                    </Helmet>
                    <AddMockPaperForm />
                </Route>
                <Route path={`${match.path}/add-topic-area`}>
                    <Helmet>
                        <title>MathsPlanner - Add Topic Area</title>
                    </Helmet>
                    <AddTopicAreaForm />
                </Route>
                <Route path={`${match.path}/add-topic`}>
                    <Helmet>
                        <title>MathsPlanner - Add Topic</title>
                    </Helmet>
                    <AddTopicForm />
                </Route>
                <Route path={`${match.path}/add-subtopic`}>
                    <Helmet>
                        <title>MathsPlanner - Add Subtopic</title>
                    </Helmet>
                    <AddSubTopicForm />
                </Route>
                <Route
                    path={`/admin/edit-statement-question`}
                    render={(props) => (
                        <>
                            <Helmet>
                                <title>
                                    MathsPlanner - Edit Statement Question
                                </title>
                            </Helmet>
                            <AddQuestionForm
                                editing={new URLSearchParams(
                                    props.location.search
                                ).get("id")}
                                {...props}
                            />
                        </>
                    )}
                />
                <Route
                    path={`/admin/edit-image-question`}
                    render={(props) => (
                        <>
                            <Helmet>
                                <title>
                                    MathsPlanner - Edit Image Question
                                </title>
                            </Helmet>
                            <AddQuestionForm
                                editing={new URLSearchParams(
                                    props.location.search
                                ).get("id")}
                                isImage={true}
                                {...props}
                            />
                        </>
                    )}
                />{" "}
                <Route
                    path={`/admin/edit-chart-question`}
                    render={(props) => (
                        <>
                            <Helmet>
                                <title>
                                    MathsPlanner - Edit Chart Question
                                </title>
                            </Helmet>
                            <AddQuestionForm
                                editing={new URLSearchParams(
                                    props.location.search
                                ).get("id")}
                                isChart={true}
                                {...props}
                            />
                        </>
                    )}
                />
                <Route
                    path={`/admin/edit-mock-paper`}
                    render={(props) => (
                        <>
                            <Helmet>
                                <title>MathsPlanner - Edit Mock Paper</title>
                            </Helmet>
                            <AddMockPaperForm
                                editingId={new URLSearchParams(
                                    props.location.search
                                ).get("id")}
                                {...props}
                            />
                        </>
                    )}
                />
                <Route path={`${match.path}/edit-topic-area`}>
                    <Helmet>
                        <title>MathsPlanner - Edit Topic Area</title>
                    </Helmet>
                    <AddTopicAreaForm editing={query.get("name")} />
                </Route>
                <Route path={`${match.path}/edit-topic`}>
                    <Helmet>
                        <title>MathsPlanner - Edit Topic</title>
                    </Helmet>
                    <AddTopicForm editing={query.get("name")} />
                </Route>
                <Route path={`${match.path}/edit-subtopic`}>
                    <Helmet>
                        <title>MathsPlanner - Edit Subtopic</title>
                    </Helmet>
                    <AddSubTopicForm editing={query.get("name")} />
                </Route>
                <Route path={`${match.path}/manage-question/:subtopic?`}>
                    <Helmet>
                        <title>MathsPlanner - Manage Questions</title>
                    </Helmet>
                    <ManageQuestionsForm urlStub={match.path} />
                </Route>
                <Route path={`${match.path}/manage-mock-papers`}>
                    <Helmet>
                        <title>MathsPlanner - Manage Mock Papers</title>
                    </Helmet>
                    <ManageMockPapersForm urlStub={match.path} />
                </Route>
                <Route path={`${match.path}/manage-topic-area`}>
                    <Helmet>
                        <title>MathsPlanner - Manage Topic Areas</title>
                    </Helmet>
                    <ManageTopicAreasForm urlStub={match.path} />
                </Route>
                <Route path={`${match.path}/manage-topic`}>
                    <Helmet>
                        <title>MathsPlanner - Manage Topics</title>
                    </Helmet>
                    <ManageTopicsForm urlStub={match.path} />
                </Route>
                <Route path={`${match.path}/manage-subtopic`}>
                    <Helmet>
                        <title>MathsPlanner - Manage Subtopics</title>
                    </Helmet>
                    <ManageSubTopicsForm urlStub={match.path} />
                </Route>
                {isContentEnvironment && (
                    <Route path={`${match.path}/starter-preview`}>
                        <Helmet>
                            <title>MathsPlanner - Lesson Starter</title>
                        </Helmet>
                        <div
                            style={{
                                display: "flex",
                                height: "calc(100% - 6rem)",
                                overflow: "hidden",
                                position: "relative"
                            }}
                        >
                            <LessonActivity
                                fullscreen={fullscreen}
                                makeFullscreen={makeFullscreen}
                                loadId={null}
                                gcseStarterParams={null}
                                autoStarterParams={null}
                                saveable={false}
                            />
                        </div>
                    </Route>
                )}
                <Route exact path={match.path}>
                    <DashboardButtons />
                </Route>
            </Switch>
        </div>
    );
}

export default AdminDashboard;
