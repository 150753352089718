import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DownOutlined, UpOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Form, Input, Typography } from "antd";
import SvgIconEdit from "assets/icons/IconEdit";
import { EditableClassTag } from "features/classTags/ClassTag";
import { getSaveLocationString } from "helpers/starterHelpers";
import { getTimestampName } from "helpers/miscHelpers";
import {
    useRenameStarterMutation,
    useSetStarterClassTagMutation
} from "features/timetable/savesSlice";
import debounce from "lodash.debounce";

const { Text } = Typography;

function SaveDetailsForm({
    defaultNamePrefix = "",
    saveId = null,
    saveDetails = null,
    isDropdown = true,
    onSaveNew = () => {}
}) {
    const [renameStarter] = useRenameStarterMutation();
    const [setClassTag] = useSetStarterClassTagMutation();

    const [isOpen, setIsOpen] = useState(!isDropdown);
    const [showClassTags, setShowClassTags] = useState(false);
    const [localName, setLocalName] = useState(
        saveDetails?.name || defaultNamePrefix + getTimestampName()
    );
    const [localClassId, setLocalClassId] = useState(saveDetails?.classId);

    const updateStarterName = useCallback(
        (saveId, newName) => {
            renameStarter({ id: saveId, newName: newName });
        },
        [renameStarter]
    );

    const debouncedUpdateName = useMemo(
        () =>
            debounce(
                (saveId, newName) => updateStarterName(saveId, newName),
                2000,
                {
                    leading: true,
                    trailing: true
                }
            ),
        [updateStarterName]
    );
    useEffect(() => {
        if (!saveId) {
            return;
        }
        if (localName !== saveDetails?.name) {
            debouncedUpdateName(saveId, localName);
        }
    }, [debouncedUpdateName, localName, saveDetails?.name, saveId]);

    useEffect(() => {
        setLocalName(
            saveDetails?.name || defaultNamePrefix + getTimestampName()
        );
    }, [defaultNamePrefix, saveDetails?.name]);

    const saveDetailsForm = (
        <Form
            layout={"vertical"}
            className={"starter_type_selector__details_form"}
        >
            <Form.Item label={"Save Name"}>
                <Input
                    value={localName}
                    onChange={(e) => setLocalName(e.target.value)}
                    suffix={<SvgIconEdit />}
                />
            </Form.Item>
            <Form.Item label={"Class Tag"} name={"class_tag"}>
                <EditableClassTag
                    classId={localClassId}
                    onClassSelect={(e, c) => {
                        setLocalClassId(c.id);
                        if (saveId) {
                            setClassTag({
                                id: saveId,
                                newClassId: c.id
                            })
                                .unwrap()
                                .then(() => setShowClassTags(false));
                        } else {
                            setShowClassTags(false);
                        }
                    }}
                    showList={showClassTags}
                    setShowList={setShowClassTags}
                    suffix={<SvgIconEdit />}
                />
            </Form.Item>
        </Form>
    );

    const saveLocation = (
        <p className={"starter_type_selector__save_location"}>
            Save Location:{" "}
            <span className={"starter_type_selector__save_location--accented"}>
                {getSaveLocationString(
                    saveDetails?.timetableColumn,
                    saveDetails?.timetablePeriod
                )}
            </span>
        </p>
    );

    return (
        <div
            className={
                "starter_type_selector__drop_down lesson_type_modal__accent_box " +
                (isOpen ? "visible " : "hidden ")
            }
        >
            <div
                className={"starter_type_selector__drop_down__header"}
                onClick={() => setIsOpen((isOpen) => !isOpen)}
            >
                {saveDetails ? (
                    <h3>Lesson Save Details</h3>
                ) : (
                    <Text type="danger">
                        <WarningOutlined style={{ marginBottom: "3px" }} /> This
                        lesson is unsaved
                    </Text>
                )}
                {isDropdown && (
                    <span>{isOpen ? <UpOutlined /> : <DownOutlined />}</span>
                )}
            </div>
            <div className={"starter_type_selector__drop_down__content"}>
                {saveDetailsForm}
                {saveDetails ? (
                    saveLocation
                ) : (
                    <Button
                        type={"primary"}
                        style={{ marginTop: "1.5rem" }}
                        onClick={() =>
                            onSaveNew({
                                name: localName,
                                classId: localClassId
                            })
                        }
                    >
                        Save to Drawer
                    </Button>
                )}
            </div>
        </div>
    );
}

export default SaveDetailsForm;
