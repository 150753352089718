import React, { useCallback, useEffect, useState } from "react";

import { apiGet, apiPost, apiPut } from "helpers/api";
import {
    Alert,
    Button,
    Cascader,
    Checkbox,
    Form,
    Input,
    message,
    Popconfirm,
    Radio,
    Tooltip
} from "antd";
import SelectWithInput from "commonComponents/SelectWithInput";
import {
    filterCascader,
    getImage,
    renderCascader,
    TexComponent
} from "helpers/questionHelpers";
import { ListForm } from "./ListForm";
import { withCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import {
    removeDifficultiesFromTopicsTree,
    tooltipColour
} from "helpers/starterHelpers";
import { StaticQuestionCard } from "features/lesson/QuestionCard";
import { ErrorBoundary } from "react-error-boundary";
import { Chart } from "react-chartjs-2";
import {
    chartConfigHelpText,
    chartTypes,
    getChartDataTemplate,
    getChartOptionsTemplate
} from "../helpers/adminChartHelpers";
import { registerChartJSElements } from "helpers/chartHelpers";
import { InfoCircleOutlined } from "@ant-design/icons";

registerChartJSElements();

function PreviewCard({
    label,
    colour,
    size = "normal",
    innerIcon = null,
    aboveComponent = null,
    hasHoverState = false,
    children
}) {
    return (
        <div className={"previewCardWrapper"}>
            {aboveComponent || undefined}
            <div
                className={
                    "previewCard " +
                    colour +
                    " " +
                    size +
                    " " +
                    (hasHoverState ? "hover" : "")
                }
            >
                {innerIcon && (
                    <div className={"cardLogo"}>
                        <img src={innerIcon} alt="Notion logo" />
                    </div>
                )}
                <div className={"cardContents"}>
                    <h2>{label}</h2>
                    {children}
                </div>
            </div>
        </div>
    );
}

const parseGeneratorName = (name) => {
    const [prefix, ...indexChars] = name;
    const index = parseInt(indexChars.join(""));
    return { prefix, index };
};

const getNumberOfGeneratorsOfType = (prefix, form) => {
    switch (prefix) {
        case "v":
            return form.getFieldValue("variableGeneratorIds").length;
        case "c":
            return form.getFieldValue("contextGeneratorIds").length;
        case "a":
            return form.getFieldValue("answerLambdaIds").length;
        default:
            return Math.max(
                form.getFieldValue("variableGeneratorIds").length,
                form.getFieldValue("contextGeneratorIds").length,
                form.getFieldValue("answerLambdaIds").length
            );
    }
};

const applyToEachField = (fn, form) => {
    const formCopy = Object.assign({}, form.getFieldsValue());
    if (formCopy?.question) {
        formCopy.question = fn(formCopy.question);
    }
    if (formCopy?.answer) {
        formCopy.answer = fn(formCopy.answer);
    }
    if (formCopy?.variableGeneratorIds) {
        formCopy.variableGeneratorIds = formCopy.variableGeneratorIds.map(
            (val) => (val ? fn(val) : val)
        );
    }
    if (formCopy?.contextGeneratorIds) {
        formCopy.contextGeneratorIds = formCopy.contextGeneratorIds.map(
            (val) => (val ? fn(val) : val)
        );
    }
    if (formCopy?.answerLambdaIds) {
        formCopy.answerLambdaIds = formCopy.answerLambdaIds.map((val) =>
            val ? fn(val) : val
        );
    }
    if (formCopy?.chartDataJSON) {
        formCopy.chartDataJSON = fn(formCopy.chartDataJSON);
    }
    if (formCopy?.chartOptionsJSON) {
        formCopy.chartOptionsJSON = fn(formCopy.chartOptionsJSON);
    }
    form.setFieldsValue(formCopy);
};

function AddQuestionForm({
    cookies,
    editing = null,
    isImage = false,
    isChart = false
}) {
    const [form] = Form.useForm();
    const [reRender, toggleReRender] = useState(true);
    const [subTopics, setSubTopics] = useState([]);
    const [answerFunctions, setAnswerFunctions] = useState([]);
    const [variableFunctions, setVariableFunctions] = useState([]);
    const [contextFunctions, setContextFunctions] = useState([]);
    const [preview, setPreview] = useState(null);
    const [previewError, setPreviewError] = useState(null);
    const [questionParseError, setQuestionError] = useState(null);
    const [answerParseError, setAnswerError] = useState(null);
    const [csrfToken] = useState(cookies.get("XSRF-TOKEN"));
    const [hasIssue, setHasIssue] = useState({
        hasIssue: false,
        description: null
    });
    const [hideIssue, setHideIssue] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (editing) {
            apiGet(
                "/api/question?id=" + encodeURIComponent(editing),
                (body) => {
                    const topicArea = subTopics.find((ta) =>
                        (ta?.children || [])
                            .flatMap((t) => t?.children || [])
                            .map((st) => st.value)
                            .includes(body.subTopic)
                    );
                    const topic = subTopics
                        .flatMap((ta) => ta?.children || [])
                        .find((t) =>
                            (t?.children || [])
                                .map((st) => st.value)
                                .includes(body.subTopic)
                        );
                    let copy = {};
                    copy.noVarGenerators = body.variableGeneratorIds.length;
                    copy.variableGeneratorIds = body.variableGeneratorIds;
                    copy.contextGeneratorIds = body.contextGeneratorIds;
                    copy.description = body.description;
                    copy.subTopic = [
                        topicArea.value,
                        topic.value,
                        body.subTopic
                    ];
                    copy.difficulty = body.difficulty;
                    copy.question = body.question;
                    copy.answer = body.answer;
                    copy.answerLambdaIds = body.answerLambdaIds;
                    copy.imageUrlSuffix = body?.imageUrlSuffix;
                    copy.answerImageUrlSuffix = body?.answerImageUrlSuffix;
                    copy.gcseLevel = body.gcseLevel;
                    copy.calculatorAllowed = body.calculatorAllowed;
                    copy.skillsQuestion = body.skillsQuestion;
                    copy.chartType = body?.chartData?.type;
                    copy.chartDataJSON = JSON.stringify(
                        body?.chartData?.data,
                        null,
                        2
                    );
                    copy.chartOptionsJSON = JSON.stringify(
                        body?.chartData?.options,
                        null,
                        2
                    );
                    copy.answerChartType = body?.answerChartData?.type;
                    copy.answerChartDataJSON = JSON.stringify(
                        body?.answerChartData?.data,
                        null,
                        2
                    );
                    copy.answerChartOptionsJSON = JSON.stringify(
                        body?.answerChartData?.options,
                        null,
                        2
                    );
                    form && form.setFieldsValue(copy);
                    setHasIssue({
                        hasIssue: body.issueReported,
                        reporterId: body.issueReporterId,
                        description: body.issueDescription
                    });
                    setHideIssue(false);
                    toggleReRender((p) => !p);
                }
            );
        }
    }, [editing, form, subTopics]);

    // Load question topics
    useEffect(() => {
        setSubTopics([]);
        apiGet(
            "/api/all-topics-tree",
            (body) => {
                setSubTopics(removeDifficultiesFromTopicsTree(body, true));
            },
            () => {
                const error = {
                    value: "error",
                    label: "Error: Unable to retrieve topics",
                    disabled: true
                };
                setSubTopics([error]);
            }
        );
    }, []);

    useEffect(
        () =>
            apiGet("/api/answer-functions", (body) => {
                setAnswerFunctions(
                    body.map((afn) => ({ label: afn, value: afn }))
                );
            }),
        []
    );

    useEffect(
        () =>
            apiGet("/api/variable-functions", (body) =>
                setVariableFunctions(
                    body.map((vfn) => ({ label: vfn, value: vfn }))
                )
            ),
        []
    );

    useEffect(
        () =>
            apiGet("/api/context-functions", (body) =>
                setContextFunctions(
                    body.map((vfn) => ({ label: vfn, value: vfn }))
                )
            ),
        []
    );

    useEffect(() => {
        if (!form) {
            return;
        }
        setPreview(null);
        setPreviewError(null);
        setQuestionError(null);
        setAnswerError(null);

        function b64EncodeUnicode(str) {
            return btoa(
                encodeURIComponent(str).replace(
                    /%([0-9A-F]{2})/g,
                    (match, p1) => String.fromCharCode(parseInt(p1, 16))
                )
            );
        }

        const previewData = {
            question: form.getFieldValue("question"),
            answer: form.getFieldValue("answer"),
            variableGeneratorIds: b64EncodeUnicode(
                JSON.stringify(
                    (form.getFieldValue("variableGeneratorIds") || []).map(
                        (field) => field ?? ""
                    )
                )
            ),
            contextGeneratorIds: b64EncodeUnicode(
                JSON.stringify(
                    (form.getFieldValue("contextGeneratorIds") || []).map(
                        (field) => field ?? ""
                    )
                )
            ),
            answerLambdaIds: b64EncodeUnicode(
                JSON.stringify(
                    (form.getFieldValue("answerLambdaIds") || []).map(
                        (field) => field ?? ""
                    )
                )
            ),
            ...(isChart
                ? {
                      chartConfig: b64EncodeUnicode(
                          JSON.stringify({
                              type: form.getFieldValue("chartType"),
                              data: form.getFieldValue("chartDataJSON") ?? "",
                              options:
                                  form.getFieldValue("chartOptionsJSON") ?? ""
                          })
                      )
                  }
                : {}),
            ...(isChart
                ? {
                      answerChartConfig: b64EncodeUnicode(
                          JSON.stringify({
                              type: form.getFieldValue("answerChartType"),
                              data:
                                  form.getFieldValue("answerChartDataJSON") ??
                                  "",
                              options:
                                  form.getFieldValue(
                                      "answerChartOptionsJSON"
                                  ) ?? ""
                          })
                      )
                  }
                : {})
        };
        const url =
            "/api/preview?" +
            Object.keys(previewData)
                .map(function (field) {
                    return (
                        encodeURIComponent(field) +
                        "=" +
                        encodeURIComponent(previewData[field])
                    );
                })
                .join("&");
        const controller = new AbortController();
        apiGet(
            url,
            (body) => {
                setPreview(body);
                setPreviewError(null);
            },
            setPreviewError,
            controller.signal
        );

        return () => {
            controller.abort("component unmount");
        };
    }, [form, isChart, reRender]);

    function formatDescription(descriptionString) {
        const descriptions = descriptionString.split("Type");
        let parsed = [];
        for (const str of descriptions) {
            if (!str.length) {
                continue;
            }
            const matches = str.match(
                /:?\s*\[?(.*)]?\.\s*Description:\s*(.*)\.\s*Question:\s*({.*})\s*/
            );
            if (matches) {
                parsed.push(
                    <>
                        <b>Type(s):</b> {matches?.[1].replaceAll(/["[\]]/g, "")}
                        <br />
                        <b>Description:</b> {matches?.[2]}
                        <br />
                        <b>Question:</b> {JSON.parse(matches?.[3])?.question}
                        <br />
                        <b>Answer:</b> {JSON.parse(matches?.[3])?.answer}
                        <br />
                        <br />
                    </>
                );
            } else {
                const matches = str.match(
                    /:?\s*\[?(.*)]?\.\s*Description:\s*(.*)\s*/
                );
                if (matches) {
                    parsed.push(
                        <>
                            <b>Type(s):</b>{" "}
                            {matches?.[1].replaceAll(/["[\]]/g, "")}
                            <br />
                            <b>Description:</b> {matches?.[2]}
                            <br />
                            <br />
                        </>
                    );
                } else {
                    parsed.push(str);
                }
            }
        }
        return <>{parsed}</>;
    }

    function onSubmit(formData) {
        const addEndpoint = isImage
            ? "/api/add-image-question"
            : isChart
              ? "/api/add-chart-question"
              : "/api/add-statement-question";
        const updateEndpoint = isImage
            ? "/api/update-image-question?id="
            : isChart
              ? "/api/update-chart-question?id="
              : "/api/update-statement-question?id=";
        const editEndpoint = isImage
            ? "/admin/edit-image-question?id="
            : isChart
              ? "/admin/edit-chart-question?id="
              : "/admin/edit-statement-question?id=";

        formData.variableGeneratorIds = formData.variableGeneratorIds || [];
        formData.contextGeneratorIds = formData.contextGeneratorIds || [];
        formData.answerLambdaIds = formData.answerLambdaIds || [];
        formData.subTopic = formData.subTopic[2];
        if (!editing || formData.saveAsNew) {
            apiPost(
                addEndpoint,
                JSON.stringify(formData),
                csrfToken,
                async (res) => {
                    const body = await res.json();
                    message.success("Success");
                    history.replace(editEndpoint + encodeURIComponent(body.id));
                }
            );
        } else {
            apiPut(
                updateEndpoint + encodeURIComponent(editing),
                JSON.stringify(formData),
                csrfToken
            );
        }
    }

    const isEditing = !(!editing || form.getFieldValue("saveAsNew"));

    const onGeneratorAdd = useCallback(
        (added) => {
            const { prefix, index } = parseGeneratorName(added);
            const maxIndex = getNumberOfGeneratorsOfType(prefix, form);
            // Iterate backwards
            applyToEachField((field) => {
                let fieldCopy = field;
                for (let i = maxIndex; i >= index; i--) {
                    fieldCopy = fieldCopy.replaceAll(
                        RegExp(String.raw`${prefix}${i}(?![0-9])`, "g"),
                        `${prefix}${i + 1}`
                    );
                }
                return fieldCopy;
            }, form);
            toggleReRender((p) => !p);
        },
        [form]
    );

    const onGeneratorRemove = useCallback(
        (removed) => {
            const { prefix, index } = parseGeneratorName(removed);
            const maxIndex = getNumberOfGeneratorsOfType(prefix, form);
            applyToEachField((field) => {
                let fieldCopy = field;
                fieldCopy = fieldCopy.replaceAll(
                    RegExp(String.raw`${prefix}${index}(?![0-9])`, "g"),
                    `${prefix}_{??}`
                );
                // Iterate forwards
                for (let i = index + 1; i <= maxIndex; i++) {
                    fieldCopy = fieldCopy.replaceAll(
                        RegExp(String.raw`${prefix}${i}(?![0-9])`, "g"),
                        `${prefix}${i - 1}`
                    );
                }
                return fieldCopy;
            }, form);
            toggleReRender((p) => !p);
        },
        [form]
    );

    return (
        <>
            <h1>
                {(isImage ? "Image " : isChart ? "Chart " : "") +
                    "Question " +
                    (isEditing ? "Editor" : "Creator")}
            </h1>
            <div className={"sideBySideContainer"}>
                <div className={"formContainer"}>
                    <Form
                        onFieldsChange={() => {
                            toggleReRender((p) => !p);
                        }}
                        form={form}
                        onFinish={onSubmit}
                        onFinishFailed={({ errorFields }) =>
                            message.error(
                                <>
                                    <p>Failed to submit - please check data:</p>
                                    {[
                                        ...new Set(
                                            errorFields
                                                .flatMap((obj) => obj.errors)
                                                .map((err) => err.toString())
                                        )
                                    ].map((errorMessage) => (
                                        <p>{errorMessage}</p>
                                    ))}
                                </>
                            )
                        }
                        requiredMark={false}
                    >
                        {isEditing && hasIssue.hasIssue && !hideIssue && (
                            <Form.Item>
                                <Alert
                                    message="Issue Reported"
                                    description={
                                        <>
                                            <b>Reporter ID: </b>
                                            {hasIssue.reporterId || "Unknown"}
                                            <br />
                                            <br />
                                            {hasIssue.description ? (
                                                formatDescription(
                                                    hasIssue.description
                                                )
                                            ) : (
                                                <b>No Description Available</b>
                                            )}
                                            <br />
                                            <br />
                                            <Popconfirm
                                                title={
                                                    <>
                                                        Has the issue been
                                                        resolved, and{" "}
                                                        <b>saved</b>?
                                                    </>
                                                }
                                                onConfirm={() => {
                                                    apiPut(
                                                        "/api/resolve-issue?id=" +
                                                            encodeURIComponent(
                                                                editing
                                                            ),
                                                        {},
                                                        csrfToken,
                                                        () => setHideIssue(true)
                                                    );
                                                }}
                                                onCancel={() => {}}
                                                okText="Yes"
                                                cancelText="Cancel"
                                            >
                                                <Button>
                                                    Mark as Resolved
                                                </Button>
                                            </Popconfirm>
                                        </>
                                    }
                                    type="error"
                                    showIcon
                                />
                            </Form.Item>
                        )}
                        {isImage && (
                            <Form.Item
                                name={"imageUrlSuffix"}
                                label={"Image URL"}
                                validateTrigger={"onSubmit"}
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                value ||
                                                getFieldValue(
                                                    "answerImageUrlSuffix"
                                                )
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    "Either question or answer needs an image URL"
                                                )
                                            );
                                        }
                                    })
                                ]}
                                className={"primaryLabel topLabel"}
                            >
                                <Input
                                    placeholder="E.g. triangles/sine-rule-1.svg"
                                    prefix={
                                        process.env.REACT_APP_IMAGE_DIRECTORY
                                    }
                                />
                            </Form.Item>
                        )}
                        {isImage && (
                            <Form.Item
                                name={"answerImageUrlSuffix"}
                                label={"Answer Image URL"}
                                validateTrigger={"onSubmit"}
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                value ||
                                                getFieldValue("imageUrlSuffix")
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    "Either question or answer needs an image URL"
                                                )
                                            );
                                        }
                                    })
                                ]}
                                className={"primaryLabel topLabel"}
                            >
                                <Input
                                    placeholder="E.g. triangles/sine-rule-1.svg"
                                    prefix={
                                        process.env.REACT_APP_IMAGE_DIRECTORY
                                    }
                                />
                            </Form.Item>
                        )}
                        <Form.Item
                            name={"description"}
                            label={"Description"}
                            rules={[{ required: true }]}
                            className={"topLabel"}
                        >
                            <Input placeholder="E.g. Adding two 2-digit integers." />
                        </Form.Item>
                        <div className={"subTopicFormContainer"}>
                            <Form.Item
                                name={"subTopic"}
                                label={"Sub Topic"}
                                className={"topLabel"}
                                rules={[
                                    { required: true },
                                    {
                                        validator: (_, value) =>
                                            value?.[2]
                                                ? Promise.resolve()
                                                : Promise.reject(
                                                      new Error(
                                                          "Select a Sub-Topic"
                                                      )
                                                  )
                                    }
                                ]}
                            >
                                <Cascader
                                    popupClassName={"topicsPopup"}
                                    options={subTopics}
                                    placeholder="Search or Select Sub Topic"
                                    showSearch={{
                                        filter: filterCascader,
                                        render: renderCascader
                                    }}
                                    changeOnSelect={true}
                                    className="topicSelector"
                                />
                            </Form.Item>
                            <Form.Item
                                name={"difficulty"}
                                className={"solidRadioButtons"}
                                // label={"Difficulty"}
                                rules={[{ required: true }]}
                            >
                                <Radio.Group buttonStyle="solid">
                                    <Radio.Button value={"Easy"}>
                                        Easy
                                    </Radio.Button>
                                    <Radio.Button value={"Medium"}>
                                        Medium
                                    </Radio.Button>
                                    <Radio.Button value={"Hard"}>
                                        Hard
                                    </Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                        <Form.Item
                            name={"gcseLevel"}
                            label={"GCSE Grade"}
                            className={"topLabel"}
                        >
                            <Radio.Group>
                                {Array.from({ length: 9 }, (v, k) => k + 1).map(
                                    (k) => (
                                        <Radio key={k} value={k}>
                                            {k}
                                        </Radio>
                                    )
                                )}
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name={"calculatorAllowed"}
                            label={"Calculator?"}
                            className={"topLabel"}
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                        <Form.Item
                            name={"skillsQuestion"}
                            label={"Skills Question?"}
                            className={"topLabel"}
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                        {isChart && (
                            <>
                                <h2>
                                    Question Chart Configuration{" "}
                                    <Tooltip
                                        title={chartConfigHelpText}
                                        color={tooltipColour}
                                        overlayInnerStyle={{
                                            width: "min(65rem, 90vw)"
                                        }}
                                    >
                                        <InfoCircleOutlined
                                            style={{ color: "gray" }}
                                        />
                                    </Tooltip>
                                </h2>
                                <Form.Item
                                    name={"chartType"}
                                    label={"Chart Type"}
                                    className={"topLabel"}
                                    wrapperCol={{ span: 6 }}
                                >
                                    <SelectWithInput
                                        onSelect={(label) => {
                                            form.setFieldsValue({
                                                chartDataJSON: JSON.stringify(
                                                    getChartDataTemplate(label),
                                                    null,
                                                    2
                                                ),
                                                chartOptionsJSON:
                                                    JSON.stringify(
                                                        getChartOptionsTemplate(
                                                            label
                                                        ),
                                                        null,
                                                        2
                                                    ),
                                                chartType:
                                                    label?.split(" ")?.[0]
                                            });
                                        }}
                                        options={chartTypes.map((type) => ({
                                            label: type,
                                            value: type
                                        }))}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={"Data"}
                                    className={"topLabel"}
                                    name={"chartDataJSON"}
                                >
                                    <Input.TextArea
                                        data-gramm="false"
                                        data-gramm_editor="false"
                                        data-enable-grammarly="false"
                                        autoSize={{ minRows: 3 }}
                                        style={{
                                            fontFamily:
                                                "Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace"
                                        }}
                                        disabled={
                                            !form.getFieldValue("chartType")
                                        }
                                        placeholder={`{
  datasets: [{
    data: [10, 20, 30, 40, 50, 60, 70]
  }]
}`}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={"Options"}
                                    className={"topLabel"}
                                    name={"chartOptionsJSON"}
                                >
                                    <Input.TextArea
                                        data-gramm="false"
                                        data-gramm_editor="false"
                                        data-enable-grammarly="false"
                                        autoSize={{ minRows: 3 }}
                                        style={{
                                            fontFamily:
                                                "Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace"
                                        }}
                                        disabled={
                                            !form.getFieldValue("chartType")
                                        }
                                        placeholder={`{
  scales: {
    y: {
      beginAtZero: true
    }
  }
}`}
                                    />
                                </Form.Item>
                            </>
                        )}
                        <h2>Question Format</h2>
                        <Form.Item
                            name={"question"}
                            rules={[{ required: true }]}
                            extra={
                                <>
                                    <span className={"ant-form-item-explain"}>
                                        In MathJax (TeX) format with variables{" "}
                                        {"<- {vn | n <- {0,1,...} }"}. 1 slash
                                        is sufficient to escape (e.g. \$, \frac)
                                    </span>
                                    {questionParseError && (
                                        <Alert
                                            message={questionParseError}
                                            type="error"
                                            showIcon
                                        />
                                    )}
                                </>
                            }
                        >
                            <Input.TextArea
                                autoSize={{ minRows: 2 }}
                                placeholder="E.g. What is $v0 + v1$?"
                                onPressEnter={(e) => e.preventDefault()}
                            />
                        </Form.Item>
                        <ListForm
                            name={"variableGeneratorIds"}
                            label={"Variable Generators"}
                            labelFunction={(index) => `v${index}`}
                            options={variableFunctions}
                            usesLocalAdd
                            onAdd={onGeneratorAdd}
                            onRemove={onGeneratorRemove}
                        />
                        <ListForm
                            name={"contextGeneratorIds"}
                            label={"Context Generators"}
                            labelFunction={(index) => `c${index}`}
                            options={contextFunctions}
                            required={false}
                            usesLocalAdd
                            onAdd={onGeneratorAdd}
                            onRemove={onGeneratorRemove}
                        />
                        <ListForm
                            name={"answerLambdaIds"}
                            label={"Answer Lambdas"}
                            labelFunction={(index) => `a${index}`}
                            options={answerFunctions}
                            usesLocalAdd
                            onAdd={onGeneratorAdd}
                            onRemove={onGeneratorRemove}
                        />
                        {isChart && (
                            <>
                                <h2>
                                    Answer Chart Configuration{" "}
                                    <Tooltip
                                        title={chartConfigHelpText}
                                        color={tooltipColour}
                                        overlayInnerStyle={{
                                            width: "min(65rem, 90vw)"
                                        }}
                                    >
                                        <InfoCircleOutlined
                                            style={{ color: "gray" }}
                                        />
                                    </Tooltip>
                                </h2>
                                <Form.Item
                                    name={"answerChartType"}
                                    label={"Chart Type"}
                                    className={"topLabel"}
                                    wrapperCol={{ span: 6 }}
                                >
                                    <SelectWithInput
                                        onSelect={(label) => {
                                            form.setFieldsValue({
                                                answerChartDataJSON:
                                                    JSON.stringify(
                                                        getChartDataTemplate(
                                                            label
                                                        ),
                                                        null,
                                                        2
                                                    ),
                                                answerChartOptionsJSON:
                                                    JSON.stringify(
                                                        getChartOptionsTemplate(
                                                            label
                                                        ),
                                                        null,
                                                        2
                                                    ),
                                                answerChartType:
                                                    label?.split(" ")?.[0]
                                            });
                                        }}
                                        options={chartTypes.map((type) => ({
                                            label: type,
                                            value: type
                                        }))}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={"Data"}
                                    className={"topLabel"}
                                    name={"answerChartDataJSON"}
                                >
                                    <Input.TextArea
                                        data-gramm="false"
                                        data-gramm_editor="false"
                                        data-enable-grammarly="false"
                                        autoSize={{ minRows: 3 }}
                                        style={{
                                            fontFamily:
                                                "Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace"
                                        }}
                                        disabled={
                                            !form.getFieldValue(
                                                "answerChartType"
                                            )
                                        }
                                        placeholder={`{
  datasets: [{
    data: [10, 20, 30, 40, 50, 60, 70]
  }]
}`}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={"Options"}
                                    className={"topLabel"}
                                    name={"answerChartOptionsJSON"}
                                >
                                    <Input.TextArea
                                        data-gramm="false"
                                        data-gramm_editor="false"
                                        data-enable-grammarly="false"
                                        autoSize={{ minRows: 3 }}
                                        style={{
                                            fontFamily:
                                                "Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace"
                                        }}
                                        disabled={
                                            !form.getFieldValue(
                                                "answerChartType"
                                            )
                                        }
                                        placeholder={`{
  scales: {
    y: {
      beginAtZero: true
    }
  }
}`}
                                    />
                                </Form.Item>
                            </>
                        )}
                        <h2>Answer Format</h2>
                        <Form.Item
                            name={"answer"}
                            rules={[{ required: true }]}
                            extra={
                                answerParseError && (
                                    <Alert
                                        message={answerParseError}
                                        type="error"
                                        showIcon
                                    />
                                )
                            }
                        >
                            <Input.TextArea
                                autoSize={{ minRows: 2 }}
                                placeholder="E.g. $\frac{a0}{a1}$"
                                onPressEnter={(e) => e.preventDefault()}
                            />
                        </Form.Item>

                        <div className={"submitButtonContainer"}>
                            <Form.Item>
                                {!isEditing ? (
                                    <Button
                                        key="submit"
                                        type="primary"
                                        htmlType="submit"
                                        className={"formSubmit"}
                                    >
                                        Save
                                    </Button>
                                ) : (
                                    <Popconfirm
                                        title={
                                            <>
                                                Are you sure you want to{" "}
                                                <b>overwrite</b> the current
                                                save you are editing?
                                            </>
                                        }
                                        onConfirm={form.submit}
                                        onCancel={() => {}}
                                        okText="Overwrite"
                                        cancelText="Cancel"
                                    >
                                        <Button
                                            key="submit"
                                            type="primary"
                                            htmlType="submit"
                                            className={"formSubmit"}
                                        >
                                            Update
                                        </Button>
                                    </Popconfirm>
                                )}
                            </Form.Item>
                            {editing && (
                                <Form.Item
                                    name={"saveAsNew"}
                                    label={"Save as new template?"}
                                    valuePropName="checked"
                                >
                                    <Checkbox />
                                </Form.Item>
                            )}
                        </div>
                    </Form>
                </div>
                <div className={"previewContainer"}>
                    <a
                        href={process.env?.REACT_APP_NOTION_DOCS}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <PreviewCard
                            label={"Generator Syntax Docs."}
                            colour={"orange"}
                            size={"small"}
                            innerIcon={
                                process.env.PUBLIC_URL + "/svgs/notion.svg"
                            }
                            hasHoverState={process.env?.REACT_APP_NOTION_DOCS}
                        >
                            All support docs and examples of syntax required can
                            be found on Notion
                        </PreviewCard>
                    </a>
                    <PreviewCard
                        label={"Question Format Preview"}
                        colour={"pink"}
                        aboveComponent={
                            <div className={"iconContainer"}>
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/svgs/question-steps.svg"
                                    }
                                    alt="Notion logo"
                                />
                            </div>
                        }
                    >
                        {form.getFieldValue("question") && (
                            <TexComponent
                                tex={form.getFieldValue("question")}
                            />
                        )}
                    </PreviewCard>
                    {isImage && (
                        <PreviewCard label={"Image Preview"} colour={"purple"}>
                            <div className={"imagePreviewContainer"}>
                                {form.getFieldValue("imageUrlSuffix") &&
                                    getImage(
                                        form.getFieldValue("imageUrlSuffix"),
                                        "formImage"
                                    )}
                            </div>
                        </PreviewCard>
                    )}

                    <PreviewCard
                        label={"Question Preview"}
                        colour={!previewError ? "blue" : "red"}
                        aboveComponent={
                            <div
                                className={"refreshContainer"}
                                onClick={() => toggleReRender((p) => !p)}
                            >
                                <div className={"iconContainer"}>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/svgs/infinite.svg"
                                        }
                                        alt="Notion logo"
                                    />
                                </div>
                                <Button type={"link"}>Refresh</Button>
                            </div>
                        }
                    >
                        <span className="overflow-auto">
                            {previewError && "Error: " + previewError?.message}
                        </span>
                        {preview && (
                            <TexComponent
                                tex={preview.question}
                                onParseError={setQuestionError}
                            />
                        )}
                    </PreviewCard>

                    {isChart && preview && preview?.chartData?.type && (
                        <PreviewCard label={"Chart Preview"} colour={"purple"}>
                            <div className={"chartPreviewContainer"}>
                                <ErrorBoundary
                                    fallback={<div>Something went wrong</div>}
                                    key={
                                        preview.chartData.type +
                                        preview.chartData?.data +
                                        preview.chartData?.options
                                    }
                                >
                                    <Chart
                                        type={preview.chartData.type}
                                        data={preview.chartData?.data}
                                        options={
                                            preview.chartData?.options || {}
                                        }
                                        plugins={[]}
                                    />
                                </ErrorBoundary>
                            </div>
                        </PreviewCard>
                    )}

                    {isImage && (
                        <PreviewCard
                            label={"Answer Image Preview"}
                            colour={"purple"}
                        >
                            <div className={"imagePreviewContainer"}>
                                {form.getFieldValue("answerImageUrlSuffix") &&
                                    getImage(
                                        form.getFieldValue(
                                            "answerImageUrlSuffix"
                                        ),
                                        "formImage"
                                    )}
                            </div>
                        </PreviewCard>
                    )}
                    <PreviewCard
                        label={"Answer Preview"}
                        colour={!previewError ? "green" : "red"}
                    >
                        {preview && (
                            <TexComponent
                                tex={preview.answer}
                                onParseError={setAnswerError}
                            />
                        )}
                    </PreviewCard>

                    {isChart && preview && preview?.answerChartData?.type && (
                        <PreviewCard
                            label={"Answer Chart Preview"}
                            colour={"purple"}
                        >
                            <div className={"chartPreviewContainer"}>
                                <ErrorBoundary
                                    fallback={<div>Something went wrong</div>}
                                    key={
                                        preview.answerChartData.type +
                                        preview.answerChartData?.data +
                                        preview.answerChartData?.options
                                    }
                                >
                                    <Chart
                                        type={preview.answerChartData.type}
                                        data={preview.answerChartData?.data}
                                        options={
                                            preview.answerChartData?.options ||
                                            {}
                                        }
                                        plugins={[]}
                                    />
                                </ErrorBoundary>
                            </div>
                        </PreviewCard>
                    )}

                    <div style={{ flexBasis: "30rem", flexShrink: 0 }}>
                        <StaticQuestionCard
                            questionComponent={{
                                question: preview && (
                                    <TexComponent tex={preview.question} />
                                ),
                                imageUrlSuffix:
                                    isImage &&
                                    form.getFieldValue("imageUrlSuffix"),
                                answerImageUrlSuffix:
                                    isImage &&
                                    form.getFieldValue("answerImageUrlSuffix"),
                                answer: preview && (
                                    <TexComponent tex={preview.answer} />
                                ),
                                calculatorAllowed:
                                    form.getFieldValue("calculatorAllowed"),
                                chartData:
                                    isChart && preview && preview.chartData,
                                answerChartData:
                                    isChart &&
                                    preview &&
                                    preview.answerChartData
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default withCookies(AddQuestionForm);
