import * as React from "react";

const SvgCalculatorAllowed = ({ allowed = true, props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={"1em"}
        height={"1em"}
        viewBox={"0 0 238 243"}
        fill="none"
        {...props}
    >
        <path
            fill="#CDE4FF"
            stroke="#004AAD"
            strokeWidth={4}
            d="M175.176 211.186c6.463 0 12.002-4.924 12.002-12.003V42.848c0-6.463-4.308-9.848-11.694-9.848H59.771C52.693 33 49 36.078 49 44.694v154.489c0 6.771 2.77 11.695 10.771 12.003h115.405Z"
        />
        <path
            fill="#fff"
            stroke="#004AAD"
            strokeWidth={4}
            d="M166.252 83.162c4.951 0 4.616-3.384 4.616-4.924V48.387c0-1.407-.189-4.309-5.847-4.309H69.927c-5.423 0-5.232 2.741-5.232 4.616v28.313c0 3.078 0 6.155 4.616 6.155h96.941Z"
        />
        <path
            fill="#004AAD"
            d="M108.654 195.49c2.015 0 3.742-1.156 3.742-2.819V155.95c0-1.518-1.343-2.313-3.646-2.313H72.67c-2.207 0-3.359.723-3.359 2.747v36.287c0 1.59.864 2.747 3.359 2.819h35.984ZM164.664 195.49c2.015 0 3.742-1.156 3.742-2.819V155.95c0-1.518-1.344-2.313-3.646-2.313h-36.08c-2.207 0-3.359.723-3.359 2.747v36.287c0 1.59.864 2.747 3.359 2.819h35.984ZM164.664 140.711c2.015 0 3.742-1.157 3.742-2.819v-36.721c0-1.518-1.344-2.314-3.646-2.314h-36.08c-2.207 0-3.359.723-3.359 2.747v36.288c0 1.59.864 2.747 3.359 2.819h35.984ZM108.654 140.711c2.015 0 3.742-1.157 3.742-2.819v-36.721c0-1.518-1.343-2.314-3.646-2.314H72.67c-2.207 0-3.359.723-3.359 2.747v36.288c0 1.59.864 2.747 3.359 2.819h35.984Z"
        />
        <path
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth={8}
            d="m81.006 165.023 10.31 10.002m10.309 10.002-10.31-10.002m0 0 10.31-10.002m-10.31 10.002-10.31 10.002M78.852 119.99H91.06m11.488 0H91.06m0 0v-12.208m0 12.208v12.104m42.263-12.104h28.005m-28.62 49.034h28.005m-28.006 12.925h28.005"
        />
        {allowed ? (
            <path
                stroke="#1BC400"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={22}
                d="M34 129.969 83.714 180C152.726 109.098 166.041 95.804 196 67"
            />
        ) : (
            <path
                stroke="#FC0000"
                strokeLinecap="round"
                strokeWidth={22}
                d="M48.765 204.286 186.387 34.598"
            />
        )}
    </svg>
);
export default SvgCalculatorAllowed;
