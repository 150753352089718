import { Switch } from "antd";
import React, { useState } from "react";

function CustomSwitch({
    falseOption = null,
    trueOption,
    selected,
    setSelected,
    disabled = false,
    onChange = () => {}
}) {
    const switchComponent = (
        <Switch
            size={"small"}
            checked={selected}
            onChange={(e) => {
                setSelected(e);
                onChange(e);
            }}
            disabled={disabled}
        />
    );
    if (!falseOption) {
        return (
            <div
                className={
                    "customSwitch singleLabel narrowControl " +
                    (disabled ? "disabled" : "")
                }
            >
                <span>{trueOption}</span>
                {switchComponent}
            </div>
        );
    }
    return (
        <div className={"customSwitch " + (disabled ? "disabled" : "")}>
            <span className={!selected ? "selected" : ""}>{falseOption}</span>
            {switchComponent}
            <span className={selected ? "selected" : ""}>{trueOption}</span>
        </div>
    );
}

export const useCustomSwitch = (
    falseOption,
    trueOption,
    defaultValue = false,
    disabled = false,
    onChange = () => {}
) => {
    const [selected, setSelected] = useState(defaultValue);
    return [
        <CustomSwitch
            {...{
                selected,
                setSelected,
                trueOption,
                falseOption,
                disabled,
                onChange
            }}
        />,
        selected,
        setSelected
    ];
};
