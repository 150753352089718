import * as React from "react";

const SvgIconRedo = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m7.25 5.146-.501-.865.502.865ZM4.14 16.82l-.867.498.867-.498Zm11.61 3.128-1-.017a1 1 0 0 0 .982 1.017l.017-1Zm3.11-11.674.867-.498-.867.498ZM5.05 19.557a1 1 0 1 0 1.361-1.465L5.05 19.557ZM16.8 16.85a1 1 0 0 0-2-.033l2 .033ZM18.83 21a1 1 0 0 0 .034-2l-.034 2ZM6.749 4.28C2.206 6.92.653 12.755 3.273 17.318l1.734-.996c-2.074-3.612-.84-8.229 2.746-10.31l-1.004-1.73Zm9.502 16.531c4.543-2.637 6.096-8.473 3.476-13.036l-1.734.996c2.074 3.612.84 8.229-2.746 10.31l1.004 1.73Zm3.476-13.036C17.106 3.211 11.295 1.642 6.75 4.281l1.004 1.73c3.583-2.08 8.168-.848 10.24 2.761l1.734-.996ZM3.273 17.318a9.538 9.538 0 0 0 1.777 2.239l1.361-1.465a7.536 7.536 0 0 1-1.404-1.77l-1.734.996Zm11.529-.501-.053 3.114 2 .033.052-3.114-2-.033Zm.93 4.13L18.83 21l.034-2-3.097-.052-.034 2Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgIconRedo;
