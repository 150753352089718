import { configureStore } from "@reduxjs/toolkit";
import { additionalApiMiddleware, apiSlice } from "helpers/apiSlice";
import dashboardSlice from "features/dashboard/dashboardSlice";
import utilsSlice from "helpers/utilsSlice";

export default configureStore({
    reducer: {
        dashboard: dashboardSlice.reducer,
        utils: utilsSlice.reducer,
        [apiSlice.reducerPath]: apiSlice.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([
            apiSlice.middleware,
            ...additionalApiMiddleware
        ])
});
