import { Button } from "antd";
import React from "react";

export function OurLibraryButtonRow({
    showAllOption = false,
    options,
    selected,
    setSelected,
    onClick = () => {}
}) {
    const hidden = options.length === 0;

    return (
        <div className={"our-library__button_row " + (hidden ? "hidden " : "")}>
            {showAllOption && (
                <Button
                    onClick={() => {
                        setSelected(null);
                        onClick(null);
                    }}
                    className={
                        "our-library__topic_area_button " +
                        (null === selected ? "current " : "")
                    }
                >
                    All
                </Button>
            )}
            {options.map((t) => (
                <Button
                    key={t.label}
                    onClick={() => {
                        if (t?.disabled) {
                            return;
                        }
                        setSelected((prev) =>
                            t.label === prev?.label ? null : t
                        );
                        onClick(t);
                    }}
                    disabled={t?.disabled}
                    className={
                        "our-library__topic_area_button " +
                        (t.colour + " ") +
                        (t.label === selected?.label && !t?.disabled
                            ? "current "
                            : "") +
                        (t?.disabled ? "disabled " : "")
                    }
                >
                    {t.label}
                </Button>
            ))}
        </div>
    );
}
