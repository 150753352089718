import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import { Button, Result, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import { getSaveLocationString, tooltipColour } from "helpers/starterHelpers";
import { getStepIndex, TourContext } from "features/tour/Tour";
import LessonFunctionButtons from "./components/LessonFunctionButtons";
import { Helmet } from "react-helmet-async";
import StarterSection from "./components/starter/StarterSection";
import PlenarySection from "./components/plenary/PlenarySection";
import LessonContext, {
    formatStarterStateForSave,
    LessonContextProvider,
    setAllShowAnswersValue
} from "./context/LessonContext";
import {
    useGetLessonDetailsQuery,
    useUpdateSavedStarterMutation
} from "features/timetable/savesSlice";
import LessonPrintModal from "./components/printView/LessonPrintModal";
import debounce from "lodash.debounce";
import LoadingSpinner from "./components/LoadingSpinner";
import SvgIconHome from "assets/icons/IconHome";
import RoundedButton from "commonComponents/RoundedButton";

function LessonActivity({ saveable = true, ...props }) {
    return (
        <LessonContextProvider loadId={props?.loadId} saveable={saveable}>
            <Lesson {...props} />
        </LessonContextProvider>
    );
}

function Lesson({ fullscreen, makeFullscreen, loadId, autoStarterParams }) {
    const tourContext = useContext(TourContext);
    const plenaryVisible = window.location.hash === "#plenary";

    const {
        questionFontSizeState: [questionFontSize, setQuestionFontSize],
        senBackgroundState: [senBackground, setSENBackground],
        starterTitleState: [starterTitle],
        starterLearningObjectiveState: [starterLearningObjective],
        starterCueImageState: [selectedCueImage],
        starterChoicesState: [starterChoices],
        plenaryFeedbackRef,
        plenaryChoicesState: [plenaryChoices],
        starterGridDimensionsState: [starterGridDimensions],
        starterHasRecallTagsState: [starterHasRecallTags],
        staleEditsState: [staleEdits, setStaleEdits],
        loadNameState: [loadName, setLoadName],
        showStarterAnswersState: [, setShowStarterAnswers],
        showPlenaryAnswersState: [, setShowPlenaryAnswers],
        isExistingSave,
        isNewState: [, setIsNew],
        isSharedSaveState: [isSharedSave, setIsSharedSave],
        saveLocationStringState: [, setSaveLocationString],
        starterSectionRef,
        plenarySectionRef
    } = useContext(LessonContext);

    const [showPrintModal, setShowPrintModal] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (window.location.hash === "#plenary") {
        } else {
            const loc = Object.assign({}, history.location);
            loc.hash = "starter";
            history.replace(loc);
        }
    }, [history]);

    function scrollToVisible(delay = 0) {
        setTimeout(() => {
            if (window.location.hash === "#plenary") {
                document.getElementById("plenary")?.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest"
                });
            } else {
                document.getElementById("starter")?.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest"
                });
            }
        }, delay);
    }

    useEffect(() => {
        const delay = 200;
        document.addEventListener(
            "webkitfullscreenchange",
            () => scrollToVisible(delay),
            false
        );
        document.addEventListener(
            "mozfullscreenchange",
            () => scrollToVisible(delay),
            false
        );
        document.addEventListener(
            "msfullscreenchange",
            () => scrollToVisible(delay),
            false
        );
        document.addEventListener(
            "MSFullscreenChange",
            () => scrollToVisible(delay),
            false
        );
        document.addEventListener(
            "fullscreenchange",
            () => scrollToVisible(delay),
            false
        );
    });

    useEffect(
        () => {
            scrollToVisible(0);
            scrollToVisible(2000);
        },
        // eslint-disable-next-line
        [window.location.hash, fullscreen, scrollToVisible]
    );

    useEffect(() => {
        window.onbeforeunload = staleEdits ? () => true : null;
    }, [staleEdits]);

    const {
        data: loadedLesson,
        error: lessonLoadError,
        isLoading: lessonLoading
    } = useGetLessonDetailsQuery(loadId, {
        skip: !loadId
    });

    // Load starter data from loadedLesson
    useEffect(() => {
        if (!loadedLesson) {
            return;
        }
        const body = loadedLesson;
        setLoadName(body.name);
        setIsSharedSave(!body.belongsToAccessor);
        setSaveLocationString(
            getSaveLocationString(body.timetableColumn, body.timetablePeriod)
        );
        setQuestionFontSize(body.fontSize);
        setSENBackground(body.senBackground);
        if (body.new) {
            //   Don't update from true to false (if !body.new), because of double-fetched data -- only set to true
            setIsNew(true);
        }
    }, [
        loadedLesson,
        setIsNew,
        setIsSharedSave,
        setLoadName,
        setQuestionFontSize,
        setSENBackground,
        setSaveLocationString
    ]);

    const [updateSavedStarter] = useUpdateSavedStarterMutation();
    const updateStarter = useCallback(
        async (saveData) => {
            if (!isExistingSave) {
                console.error("Attempting to update a new save");
                return;
            }
            if (isSharedSave) {
                console.error("Attempting to save a shared starter");
                return;
            }
            const data = formatStarterStateForSave(saveData, loadName);
            updateSavedStarter({ id: loadId, data })
                .unwrap()
                .then(() => setStaleEdits(false));
        },
        [
            isExistingSave,
            isSharedSave,
            loadName,
            updateSavedStarter,
            loadId,
            setStaleEdits
        ]
    );

    const debouncedUpdateStarter = useMemo(
        () =>
            debounce((saveData) => updateStarter(saveData), 5000, {
                leading: true,
                trailing: true
            }),
        [updateStarter]
    );

    useEffect(() => {
        if (isExistingSave && staleEdits && !isSharedSave) {
            debouncedUpdateStarter({
                title: starterTitle,
                learningObjective: starterLearningObjective,
                plenaryTitle: null,
                feedbackRef: plenaryFeedbackRef,
                questionTopicChoices: starterChoices,
                plenaryQuestionTopicChoices: plenaryChoices,
                starterGridWidth: starterGridDimensions.width,
                starterGridHeight: starterGridDimensions.height,
                hasPlenary: true,
                hasRecallTags: starterHasRecallTags,
                plenaryGridWidth: 3,
                plenaryGridHeight: 1,
                senBackground: senBackground,
                fontSize: questionFontSize,
                cueImage: selectedCueImage
            });
        }
    }, [
        staleEdits,
        isExistingSave,
        debouncedUpdateStarter,
        isSharedSave,
        starterTitle,
        starterLearningObjective,
        starterChoices,
        plenaryChoices,
        starterGridDimensions,
        starterHasRecallTags,
        plenaryFeedbackRef,
        senBackground,
        questionFontSize,
        selectedCueImage
    ]);

    function showAllStarterAnswers(val) {
        setAllShowAnswersValue(setShowStarterAnswers, val);
    }

    function showAllPlenaryAnswers(val) {
        setAllShowAnswersValue(setShowPlenaryAnswers, val);
    }

    function activatePrintingModal() {
        setShowPrintModal(true);
    }

    const starterPlenarySwitch = (
        <>
            {!fullscreen ? (
                <div className="starter-plenary-switch">
                    <button
                        className={
                            window.location.hash === "#starter" ||
                            window.location.hash === ""
                                ? "win--active"
                                : ""
                        }
                        onClick={() => {
                            const loc = Object.assign({}, history.location);
                            loc.hash = "starter";
                            history.replace(loc);
                            showAllStarterAnswers(false);
                        }}
                    >
                        STARTER
                    </button>
                    <button
                        className={
                            window.location.hash === "#plenary"
                                ? "win--active"
                                : ""
                        }
                        onClick={() => {
                            const loc = Object.assign({}, history.location);
                            loc.hash = "plenary";
                            history.replace(loc);
                            showAllPlenaryAnswers(false);
                        }}
                    >
                        EXIT TICKET
                    </button>
                </div>
            ) : (
                <div />
            )}
        </>
    );

    return (
        <>
            {loadName !== "" && (
                <Helmet>
                    <title>{`MathsPlanner - ${loadName}`}</title>
                </Helmet>
            )}
            <div
                id={"lesson-container"}
                className="starter-container"
                data-tour={"fullscreen"}
            >
                {starterPlenarySwitch}
                <RoundedButton
                    className={
                        "homeButton icon-only-button " +
                        (plenaryVisible ? "plenary " : "starter ") +
                        (fullscreen ? "fullscreen " : "")
                    }
                    href="/dashboard"
                >
                    <SvgIconHome />
                </RoundedButton>
                {
                    <LessonPrintModal
                        isModalVisible={showPrintModal}
                        hideModal={() => setShowPrintModal(false)}
                        isShowingPlenary={plenaryVisible}
                    />
                }
                {!lessonLoadError ? (
                    !lessonLoading ? (
                        <div className={"slider"}>
                            <div className={"starter-slide"} id="starter">
                                <StarterSection
                                    fullscreen={fullscreen}
                                    makeFullscreen={makeFullscreen}
                                    isVisible={!plenaryVisible}
                                    loadId={loadId}
                                    autoStarterParams={autoStarterParams}
                                    ref={starterSectionRef}
                                />
                            </div>
                            <div className={"starter-slide"} id="plenary">
                                <PlenarySection
                                    fullscreen={fullscreen}
                                    makeFullscreen={makeFullscreen}
                                    isVisible={plenaryVisible}
                                    loadId={loadId}
                                    activatePrintingModal={
                                        activatePrintingModal
                                    }
                                    ref={plenarySectionRef}
                                />
                            </div>
                        </div>
                    ) : (
                        <LoadingSpinner />
                    )
                ) : (
                    <Result
                        className={"starterError"}
                        status="error"
                        title="Something went wrong!"
                        subTitle={
                            <>
                                You may not have permission to view this lesson,
                                it may have been deleted, or it may be an issue
                                at our end. Please try again later. If this
                                problem persists please contact:{" "}
                                <a href={"mailto:chloe@mathsplanner.com"}>
                                    chloe@mathsplanner.com
                                </a>
                            </>
                        }
                        extra={"Message: " + lessonLoadError}
                    />
                )}
            </div>
            <div className="starter-right-container">
                <LessonFunctionButtons
                    fullscreen={fullscreen}
                    makeFullscreen={makeFullscreen}
                    disabled={lessonLoadError}
                    activatePrintingModal={activatePrintingModal}
                />
                <div className={"slider-toggle-container"}>
                    {window.location.hash === "#plenary" ? (
                        <Tooltip
                            title="Switch to Starter"
                            color={tooltipColour}
                            placement={"left"}
                        >
                            {" "}
                            <Button
                                onClick={() => {
                                    const loc = Object.assign(
                                        {},
                                        history.location
                                    );
                                    loc.hash = "starter";
                                    history.replace(loc);
                                    showAllStarterAnswers(false);
                                }}
                                className={
                                    "sliderToggle " +
                                    (window.location.hash === "#plenary"
                                        ? "starter-btn"
                                        : " ")
                                }
                            >
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/svgs/Icon_arrow_up.svg"
                                    }
                                    alt={"Up Arrow"}
                                />
                            </Button>
                        </Tooltip>
                    ) : (
                        <Button disabled={true} className="sliderToggle">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/svgs/Icon_arrow_up.svg"
                                }
                                alt={"Up Arrow"}
                            />
                        </Button>
                    )}

                    {window.location.hash === "#starter" ||
                    window.location.hash === "" ? (
                        <Tooltip
                            title="Switch to Exit Ticket"
                            color={tooltipColour}
                            placement={"left"}
                        >
                            <Button
                                data-tour={"plenaryOpen"}
                                onClick={() => {
                                    if (
                                        tourContext.isOpen &&
                                        tourContext.currentStep ===
                                            getStepIndex("plenaryOpen")
                                    ) {
                                        tourContext.nextStep();
                                    }
                                    const loc = Object.assign(
                                        {},
                                        history.location
                                    );
                                    loc.hash = "plenary";
                                    history.replace(loc);
                                    showAllPlenaryAnswers(false);
                                }}
                                className="sliderToggle plenary-btn"
                            >
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/svgs/Icon_arrow_down.svg"
                                    }
                                    alt={"Down Arrow"}
                                />
                            </Button>
                        </Tooltip>
                    ) : (
                        <Button
                            data-tour={"plenaryOpen"}
                            className="sliderToggle"
                            disabled={true}
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/svgs/Icon_arrow_down.svg"
                                }
                                alt={"Down Arrow"}
                            />
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
}

export default LessonActivity;
