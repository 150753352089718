import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import MultiplicationActivity from "features/quickTables/MultiplicationActivity";
import SkillsActivity from "features/skillsActivity/SkillsActivity";

function FreeResources({ fullscreen, makeFullscreen }) {
    return (
        <div className={"publicResourcesContainer"}>
            <div
                className={
                    "publicResourcesActivityContainer " +
                    (fullscreen ? "fullscreen" : "")
                }
            >
                <Switch>
                    <Route path={`/quick-tables`}>
                        <Helmet>
                            <title>MathsPlanner - Quick Tables</title>
                        </Helmet>
                        <MultiplicationActivity
                            fullscreen={fullscreen}
                            makeFullscreen={makeFullscreen}
                        />
                    </Route>
                    <Route path={`/skills`}>
                        <Helmet>
                            <title>MathsPlanner - Skills Check</title>
                        </Helmet>
                        <SkillsActivity
                            fullscreen={fullscreen}
                            makeFullscreen={makeFullscreen}
                        />
                    </Route>
                    <Route>
                        <Redirect from="/" to={`/quick-tables`} />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}

export default FreeResources;
