import { Amplify, Auth } from "aws-amplify";
import { apiGetDataPublic } from "./api";
import { apiSlice } from "helpers/apiSlice";
import reduxStore from "app/reduxStore";
import { useEffect, useState } from "react";
import { matchPath } from "react-router-dom";
import { MOCK_PAPERS_PATH } from "app/App";

Amplify.configure({
    Auth: {
        region: "eu-west-2",
        userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        authenticationFlowType: "USER_PASSWORD_AUTH"
    }
});

export const getSubscriptionType = (user) =>
    Boolean(user) && (user["custom:subscriptionType"] || "None");
export const getUserGroups = (user) =>
    (Boolean(user) && user?.["cognito:groups"]) || [];
export const isUserAdmin = (user) => getUserGroups(user).includes("admin");
export const isUserPaid = (user) => getUserGroups(user).includes("paid");
export const isUserTrial = (user) => getUserGroups(user).includes("trial");
export const isUserAwaitingPayment = (user) =>
    getUserGroups(user).includes("awaitingPayment");

export const isUserSiteAdmin = (user) => {
    const subscriptionTypeEnumValue = getSubscriptionType(user);
    return (
        subscriptionTypeEnumValue === "Site" ||
        subscriptionTypeEnumValue === "Admin"
    );
};

export const userHasMockPapersAccess = (user) =>
    user?.["cognito:groups"]?.includes("edexcelMockPapers") ||
    user?.["cognito:groups"]?.includes("aqaMockPapers") ||
    isUserAdmin(user);

export function isEnvironmentFreeResourcesOnly() {
    return Boolean(process.env?.REACT_APP_ONLY_FREE_RESOURCES || false);
}

export function isEnvironmentContentCreationOnly() {
    return Boolean(process.env?.REACT_APP_ONLY_CONTENT_CREATION || false);
}

export async function getIdToken() {
    try {
        const session = await Auth.currentSession();
        return session.getIdToken().getJwtToken();
    } catch (err) {
        return null;
    }
}

export const useLoginState = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        getIdToken().then((token) => setIsLoggedIn(Boolean(token)));
    }, []);

    return isLoggedIn;
};

export async function getName() {
    try {
        const user = await Auth.currentUserInfo();
        return await user.attributes.given_name;
    } catch (err) {
        return null;
    }
}

export function redirectToLogin() {
    if (window.location.pathname !== "/") {
        reduxStore.dispatch(apiSlice.util.resetApiState());
        window.location.replace("/");
    }
}

// Verifies the user is logged in, otherwise redirects to the login page
export async function login() {
    try {
        await Auth.currentSession();
        // Current user exists
        window.location.reload();
    } catch (err) {
        // No current user
        await redirectToLogin();
    }
}

export async function signUp(
    email,
    password,
    forename,
    surname,
    school,
    isSite
) {
    await Auth.signUp({
        username: email,
        password: password,
        attributes: {
            email: email,
            given_name: forename,
            family_name: surname,
            "custom:school": school,
            ...(isSite ? { "custom:maxSubUsers": "30" } : {}),
            "custom:subscriptionType": isSite ? "Site" : "Single"
        }
    });
}

export async function verifySignUp(username, code) {
    await Auth.confirmSignUp(username, code);
}

export async function resendSignUp(username) {
    await Auth.resendSignUp(username);
}

export async function changePassword(oldPassword, newPassword) {
    return await Auth.currentAuthenticatedUser().then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword);
    });
}

export async function requestPasswordResetCode(username) {
    await Auth.forgotPassword(username);
}

export async function confirmPasswordReset(username, code, newPassword) {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
}

export function passwordValidator(value) {
    let valid = [true, true, true, true];
    if (!value || value.length < 8) {
        valid[0] = false;
    }
    if (!value || !value.match(/[0-9]/g)) {
        valid[1] = false;
    }
    if (!value || !value.match(/[A-Z]/g)) {
        valid[2] = false;
    }
    if (!value || !value.match(/[a-z]/g)) {
        valid[3] = false;
    }
    if (valid.some((v) => !v)) {
        return Promise.reject(new Error());
    }
    return Promise.resolve();
}

export async function signIn(
    username,
    password,
    reload = true,
    reloadPath = "/"
) {
    try {
        const user = await Auth.signIn(username, password);
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            return { status: "NEW_PASSWORD_REQUIRED", user: user };
        } else if (reload) {
            window.location.href = reloadPath;
        }
    } catch (err) {
        if (err.code === "UserNotConfirmedException") {
            const subscriptionType = (
                await apiGetDataPublic(
                    "/api/unconfirmed_subscription_type?email=" +
                        encodeURIComponent(username)
                )
            ).subscriptionType;
            return {
                status: "VERIFICATION_REQUIRED",
                user: { isSingleLicence: subscriptionType === "Single" }
            };
        }
        throw err;
    }
}

export async function forceNewPassword(user, newPassword) {
    await Auth.completeNewPassword(user, newPassword);
}

export const paymentModalVisible = (user) => {
    const hasMockPapersAccess = userHasMockPapersAccess(user);
    const isPageClassroomMocks = matchPath(window.location.pathname, {
        path: MOCK_PAPERS_PATH,
        exact: false
    });
    if (hasMockPapersAccess && isPageClassroomMocks) {
        return false;
    }
    return !hasPremiumAccess(user);
};

export const hasPremiumAccess = (user) => {
    const loggedIn = Boolean(user);
    const hasGroups = loggedIn && user["cognito:groups"];
    const admin = hasGroups && user["cognito:groups"].includes("admin");
    const paid =
        hasGroups && (user["cognito:groups"].includes("paid") || admin);
    const trialing = hasGroups && user["cognito:groups"].includes("trial");
    const awaitingPayment =
        hasGroups && user["cognito:groups"].includes("awaitingPayment");
    return !loggedIn || paid || trialing || awaitingPayment || admin;
};
