import PlenaryHeader from "./PlenaryHeader";
import { QuestionGrid } from "../QuestionGrid";
import {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from "react";
import { apiGet } from "helpers/api";
import { useResizeDetector } from "react-resize-detector";
import PlenaryFeedbackCards from "./PlenaryFeedbackCards";
import SelectPlenaryTypeModal from "../typeSelection/SelectPlenaryTypeModal";
import { getStepIndex, TourContext } from "features/tour/Tour";
import { message, Result } from "antd";
import LessonContext from "../../context/LessonContext";
import { useHistory } from "react-router-dom";
import { useGetLessonDetailsQuery } from "features/timetable/savesSlice";
import LoadingSpinner from "../LoadingSpinner";

const PlenarySection = forwardRef(
    (
        {
            fullscreen,
            makeFullscreen,
            loadId,
            isVisible,
            activatePrintingModal
        },
        ref
    ) => {
        const history = useHistory();
        const tourContext = useContext(TourContext);
        const {
            plenaryChoicesState: [
                plenaryQuestionTopicChoices,
                setPlenaryQuestionTopicChoices
            ],
            plenaryGridDimensionsState: {
                width: plenaryGridWidth,
                height: plenaryGridHeight
            },
            questionFontSizeState: [questionFontSize],
            senBackgroundState: [senBackground],
            showPlenaryAnswersState: [showAnswers, setShowAnswers],
            isNewState: [, setIsNew],
            staleEditsState: [, setStaleEdits],
            plenaryFeedbackRef: feedbackRef,
            plenaryCalculatorAllowedState: [
                plenaryCalculatorAllowedValues,
                setPlenaryCalculatorAllowedValues
            ],
            plenaryCardRefs,
            loadedFeedbackState: [loadedFeedback, setLoadedFeedback]
        } = useContext(LessonContext);

        const [plenaryQuestionComponent0, setPlenaryQuestionComponent0] =
            useState(null);
        const [plenaryQuestionComponent1, setPlenaryQuestionComponent1] =
            useState(null);
        const [plenaryQuestionComponent2, setPlenaryQuestionComponent2] =
            useState(null);
        const [plenaryQuestionComponent3, setPlenaryQuestionComponent3] =
            useState(null);
        const [plenaryQuestionComponent4, setPlenaryQuestionComponent4] =
            useState(null);
        const [plenaryQuestionComponent5, setPlenaryQuestionComponent5] =
            useState(null);
        const plenaryQuestionComponents = useMemo(
            () => [
                plenaryQuestionComponent0,
                plenaryQuestionComponent1,
                plenaryQuestionComponent2,
                plenaryQuestionComponent3,
                plenaryQuestionComponent4,
                plenaryQuestionComponent5
            ],
            [
                plenaryQuestionComponent0,
                plenaryQuestionComponent1,
                plenaryQuestionComponent2,
                plenaryQuestionComponent3,
                plenaryQuestionComponent4,
                plenaryQuestionComponent5
            ]
        );
        const plenarySetQuestionComponents = useMemo(
            () => [
                setPlenaryQuestionComponent0,
                setPlenaryQuestionComponent1,
                setPlenaryQuestionComponent2,
                setPlenaryQuestionComponent3,
                setPlenaryQuestionComponent4,
                setPlenaryQuestionComponent5
            ],
            [
                setPlenaryQuestionComponent0,
                setPlenaryQuestionComponent1,
                setPlenaryQuestionComponent2,
                setPlenaryQuestionComponent3,
                setPlenaryQuestionComponent4,
                setPlenaryQuestionComponent5
            ]
        );

        useImperativeHandle(ref, () => ({
            getComponents: () => plenaryQuestionComponents
        }));

        const numSelectedQuestions = plenaryQuestionTopicChoices
            .slice(0, plenaryGridWidth * plenaryGridHeight)
            .map((qc) => qc?.difficulty)
            .filter((d) => d).length;
        const hasNoQuestions = Boolean(numSelectedQuestions === 0);

        const [error, setError] = useState(null);
        const [loading, setLoading] = useState(false);

        const onResize = useCallback(() => {
            plenaryCardRefs?.current.forEach((r) => r && r.resetFontSize());
        }, [plenaryCardRefs]);

        const { ref: targetRef } = useResizeDetector({
            onResize,
            skipOnMount: true
        });
        const [plenaryTypeModalVisible, setPlenaryTypeModalVisible] = useState(
            !loadId && hasNoQuestions
        );

        const { data: loadedLesson } = useGetLessonDetailsQuery(loadId, {
            skip: !loadId
        });

        const firstLoad = useRef(false);
        useEffect(() => {
            // Prevents the modal flashing up during initialisation of a loaded lesson save
            if (!firstLoad.current) {
                firstLoad.current = true;
                return;
            }
            if (hasNoQuestions && isVisible) {
                // Delay to allow for slider transition to complete
                setTimeout(() => setPlenaryTypeModalVisible(true), 800);
            } else {
                setTimeout(() => setPlenaryTypeModalVisible(false), 800);
            }
            // eslint-disable-next-line
        }, [isVisible]);

        const clearPlenary = useCallback(() => {
            setPlenaryQuestionTopicChoices([]);
            setStaleEdits(false);
        }, [setPlenaryQuestionTopicChoices, setStaleEdits]);

        // Load starter data from loadedLesson
        useEffect(() => {
            if (!loadedLesson) {
                return;
            }
            clearPlenary();
            const body = loadedLesson;
            setLoadedFeedback({
                wwwTitle: body.editedWWWTitle,
                ebiTitle: body.editedEBITitle,
                wwwStatements: JSON.parse(body.wwwStatements),
                ebiStatements: JSON.parse(body.ebiStatements)
            });
            setPlenaryQuestionTopicChoices(
                body.plenaryQuestionChoices.map((s) => {
                    const choices = JSON.parse(s);
                    if (s && choices && !choices?.randomSeed) {
                        choices.randomSeed = Math.floor(Math.random() * 10000);
                        setStaleEdits(true);
                    }
                    return s && choices
                        ? choices
                        : {
                              topicArea: null,
                              topic: null,
                              subtopic: null,
                              difficulty: null,
                              randomSeed: null
                          };
                })
            );
        }, [
            clearPlenary,
            loadedLesson,
            setLoadedFeedback,
            setPlenaryQuestionTopicChoices,
            setStaleEdits
        ]);

        function activatePlenaryTypeModal() {
            clearPlenary();
            setPlenaryTypeModalVisible(true);
        }

        const createLibraryPlenary = useCallback(
            ({ subTopic, difficulty }) => {
                setLoading(true);
                apiGet(
                    "/api/library-starter?subTopic=" +
                        encodeURIComponent(subTopic) +
                        "&difficulty=" +
                        encodeURIComponent(difficulty),
                    (body) => {
                        setPlenaryQuestionTopicChoices(
                            body.questionChoices.slice(0, 3).map((s) => {
                                return (
                                    s || {
                                        topicArea: null,
                                        topic: null,
                                        subtopic: null,
                                        difficulty: null,
                                        recallTag: null,
                                        randomSeed: null
                                    }
                                );
                            })
                        );
                        setStaleEdits(true);
                        setIsNew(true);
                        setLoading(false);
                    },
                    (err) => {
                        setError(err);
                        setLoading(false);
                    }
                );
            },
            [setIsNew, setPlenaryQuestionTopicChoices, setStaleEdits]
        );

        const autoDisabled = numSelectedQuestions < 3;

        function autoGenerateStarter() {
            const visibleQuestions = [
                ...plenaryQuestionTopicChoices.slice(
                    0,
                    plenaryGridWidth * plenaryGridHeight
                )
            ];
            const topics = visibleQuestions.map((qc) => [
                qc.subtopic,
                qc.difficulty
            ]);
            const uniqueTopics = [...new Set(topics.map(JSON.stringify))].map(
                JSON.parse
            );
            if (
                topics.length === 0 ||
                (uniqueTopics.length === 1 && !uniqueTopics[0]) // Check if all topics contain "null" values
            ) {
                message.warning(
                    "You must choose some question topics before automatically generating a starter."
                );
                return;
            }
            if (
                tourContext.isOpen &&
                tourContext.currentStep === getStepIndex("autoStarter")
            ) {
                tourContext.nextStep();
            }
            history.push(
                `/starter/auto?topics=${encodeURIComponent(
                    JSON.stringify(topics)
                )}&num=${plenaryGridWidth * plenaryGridHeight}`
            );
        }

        const plenaryQuestionGrid = useMemo(
            () => (
                <QuestionGrid
                    cardRefs={plenaryCardRefs}
                    height={plenaryGridHeight}
                    width={plenaryGridWidth}
                    isStarter={false}
                    questionFont={questionFontSize}
                    senBg={senBackground}
                    questionTopicChoices={plenaryQuestionTopicChoices}
                    setQuestionTopicChoices={setPlenaryQuestionTopicChoices}
                    extensionTopicChoices={[]}
                    setExtensionTopicChoices={() => {}}
                    showAnswers={showAnswers}
                    setShowAnswers={setShowAnswers}
                    questionComponents={plenaryQuestionComponents}
                    setQuestionComponents={plenarySetQuestionComponents}
                    calculatorAllowedValues={plenaryCalculatorAllowedValues}
                    setCalculatorAllowedValues={
                        setPlenaryCalculatorAllowedValues
                    }
                    hasRecallTags={false}
                    fullscreen={fullscreen}
                    showExtension={false}
                    setStaleEdits={setStaleEdits}
                />
            ),
            [
                plenaryCardRefs,
                plenaryGridHeight,
                plenaryGridWidth,
                questionFontSize,
                senBackground,
                plenaryQuestionTopicChoices,
                setPlenaryQuestionTopicChoices,
                showAnswers,
                setShowAnswers,
                plenaryQuestionComponents,
                plenarySetQuestionComponents,
                plenaryCalculatorAllowedValues,
                setPlenaryCalculatorAllowedValues,
                fullscreen,
                setStaleEdits
            ]
        );

        // In tour: populate plenary
        useEffect(() => {
            const numPlenaryQs = plenaryGridHeight * plenaryGridWidth;
            if (
                tourContext.isOpen &&
                tourContext.currentStep === getStepIndex("autoStarter") &&
                plenaryQuestionTopicChoices.filter((qc) => qc?.difficulty)
                    .length < numPlenaryQs
            ) {
                setPlenaryQuestionTopicChoices((prevVal) => {
                    if (prevVal.length < numPlenaryQs) {
                        prevVal = prevVal.concat(
                            Array(numPlenaryQs - prevVal.length).fill(null)
                        );
                    }
                    return prevVal.map((tc) =>
                        tc?.difficulty
                            ? tc
                            : {
                                  topicArea: "Number",
                                  topic: "Fractions",
                                  subtopic: "Dividing Fractions",
                                  difficulty: "Easy",
                                  randomSeed: Math.floor(Math.random() * 10000)
                              }
                    );
                });
            }
        }, [
            tourContext.isOpen,
            tourContext.currentStep,
            plenaryGridHeight,
            plenaryGridWidth,
            plenaryQuestionTopicChoices,
            setPlenaryQuestionTopicChoices
        ]);

        if (error) {
            return (
                <Result
                    className={"starterError"}
                    status="error"
                    title="Something went wrong!"
                    subTitle={
                        <>
                            Unable to create your exit ticket. Please try again
                            later. If this problem persists please contact:{" "}
                            <a href={"mailto:chloe@mathsplanner.com"}>
                                chloe@mathsplanner.com
                            </a>
                        </>
                    }
                    extra={"Reason: " + error}
                />
            );
        }

        return (
            <div className={"main-starter-container"} ref={targetRef}>
                <PlenaryHeader
                    fullscreen={fullscreen}
                    makeFullscreen={makeFullscreen}
                    autoDisabled={autoDisabled}
                    autoGenerateStarter={autoGenerateStarter}
                    activatePrintingModal={activatePrintingModal}
                    activatePlenaryTypeModal={activatePlenaryTypeModal}
                />

                <SelectPlenaryTypeModal
                    open={plenaryTypeModalVisible && isVisible}
                    setOpen={setPlenaryTypeModalVisible}
                    saveId={loadId}
                    createLibraryPlenary={createLibraryPlenary}
                />
                {loading ? <LoadingSpinner /> : plenaryQuestionGrid}
                <PlenaryFeedbackCards
                    ref={feedbackRef}
                    activatePrintingModal={activatePrintingModal}
                    autoDisabled={autoDisabled}
                    autoGenerateStarter={autoGenerateStarter}
                    initialLoadedFeedback={loadedFeedback}
                    setStaleEdits={setStaleEdits}
                />
            </div>
        );
    }
);

export default PlenarySection;
