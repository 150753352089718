import React, { useMemo } from "react";

import { Button, Card, Divider, notification } from "antd";
import Title from "antd/lib/typography/Title";
import referralsImage from "assets/illustrations/referrals-guide.png";
import { useGetUserQuery } from "features/account/accountSlice";
import { createSelector } from "@reduxjs/toolkit";

function ReferralInfo() {
    const selectReferralCode = useMemo(
        () =>
            createSelector(
                (res) => res.data,
                (user) => user?.referral_code
            ),
        []
    );

    const { code } = useGetUserQuery(undefined, {
        selectFromResult: (res) => ({ ...res, code: selectReferralCode(res) })
    });
    return (
        <Card className={"card-small accountCard"}>
            <Title level={2}>Referral Code</Title>
            <Divider />
            <div className={"referrals-container"}>
                <div className={"referrals-code-container"}>
                    <p>Your unique referral code:</p>
                    <div className={"referrals-code"}>{code}</div>
                    <Button
                        type={"primary"}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (navigator?.clipboard?.writeText) {
                                navigator.clipboard.writeText(code);
                            } else {
                                // Legacy browser implementation
                                // Create dummy input containing URL and select it
                                const dummy = document.createElement("input");
                                document.body.appendChild(dummy);
                                dummy.value = code;
                                dummy.select();
                                dummy.setSelectionRange(0, 99999);
                                document.execCommand("copy");
                                document.body.removeChild(dummy);
                            }
                            notification.open({
                                message: "Success",
                                duration: 1.5,
                                description: "Code copied to clipboard."
                            });
                        }}
                    >
                        Click to Copy
                    </Button>
                </div>
                <img
                    src={referralsImage}
                    alt={"Graphic explaining how the referrals scheme works"}
                />
            </div>
        </Card>
    );
}

export default ReferralInfo;
