import React, { useContext } from "react";
import { Button, Divider, Modal, Space, Switch } from "antd";
import {
    AppstoreAddOutlined,
    CalendarOutlined,
    CompassOutlined,
    SettingOutlined,
    VideoCameraOutlined
} from "@ant-design/icons";
import { TourContext } from "features/tour/Tour";
import {
    useGetUserQuery,
    useSetShowSaturdayOnTimetableMutation
} from "features/account/accountSlice";
import { isUserSiteAdmin } from "helpers/security";

function DashboardSettingsModal({ isModalVisible, setIsModalVisible }) {
    const tourContext = useContext(TourContext);
    const { data: user } = useGetUserQuery();

    const isSiteAdmin = isUserSiteAdmin(user);
    const [setShowSaturday] = useSetShowSaturdayOnTimetableMutation();

    return (
        <Modal
            title={<div className="settings-title">Timetable Settings</div>}
            open={isModalVisible}
            width={"min(57rem, 95%)"}
            closable={false}
            maskClosable={true}
            onCancel={() => setIsModalVisible(false)}
            footer={
                <Button
                    type="primary"
                    onClick={() => {
                        setIsModalVisible(false);
                    }}
                >
                    Done
                </Button>
            }
        >
            <div className="dashboard-toggle">
                <Space size="middle">
                    <h3>
                        <CalendarOutlined />
                    </h3>
                    <h3>Enable Saturday on time table</h3>
                </Space>
                <Switch
                    defaultChecked={user && user.show_saturday}
                    onChange={(e) => {
                        const show = Boolean(e);
                        setShowSaturday(show);
                    }}
                    style={{ display: "inline" }}
                />
            </div>
            <Divider />
            <div className="dashboard-toggle">
                <Space size="middle">
                    <h3>
                        <VideoCameraOutlined />
                    </h3>
                    <h3>Watch our how-to training videos</h3>
                </Space>
                <Button
                    size="middle"
                    href={
                        "https://www.mathsplanner.com/premium-app-how-to-tutorials/"
                    }
                    target={"_blank"}
                    rel="noreferrer"
                >
                    Browse Videos
                </Button>
            </div>
            <Divider />
            <div className="dashboard-toggle">
                <Space size="middle">
                    <h3>
                        <CompassOutlined />
                    </h3>
                    <h3>Learn how to use Maths Planner</h3>
                </Space>
                <Button
                    size="middle"
                    onClick={() => {
                        setIsModalVisible(false);
                        tourContext.startTour();
                    }}
                >
                    Take Tour
                </Button>
            </div>
            <>
                <Divider />
                <div className="dashboard-toggle">
                    <Space size="middle">
                        <h3>
                            <AppstoreAddOutlined />
                        </h3>
                        <h3>Set your starter template</h3>
                    </Space>
                    <Button
                        size="middle"
                        href={"/settings/starter"}
                        onClick={() => {
                            setIsModalVisible(false);
                        }}
                    >
                        Template Defaults
                    </Button>
                </div>
            </>
            {isSiteAdmin && (
                <>
                    <Divider />
                    <div className="dashboard-toggle">
                        <Space size="middle">
                            <h3>
                                <SettingOutlined />
                            </h3>
                            <h3>Manage your department's accounts</h3>
                        </Space>
                        <Button
                            size="middle"
                            href={"/settings/sub-users"}
                            onClick={() => {
                                setIsModalVisible(false);
                            }}
                        >
                            Sub-Accounts
                        </Button>
                    </div>
                </>
            )}
        </Modal>
    );
}

export default DashboardSettingsModal;
