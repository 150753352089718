import * as React from "react";

const SvgAccountBillingIcon = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-8Zm8 6a1 1 0 0 1-1 1h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 1 1Zm0-3a1 1 0 0 1-1 1h-3a1 1 0 1 1 0-2h3a1 1 0 0 1 1 1Z"
            fill="currentColor"
            fillOpacity={0.6}
        />
        <path
            d="M5.5 9a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.4 13a3 3 0 0 0-2.994 2.804l-.197 3A3 3 0 0 0 6.203 22h4.594a3 3 0 0 0 2.994-3.196l-.197-3A3 3 0 0 0 10.601 13H6.399Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgAccountBillingIcon;
