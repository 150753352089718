import { capitalizeFirstLetter, formatAsDateString } from "./miscHelpers";

export function lastUpdatedString(lastUpdate) {
    const MINUTE = 1000 * 60;
    const HOUR = MINUTE * 60;
    const DAY = HOUR * 24;
    const WEEK = DAY * 7;

    const now = Date.now();
    if (lastUpdate >= now - MINUTE) {
        return "Now";
    } else if (lastUpdate > now - HOUR) {
        return `${Math.round((now - lastUpdate) / MINUTE)} minutes ago`;
    } else if (lastUpdate > now - DAY) {
        return `${Math.round((now - lastUpdate) / HOUR)} hours ago`;
    } else if (lastUpdate > now - WEEK) {
        return `${Math.round((now - lastUpdate) / DAY)} days ago`;
    }
    return `${Math.ceil((now - lastUpdate) / WEEK)} weeks ago`;
}

export function getSaveLocationString(timetableColumn, timetablePeriod) {
    if (!timetableColumn || (!timetablePeriod && timetablePeriod !== 0)) {
        return "";
    }
    const inDrawer = timetableColumn === "backlog";
    const week = timetableColumn?.slice(-1) === "b" ? "B" : "A";
    const day = capitalizeFirstLetter(timetableColumn.replaceAll("b", ""));
    const period = JSON.parse(timetablePeriod) + 1;
    return inDrawer ? "Saves Drawer" : `Week ${week} - ${day} P${period}`;
}

export function getDateString() {
    return formatAsDateString(new Date());
}

function treeNodeHasDisabledChildren(node, level = 0) {
    if (level >= 2 /* sub-topic or difficulty level */) {
        return node.disabled;
    }
    if (node.disabled || !node?.children?.length) {
        return true;
    }
    return node.children.every((child) =>
        treeNodeHasDisabledChildren(child, level + 1)
    );
}

/**
 * API only returns disabled at sub-topic level to show all topics even if incomplete
 * This function propagates the disable up the tree to topic areas and topics
 */
export function checkForDisabledTopics(tree) {
    return tree.map((node) => {
        const children = node.children.map((child) => ({
            ...child,
            disabled: child?.disabled || treeNodeHasDisabledChildren(child)
        }));
        return {
            ...node,
            children,
            disabled:
                node?.disabled ||
                treeNodeHasDisabledChildren({ ...node, children })
        };
    });
}

export function removeDifficultiesFromTopicsTree(tree, allEnabled = false) {
    if (!tree) {
        return null;
    }
    return tree.map((e) => ({
        label: e.label,
        key: e.label,
        value: e.value,
        disabled: allEnabled ? false : e?.disabled,
        children: e.children
            ? e.children.map((e2) => ({
                  label: e2.label,
                  key: e2.label,
                  value: e2.value,
                  disabled: allEnabled ? false : e2?.disabled,
                  children: e2.children
                      ? e2.children.map((e3) => ({
                            label: e3.label,
                            key: e3.label,
                            value: e3.value,
                            disabled: allEnabled ? false : e3?.disabled
                        }))
                      : null
              }))
            : null
    }));
}

export const tooltipColour = "#000000ee";

export const DEFAULT_PREFERENCES = {
    starterGridWidth: 3,
    plenaryGridWidth: 3,
    hasLearningObjective: false,
    hasRecallTags: true,
    hasPlenary: true,
    questionBackground: null,
    questionFontSize: 6,
    recallTagsSixQs: [null, null, null, null, null, null],
    recallTagsFourQs: [null, null, null, null]
};

export const DEFAULT_TITLES = {
    www: "WWW",
    ebi: "EBI"
};
