import { Modal } from "antd";
import { getChart } from "helpers/questionHelpers";

function FullscreenChartModal({ isOpen, setIsOpen, chartData }) {
    return (
        <Modal
            className={"fullscreenChartModal"}
            width={"95vw"}
            open={isOpen && chartData?.type}
            onCancel={() => setIsOpen(false)}
            title={null}
            footer={null}
            centered
        >
            {getChart(chartData, "fullscreenChartModal__chart")}
        </Modal>
    );
}

export default FullscreenChartModal;
