import StarterHeader from "./StarterHeader";
import ExtensionModal from "./extension/ExtensionModal";
import { QuestionGrid } from "../QuestionGrid";
import {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from "react";
import { useGetUserQuery } from "features/account/accountSlice";
import SelectStarterTypeModal, {
    STARTER_TYPES
} from "../typeSelection/SelectStarterTypeModal";
import { apiGet } from "helpers/api";
import { useResizeDetector } from "react-resize-detector";
import AutoStarterSaveModal from "../typeSelection/AutoStarterSaveModal";
import { message, Result } from "antd";
import LessonContext from "../../context/LessonContext";
import { areRecallTagsEqual } from "helpers/tagHelpers";
import { useGetLessonDetailsQuery } from "features/timetable/savesSlice";
import { plenaryRecallTag } from "helpers/questionHelpers";
import LoadingSpinner from "../LoadingSpinner";
import { useLazyGetNewPaperStarterQuery } from "features/mockPapers/mockPapersSlice";

const StarterSection = forwardRef(
    (
        { fullscreen, makeFullscreen, isVisible, loadId, autoStarterParams },
        ref
    ) => {
        const { data: user } = useGetUserQuery();
        const [prevAutoStarterParams, setPrevAutoStarterParams] =
            useState(null);

        const {
            starterTitleState: [title, setTitle],
            starterLearningObjectiveState: [
                learningObjective,
                setLearningObjective
            ],
            starterChoicesState: [
                questionTopicChoices,
                setQuestionTopicChoices
            ],
            starterCueImageState: [selectedCueImage, setSelectedCueImage],
            extensionChoicesState: [
                extensionTopicChoices,
                setExtensionTopicChoices
            ],
            starterGridDimensionsState: [
                { width: gridWidth, height: gridHeight },
                setGridDimensions
            ],
            questionFontSizeState: [questionFontSize],
            senBackgroundState: [senBackground],
            showStarterAnswersState: [showAnswers, setShowAnswers],
            starterHasRecallTagsState: [hasRecallTags, setHasRecallTags],
            isNewState: [, setIsNew],
            staleEditsState: [, setStaleEdits],
            showStarterExtensionState: [showExtension, setShowExtension],
            saveCurrentLessonStateAsNew,
            starterCalculatorAllowedState: [
                starterCalculatorAllowedValues,
                setStarterCalculatorAllowedValues
            ],
            starterCardRefs,
            showSettingsPanelState: [, setIsSettingsPanelVisible]
        } = useContext(LessonContext);
        const setStarterGridWidth = useCallback(
            (val) => setGridDimensions((prev) => ({ ...prev, width: val })),
            [setGridDimensions]
        );
        const setStarterGridHeight = useCallback(
            (val) => setGridDimensions((prev) => ({ ...prev, height: val })),
            [setGridDimensions]
        );
        const clearStarter = useCallback(() => {
            setTitle(null);
            setError(null);
            setQuestionTopicChoices([]);
            setStaleEdits(false);
            setHasRecallTags(true);
        }, [
            setHasRecallTags,
            setQuestionTopicChoices,
            setStaleEdits,
            setTitle
        ]);

        const numSelectedQuestions = questionTopicChoices
            .slice(0, gridWidth * gridHeight)
            .map((qc) => qc?.difficulty)
            .filter((d) => d).length;
        const hasNoQuestions = Boolean(numSelectedQuestions === 0);

        const { data: loadedLesson } = useGetLessonDetailsQuery(loadId, {
            skip: !loadId
        });

        // Load starter data from loadedLesson
        useEffect(() => {
            if (!loadedLesson) {
                return;
            }
            clearStarter();
            const body = loadedLesson;
            setStarterGridWidth(body.gridWidth);
            setStarterGridHeight(body.gridHeight);
            setTitle(body.title);
            setLearningObjective(body.learningObjective);
            setSelectedCueImage(body.cueImage);
            setHasRecallTags(true);
            setQuestionTopicChoices(
                body.questionChoices.map((s, i) => {
                    const choices = JSON.parse(s);
                    if (s && choices && !choices?.randomSeed) {
                        choices.randomSeed = Math.floor(Math.random() * 10000);
                        setStaleEdits(true);
                    }
                    return s && choices
                        ? choices
                        : {
                              topicArea: null,
                              topic: null,
                              subtopic: null,
                              difficulty: null,
                              recallTag:
                                  body.gridWidth === 3
                                      ? user?.starter_preferences
                                            ?.recallTagsSixQs[i]
                                      : user?.starter_preferences
                                            ?.recallTagsFourQs[i],
                              randomSeed: null
                          };
                })
            );

            if (
                body.new &&
                !body?.questionChoices
                    ?.map((s) => JSON.parse(s)?.difficulty)
                    .filter((s) => s)?.length
            ) {
                setStarterTypeModalVisible(true);
            }
        }, [
            clearStarter,
            loadId,
            loadedLesson,
            setHasRecallTags,
            setLearningObjective,
            setQuestionTopicChoices,
            setSelectedCueImage,
            setStaleEdits,
            setStarterGridHeight,
            setStarterGridWidth,
            setTitle,
            user?.starter_preferences
        ]);

        const createStarterFromPlenary = useCallback(
            (topics) =>
                apiGet(
                    "/api/starter-from-plenary?numQuestions=6&plenarySubtopics=" +
                        encodeURIComponent(topics.map((t) => t[0])) +
                        "&plenaryDifficulties=" +
                        encodeURIComponent(topics.map((t) => t[1])),
                    (body) => {
                        setTitle(null);
                        setLearningObjective(null);
                        setHasRecallTags(true);
                        setStarterGridWidth(
                            Math.floor(body.questionChoices.length / 2)
                        );
                        setStarterGridHeight(2);
                        setQuestionTopicChoices(
                            body.questionChoices.map((s) => {
                                if (s) {
                                    s.recallTag = {
                                        ...plenaryRecallTag,
                                        name: s.recallTag
                                    };
                                }
                                return (
                                    s || {
                                        topicArea: null,
                                        topic: null,
                                        subtopic: null,
                                        difficulty: null,
                                        recallTag: null,
                                        randomSeed: null
                                    }
                                );
                            })
                        );
                        setStaleEdits(true);
                        setIsNew(true);
                        setAutoStarterModalVisible(true);
                    },
                    setError
                ),
            [
                setHasRecallTags,
                setIsNew,
                setLearningObjective,
                setQuestionTopicChoices,
                setStaleEdits,
                setStarterGridHeight,
                setStarterGridWidth,
                setTitle
            ]
        );

        // Load auto starter from plenary questions
        useEffect(() => {
            if (
                !autoStarterParams ||
                (prevAutoStarterParams &&
                    prevAutoStarterParams.num === autoStarterParams.num &&
                    prevAutoStarterParams.topics === autoStarterParams.topics)
            ) {
                return;
            }
            setPrevAutoStarterParams(autoStarterParams);
            clearStarter();
            const topics = JSON.parse(autoStarterParams.topics);
            if (!topics) {
                setError("Auto-generation topics must be provided.");
                return;
            }
            createStarterFromPlenary(topics);
        }, [
            autoStarterParams,
            clearStarter,
            createStarterFromPlenary,
            prevAutoStarterParams
        ]);

        // Update recall tags when grid size changed
        useEffect(
            () =>
                setQuestionTopicChoices((prevState) => {
                    let copy = [...prevState.map((s) => ({ ...s }))];
                    if (gridWidth === 3) {
                        [4, 5].forEach((i) => {
                            if (!copy[i]) {
                                copy[i] = {
                                    topicArea: null,
                                    topic: null,
                                    subtopic: null,
                                    difficulty: null,
                                    randomSeed: null
                                };
                            }
                        });
                    }
                    for (let i = 0; i < copy.length; i++) {
                        if (
                            copy?.[i] &&
                            prevState[i]?.recallTag &&
                            !areRecallTagsEqual(
                                prevState[i].recallTag,
                                user?.starter_preferences?.recallTagsSixQs[i]
                            ) &&
                            !areRecallTagsEqual(
                                prevState[i].recallTag,
                                user?.starter_preferences?.recallTagsFourQs[i]
                            )
                        ) {
                            // Recall tags have been changed from defaults -- Don't erase changes
                            return copy;
                        }
                    }
                    const preferredTags =
                        (gridWidth || 3) === 3
                            ? user?.starter_preferences?.recallTagsSixQs
                            : user?.starter_preferences?.recallTagsFourQs;
                    console.log({ preferredTags, copy, gridWidth });
                    for (let i = 0; i < copy.length; i++) {
                        if (!copy?.[i] || !preferredTags?.[i]) {
                            continue;
                        }
                        copy[i].recallTag = preferredTags[i];
                    }
                    return copy;
                }),
            [gridWidth, setQuestionTopicChoices, user?.starter_preferences]
        );

        const [starterQuestionComponent0, setStarterQuestionComponent0] =
            useState(null);
        const [starterQuestionComponent1, setStarterQuestionComponent1] =
            useState(null);
        const [starterQuestionComponent2, setStarterQuestionComponent2] =
            useState(null);
        const [starterQuestionComponent3, setStarterQuestionComponent3] =
            useState(null);
        const [starterQuestionComponent4, setStarterQuestionComponent4] =
            useState(null);
        const [starterQuestionComponent5, setStarterQuestionComponent5] =
            useState(null);
        const starterQuestionComponents = useMemo(
            () => [
                starterQuestionComponent0,
                starterQuestionComponent1,
                starterQuestionComponent2,
                starterQuestionComponent3,
                starterQuestionComponent4,
                starterQuestionComponent5
            ],
            [
                starterQuestionComponent0,
                starterQuestionComponent1,
                starterQuestionComponent2,
                starterQuestionComponent3,
                starterQuestionComponent4,
                starterQuestionComponent5
            ]
        );
        const starterSetQuestionComponents = useMemo(
            () => [
                setStarterQuestionComponent0,
                setStarterQuestionComponent1,
                setStarterQuestionComponent2,
                setStarterQuestionComponent3,
                setStarterQuestionComponent4,
                setStarterQuestionComponent5
            ],
            [
                setStarterQuestionComponent0,
                setStarterQuestionComponent1,
                setStarterQuestionComponent2,
                setStarterQuestionComponent3,
                setStarterQuestionComponent4,
                setStarterQuestionComponent5
            ]
        );

        useImperativeHandle(ref, () => ({
            getComponents: () => starterQuestionComponents
        }));

        const [starterTypeModalVisible, setStarterTypeModalVisible] = useState(
            !loadId && hasNoQuestions && !autoStarterParams
        );
        const [autoStarterModalVisible, setAutoStarterModalVisible] = useState(
            Boolean(autoStarterParams)
        );
        const [selectedStarterTypeOptions, setSelectedStarterTypeOptions] =
            useState({});

        const [error, setError] = useState(null);
        const [loading, setLoading] = useState(false);

        const firstLoad = useRef(false);
        useEffect(() => {
            if (!firstLoad.current) {
                // Prevents the modal flashing up during initialisation of a loaded lesson save
                firstLoad.current = true;
                return;
            }
            if (hasNoQuestions && isVisible) {
                // Delay to allow for slider transition to complete
                setTimeout(() => setStarterTypeModalVisible(true), 800);
            } else {
                setTimeout(() => setStarterTypeModalVisible(false), 800);
            }
            // eslint-disable-next-line
        }, [isVisible]);

        const errorIsCannotFindQuestions = error
            ?.toString()
            ?.includes("Unable to find any questions for");

        const onResize = useCallback(() => {
            starterCardRefs?.current.forEach((r) => r && r.resetFontSize());
        }, [starterCardRefs]);

        const { ref: targetRef } = useResizeDetector({
            onResize,
            skipOnMount: true
        });

        function activateStarterTypeModal() {
            clearStarter();
            setStarterTypeModalVisible(true);
        }

        const createGCSEStarter = useCallback(
            ({ grades, topics, numQs }, keepFields = false) => {
                setLoading(true);
                apiGet(
                    "/api/gcse-starter?grades=" +
                        encodeURIComponent(grades) +
                        "&topics=" +
                        encodeURIComponent(topics) +
                        "&numQs=6",
                    (body) => {
                        if (!keepFields) {
                            setTitle(null);
                            setLearningObjective(null);
                            setHasRecallTags(false);
                            setStarterGridWidth(numQs > 4 ? 3 : 2);
                            setStarterGridHeight(2);
                        }
                        setQuestionTopicChoices(
                            body.questionChoices.map((s) => {
                                return (
                                    s || {
                                        topicArea: null,
                                        topic: null,
                                        subtopic: null,
                                        difficulty: null,
                                        recallTag: null,
                                        randomSeed: null
                                    }
                                );
                            })
                        );
                        setStaleEdits(true);
                        setIsNew(true);
                        setLoading(false);
                    },
                    (err) => {
                        setError(err);
                        setLoading(false);
                    }
                );
            },
            [
                setTitle,
                setLearningObjective,
                setHasRecallTags,
                setStarterGridWidth,
                setStarterGridHeight,
                setQuestionTopicChoices,
                setStaleEdits,
                setIsNew
            ]
        );

        const createLibraryStarter = useCallback(
            ({ subTopic, difficulty }) => {
                setLoading(true);
                apiGet(
                    "/api/library-starter?subTopic=" +
                        encodeURIComponent(subTopic) +
                        "&difficulty=" +
                        encodeURIComponent(difficulty),
                    (body) => {
                        setTitle(null);
                        setLearningObjective(null);
                        setHasRecallTags(false);
                        setStarterGridWidth(3);
                        setStarterGridHeight(2);
                        setQuestionTopicChoices(
                            body.questionChoices.map((s) => {
                                return (
                                    s || {
                                        topicArea: null,
                                        topic: null,
                                        subtopic: null,
                                        difficulty: null,
                                        recallTag: null,
                                        randomSeed: null
                                    }
                                );
                            })
                        );
                        setStaleEdits(true);
                        setIsNew(true);
                        setLoading(false);
                    },
                    (err) => {
                        setError(err);
                        setLoading(false);
                    }
                );
            },
            [
                setTitle,
                setLearningObjective,
                setHasRecallTags,
                setStarterGridWidth,
                setStarterGridHeight,
                setQuestionTopicChoices,
                setStaleEdits,
                setIsNew
            ]
        );

        const [getMockPaperStarter] = useLazyGetNewPaperStarterQuery();

        const createMockPaperStarter = useCallback(
            ({ paperId, questionNumbers }) => {
                setLoading(true);
                getMockPaperStarter({ paperId, questionNumbers })
                    .unwrap()
                    .then((body) => {
                        setTitle(null);
                        setLearningObjective(null);
                        setHasRecallTags(false);
                        setStarterGridWidth(3);
                        setStarterGridHeight(2);
                        setQuestionTopicChoices(
                            body.questionChoices.map((s) => {
                                return (
                                    { ...s } || {
                                        topicArea: null,
                                        topic: null,
                                        subtopic: null,
                                        difficulty: null,
                                        recallTag: null,
                                        randomSeed: null
                                    }
                                );
                            })
                        );
                        setStaleEdits(true);
                        setIsNew(true);
                        setLoading(false);
                    })
                    .catch((err) => {
                        setError(err);
                        setLoading(false);
                    });
            },
            [
                getMockPaperStarter,
                setHasRecallTags,
                setIsNew,
                setLearningObjective,
                setQuestionTopicChoices,
                setStaleEdits,
                setStarterGridHeight,
                setStarterGridWidth,
                setTitle
            ]
        );

        const starterQuestionGrid = useMemo(
            () => (
                <QuestionGrid
                    cardRefs={starterCardRefs}
                    height={gridHeight}
                    width={gridWidth}
                    isStarter={true}
                    questionFont={questionFontSize}
                    senBg={senBackground}
                    questionTopicChoices={questionTopicChoices}
                    setQuestionTopicChoices={setQuestionTopicChoices}
                    extensionTopicChoices={extensionTopicChoices}
                    setExtensionTopicChoices={setExtensionTopicChoices}
                    showAnswers={showAnswers}
                    setShowAnswers={setShowAnswers}
                    questionComponents={starterQuestionComponents}
                    setQuestionComponents={starterSetQuestionComponents}
                    calculatorAllowedValues={starterCalculatorAllowedValues}
                    setCalculatorAllowedValues={
                        setStarterCalculatorAllowedValues
                    }
                    hasRecallTags={hasRecallTags}
                    fullscreen={fullscreen}
                    showExtension={showExtension}
                    setStaleEdits={setStaleEdits}
                />
            ),
            [
                starterCardRefs,
                gridHeight,
                gridWidth,
                questionFontSize,
                senBackground,
                questionTopicChoices,
                setQuestionTopicChoices,
                extensionTopicChoices,
                setExtensionTopicChoices,
                showAnswers,
                setShowAnswers,
                starterQuestionComponents,
                starterSetQuestionComponents,
                starterCalculatorAllowedValues,
                setStarterCalculatorAllowedValues,
                hasRecallTags,
                fullscreen,
                showExtension,
                setStaleEdits
            ]
        );

        if (error) {
            return (
                <Result
                    className={"starterError"}
                    status="error"
                    title="Something went wrong!"
                    subTitle={
                        errorIsCannotFindQuestions ? (
                            error.toString()
                        ) : (
                            <>
                                Unable to create your starter. Please try again
                                later. If this problem persists please contact:{" "}
                                <a href={"mailto:chloe@mathsplanner.com"}>
                                    chloe@mathsplanner.com
                                </a>
                            </>
                        )
                    }
                    extra={!errorIsCannotFindQuestions && "Reason: " + error}
                />
            );
        }

        return (
            <div className={"main-starter-container"} ref={targetRef}>
                <StarterHeader
                    fullscreen={fullscreen}
                    makeFullscreen={makeFullscreen}
                    title={title}
                    setTitle={setTitle}
                    learningObjective={learningObjective}
                    setLearningObjective={setLearningObjective}
                    setStaleEdits={setStaleEdits}
                    activateStarterTypeModal={activateStarterTypeModal}
                    showRecreateStarter={
                        selectedStarterTypeOptions?.type === STARTER_TYPES.GCSE
                    }
                    recreateStarter={() => {
                        if (
                            selectedStarterTypeOptions?.type !==
                                STARTER_TYPES.GCSE ||
                            !selectedStarterTypeOptions?.options
                        ) {
                            return;
                        }
                        createGCSEStarter(
                            selectedStarterTypeOptions.options,
                            true
                        );
                    }}
                    selectedCueImage={selectedCueImage}
                    setSelectedCueImage={setSelectedCueImage}
                />
                <SelectStarterTypeModal
                    open={starterTypeModalVisible && isVisible}
                    setOpen={setStarterTypeModalVisible}
                    saveId={loadId}
                    createGCSEStarter={createGCSEStarter}
                    createLibraryStarter={createLibraryStarter}
                    createMockPaperStarter={createMockPaperStarter}
                    onSelect={(selected) => {
                        setSelectedStarterTypeOptions(selected);
                        setIsSettingsPanelVisible(true);
                    }}
                />
                <AutoStarterSaveModal
                    open={autoStarterModalVisible && isVisible}
                    setOpen={setAutoStarterModalVisible}
                    saveCurrentStarterAsNew={saveCurrentLessonStateAsNew}
                    onStarterSave={() => {
                        message.success("Saved");
                        setIsNew(false);
                    }}
                />
                {loading ? <LoadingSpinner /> : starterQuestionGrid}
                <ExtensionModal
                    isModalVisible={gridWidth === 3 && showExtension}
                    setIsModalVisible={setShowExtension}
                    fullscreen={fullscreen}
                    questionFont={questionFontSize}
                    senBg={senBackground}
                    questionTopicChoices={questionTopicChoices}
                    extensionTopicChoices={extensionTopicChoices}
                    setExtensionTopicChoices={setExtensionTopicChoices}
                    randomSeed={extensionTopicChoices[0]?.randomSeed}
                    setRandomSeed={(value) =>
                        setExtensionTopicChoices((prevState) => {
                            const copy = [...prevState];
                            if (typeof value === "function") {
                                copy[0].randomSeed = value(copy[0]?.randomSeed);
                            } else {
                                copy[0].randomSeed = value;
                            }
                            return copy;
                        })
                    }
                />
            </div>
        );
    }
);

export default StarterSection;
