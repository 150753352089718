import * as React from "react";

const SvgAdminIcon = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M8 6a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z" fill="currentColor" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.496 14.728A4 4 0 0 1 9.486 11h5.027a4 4 0 0 1 3.991 3.728l.205 3A4 4 0 0 1 14.718 22H9.282a4 4 0 0 1-3.99-4.272l.204-3ZM15 16a1 1 0 1 0 0-2h-2a1 1 0 1 0 0 2h2Z"
            fill="currentColor"
            fillOpacity={0.6}
        />
    </svg>
);

export default SvgAdminIcon;
