import { getIdToken, redirectToLogin } from "./security";
import { message } from "antd";

export const getS3FilePath = (fileName) =>
    `${process.env.PUBLIC_URL}/api/files/s3?file=${encodeURIComponent(
        fileName
    )}`;

async function handleErrors(res) {
    if (!res.ok) {
        const text = await res.text();
        // Get message from response body if it exists, else get status code text
        let body = { message: res.status + ": " + res.statusText };
        try {
            if (text && text !== "") {
                body = await JSON.parse(text);
            }
        } catch (ignore) {}
        const msg = body.message;
        const error = new Error(msg);
        error.code = res.status;
        throw error;
    }
    return res;
}

export const apiGetPublic = (url, bodyHandler, errorHandler) => {
    async function getData() {
        const response = await fetch(url, {
            credentials: "include"
        }).then(handleErrors);
        const body = await response.json();
        bodyHandler(body);
    }

    getData().catch((err) => errorHandler && errorHandler(err));
};

export const apiGetDataPublic = (url, errorHandler) => {
    async function getData() {
        const response = await fetch(url, {
            credentials: "include"
        }).then(handleErrors);
        return await response.json();
    }

    return getData().catch((err) => errorHandler && errorHandler(err));
};

export const apiGet = (
    url,
    bodyHandler,
    errorHandler = undefined,
    signal = undefined
) => {
    async function getData() {
        const idToken = await getIdToken();
        if (idToken == null) {
            redirectToLogin();
            return;
        }

        const response = await fetch(url, {
            credentials: "include",
            headers: { Authorization: `Bearer ${idToken}` },
            signal: signal
        }).then(handleErrors);
        const body = await response.json();
        bodyHandler(body);
    }

    getData().catch((err) => {
        if (signal?.aborted) {
            return;
        }
        errorHandler && errorHandler(err);
    });
};

export const apiGetData = (url, errorHandler) => {
    async function getData() {
        const idToken = await getIdToken();
        if (idToken == null) {
            redirectToLogin();
            return;
        }

        const response = await fetch(url, {
            credentials: "include",
            headers: { Authorization: `Bearer ${idToken}` }
        }).then(handleErrors);
        return await response.json();
    }

    return getData().catch((err) => errorHandler && errorHandler(err));
};

export const apiGetStreaming = (
    url,
    chunkBodyHandler,
    errorHandler,
    onCompleted = () => {}
) => {
    async function getData() {
        const idToken = await getIdToken();
        if (idToken == null) {
            redirectToLogin();
            return;
        }

        const response = await fetch(url, {
            credentials: "include",
            headers: { Authorization: `Bearer ${idToken}` }
        }).then(handleErrors);

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        return new ReadableStream({
            start(controller) {
                return readChunk();

                function readChunk() {
                    return reader.read().then(({ done, value }) => {
                        if (done) {
                            onCompleted();
                            controller.close();
                            return;
                        }
                        controller.enqueue(value);
                        chunkBodyHandler(JSON.parse(decoder.decode(value)));
                        return readChunk();
                    });
                }
            }
        });
    }

    getData().catch((err) => errorHandler && errorHandler(err));
};

export const apiDelete = (
    url,
    csrfToken,
    okayHandler = () => message.success("Success"),
    errorHandler = (err) => message.error(err.message)
) => {
    async function sendData() {
        const idToken = await getIdToken();
        if (idToken == null) {
            redirectToLogin();
            return;
        }

        const response = await fetch(url, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "X-XSRF-TOKEN": csrfToken,
                Authorization: `Bearer ${idToken}`
            }
        }).then(handleErrors);
        okayHandler && okayHandler(response);
    }

    sendData().catch((err) => errorHandler && errorHandler(err));
};

export const apiPost = (
    url,
    body,
    csrfToken,
    okayHandler = () => message.success("Success"),
    errorHandler = (err) => message.error(err.message)
) => {
    async function sendData() {
        const idToken = await getIdToken();
        if (idToken == null) {
            redirectToLogin();
            return;
        }

        const response = await fetch(url, {
            method: "POST",
            credentials: "include",
            // redirect: 'follow',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-XSRF-TOKEN": csrfToken,
                Authorization: `Bearer ${idToken}`
            },
            body: body
        }).then(handleErrors);
        okayHandler && okayHandler(response);
    }

    sendData().catch((err) => errorHandler && errorHandler(err));
};

export const synchronousApiPut = async (
    url,
    body,
    csrfToken,
    okayHandler = () => message.success("Success")
) => {
    const idToken = await getIdToken();
    if (idToken == null) {
        redirectToLogin();
        return;
    }

    const response = await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-XSRF-TOKEN": csrfToken,
            Authorization: `Bearer ${idToken}`
        },
        body: body
    }).then(handleErrors);
    okayHandler && (await okayHandler(response));
};

export const apiPut = (
    url,
    body,
    csrfToken,
    okayHandler = () => message.success("Success"),
    errorHandler = (err) => message.error(err.message)
) => {
    synchronousApiPut(url, body, csrfToken, okayHandler).catch(
        (err) => errorHandler && errorHandler(err)
    );
};
