import doNowImage from "./mockCueImages/doNow.png";
import silentImage from "./mockCueImages/silent.png";
import timedImage from "./mockCueImages/timed.png";
import { Dropdown } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

export default function CueImageSelector({
    fullscreen,
    selectedKey,
    setSelectedKey
}) {
    const options = [
        {
            key: "doNow",
            name: "Do Now",
            image: doNowImage
        },
        {
            key: "silent",
            name: "Silent Task",
            image: silentImage
        },
        {
            key: "timed",
            name: "Timed Task",
            image: timedImage
        }
    ];

    const selectedOption =
        selectedKey && options.find((opt) => opt.key === selectedKey);

    if (fullscreen && !selectedOption) {
        return null;
    }

    return (
        <Dropdown
            overlayClassName={"cueImageDropdown"}
            trigger={"click"}
            menu={{
                onClick: (e) =>
                    setSelectedKey(!e?.key || e?.key === "null" ? null : e.key),
                items: [
                    {
                        key: "null",
                        label: (
                            <div
                                key={"cueNone"}
                                className={"cueImageOption"}
                                style={{
                                    paddingTop: "1rem",
                                    paddingBottom: "1rem"
                                }}
                            >
                                None
                            </div>
                        )
                    }
                ].concat(
                    options.map((opt, i) => ({
                        key: opt.key,
                        label: (
                            <div
                                key={"cueOpt" + i}
                                className={"cueImageOption"}
                            >
                                <img
                                    src={opt.image}
                                    alt={opt.name + " cue icon"}
                                />
                                {opt.name}
                            </div>
                        )
                    }))
                )
            }}
            placement="bottom"
            arrow
        >
            <div
                className={
                    "cueImageContainer " + (selectedOption ? "" : "empty")
                }
            >
                {selectedOption ? (
                    <>
                        <img
                            src={selectedOption?.image}
                            alt={selectedOption?.name + " cue icon"}
                        />
                    </>
                ) : (
                    <>
                        <PlusCircleOutlined /> CUE
                    </>
                )}
            </div>
        </Dropdown>
    );
}
