import React, { useContext, useState } from "react";
import { getStepIndex, TourContext } from "features/tour/Tour";
import { useAddClassMutation } from "features/classTags/classesSlice";
import { Button, Form, Input, message } from "antd";
import { ColourTags } from "features/classTags/ClassNotesModal";

function NewClassForm() {
    const [form] = Form.useForm();
    const tourContext = useContext(TourContext);
    const [addNewClass, { isLoading: isSavingClass }] = useAddClassMutation();
    const [popoverSelectedCol, setPopoverSelectedCol] = useState("red");
    return (
        <Form
            className={"newClassForm"}
            data-no-dnd={"true"}
            form={form}
            layout={"vertical"}
            requiredMark={false}
            onFinish={(val) => {
                if (
                    tourContext.isOpen &&
                    tourContext.currentStep === getStepIndex("classTagDetails")
                ) {
                    tourContext.nextStep();
                }
                addNewClass({
                    name: val.className,
                    colour: popoverSelectedCol,
                    notes: ""
                })
                    .unwrap()
                    .then(() => {
                        message.success("Class added");
                        form.resetFields();
                    });
            }}
        >
            <div className={"newClassForm__row"}>
                <Form.Item
                    name="className"
                    label={"Class Name"}
                    rules={[
                        {
                            required: true,
                            message: "Please enter a name"
                        }
                    ]}
                >
                    <Input placeholder={"e.g. 10 set 3, 11R1"} />
                </Form.Item>
                <Form.Item
                    name="colour"
                    initialValue={"red"}
                    rules={[
                        {
                            required: true,
                            message: "Please select a colour"
                        }
                    ]}
                >
                    <ColourTags
                        colour={popoverSelectedCol}
                        onClick={(selected) => {
                            setPopoverSelectedCol(selected);
                        }}
                    />
                </Form.Item>
            </div>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    disabled={isSavingClass}
                >
                    ADD NEW CLASS
                </Button>
            </Form.Item>
        </Form>
    );
}

export default NewClassForm;
