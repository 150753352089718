import React, { useContext, useEffect, useMemo, useState } from "react";
import { Alert, Button, Drawer, Input, Popconfirm, Space, Spin } from "antd";
import ReactQuill from "react-quill";
import Icon, {
    CheckCircleOutlined,
    CheckCircleTwoTone,
    CloseOutlined,
    EditOutlined
} from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import SaveItem from "commonComponents/SaveItem";
import { tagColoursContinuous } from "helpers/tagHelpers";
import { getStepIndex, TourContext } from "features/tour/Tour";
import EmptyCircle from "assets/icons/EmptyCircle";
import {
    useGetClassesQuery,
    useUpdateClassMutation
} from "features/classTags/classesSlice";
import { useGetSavedStartersQuery } from "features/timetable/savesSlice";
import { createSelector } from "@reduxjs/toolkit";

const selectClassForId = createSelector(
    (res) => res.data,
    (res, classId) => classId,
    (classes, classId) =>
        (classId &&
            classId !== "null" &&
            classes?.find((c) => c.id === classId)) ||
        undefined
);

export function ClassTitle({ classId, small = false }) {
    const { class: classData } = useGetClassesQuery(undefined, {
        selectFromResult: (result) => ({
            class: selectClassForId(result, classId)
        })
    });
    const [updateClass, { isLoading: submitting }] = useUpdateClassMutation();
    const name = classData?.name;
    const [editing, setEditing] = useState(false);
    const [nameEditState, setNameEditState] = useState(name);

    useEffect(() => setNameEditState(name), [name]);

    if (!name) {
        return null;
    }
    if (editing) {
        return (
            <Space direction={"horizontal"}>
                <Input
                    value={nameEditState}
                    onChange={(e) => setNameEditState(e.target.value)}
                />
                <Button
                    type={"primary"}
                    size={small ? "small" : "medium"}
                    onClick={() =>
                        updateClass({
                            id: classId,
                            name: nameEditState,
                            colour: classData?.colour,
                            notes: classData?.notes
                        })
                            .unwrap()
                            .then(() => setEditing(false))
                    }
                >
                    {submitting ? <Spin /> : "Save"}
                </Button>
                <Button
                    type={"default"}
                    size={small ? "small" : "medium"}
                    onClick={() => {
                        setEditing(false);
                        setNameEditState(name);
                    }}
                >
                    Cancel
                </Button>
            </Space>
        );
    }

    if (small) {
        return (
            <p>
                {name}{" "}
                <Button
                    type={"text"}
                    icon={<EditOutlined />}
                    style={{ color: "#9d9d9d" }}
                    onClick={() => setEditing(true)}
                    size={"small"}
                />
            </p>
        );
    }

    return (
        <Title level={2}>
            {name}{" "}
            <Button
                type={"text"}
                icon={<EditOutlined />}
                style={{ color: "#9d9d9d" }}
                onClick={() => setEditing(true)}
            />
        </Title>
    );
}

export function ColourTags({
    colour,
    onClick,
    includeNone = false,
    options = undefined
}) {
    const colours = (includeNone ? [null] : []).concat(
        options || tagColoursContinuous
    );
    const tags = colours.map((col) =>
        colour !== undefined && col === colour ? (
            <CheckCircleOutlined
                key={col}
                className={"colourSelector clickable " + col}
            />
        ) : (
            <Icon
                component={EmptyCircle}
                key={col}
                className={"colourSelector clickable " + col}
                onClick={() => onClick(col)}
            />
        )
    );
    return <div className={"colourSelectorContainer"}>{tags}</div>;
}

const formattingModules = {
    toolbar: [
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "strike"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" }
        ],
        ["link"],
        ["clean"]
    ]
};

const EMPTY = [];

function ClassNotesModal({
    isModalVisible,
    setIsModalVisible,
    classId,
    onLoadStarter = () => {}
}) {
    const { class: classData, isFetching: loading } = useGetClassesQuery(
        undefined,
        {
            selectFromResult: (result) => ({
                class: selectClassForId(result, classId),
                isFetching: result.isFetching
            })
        }
    );
    const [updateClass] = useUpdateClassMutation();

    const selectSavesForClass = useMemo(() => {
        return createSelector(
            (res) => res.data,
            (res, classId) => classId,
            (savesTable, classId) =>
                (classId &&
                    Object.values(savesTable || {})
                        .flat()
                        .filter((save) => save?.classId === classId)) ||
                EMPTY
        );
    }, []);
    const { matchingSaves: savesForClass } = useGetSavedStartersQuery(
        undefined,
        {
            selectFromResult: (result) => ({
                matchingSaves: selectSavesForClass(result, classId)
            })
        }
    );

    const [quillValue, setQuillValue] = useState(classData?.notes);
    const [tagCol, setTagCol] = useState(classData?.colour);
    const [unsaved, setUnsaved] = useState(false);
    const tourContext = useContext(TourContext);

    useEffect(() => {
        setQuillValue(classData?.notes);
        setTagCol(classData?.colour);
    }, [classData, classId]);

    useEffect(() => {
        if (isModalVisible) {
            setUnsaved(false);
        }
    }, [isModalVisible]);

    async function updateSave() {
        await updateClass({
            id: classId,
            name: classData.name,
            colour: tagCol,
            notes: quillValue
        });
        setUnsaved(false);
    }

    const savesColumn = (
        <>
            <div
                className={
                    "droppableArea day-dragable-container notesModalColumn"
                }
            >
                {savesForClass.map((save, i) => (
                    <SaveItem
                        key={save.id}
                        save={save}
                        i={i}
                        onLoadStarter={onLoadStarter}
                        hasFunctions={false}
                    />
                ))}
            </div>
        </>
    );

    useEffect(() => {
        if (!tourContext.containsModalClose("classNotes")) {
            tourContext.addTourModalClose("classNotes", () =>
                setIsModalVisible(false)
            );
        }
    }, [tourContext, setIsModalVisible]);

    return (
        <Drawer
            title={<div className="dash-set-title">Class Notes</div>}
            placement={"right"}
            className={"classNotes " + (tourContext.isOpen && "tourOpen")}
            open={isModalVisible}
            closable={false}
            destroyOnClose={true}
            extra={
                <Popconfirm
                    title={
                        "You have unsaved changes. Are you sure you want to exit?"
                    }
                    okText={"Yes"}
                    disabled={!unsaved}
                    onConfirm={() => setIsModalVisible(false)}
                >
                    <Button
                        type={"text"}
                        onClick={() => {
                            if (
                                tourContext.isOpen &&
                                tourContext.currentStep ===
                                    getStepIndex("classModal")
                            ) {
                                tourContext.nextStep();
                                setIsModalVisible(false);
                            }
                            if (!unsaved) {
                                setIsModalVisible(false);
                            }
                        }}
                    >
                        <CloseOutlined />
                    </Button>
                </Popconfirm>
            }
            maskClosable={false}
            onCancel={() => {
                setIsModalVisible(false);
            }}
            width={"min(100vw, 60rem)"}
            footer={null}
        >
            <div className={"titleBar"}>
                {loading ? <Spin /> : <ClassTitle classId={classId} />}
                <div className={"functionBar"}>
                    <div className={"tagColourChangerContainer"}>
                        <p>Tag Colour:</p>
                        <ColourTags
                            colour={tagCol}
                            onClick={(selected) => {
                                setTagCol(selected);
                                setUnsaved(true);
                            }}
                        />
                    </div>
                    <div>
                        {unsaved ? (
                            <Alert
                                type={"warning"}
                                message={"There are unsaved edits"}
                                description={<Button>Save!</Button>}
                                onClick={updateSave}
                            />
                        ) : (
                            <p>
                                <CheckCircleTwoTone
                                    twoToneColor="#52c41a"
                                    className={"success"}
                                    size={"small"}
                                />
                                All Changes Saved
                            </p>
                        )}
                    </div>
                </div>
            </div>
            <div className={"mainBar"}>
                <div className={"notesContainer"}>
                    <div className={"sideTitle"}>Notes</div>
                    <ReactQuill
                        theme="snow"
                        value={quillValue}
                        onChange={(val, delta, source) => {
                            setQuillValue(val);
                            if (source === "user") {
                                setUnsaved(true);
                            }
                        }}
                        modules={formattingModules}
                    />
                </div>
                <div className={"starterContainer"}>
                    <div className={"sideTitle"}>Saves</div>
                    <div className={"saves timetableColumn"}>{savesColumn}</div>
                </div>
            </div>
        </Drawer>
    );
}

export default ClassNotesModal;
