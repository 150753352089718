import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import RoundedButton from "commonComponents/RoundedButton";
import { QuestionGrid } from "./components/QuestionGrid";
import LessonContext, {
    formatStarterStateForSave,
    LessonContextProvider
} from "./context/LessonContext";
import Countdown from "commonComponents/Countdown";
import { createSelector } from "@reduxjs/toolkit";
import { useGetUserQuery } from "features/account/accountSlice";
import LessonFunctionButtons from "./components/LessonFunctionButtons";
import { useUpdateSavedStarterMutation } from "features/timetable/savesSlice";
import debounce from "lodash.debounce";

function CloseGapStarterActivity({ saveable = false, ...props }) {
    const [drawerSaveId, setDrawerSaveId] = useState(null);
    return (
        <LessonContextProvider saveable={saveable} loadId={drawerSaveId}>
            <CloseGapStarter
                drawerSaveId={drawerSaveId}
                setDrawerSaveId={setDrawerSaveId}
                {...props}
            />
        </LessonContextProvider>
    );
}

function CloseGapStarter({
    drawerSaveId,
    setDrawerSaveId,
    fullscreen,
    makeFullscreen,
    onBack,
    name = null,
    initialTopicChoices = [],
    initialGridWidth
}) {
    const selectDefaultCountdownForUser = useMemo(() => {
        return createSelector(
            (res) => res.data,
            (user) => user?.default_countdown
        );
    }, []);
    const { default_countdown: defaultCountdown } = useGetUserQuery(undefined, {
        selectFromResult: (result) => ({
            default_countdown: selectDefaultCountdownForUser(result)
        })
    });
    const {
        starterChoicesState: [questionTopicChoices, setQuestionTopicChoices],
        starterGridDimensionsState: [
            { width: gridWidth, height: gridHeight },
            setStarterGridDimensions
        ],
        staleEditsState: [staleEdits, setStaleEdits],
        loadNameState: [loadName],
        questionFontSizeState: [questionFontSize],
        senBackgroundState: [senBackground],
        showStarterAnswersState: [showAnswers, setShowAnswers],
        starterCardRefs: cardRefs
    } = useContext(LessonContext);

    useEffect(() => {
        if (initialGridWidth) {
            setStarterGridDimensions((prev) => ({
                ...prev,
                width: initialGridWidth
            }));
            setStaleEdits(true);
        }
    }, [initialGridWidth, setStaleEdits, setStarterGridDimensions]);

    useEffect(() => {
        if (initialTopicChoices) {
            setQuestionTopicChoices([
                ...initialTopicChoices.map((tc) => ({ ...tc }))
            ]);
            setStaleEdits(true);
        }
    }, [initialTopicChoices, setQuestionTopicChoices, setStaleEdits]);

    const [updateSavedStarter] = useUpdateSavedStarterMutation();
    const updateStarter = useCallback(
        async (saveData) => {
            if (!drawerSaveId) {
                console.error("Attempting to update a new save");
                return;
            }
            const data = formatStarterStateForSave(saveData, loadName);
            updateSavedStarter({ id: drawerSaveId, data })
                .unwrap()
                .then(() => setStaleEdits(false));
        },
        [drawerSaveId, loadName, updateSavedStarter, setStaleEdits]
    );
    const debouncedUpdateStarter = useMemo(
        () =>
            debounce((saveData) => updateStarter(saveData), 5000, {
                leading: true,
                trailing: true
            }),
        [updateStarter]
    );
    useEffect(() => {
        if (drawerSaveId && staleEdits) {
            debouncedUpdateStarter({
                title: "Closing the Gap:" + name,
                plenaryTitle: null,
                questionTopicChoices: questionTopicChoices,
                plenaryQuestionTopicChoices: [],
                starterGridWidth: gridWidth,
                starterGridHeight: gridHeight,
                hasPlenary: true,
                hasRecallTags: false,
                plenaryGridWidth: 3,
                plenaryGridHeight: 1,
                senBackground: senBackground,
                fontSize: questionFontSize
            });
        }
    }, [
        staleEdits,
        debouncedUpdateStarter,
        senBackground,
        questionFontSize,
        drawerSaveId,
        name,
        questionTopicChoices,
        gridWidth,
        gridHeight
    ]);

    const [questionComponent0, setQuestionComponent0] = useState(null);
    const [questionComponent1, setQuestionComponent1] = useState(null);
    const [questionComponent2, setQuestionComponent2] = useState(null);
    const [questionComponent3, setQuestionComponent3] = useState(null);
    const [questionComponent4, setQuestionComponent4] = useState(null);
    const [questionComponent5, setQuestionComponent5] = useState(null);
    const questionComponents = useMemo(
        () => [
            questionComponent0,
            questionComponent1,
            questionComponent2,
            questionComponent3,
            questionComponent4,
            questionComponent5
        ],
        [
            questionComponent0,
            questionComponent1,
            questionComponent2,
            questionComponent3,
            questionComponent4,
            questionComponent5
        ]
    );
    const setQuestionComponents = useMemo(
        () => [
            setQuestionComponent0,
            setQuestionComponent1,
            setQuestionComponent2,
            setQuestionComponent3,
            setQuestionComponent4,
            setQuestionComponent5
        ],
        [
            setQuestionComponent0,
            setQuestionComponent1,
            setQuestionComponent2,
            setQuestionComponent3,
            setQuestionComponent4,
            setQuestionComponent5
        ]
    );
    const [starterCalculatorAllowedValues, setStarterCalculatorAllowedValues] =
        useState([]);

    return (
        <div className="close-gap-starter">
            <div className={"close-gap-starter__header"}>
                <RoundedButton
                    onClick={onBack}
                    style={{ minWidth: "fit-content" }}
                >
                    {"<"} Back
                </RoundedButton>
                <h1>
                    <span className={"accent"}>Closing the Gap:</span> {name}
                </h1>
                <div className="cntdwn-timer">
                    <Countdown
                        initialTimeMinutes={
                            defaultCountdown ? defaultCountdown / 60000 : 6
                        }
                        startCallback={() => makeFullscreen(true)}
                        hasDefaultCheckbox
                    />
                </div>
            </div>
            <div className="close-gap-starter__grid">
                <QuestionGrid
                    cardRefs={cardRefs}
                    height={gridHeight}
                    width={gridWidth}
                    isStarter={true}
                    questionFont={questionFontSize}
                    senBg={senBackground}
                    questionTopicChoices={questionTopicChoices}
                    setQuestionTopicChoices={setQuestionTopicChoices}
                    calculatorAllowedValues={starterCalculatorAllowedValues}
                    setCalculatorAllowedValues={
                        setStarterCalculatorAllowedValues
                    }
                    showAnswers={showAnswers}
                    setShowAnswers={setShowAnswers}
                    questionComponents={questionComponents}
                    setQuestionComponents={setQuestionComponents}
                    fullscreen={fullscreen}
                    setStaleEdits={setStaleEdits}
                />
            </div>
            <div className="close-gap-starter__controls">
                <LessonFunctionButtons
                    fullscreen={fullscreen}
                    makeFullscreen={makeFullscreen}
                    hidePrint
                    hideLink
                    hideExtension
                    redirectOnSave={false}
                    onSave={(id) => setDrawerSaveId(id)}
                />
            </div>
        </div>
    );
}

export default CloseGapStarterActivity;
