import { apiSlice, mutationHeaders } from "helpers/apiSlice";

export const extendedApiSlice = apiSlice
    .enhanceEndpoints({ addTagTypes: ["Classes"] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getClasses: builder.query({
                query: () => "/classes",
                // The `LIST` id is a "virtual id" that allows invalidation of this query specifically when a new class is added
                providesTags: (result) => [
                    ...(result?.map(({ id }) => ({ type: "Classes", id })) ||
                        []),
                    { type: "Classes", id: "LIST" }
                ]
            }),
            addClass: builder.mutation({
                query: (body) => ({
                    url: "/add-class",
                    method: "POST",
                    headers: mutationHeaders,
                    body: JSON.stringify(body)
                }),
                invalidatesTags: [{ type: "Classes", id: "LIST" }]
            }),
            updateClass: builder.mutation({
                query: ({ id: classId, ...body }) => ({
                    url: `/update-class?id=${encodeURIComponent(classId)}`,
                    method: "PUT",
                    body: JSON.stringify(body),
                    headers: mutationHeaders
                }),
                invalidatesTags: (result, error, { id }) => [
                    { type: "Classes", id }
                ]
            }),
            deleteClass: builder.mutation({
                query: (classId) => ({
                    url: `/class?id=${encodeURIComponent(classId)}`,
                    method: "DELETE"
                }),
                invalidatesTags: (result, error, id) => [
                    { type: "Classes", id },
                    { type: "Starters", id: "LIST" }
                ]
            })
        })
    });

export const {
    useGetClassesQuery,
    useAddClassMutation,
    useUpdateClassMutation,
    useDeleteClassMutation
} = extendedApiSlice;
